import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UploadService } from "services";
import { generateImageSrc } from "utils/function";
import "./style.css";

export default function Editor(props) {
  const { data = "", setData = () => {} } = props;

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            UploadService.upload(body)
              .then((res) => {
                resolve({
                  default: res?.data?.data
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className="App">
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin]
        }}
        editor={ClassicEditor}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "height",
              "300px",
              editor.editing.view.document.getRoot()
            );
          });
        }}
        data={data}
        onChange={(event, editor) => {
          const data = editor.getData();
          setData((prevState) => ({ ...prevState, body: data }));
        }}
      />
    </div>
  );
}
