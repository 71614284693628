import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManageCouponService } from "services";
import { withToast, withParam } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled
} from "@mui/material";
import { formatDate } from "utils/function";

import ListIssuedMember from "./components/ListIssuedMember";
import ListManualIssuingMember from "./components/ListManualIssuingMember";
import { BENEFIT_TYPE } from "./../constants";

import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 43,
    padding: 5,
    border: "1px solid rgb(110, 118, 118)"
  }
}));

const dateOfUse = (start, end) => {
  if (!start || !end) {
    return "무기한";
  }

  return `${formatDate(start, "YYYY.MM.DD")} - ${formatDate(
    end,
    "YYYY.MM.DD"
  )}`;
};

function CouponDetail({ params, showToast }) {
  const [loading, setLoading] = useState(true);
  const [detailCoupon, setDetailCoupon] = useState({});
  const [timeReCall, setTimeReCall] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await ManageCouponService.getCoupon(params.id);
        if (res?.data?.statusCode === 200) {
          setDetailCoupon(res?.data?.data ?? {});
          setLoading(false);
        } else {
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, res?.data?.message ?? "Error");
        }
      } catch (error) {
        setLoading(false);
        const message = error?.response
          ? error.response?.statusText
          : error?.statusText ?? "";
        showToast(TOAST_TYPE.ERROR, message);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout header="쿠폰 상세보기">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column">
          <Grid item>
            <Grid container flexDirection="column">
              <Grid
                item
                style={{
                  backgroundColor: "#1565c0",
                  color: "#fff",
                  height: "40px",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "10px"
                }}
              >
                쿠폰정보
              </Grid>
              <TableContainer>
                <Table>
                  <TableBody>
                    {/* <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        쿠폰번호:
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "35%" }}>
                        {detailCoupon.id ? detailCoupon.id : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: "25%", fontWeight: "bold" }}
                      >
                        쿠폰 설명:
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "35%" }}>
                        {detailCoupon.description
                          ? detailCoupon.description
                          : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        쿠폰명:
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "35%" }}>
                        {detailCoupon.name ? detailCoupon.name : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: "25%", fontWeight: "bold" }}
                      >
                        사용기한:
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "35%" }}>
                        {dateOfUse(detailCoupon.startUse, detailCoupon.endUse)}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        발급구분:
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "35%" }}>
                        {detailCoupon.issuanceMethod
                          ? detailCoupon.issuanceMethod
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: "25%", fontWeight: "bold" }}
                        rowSpan={2}
                      >
                        생성일자 전체:
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ width: "35%" }}
                        rowSpan={2}
                      >
                        {detailCoupon.createdAt
                          ? formatDate(detailCoupon.createdAt)
                          : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        혜택:
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "35%" }}>
                        {detailCoupon.benifitType
                          ? BENEFIT_TYPE[detailCoupon.benifitType]
                          : ""}
                      </StyledTableCell>
                    </TableRow> */}
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        쿠폰 번호
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {detailCoupon.id ? detailCoupon.id : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        쿠폰명
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "20%" }}>
                        {detailCoupon.name ? detailCoupon.name : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        사용기한
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "20%" }}>
                        {dateOfUse(detailCoupon.start, detailCoupon.end)}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        혜택 구분
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "15%" }}>
                        {detailCoupon.benefit
                          ? BENEFIT_TYPE[detailCoupon.benefit]
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        쿠폰 설명
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "20%" }}>
                        {detailCoupon.description
                          ? detailCoupon.description
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ width: "15%", fontWeight: "bold" }}
                      >
                        쿠폰 발급 일자
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{ width: "20%" }}>
                        {detailCoupon.createdAt
                          ? formatDate(detailCoupon.createdAt)
                          : ""}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container flexDirection="column">
              <Grid
                item
                style={{
                  backgroundColor: "#1565c0",
                  color: "#fff",
                  height: "40px",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "10px"
                }}
              >
                발급 받은 회원 검색
              </Grid>
              <Grid item>
                <ListIssuedMember
                  couponId={params.id}
                  timeReCall={timeReCall}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item sx={{ mt: 2 }}>
            <Grid container flexDirection="column">
              <Grid
                item
                style={{
                  backgroundColor: "#1565c0",
                  color: "#fff",
                  height: "40px",
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "10px"
                }}
              >
                수동 발급 회원 선택
              </Grid>
              <Grid item>
                <ListManualIssuingMember
                  couponId={params.id}
                  setTimeReCall={setTimeReCall}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      )}
    </Layout>
  );
}

export default withParam(withToast(CouponDetail));
