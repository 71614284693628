import React from "react";
import withToast from "HOC/withToast";
import Layout from "components/Layout";
import TabDetailedCategory from "./components/TabDetailedCategory";
import TabVehicleManagement from "./components/TabVehicleManagement";
import Tabs from "./components/Tabs";

function Category() {
  return (
    <Layout header="패턴관리 - 패턴 카테고리">
      <Tabs
        tab1Content={<TabVehicleManagement />}
        tab2Content={<TabDetailedCategory />}
      />
    </Layout>
  );
}

export default withToast(Category);
