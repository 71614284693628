import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManageEmailService, UploadService } from "services";
import { withToast, withParam, withState } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Editor from "./components/Editor";
import { formatDate } from "utils/function";
import EditorReply from "./components/EditorReply";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "0px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function ReplyReceivedEmail({ params, showToast, routeState }) {
  const [email, setEmail] = useState({
    title: "",
    content: ""
  });
  const [emailDetail, setEmailDetail] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await ManageEmailService.getEmailContactUsById(params.id);
        // console.log("res", res);
        if (res?.data?.statusCode === 200) {
          // const listEmail = res?.data?.data ?? [];
          // const email = listEmail.find((item) => item.id === Number(params.id));
          const email = res?.data?.data ?? {};
          setEmailDetail(email ?? {});
          // setSelectedFile(email?.file ?? "");
          setLoading(false);
        } else {
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, res?.data?.message ?? "Error");
        }
      } catch (error) {
        setLoading(false);
        const message = error?.response
          ? error.response?.statusText
          : error?.statusText ?? "";
        showToast(TOAST_TYPE.ERROR, message);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReply = async () => {
    try {
      const response = await ManageEmailService.sendNotification({
        // userIds: [emailDetail?.userId ?? ""],
        emails: [emailDetail?.email ?? ""],
        content: email?.content ?? "",
        title: email?.title ?? "",
        sender: emailDetail?.email ?? "",
        file: selectedFile,
        contactId: +Number(params.id)
      });
      if (response?.data?.statusCode === 200) {
        showToast(TOAST_TYPE.SUCCESS, "Reply success");
        navigate(`/e-mail-management/received-emails/detail/${params.id}`);
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message?.[0] ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? error.message ?? "System Error";
      if (Array.isArray(errorMessage)) {
        showToast(TOAST_TYPE.ERROR, errorMessage?.[0]);
      } else {
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
  };

  const handleCancel = () => {
    navigate(`/e-mail-management/received-emails/detail/${params.id}`);
  };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    let fileUrl = "";
    try {
      const form = new FormData();
      form.append("file", e.target.files[0]);
      const response = await UploadService.upload(form);
      if (response?.data?.statusCode === 200) {
        fileUrl = response?.data?.data ?? "";
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setSelectedFile(fileUrl);
  };

  const getFileName = (url) => {
    const parts = url.split("/");

    const fileName = parts[parts.length - 1];

    const fileParts = fileName.split("-");
    const actualFileName = fileParts[fileParts.length - 1];

    return actualFileName ? decodeURIComponent(actualFileName) : "";
  };

  return (
    <Layout header="받은 이메일 관리">
      <Grid container flexDirection="column">
        <Grid item>
          <Box
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              <Typography sx={{ flexBasis: "20%" }}>제목</Typography>
              <StyledTextField
                fullWidth
                height="0px"
                value={email?.title ? email.title : ""}
                placeholder="제목을 입력해주세요"
                onChange={(e) => {
                  setEmail({ ...email, title: e.target.value });
                }}
                sx={{ flexBasis: "80%" }}
              />
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              <Typography sx={{ flexBasis: "20%" }}>발신자 이메일</Typography>
              <Typography>{emailDetail?.email ?? ""}</Typography>
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              <Typography sx={{ flexBasis: "20%" }}>수신 날짜</Typography>
              <Typography>
                {emailDetail.createdAt ? formatDate(emailDetail.createdAt) : ""}
              </Typography>
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              <Typography>내용</Typography>
            </Grid>
            <EditorReply data={email?.content} setData={setEmail} />
            <Box
              style={{
                width: "100%",
                height: "150px",
                border: "1px solid #c4c4c4",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "10px"
              }}
            >
              {selectedFile ? (
                <img
                  src={selectedFile}
                  height="140px"
                  alt=""
                  style={{
                    objectFit: "cover"
                    // margin: "auto",
                  }}
                />
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Grid container alignItems="center" sx={{ position: "relative" }}>
            <Button
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px",

                width: 90,
                marginRight: 5
              }}
            >
              첨부파일
              <input
                type="file"
                // hidden
                onChange={(e) => {
                  handleSelectFile(e);
                  e.target.value = null;
                }}
                style={{
                  position: "absolute",
                  left: "0px",
                  width: "90px",
                  height: "36px",
                  opacity: 0,
                  cursor: "pointer"
                }}
              />
            </Button>
            <StyledTextField
              width="150px"
              height="19px"
              size="small"
              value={selectedFile ? getFileName(selectedFile) : "파일 선택"}
              disabled
            />
            <input
              type="file"
              onChange={(e) => handleSelectFile(e)}
              style={{
                position: "absolute",
                left: "95px",
                width: "150px",
                opacity: 0
              }}
            />
          </Grid>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Grid container alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px",
                marginRight: 5
              }}
              onClick={handleReply}
            >
              전송
            </Button>
            {/* <Button
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px"
              }}
              onClick={handleCancel}
            >
              삭제
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default withState(withParam(withToast(ReplyReceivedEmail)));
