import React, { useEffect, useMemo, useState } from "react";
import Layout from "components/Layout";
import {
  // ManageUltrafitShopService,
  // ManagePatternService,
  // UploadService,
  ManageEmailService
} from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
// import LoadingPage from "components/LoadingPage";
import LoadingOneLine from "components/LoadingOneLine";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { useForm } from "react-hook-form";
import {
  Grid,
  // TextField,
  Typography,
  // Box,
  styled,
  Button,
  Select,
  MenuItem,
  Box
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
// import { generateImageSrc, listGroupBy, sortList } from "utils/function";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
import { useNavigate } from "react-router-dom";
import AutomaticTableData from "./AutomaticTableData";
import { useGlobalStore } from "hooks";
import {
  getListEmailAuto,
  resetEmailsAuto,
  updateEmailState
} from "redux/actions/email";
import Popup from "./Popup";

const listState = [
  {
    value: "automatic",
    text: "자동발송"
  },
  {
    value: "stop",
    text: "발송 중지"
  }
];

const listConditionAutoSend = [
  { value: "sign_up", text: "회원 가입 시" },
  { value: "disable_user", text: "휴면 회원 전환 시" },
  { value: "suspension", text: "회원 정지 시" },
  { value: "delivery", text: "배송 시작 시" }
];

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  border: "1px solid #c4c4c4",
  color: "#000"
});

const StyledSelect = styled(Select)(({ width }) => ({
  borderRadius: "0",
  height: "27px",
  width: width ? width : "200px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

const conditionListEnToKo = (condition) => {
  switch (condition) {
    case "welcome":
      return "회원가입축하";
    case "registration":
      return "회원가입";
    case "sponsor_ads":
      return "Sponsor AD 구매";
    case "shopping_mall":
      return "Shop 구매결제";
    case "reset_password":
      return "비밀번호찾기";
    case "withdrawal":
      return "Profit출금";
    case "after_withdrawal":
      return "회원탈퇴";
    case "order_delivery":
      return "배송메일";
    case "credit_refund":
      return "Credit 환불";
    case "confirm_payment":
      return "Credit Pack구매";
    case "order_canncelled":
      return "주문취소메일";
    case "edit_plot":
      return "Edit&Plot 구매";
    default:
      return "";
  }
};

function AutomaticList({ showToast }) {
  const [state, dispatch] = useGlobalStore();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [imageShowed, setImageShowed] = useState("");

  const navigate = useNavigate();

  const {
    emailsAuto = [],
    loadingAuto = true,
    countAuto = 0,
    errorAuto = null
  } = useMemo(() => state?.email, [state]);

  const handleCreateCondition = () => {
    let condition = { sendType: "automatic" };
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: page + 1,
        limit: rowsPerPage
      };
    }

    return condition;
  };

  useEffect(() => {
    dispatch(getListEmailAuto(handleCreateCondition()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!loadingAuto && errorAuto) {
      showToast(TOAST_TYPE.ERROR, errorAuto);
    }
  }, [errorAuto, loadingAuto, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetEmailsAuto());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleEditAutomaticEmail = (id) => {
  //   navigate(`/e-mail-management/list-emails/edit-email-automatic-send/${id}`);
  // };

  const handleChangeState = async (e, email) => {
    try {
      const response = await ManageEmailService.updateEmail(email.id, {
        ...email,
        type: e.target.value
      });
      if (response?.data?.statusCode === 200) {
        // dispatch(updateEmailState({ id: id, type: e.target.value }));
        dispatch(getListEmailAuto(handleCreateCondition()));
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleAppear = async (email, appear = "automatic") => {
    try {
      const response = await ManageEmailService.updateEmail(email.id, {
        ...email,
        sendType: appear === "stopped" ? "automatic" : "stopped"
      });
      if (response?.data?.statusCode === 200) {
        // dispatch(updateEmailState({ id: id, type: e.target.value }));
        dispatch(getListEmailAuto(handleCreateCondition()));
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const getFileName = (url) => {
    const parts = url.split("/");

    const fileName = parts[parts.length - 1];

    const fileParts = fileName.split("-");
    const actualFileName = fileParts[fileParts.length - 1];

    return actualFileName ? decodeURIComponent(actualFileName) : "";
  };

  const renderAutomaticTable = () => {
    // const sizeWidth = ["15%", "45%", "10%", "15%", "15%"];
    const sizeWidth = ["30%", "30%", "60%"];

    // const headersTitle = ["제목", "내용", "사용", "조건", "내용 수정"];
    const headersTitle = ["제목", "조건", "메일 플랫폼"];

    const tableData = emailsAuto.map((email) => {
      const file = email?.files ?? "";
      const fileName = getFileName(file);
      return {
        ...email,
        name: email?.title ?? "",
        content: (
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1
            }}
            variant="body1"
            component={"span"}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: email?.body ? email.body : ""
              }}
            />
          </Typography>
        ),
        state: (
          <StyledSelect
            MenuProps={{
              disableScrollLock: true
            }}
            width="120px"
            value={email?.type ?? ""}
            onChange={(e) => handleChangeState(e, email)}
          >
            {listConditionAutoSend.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </StyledSelect>
        ),
        condition: conditionListEnToKo(email?.type ?? ""),
        file: (
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenModal(true);
              setImageShowed(file);
            }}
          >
            {fileName}
          </Typography>
        ),
        edit: (
          <StyledButton
            variant="outlined"
            // onClick={() => handleEditAutomaticEmail(email.id)}
          >
            수정
          </StyledButton>
        ),
        use: (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CheckBoxOutlinedIcon
              color={email.sendType !== "stopped" ? "primary" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleAppear(email, email.sendType)}
            />
            <DisabledByDefaultOutlinedIcon
              sx={
                email.sendType === "stopped"
                  ? { color: "#ff5454", ml: 1 }
                  : { ml: 1 }
              }
              style={{ cursor: "pointer" }}
              onClick={() => handleAppear(email, email.sendType)}
            />
          </Box>
        )
      };
    });

    return (
      <AutomaticTableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={countAuto}
      />
    );
  };

  return (
    <React.Fragment>
      {loadingAuto ? (
        <LoadingOneLine />
      ) : (
        <>
          {renderAutomaticTable()}
          {openModal && imageShowed ? (
            <Popup
              open={openModal}
              image={imageShowed}
              setOpen={setOpenModal}
              button="닫기"
              onClick={() => setOpenModal(false)}
            />
          ) : null}
        </>
      )}
    </React.Fragment>
  );
}

export default withToast(AutomaticList);
