import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  styled,
  Button,
  FormControlLabel
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/TableData";
import { generateImageSrc } from "utils/function";
import { useGlobalStore } from "hooks";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageBannerService } from "services";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import Popup2Action from "./components/Popup2Action";
import { useNavigate } from "react-router-dom";
import {
  deleteBanners,
  getListBanner,
  resetBanners,
  updateBannerStatus
} from "redux/actions/banner";
import { BANNER_CLASSIFICATION } from "../constants";

const listSelectName = [
  {
    value: "banner name",
    text: "배너명"
  }
];

const listSelectCategory = [
  {
    value: "category",
    text: "대분류"
  }
];

const listSelectMiddleCategory = [
  {
    value: "middle category",
    text: "중분류"
  }
];

const listSelectSubcategory = [
  {
    value: "subcategory",
    text: "세분류"
  }
];

const listSelectSubdivision = [
  {
    value: "Subdivision",
    text: "소분류"
  }
];

const listSelectRegistrationDate = [
  {
    value: "registration date",
    text: "등록일"
  }
];

const listSelectSearch = [
  {
    value: "banner name",
    text: "배너명",
    id: 1
  },
  {
    value: "classification",
    text: "분류",
    id: 2
  },
  {
    value: "banner being applied",
    text: "적용 중인 배너",
    id: 3
  }
];

const StyledSelect = styled(Select)(({ width, height }) => ({
  borderRadius: "0",
  height: height ? height : "33px",
  width: width ? width : "200px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "150px",
    height: height ? height : "25px",
    padding: "4px 10px",
    fontSize: "0.9rem"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function ListBanner({ showToast }) {
  const [listChecked, setListChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState({
    search: "",
    typeSearch: 1
  });

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [state, dispatch] = useGlobalStore();

  const navigate = useNavigate();

  const {
    banners = [],
    loading = true,
    error = null
  } = useMemo(() => state?.banner, [state]);

  useEffect(() => {
    dispatch(getListBanner({ type: "banner" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetBanners());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectBanner = (e, bid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, bid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== bid);
      setListChecked(newListChecked);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      banners.forEach((transaction) => {
        newListChecked.push(transaction.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await dispatch(
      getListBanner({
        ...searchTerms,
        type: "banner"
      })
    );
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ search: "", typeSearch: 1 });
    await dispatch(getListBanner({ type: "banner" }));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  // const handleGetActiveMember = async () => {
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: true });
  //   await dispatch(getListMember({ status: "active" }));
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: false });
  // };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "typeSearch") {
      setSearchTerms((prevState) => ({ ...prevState, typeSearch: value }));
    }
  };

  const handleDelete = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No banner selected");
      return;
    }
    try {
      const response = await ManageBannerService.deleteBanner({
        ids: [...listChecked]
      });
      if (response.status === 201) {
        dispatch(deleteBanners(listChecked, { type: "banner" }));
        showToast(TOAST_TYPE.SUCCESS, "Delete success");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setOpenModalDelete(false);
    setListChecked([]);
    setIsCheckAll(false);
  };

  const handleAdd = () => {
    navigate("/popup-banner-management/banner-list/add");
  };

  const handleAppear = async (id, appear = true) => {
    const body = {
      status: appear ? "enable" : "disable"
    };
    try {
      const response = await ManageBannerService.updateBanner(id, {
        ...body
      });
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateBannerStatus(
            { id: id, status: appear ? "enable" : "disable" },
            { type: "banner" }
          )
        );
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleEditBanner = (id) => {
    navigate(`/popup-banner-management/banner-list/edit/${id}`);
  };

  // const renderTable = () => {
  //   const sizeWidth = ["15%", "5%", "12%", "18%", "10%", "30%", "10%"];

  //   const headersTitle = [
  //     // <Checkbox
  //     //   checked={isCheckAll}
  //     //   onChange={(e) => handleSelectAll(e)}
  //     //   sx={{
  //     //     "& .MuiSvgIcon-root": {
  //     //       color: "#fff"
  //     //     }
  //     //   }}
  //     // />,
  //     <FormControlLabel
  //       label="전체선택"
  //       labelPlacement="start"
  //       control={
  //         <Checkbox
  //           checked={isCheckAll}
  //           onChange={(e) => handleSelectAll(e)}
  //           sx={{
  //             "& .MuiSvgIcon-root": {
  //               color: "#fff"
  //             }
  //           }}
  //         />
  //       }
  //     />,
  //     "코드",
  //     "분류",
  //     "배너명",
  //     "사용",
  //     "미리보기",
  //     "내용 수정"
  //   ];

  //   const tableData = banners.map((banner) => {
  //     return {
  //       ...banner,
  //       category: banner.category ? banner.category.replace(", ", " ") : "",
  //       check: (
  //         <Checkbox
  //           checked={listChecked.includes(banner.id) ? true : false}
  //           onChange={(e) => handleSelectBanner(e, banner.id)}
  //         />
  //       ),
  //       use: (
  //         <Box
  //           style={{
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center"
  //           }}
  //         >
  //           <CheckBoxOutlinedIcon
  //             color={banner.status === "사용함" ? "primary" : ""}
  //             style={{ cursor: "pointer" }}
  //             onClick={() => handleAppear(banner.id, true)}
  //           />
  //           <DisabledByDefaultOutlinedIcon
  //             sx={
  //               banner.status === "사용안함"
  //                 ? { color: "#ff5454", ml: 1 }
  //                 : { ml: 1 }
  //             }
  //             style={{ cursor: "pointer" }}
  //             onClick={() => handleAppear(banner.id, false)}
  //           />
  //         </Box>
  //       ),
  //       preview: banner?.file ? generateImageSrc(banner?.file) : null,
  //       edit: (
  //         <Button
  //           onClick={() => handleEditBanner(banner.id)}
  //           variant="outlined"
  //           style={{
  //             border: "1px solid #a5a5a5",
  //             fontWeight: "bold",
  //             color: "#000"
  //           }}
  //         >
  //           수정
  //         </Button>
  //       )
  //     };
  //   });

  //   return (
  //     <TableData
  //       tableData={tableData}
  //       headers={headersTitle}
  //       sizeWidth={sizeWidth}
  //     />
  //   );
  // };

  // const renderSearchArea = () => {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       alignItems="center"
  //       style={{ padding: "7px 10px" }}
  //     >
  //       <StyledSelect
  //         width="150px"
  //         MenuProps={{
  //           disableScrollLock: true
  //         }}
  //         value={searchTerms["typeSearch"]}
  //         onChange={(e) =>
  //           handleChangeSearchTerms("typeSearch", e.target.value)
  //         }
  //       >
  //         {listSelectSearch.map((item) => (
  //           <MenuItem value={item.id} key={item.id}>
  //             {item.text}
  //           </MenuItem>
  //         ))}
  //       </StyledSelect>
  //       <StyledTextField
  //         value={searchTerms["search"]}
  //         onChange={(e) => handleChangeSearchTerms("search", e.target.value)}
  //         width="580px"
  //         placeholder="검색어를 입력해주세요."
  //       />

  //       <LoadingButton
  //         onClick={handleSearch}
  //         loading={loadingProcess["searching"] ? true : false}
  //         loadingIndicator="검색"
  //         variant="contained"
  //         style={{
  //           textTransform: "capitalize",
  //           borderRadius: "0",
  //           marginLeft: 5,
  //           height: "33px"
  //         }}
  //       >
  //         검색
  //       </LoadingButton>
  //       <LoadingButton
  //         onClick={handleReset}
  //         loading={loadingProcess["resetting"] ? true : false}
  //         loadingIndicator="초기화"
  //         variant="contained"
  //         style={{
  //           textTransform: "capitalize",
  //           borderRadius: "0",
  //           width: 90,
  //           marginLeft: 5,
  //           height: "33px"
  //         }}
  //       >
  //         초기화
  //       </LoadingButton>
  //     </Grid>
  //   );
  // };

  // const renderSearchArea = () => {
  //   return (
  //     <Grid container direction="column">
  //       <Grid item>
  //         <Grid
  //           container
  //           direction="column"
  //           style={{ border: "1px solid rgb(110, 118, 118)" }}
  //         >
  //           <Grid
  //             item
  //             style={{
  //               backgroundColor: "#1565c0",
  //               borderBottom: "1px solid rgb(110, 118, 118)"
  //             }}
  //           >
  //             <Grid
  //               container
  //               justifyContent="center"
  //               alignItems="center"
  //               style={{ padding: "7px 10px" }}
  //             >
  //               <StyledSelect
  //                 width="150px"
  //                 MenuProps={{
  //                   disableScrollLock: true
  //                 }}
  //               >
  //                 {listSelectName.map((item) => (
  //                   <MenuItem value={item.value} key={item.value}>
  //                     {item.text}
  //                   </MenuItem>
  //                 ))}
  //               </StyledSelect>
  //               <StyledTextField
  //                 value={searchTerms["search"]}
  //                 onChange={(e) =>
  //                   handleChangeSearchTerms("search", e.target.value)
  //                 }
  //                 width="600px"
  //                 placeholder="검색어를 입력해주세요"
  //               />
  //             </Grid>
  //           </Grid>
  //           <Grid item>
  //             <Grid container>
  //               <Grid
  //                 item
  //                 style={{ borderRight: "1px solid", padding: "13px 10px" }}
  //               >
  //                 <Grid container flexDirection="column">
  //                   <Grid item sx={{ mb: 1 }}>
  //                     배너분류
  //                   </Grid>
  //                   <Grid item>정렬순</Grid>
  //                 </Grid>
  //               </Grid>
  //               <Grid item style={{ padding: "13px 10px" }}>
  //                 <Grid container flexDirection="column">
  //                   <Grid item sx={{ mb: 1 }}>
  //                     <Grid container>
  //                       <StyledSelect
  //                         height="25px"
  //                         width="120px"
  //                         MenuProps={{
  //                           disableScrollLock: true
  //                         }}
  //                       >
  //                         {listSelectCategory.map((item) => (
  //                           <MenuItem value={item.value} key={item.value}>
  //                             {item.text}
  //                           </MenuItem>
  //                         ))}
  //                       </StyledSelect>
  //                       <StyledSelect
  //                         height="25px"
  //                         width="120px"
  //                         MenuProps={{
  //                           disableScrollLock: true
  //                         }}
  //                       >
  //                         {listSelectMiddleCategory.map((item) => (
  //                           <MenuItem value={item.value} key={item.value}>
  //                             {item.text}
  //                           </MenuItem>
  //                         ))}
  //                       </StyledSelect>

  //                       <StyledSelect
  //                         height="25px"
  //                         width="120px"
  //                         MenuProps={{
  //                           disableScrollLock: true
  //                         }}
  //                       >
  //                         {listSelectSubcategory.map((item) => (
  //                           <MenuItem value={item.value} key={item.value}>
  //                             {item.text}
  //                           </MenuItem>
  //                         ))}
  //                       </StyledSelect>

  //                       <StyledSelect
  //                         height="25px"
  //                         width="120px"
  //                         MenuProps={{
  //                           disableScrollLock: true
  //                         }}
  //                       >
  //                         {listSelectSubdivision.map((item) => (
  //                           <MenuItem value={item.value} key={item.value}>
  //                             {item.text}
  //                           </MenuItem>
  //                         ))}
  //                       </StyledSelect>
  //                     </Grid>
  //                   </Grid>
  //                   <Grid item>
  //                     <StyledSelect
  //                       height="25px"
  //                       width="120px"
  //                       MenuProps={{
  //                         disableScrollLock: true
  //                       }}
  //                     >
  //                       {listSelectRegistrationDate.map((item) => (
  //                         <MenuItem value={item.value} key={item.value}>
  //                           {item.text}
  //                         </MenuItem>
  //                       ))}
  //                     </StyledSelect>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //       <Grid item sx={{ mt: 1 }}>
  //         <Grid container justifyContent="center">
  //           <Stack spacing={2} direction="row">
  //             <LoadingButton
  //               onClick={handleSearch}
  //               loading={loadingProcess["searching"] ? true : false}
  //               loadingIndicator="검색"
  //               variant="contained"
  //               style={{
  //                 textTransform: "capitalize",
  //                 borderRadius: "0"
  //               }}
  //             >
  //               검색
  //             </LoadingButton>
  //             <LoadingButton
  //               onClick={handleReset}
  //               loading={loadingProcess["resetting"] ? true : false}
  //               loadingIndicator="초기화"
  //               variant="contained"
  //               style={{
  //                 textTransform: "capitalize",
  //                 borderRadius: "0",
  //                 width: 90
  //               }}
  //             >
  //               초기화
  //             </LoadingButton>
  //           </Stack>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const renderTable = () => {
    const sizeWidth = ["20%", "20%", "15%", "30%", "15%"];

    const headersTitle = [
      // <Checkbox
      //   checked={isCheckAll}
      //   onChange={(e) => handleSelectAll(e)}
      //   sx={{
      //     "& .MuiSvgIcon-root": {
      //       color: "#fff"
      //     }
      //   }}
      // />,
      // <FormControlLabel
      //   label="전체선택"
      //   labelPlacement="start"
      //   control={
      //     <Checkbox
      //       checked={isCheckAll}
      //       onChange={(e) => handleSelectAll(e)}
      //       sx={{
      //         "& .MuiSvgIcon-root": {
      //           color: "#fff"
      //         }
      //       }}
      //     />
      //   }
      // />,
      // "코드",
      "분류",
      "배너명",
      "사용",
      "미리보기",
      "수정"
    ];

    const tableData = banners.map((banner) => {
      return {
        ...banner,
        category: banner.category ? banner.category.split(", ") : "",
        check: (
          <Checkbox
            checked={listChecked.includes(banner.id) ? true : false}
            onChange={(e) => handleSelectBanner(e, banner.id)}
          />
        ),
        use: (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CheckBoxOutlinedIcon
              color={banner.status === "enable" ? "primary" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleAppear(banner.id, true)}
            />
            <DisabledByDefaultOutlinedIcon
              sx={
                banner.status === "disable"
                  ? { color: "#ff5454", ml: 1 }
                  : { ml: 1 }
              }
              style={{ cursor: "pointer" }}
              onClick={() => handleAppear(banner.id, false)}
            />
          </Box>
        ),
        preview: banner?.file ? banner?.file : null,
        edit: (
          <Button
            onClick={() => handleEditBanner(banner.id)}
            variant="outlined"
            style={{
              border: "1px solid #a5a5a5",
              fontWeight: "bold",
              color: "#000"
            }}
          >
            수정
          </Button>
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
      />
    );
  };

  return (
    <Layout header="배너 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          {/* <Grid item>{renderSearchArea()}</Grid> */}
          <Grid item>{renderTable()}</Grid>
          {/* <Grid item>
            <Grid container alignItems="center" justifyContent="center">
              <Button
                onClick={() => setOpenModalAdd(true)}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px",
                  marginRight: "20px"
                }}
              >
                추가
              </Button>

              <Button
                onClick={() => setOpenModalDelete(true)}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px"
                }}
              >
                선택 삭제
              </Button>
            </Grid>
          </Grid>
          <Popup2Action
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            message="배너를 추가하시겠습ᅵ까?"
            onClickConfirm={handleAdd}
            onClickCancel={() => setOpenModalAdd(false)}
          />

          <Popup2Action
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            message="선택한 곡들을 정말 삭제하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenModalDelete(false)}
          /> */}
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(ListBanner);
