import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import Popup from "components/Popup";
import {
  ManageCreditService,
  ManageWithdrawService,
  ManageUploader
} from "services";
import { withToast } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import Popup2Action from "./components/Popup2Action";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl
} from "@mui/material";
import {
  formatDate,
  formatNumber,
  addMonths,
  sortListByDate,
  sortListByAmount,
  formatDateSearch,
  formatDateSearchStart,
  formatDateSearchEnd
} from "utils/function";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Excel from "exceljs";
import useModal from "hooks/useModal";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    cancel: createColor("#ff5454"),
    approve: createColor("#fff")
  }
});

const listSortBy = [
  { value: "newest", text: "최신순" },
  { value: "oldest", text: "오래된 순" },
  { value: "withdrawalAmount", text: "출금금액순" }
];

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" }
  // { value: "status", text: "상태" },
  // { value: "status", text: "상태" }
];

// const listStatus = [
//   { value: "receipt", text: "접수" },
//   { value: "complete", text: "완료" }
// ];

const listStatus = [
  { value: "pending", text: "접수" },
  { value: "approved", text: "완료" }
];

const filterSearch = (string) => {
  return string
    .trim()
    .replace(/[\s,.-]+/gi, " ")
    .split(" ")
    .map((el) => el?.toLowerCase())
    .filter(Boolean);
};

const isMatchSearchCondition = (keyword, search) => {
  const transformSearch = filterSearch(search || "");
  const keywordList = (typeof keyword === "string" ? [keyword] : keyword).map(
    (el) => el?.toLowerCase()
  );

  if (
    transformSearch
      .filter(Boolean)
      .some((item) => keywordList.some((kw) => kw.includes(item)))
  ) {
    return true;
  }

  if (
    keywordList
      .filter(Boolean)
      .some((item) => transformSearch.some((ts) => ts.includes(item)))
  ) {
    return true;
  }

  return false;
};

const mapStatusEnToKo = (status) => {
  switch (status) {
    case "approved":
      return "처리 완료";
    case "pending":
      return "접수";
    default:
      return "";
  }
};

const mapStatusKoToEn = (activity) => {
  const newActivity = activity ? activity.trim() : "";
  const result = [];
  if (isMatchSearchCondition(["처리 완료", "처리", "완료"], newActivity)) {
    result.push("approved");
  }
  if (isMatchSearchCondition(["접수", "접", "수"], newActivity)) {
    result.push("pending");
  }
  return result.filter(Boolean).join(",");
};

function WithdrawalReceiptList({ showToast }) {
  const [listTransaction, setListTransaction] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [sortBy, setSortBy] = useState("newest");
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(null);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupApprove, setOpenPopupApprove] = useState(false);
  const [openPopupCancel, setOpenPopupCancel] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [dateTo, setDateTo] = useState(null);
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: ""
  });
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    approving: false,
    cancelling: false
  });
  const [timeReCall, setTimeReCall] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const { addContent } = useModal();

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "" } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = { ...condition, page: page + 1, limit: rowsPerPage };
    }
    if (type !== "status" && type !== "all" && type !== "") {
      condition = {
        ...condition,
        type: type ? type : "all",
        search: search ? search.trim() : ""
      };
    } else if (
      search &&
      search.trim() &&
      (type === "all" || type === "") &&
      mapStatusKoToEn(search.trim())
    ) {
      condition = { ...condition, status: mapStatusKoToEn(search.trim()) };
    } else if (
      (type === "all" || type === "") &&
      search &&
      search.trim() &&
      !mapStatusKoToEn(search.trim())
    ) {
      condition = {
        ...condition,
        type: "all",
        search: search ? search.trim() : ""
      };
    } else if (search && search.trim()) {
      condition = {
        ...condition
      };
      if (mapStatusKoToEn(search.trim())) {
        condition = {
          ...condition,
          status: mapStatusKoToEn(search.trim())
        };
      }
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss")
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const response = await ManageUploader.getWithdrawalReceiptList(
        handleCreateCondition(isSearch, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListTransaction(response?.data?.data ?? []);
        setCount(response?.data?.count ?? 0);

        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "" });
    setDateFrom(null);
    setDateTo(null);
    // setPage(0);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
  };

  const handleCreateExcel = async (transactions) => {
    const fileName = "Withdrawal.js";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("withdrawal");
    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "등급",
      "출금요청일",
      // "출금완료일",
      "출금 Profit",
      // "잔액 Profit",
      "Paypal",
      "Paypal email 주소",
      "상태"
    ];
    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const columnWidth = [10, 20, 30, 20, 20, 20, 20, 20, 20, 30];
    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // add value, style cell // body
    transactions.forEach((transaction, index) => {
      ws.getCell(`A${index + 2}`).value = index + 1;
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = transaction?.user?.nickName;
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = transaction?.user?.email;
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = transaction?.user?.level;
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = transaction.createdAt
        ? formatDate(transaction.createdAt)
        : "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value = transaction?.amount
        ? Math.abs(transaction?.amount)
        : "";
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`G${index + 2}`).value =
        transaction?.paymentMethod === "paypal"
          ? Math.abs(transaction?.amount) ?? ""
          : "";
      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`H${index + 2}`).value = transaction?.email ?? "";
      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`I${index + 2}`).value = mapStatusEnToKo(
        transaction?.status ?? ""
      );
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      // ws.getCell(`J${index + 2}`).value = transaction?.email ?? "";
      // ws.getCell(`J${index + 2}`).style = {
      //   alignment: { vertical: "center", horizontal: "center" }
      // };
      // ws.getCell(`K${index + 2}`).value = mapStatusEnToKo(
      //   transaction?.status ?? ""
      // );
      // ws.getCell(`K${index + 2}`).style = {
      //   alignment: { vertical: "center", horizontal: "center" }
      // };
    });

    const buffer = await wb.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownloadExcel = async () => {
    const { type = "", search = "" } = searchTerms;
    let condition = {};
    if (isDownloadFilter) {
      condition = { ...condition, type: type ? type : "all", search };
      if (type !== "status" && type !== "all" && type !== "") {
        condition = {
          ...condition,
          type: type ? type : "all",
          search: search ? search.trim() : ""
        };
      } else if (
        search &&
        search.trim() &&
        (type === "all" || type === "") &&
        mapStatusKoToEn(search.trim())
      ) {
        condition = { ...condition, status: mapStatusKoToEn(search.trim()) };
      } else if (
        (type === "all" || type === "") &&
        search &&
        search.trim() &&
        !mapStatusKoToEn(search.trim())
      ) {
        condition = {
          ...condition,
          type: "all",
          search: search ? search.trim() : ""
        };
      } else if (search && search.trim()) {
        condition = {
          ...condition
        };
        if (mapStatusKoToEn(search.trim())) {
          condition = {
            ...condition,
            status: mapStatusKoToEn(search.trim())
          };
        }
      }
      if (dateFrom) {
        condition = {
          ...condition,
          from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
        };
      }

      if (dateTo) {
        condition = {
          ...condition,
          to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss")
        };
      }
    }
    let listTransaction = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageUploader.getWithdrawalReceiptList(condition);
      if (response?.data?.statusCode === 200) {
        const transactions = response.data?.data ?? [];
        listTransaction = [...transactions];
        handleCreateExcel(listTransaction);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSelectTransaction = (e, tid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, tid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== tid);
      setListChecked(newListChecked);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      listTransaction.forEach((transaction) => {
        newListChecked.push(transaction.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleCancelPayout = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No transaction selected");
      return;
    }
    // const data = {
    //   userIds: listChecked
    // };
    // setLoadingProcess({ ...loadingProcess, cancelling: true });
    // try {
    //   const response = await ManageWithdrawService.cancelPayout(data);
    //   if (response.status === 201 && response.data.status === SUCCESS_STATUS) {
    //     setTimeReCall((prevState) => prevState + 1);
    //     setListChecked([]);
    //     setIsCheckAll(false);
    //     showToast(TOAST_TYPE.SUCCESS, "Cancelled");
    //   } else {
    //     const errorData = response?.data
    //       ? response.data?.msg
    //       : "Error" ?? "Error";
    //     showToast(TOAST_TYPE.ERROR, errorData);
    //   }
    //   setLoadingProcess({ ...loadingProcess, cancelling: false });
    // } catch (error) {
    //   setLoadingProcess({ ...loadingProcess, cancelling: false });
    //   const errorStatusText = error?.response ? error.response?.statusText : "";
    //   const errorDataMessage = error?.response
    //     ? error.response?.data
    //       ? error.response.data?.message
    //       : ""
    //     : "";
    //   const errorMessage =
    //     errorDataMessage ?? errorStatusText ?? "System Error";
    //   showToast(TOAST_TYPE.ERROR, errorMessage);
    // }
  };

  const handleApprovePayout = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No transaction selected");
      return;
    }
    // const data = {
    //   userIds: listChecked
    // };
    setLoadingProcess({ ...loadingProcess, approving: true });
    try {
      const response = await ManageUploader.approveTransactions({
        transactionIds: listChecked
      });
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Approved");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, approving: false });
      setOpenPopupApprove(false);
      setListChecked([]);
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, approving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
      setOpenPopupApprove(false);
    }
  };

  const handleUpdateStatus = async (id, status, currStatus) => {
    if (currStatus === status) {
      return;
    }
    try {
      const response = await ManageUploader.updateStatusWithdrawal({
        transactionIds: [Number(id)],
        status
      });
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Success");
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, suspending: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleApproveWithdrawal = async (id) => {
    try {
      const response = await ManageUploader.approveWithdrawal({
        transactionIds: [Number(id)],
        status: "approved"
      });
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Success");
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, suspending: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? error.message ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = [
      "5%",
      "3%",
      "7%",
      "15%",
      "7%",
      "7%",
      // "7%",
      "7%",
      "7%",
      "7%",
      "19%",
      "9%",
      "7%"
    ];
    const headersTitle = [
      "선택",
      "NO.",
      "성명",
      "이메일",
      "등급",
      "출금요청일",
      // "출금완료일",
      "출금 Profit",
      // "잔액 Profit",
      // <Checkbox
      //   sx={{
      //     "& .MuiSvgIcon-root": {
      //       color: "#fff"
      //     }
      //   }}
      //   checked={isCheckAll}
      //   onChange={(e) => handleSelectAll(e)}
      // />
      "Paypal",
      "Paypal email 주소",
      "상태",
      ""
    ];
    const calculateFee = (netAmount, amount) => {
      if (!netAmount || !amount) return "";
      const fee = formatNumber(Number(netAmount) - Number(amount));
      return fee ? "$" + fee : "";
    };

    const tableData = listTransaction.map((transaction, index) => {
      return {
        ...transaction,
        index: index + 1 + rowsPerPage * page,
        nickName: transaction?.user?.nickName,
        email: transaction?.user?.email,
        level: transaction?.user?.level,
        amount: transaction.amount
          ? "$" + formatNumber(transaction.amount)
          : "",
        createdAt: transaction.createdAt
          ? formatDate(transaction.createdAt)
          : "",
        fees: calculateFee(transaction?.netAmount, transaction.amount),
        check: (
          <Checkbox
            checked={listChecked.includes(transaction.id) ? true : false}
            onChange={(e) => handleSelectTransaction(e, transaction.id)}
          />
        ),
        status: mapStatusEnToKo(transaction?.status ?? ""),
        // status: (
        //   <Select
        //     MenuProps={{
        //       disableScrollLock: true
        //     }}
        //     fullWidth
        //     value={transaction?.status ?? ""}
        //     onChange={(e) => {
        //       e.stopPropagation();
        //       // handleChangeSearchTerms("sorts", e.target.value);
        //       handleUpdateStatus(
        //         transaction.id,
        //         e.target.value,
        //         transaction?.status ?? ""
        //       );
        //     }}
        //     style={{
        //       borderRadius: "0",
        //       height: "30px"
        //     }}
        //   >
        //     {listStatus.map((item) => (
        //       <MenuItem key={item.value} value={item.value}>
        //         {item.text}
        //       </MenuItem>
        //     ))}
        //   </Select>
        // ),
        withdrawalRequestDate: transaction.createdAt
          ? formatDate(transaction.createdAt)
          : "",
        withdrawalCompletionDate: transaction.approvedAt
          ? formatDate(transaction.approvedAt)
          : "",
        withdrawalProfit: transaction?.amount
          ? Math.abs(transaction?.amount)
          : "",
        paypal:
          transaction?.paymentMethod === "paypal"
            ? Math.abs(transaction?.amount) ?? ""
            : "",
        ppEmail: transaction?.email ?? "",
        approveButton: (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Button
              variant="outline"
              style={{
                border: "1px solid #c4c4c4",
                textTransform: "capitalize",
                padding: "5px",
                zIndex: 100
              }}
              disabled={
                !["접수"].includes(
                  mapStatusEnToKo(transaction?.status ?? "")
                ) || !!transaction?.externalId
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addContent({
                  message: `사용자 ${transaction?.netAmount} Profit 출금 접수하셨습니다.동의해주시겠습니까?`,
                  callback: () => {
                    handleApproveWithdrawal(transaction?.id);
                  }
                });
              }}
            >
              처리완료
            </Button>
          </div>
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>검색필터</Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  출금완료일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <FormControl size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="업로더 관리 - Profit 출금 접수 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              {/* <Grid item>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography sx={{ mr: 2 }}>정렬기준</Typography>
                  <Select
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      borderRadius: "0",
                      height: "30px",
                      width: "120px"
                    }}
                  >
                    {listSortBy.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid> */}
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize",
                      padding: "5px"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel파일로 다운받기
                  </Button>
                </Grid>
                {/* <Grid item sx={{ mt: "10px" }}>
                  <Box
                  // style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        fullWidth
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "5px",
                          height: "30px"
                          // backgroundColor: "blue",
                          // color: "white"
                        }}
                        // color="approve"
                        onClick={() => setOpenPopupApprove(true)}
                        loading={loadingProcess["approving"] ? true : false}
                        loadingIndicator="승인"
                        variant="contained"
                      >
                        출금승인
                      </LoadingButton>
                      <LoadingButton
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "0",
                          height: "30px"
                        }}
                        color="cancel"
                        onClick={() => setOpenPopupCancel(true)}
                        loading={loadingProcess["cancelling"] ? true : false}
                        loadingIndicator="취소"
                        variant="contained"
                      >
                        취소
                      </LoadingButton>
                    </ThemeProvider>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup2Action
            open={openPopupApprove}
            setOpen={setOpenPopupApprove}
            message="출금승인하시겠습니까?"
            onClickConfirm={handleApprovePayout}
            onClickCancel={() => setOpenPopupApprove(false)}
          />
          <Popup2Action
            open={openPopupCancel}
            setOpen={setOpenPopupCancel}
            message="출금승인을 취소하시겠습니까?"
            onClickConfirm={handleCancelPayout}
            onClickCancel={() => setOpenPopupCancel(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(WithdrawalReceiptList);
