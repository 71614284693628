import { createTheme } from "@mui/material/styles";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    cancel: createColor("#ff5454"),
    approve: createColor("#fff"),
    add: createColor("#fff"),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          html, body, #root {
            font-family: 'AppleSDGothicNeoB00';
            width: 100%;
            height: 100%;
          }; 
        `,
    },
  },
  typography: {
    fontFamily: "AppleSDGothicNeoB00",
  },
});

export default theme;
