import { PrivateRouter } from "HOC";
import UploaderList from "../UploaderList";
import ProfitUsageHistory from "../ProfitUsageHistory";
import WithdrawalReceiptList from "../WithdrawalReceiptList";
import DetailWithdrawalReceipt from "../DetailWithdrawalReceipt";
import SponsorList from "../SponsorList";
import SponsorItemPrice from "../SponsorItemPrice";
import TermAndCondition from "../TermAndCondition";

const routerManageUploader = [
  {
    path: "/uploader-management/uploader-list",
    element: (
      <PrivateRouter>
        <UploaderList />
      </PrivateRouter>
    )
  },
  {
    path: "/uploader-management/profit-usage-history",
    element: (
      <PrivateRouter>
        <ProfitUsageHistory />
      </PrivateRouter>
    )
  },
  {
    path: "/uploader-management/profit-usage-history",
    element: (
      <PrivateRouter>
        <ProfitUsageHistory />
      </PrivateRouter>
    )
  },
  {
    path: "/uploader-management/withdrawal-receipt-list",
    element: (
      <PrivateRouter>
        <WithdrawalReceiptList />
      </PrivateRouter>
    )
  },
  {
    path: "/uploader-management/withdrawal-receipt-list/detail",
    element: (
      <PrivateRouter>
        <DetailWithdrawalReceipt />
      </PrivateRouter>
    )
  },
  {
    path: "/uploader-management/list-of-sponsor-ad-items",
    element: (
      <PrivateRouter>
        <SponsorList />
      </PrivateRouter>
    )
  },
  {
    path: "/uploader-management/sponsor-ad-item-unit-price",
    element: (
      <PrivateRouter>
        <SponsorItemPrice />
      </PrivateRouter>
    )
  },
  {
    path: "/uploader-management/term-condition",
    element: (
      <PrivateRouter>
        <TermAndCondition />
      </PrivateRouter>
    )
  }
];

export default routerManageUploader;
