import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  styled,
  Box,
  IconButton,
  TableFooter,
  TablePagination
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  FirstPage
} from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0468b4",
    color: theme.palette.common.white,
    padding: 5,
    borderRadius: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 43,
    padding: 0
  }
}));

const StyledTablePagination = styled(TablePagination)({
  ".MuiTablePagination-select": {
    paddingLeft: "0 !important"
  },
  ".MuiTablePagination-menuItem": {
    textAlign: "center"
  }
});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

export default function TableData(props) {
  const {
    tableData = [],
    headers = [],
    sizeWidth = [],
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count
  } = props;
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  // useEffect(() => {
  //   setPage(0);
  // }, [tableData.length]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer style={{ border: "1px solid rgb(110, 118, 118)" }}>
      <Table>
        <TableHead>
          <TableRow>
            {!!headers.length &&
              headers.map((columnName, index) => (
                <StyledTableCell
                  style={{ width: sizeWidth[index], fontWeight: "bold" }}
                  key={columnName}
                  align="center"
                >
                  {columnName}
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell style={{ width: sizeWidth[0] }} align="center">
                {row?.user?.nickName ? row?.user?.nickName : ""}
              </StyledTableCell>
              <StyledTableCell style={{ width: sizeWidth[1] }} align="center">
                {row?.user?.email ? row?.user?.email : ""}
              </StyledTableCell>
              <StyledTableCell style={{ width: sizeWidth[2] }} align="center">
                {row?.user?.phoneNumber ? row.user.phoneNumber : ""}
              </StyledTableCell>
              {/* <StyledTableCell style={{ width: sizeWidth[3] }} align="center">
                {row.issuance}
              </StyledTableCell> */}
              <StyledTableCell style={{ width: sizeWidth[4] }} align="center">
                {row.createdAt}
              </StyledTableCell>
              <StyledTableCell style={{ width: sizeWidth[5] }} align="center">
                {row.used === true ? row.updatedAt : "-"}
              </StyledTableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 43 * emptyRows }}>
              <StyledTableCell colSpan={headers.length} />
            </TableRow>
          )}
          {!!!tableData.length && (
            <TableRow>
              <TableCell align="left" colSpan={headers.length}>
                Data Empty
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <StyledTablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={headers.length}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page"
                },
                native: true
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
