import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManageUltrafitShopService, ManagePatternService } from "services";
import { withToast } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Checkbox,
  InputAdornment
} from "@mui/material";
import { formatNumber, generateImageSrc, listGroupBy } from "utils/function";

import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import Popup2Action from "components/Popup2Action";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  width: "400px",
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function ProductManagement({ showToast }) {
  const [listUltrafit, setListUltrafit] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchTerms, setSearchTerms] = useState("");
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    deleting: false
  });

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [listChecked, setListChecked] = useState([]);

  const [timeReCall, setTimeReCall] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const navigate = useNavigate();

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return {
        ...condition,
        page: 1,
        limit: rowsPerPage
      };
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    if (searchTerms) {
      condition = {
        ...condition,
        name: searchTerms ? searchTerms.trim() : searchTerms
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const response = await ManageUltrafitShopService.getUltrafitList(
        handleCreateCondition(isSearch, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListUltrafit(response?.data?.data ?? []);
        setCount(response?.data?.count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data?.msg ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";

      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleExposure = async (e, id, status) => {
    let condition = { status: "enable" };
    if (status === "enable") {
      condition = { status: "disable" };
    }
    try {
      const response = await ManageUltrafitShopService.updateUltrafitStatus({
        ...condition,
        ids: [Number(id)]
      });
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Success");
      } else {
        const errorData = response?.data?.msg ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleAdd = () => {
    navigate("/ultrafit-shop-management/product/add");
  };

  const handleDelete = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No products selected");
      return;
    }

    const data = {
      ids: listChecked
    };

    setLoadingProcess({ ...loadingProcess, deleting: true });

    try {
      const response = await ManageUltrafitShopService.deleteUltrafit(data);
      if (response?.data?.statusCode === 200) {
        await fetchData();
        setLoadingProcess({ ...loadingProcess, deleting: false });
        setListChecked([]);
        setIsCheckAll(false);
        // setOpenDeleteConfirm(false);
        showToast(TOAST_TYPE.SUCCESS, "Deleted product");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, deleting: false });
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setOpenDeleteConfirm(false);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      listUltrafit.forEach((transaction) => {
        newListChecked.push(transaction.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleSelectUltrafit = (e, tid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, tid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== tid);
      setListChecked(newListChecked);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["7%", "20%", "10%", "15%", "10%", "15%"];
    const headersTitle = [
      <Checkbox checked={isCheckAll} onChange={(e) => handleSelectAll(e)} />,
      "상품이미지",
      "상품명",
      "상품코드",
      "카테고리",
      "상품노출",
      "판매가"
    ];

    const inventoryText = (inventory) => {
      if (inventory < 10 && inventory > 0) {
        return (
          <React.Fragment>
            <Typography style={{ display: "block", fontWeight: "bold" }}>
              not enough inventory
            </Typography>
            <Typography style={{ display: "block", fontWeight: "bold" }}>
              {`${inventory} EA`}
            </Typography>
          </React.Fragment>
        );
      }
      if (inventory <= 0) {
        return (
          <React.Fragment>
            <Typography style={{ display: "block", fontWeight: "bold" }}>
              out of inventory
            </Typography>
            <Typography style={{ display: "block", fontWeight: "bold" }}>
              {"0 EA"}
            </Typography>
          </React.Fragment>
        );
      }
      return `${inventory} EA`;
    };

    const tableData = listUltrafit.map((ult) => {
      return {
        ...ult,
        optionPrice: ult.options.map(
          (item) => `${item.inches}: $ ${item.amount}`
        ),
        price: ult.price ? "$ " + formatNumber(Number(ult.price)) : "",
        file: ult.image ? ult.image : "",
        category: ult?.category1?.category ?? "",
        // inventory:
        //   ult.inventory || ult.inventory === 0
        //     ? inventoryText(Number(ult.inventory))
        //     : "",
        productExposure: (
          <Checkbox
            checked={ult?.status === "enable" ? true : false}
            onChange={(e) => handleExposure(e, ult.id, ult?.status)}
          />
        ),
        check: (
          <Checkbox
            checked={listChecked.includes(ult.id) ? true : false}
            onChange={(e) => handleSelectUltrafit(e, ult.id)}
          />
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item>
          <Box
            style={{
              display: "flex",
              gap: "12px"
            }}
          >
            <StyledTextField
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon edge="end" />
                  </InputAdornment>
                )
              }}
              onKeyDown={handleKeyDown}
              placeholder="제품명, 제품번호"
            />
            <LoadingButton
              onClick={handleSearch}
              loading={loadingProcess["searching"] ? true : false}
              loadingIndicator="검색"
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "46px"
              }}
            >
              검색
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="상품관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <LoadingButton
                  loading={loadingProcess["deleting"] ? true : false}
                  onClick={() => setOpenDeleteConfirm(true)}
                  color="cancel"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "0",
                    height: "46px",
                    marginRight: "20px"
                  }}
                >
                  상품삭제-
                </LoadingButton>
                <LoadingButton
                  onClick={handleAdd}
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "0",
                    height: "46px"
                  }}
                >
                  상품등록 +
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup2Action
            open={openDeleteConfirm}
            setOpen={setOpenDeleteConfirm}
            message="이 제품을 삭제하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenDeleteConfirm(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(ProductManagement);
