import React, { useState, useEffect, useRef } from "react";
import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import withToast from "HOC/withToast";
import { Box, Button /*TextareaAutosize, ToggleButton*/ } from "@mui/material";
import { ManageMemberService } from "services";
import { TOAST_TYPE } from "utils/constants";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material";
import PolicyEditor from "./components/PolicyEditor";

const StyledTabList = styled(TabList)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiButtonBase-root": {
    textTransform: "capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    margin: "0 12px 0 0",
    minWidth: "unset",
    fontSize: "1rem",
    color: "#000 !important",
  },
  "& .Mui-selected": {
    color: "#000 !important",
    fontWeight: "bold",
    backgroundColor: "#7777",
  },
});

const StyledTabPanel = styled(TabPanel)({
  paddingLeft: 0,
});

function MembershipPolicy({ showToast }) {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState("1");

  // const [loadingProcess, setLoadingProcess] = useState({
  //   saving: false,
  // });
  const [policy, setPolicy] = useState({});
  const [termOfUse, setTermOfUse] = useState({});
  // before saved
  const preDataPolicy1 = useRef();
  // After saved
  const preDataPolicy2 = useRef();
  // before changing
  const preDataPolicy3 = useRef();

  const preDataTermOfUse1 = useRef();
  const preDataTermOfUse2 = useRef();
  const preDataTermOfUse3 = useRef();

  useEffect(() => {
    async function fetchData() {
      try {
        const responsePolicy = await ManageMemberService.getPolicy({
          country: "korean",
          type: "privacy",
        });
        const responseTermOfUse = await ManageMemberService.getPolicy({
          country: "korean",
          type: "terms",
        });
        if (responsePolicy?.data?.statusCode === 200) {
          const resData = responsePolicy?.data?.data ?? [];
          const resPolicy = resData.length > 0 ? resData[0] : {};
          setPolicy(resPolicy);
          preDataPolicy1.current = resPolicy?.policy ?? "";
          preDataPolicy2.current = resPolicy?.policy ?? "";
          preDataPolicy3.current = false;
          setLoading(false);
        } else {
          showToast(TOAST_TYPE.ERROR, responsePolicy?.data?.message ?? "Error");
          setLoading(false);
        }
        if (responseTermOfUse?.data?.statusCode === 200) {
          const resData = responseTermOfUse?.data?.data ?? [];
          const resPolicy = resData.length > 0 ? resData[0] : {};
          setTermOfUse(resPolicy);
          preDataTermOfUse1.current = resPolicy?.policy ?? "";
          preDataTermOfUse2.current = resPolicy?.policy ?? "";
          preDataTermOfUse3.current = false;
          setLoading(false);
        } else {
          showToast(
            TOAST_TYPE.ERROR,
            responseTermOfUse?.data?.message ?? "Error"
          );
          setLoading(false);
        }
      } catch (error) {
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
        setLoading(false);
      }
    }
    fetchData();
  }, [showToast]);

  const handleChangePolicy = (content) => {
    preDataPolicy3.current = true;
    setPolicy({ ...policy, policy: content });
  };

  const handleChangeTermOfUse = (content) => {
    preDataTermOfUse3.current = true;
    setTermOfUse({ ...termOfUse, policy: content });
  };

  const handleSavePolicy = async () => {
    // setLoadingProcess({ ...loadingProcess, saving: true });
    if (!policy?.policy) {
      showToast("Please enter privacy policy");
    }
    try {
      if (policy.id) {
        await ManageMemberService.updatePolicy(policy.id, {
          country: "korean",
          type: "privacy",
          policy: policy?.policy ?? "",
        });
      } else {
        await ManageMemberService.createPolicy({
          country: "korean",
          type: "privacy",
          policy: policy?.policy ?? "",
        });
      }
      // setLoadingProcess({ ...loadingProcess, saving: false });
      preDataPolicy1.current = preDataPolicy2.current;
      preDataPolicy2.current = policy?.policy ?? "";
      preDataPolicy3.current = false;

      showToast(TOAST_TYPE.SUCCESS, "Update success");
    } catch (error) {
      // setLoadingProcess({ ...loadingProcess, saving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSaveTermOfUse = async () => {
    // setLoadingProcess({ ...loadingProcess, saving: true });
    if (!termOfUse?.policy) {
      showToast("Please enter privacy policy");
    }
    try {
      if (termOfUse.id) {
        await ManageMemberService.updatePolicy(termOfUse.id, {
          country: "korean",
          type: "terms",
          policy: termOfUse?.policy ?? "",
        });
      } else {
        await ManageMemberService.createPolicy({
          country: "korean",
          type: "terms",
          policy: termOfUse?.policy ?? "",
        });
      }
      // setLoadingProcess({ ...loadingProcess, saving: false });
      preDataTermOfUse1.current = preDataTermOfUse2.current;
      preDataTermOfUse2.current = termOfUse?.policy ?? "";
      preDataTermOfUse3.current = false;

      showToast(TOAST_TYPE.SUCCESS, "Update success");
    } catch (error) {
      // setLoadingProcess({ ...loadingProcess, saving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleResetPolicy = () => {
    if (preDataPolicy3.current === true) {
      setPolicy({ ...policy, policy: preDataPolicy2.current });
    } else {
      setPolicy({ ...policy, policy: preDataPolicy1.current });
    }
  };

  const handleResetTermOfUse = () => {
    if (preDataTermOfUse3.current === true) {
      setTermOfUse({ ...termOfUse, policy: preDataTermOfUse2.current });
    } else {
      setTermOfUse({ ...termOfUse, policy: preDataTermOfUse1.current });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handlePrivacyPolicyClick = () => {
  //   setPrivacyPolicySelected(true);
  //   setTermsOfServiceSelected(false);
  // };

  // const handleTermsOfService = () => {
  //   setPrivacyPolicySelected(false);
  //   setTermsOfServiceSelected(true);
  // };

  return (
    <Layout header="회원관리 - 회원 가입 정책">
      {loading ? (
        <LoadingPage />
      ) : (
        <TabContext value={value}>
          <StyledTabList
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab
              label="개인정보처리방침"
              value="1"
              style={{
                border: "1px solid #222",
                paddingInline: "15px",
              }}
            />
            <Tab
              label="이용약관"
              value="2"
              style={{ border: "1px solid #222", paddingInline: "15px" }}
            />
          </StyledTabList>
          <StyledTabPanel value="1">
            <Box sx={{ flexDirection: "column" }}>
              <Box sx={{ mt: 2 }}>
                {/* <TextareaAutosize
                  // ref={textareaRef}
                  // onFocus={(e) => (e.currentTarget.selectionStart = 0)}
                  value={policy?.policy ?? ""}
                  onChange={(e) =>
                    setPolicy({ ...policy, policy: e.target.value })
                  }
                  aria-label="minimum height"
                  minRows={29}
                  style={{ width: "100%", padding: "5px" }}
                /> */}
                <PolicyEditor data={policy} setData={handleChangePolicy} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    border: "1px solid #a5a5a5",
                    textTransform: "capitalize",
                    color: "#000",
                    width: 150,
                    marginLeft: 20,
                    fontWeight: "bold",
                  }}
                  onClick={handleSavePolicy}
                  // disabled={loadingProcess["saving"] ? true : false}
                >
                  {/* {loadingProcess["saving"] ? true : false ? "Save..." : "Save"} */}
                  저장
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    border: "1px solid #a5a5a5",
                    textTransform: "capitalize",
                    color: "#000",
                    width: 150,
                    marginLeft: 20,
                    fontWeight: "bold",
                  }}
                  onClick={handleResetPolicy}
                >
                  초기화
                </Button>
              </Box>
            </Box>
          </StyledTabPanel>
          <StyledTabPanel value="2">
            <Box sx={{ flexDirection: "column" }}>
              <Box sx={{ mt: 2 }}>
                {/* <TextareaAutosize
                  // ref={textareaRef}
                  // onFocus={(e) => (e.currentTarget.selectionStart = 0)}
                  value={termOfUse?.policy ?? ""}
                  onChange={(e) =>
                    setTermOfUse({ ...termOfUse, policy: e.target.value })
                  }
                  aria-label="minimum height"
                  minRows={29}
                  style={{ width: "100%", padding: "5px" }}
                /> */}
                <PolicyEditor
                  data={termOfUse}
                  setData={handleChangeTermOfUse}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    border: "1px solid #a5a5a5",
                    textTransform: "capitalize",
                    color: "#000",
                    width: 150,
                    marginLeft: 20,
                    fontWeight: "bold",
                  }}
                  onClick={handleSaveTermOfUse}
                  // disabled={loadingProcess["saving"] ? true : false}
                >
                  {/* {loadingProcess["saving"] ? true : false ? "Save..." : "Save"} */}
                  저장
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    border: "1px solid #a5a5a5",
                    textTransform: "capitalize",
                    color: "#000",
                    width: 150,
                    marginLeft: 20,
                    fontWeight: "bold",
                  }}
                  onClick={handleResetTermOfUse}
                >
                  초기화
                </Button>
              </Box>
            </Box>
          </StyledTabPanel>
        </TabContext>
      )}
    </Layout>
  );
}

export default withToast(MembershipPolicy);
