import axiosClient from "../axiosClient";
import URL from "./url";

class ManageCreditService {
  getListCredit() {
    const url = URL.GET_LIST_URL;
    return axiosClient.get(url);
  }
  createCredit(body = {}) {
    const url = URL.CREATE_CREDIT;
    return axiosClient.post(url, body);
  }
  updateCredit(id, body = {}) {
    const url = URL.UPDATE_CREDIT + "/" + id;
    return axiosClient.put(url, body);
  }
  getListTransaction(body = {}) {
    const url = URL.GET_TRANSACTIONS;
    return axiosClient.post(url, body);
  }
  getListTransactionsCharge(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_TRANSACTIONS_CHARGE + query;
    return axiosClient.get(url);
  }
  getListTransactionsById(id) {
    const url = URL.GET_TRANSACTIONS_BY_ID + "/" + id;
    return axiosClient.get(url);
  }
  // getCreditHistory({
  //   code = "",
  //   name = "",
  //   productId = "",
  //   startDate = "",
  //   isRefund = ""
  // }) {
  //   let query = [];
  //   if (code) query.push(`code=${code}`);
  //   if (name) query.push(`name=${name}`);
  //   if (productId) query.push(`productId=${productId}`);
  //   if (startDate) query.push(`startDate=${startDate}`);
  //   if (isRefund) query.push(`isRefund=${isRefund}`);
  //   const url = URL.GET_CREDIT_HISTORY + `?${query.join("&")}`;
  //   return axiosClient.get(url);
  // }
  getCreditHistory(condition = { page: 1, limit: 5 }) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_CREDIT_HISTORY + query;
    return axiosClient.get(url);
  }
  getCreditHistoryById(id) {
    const url = `${URL.GET_CREDIT_HISTORY_BY_ID}/${id}`;
    return axiosClient.get(url);
  }
  getListTransactionsRefund(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_TRANSACTIONS_REFUND + query;
    return axiosClient.get(url);
  }
  createGiftCredit(body = {}) {
    const url = URL.CREATE_GIFT_CREDIT;
    return axiosClient.post(url, body);
  }

  createFloatVariables(body = {}) {
    const url = URL.CREATE_FLOAT_VARIABLES;
    return axiosClient.post(url, body);
  }
  getFloatVariablesByType(type) {
    const url = URL.GET_FLOAT_VARIABLES + "?type=" + type;
    return axiosClient.get(url);
  }
  updateFloatVariables(id, body = {}) {
    const url = URL.UPDATE_FLOAT_VARIABLES + "/" + id;
    return axiosClient.put(url, body);
  }
  updateRefundStatus(id, body = {}) {
    const url = URL.UPDATE_REFUND_STATUS + "/" + id;
    return axiosClient.put(url, body);
  }
}

export default new ManageCreditService();
