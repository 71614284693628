import React from "react";
import { useFieldArray } from "react-hook-form";

import { TextField, Box, styled } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& .MuiOutlinedInput-root": {
    width: width,
    height: "33px"
  },
  "& input": {
    height: "33px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

export default function FieldArray({
  control,
  register,
  setValue,
  getValues,
  errors
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "product.options"
  });

  return (
    <>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        {/* <Box style={{ display: "flex", width: "372px" }}>
          <Box style={{ flexBasis: "24%", fontSize: "0.7rem" }}>Option</Box>
          <Box style={{ flexBasis: "24%", fontSize: "0.7rem" }}>Price</Box>
          <Box style={{ flexBasis: "24%", fontSize: "0.7rem" }}>Weight</Box>
          <Box style={{ flexBasis: "24%", fontSize: "0.7rem" }}>재고*</Box>
          <Box style={{ flexBasis: "4%", fontSize: "0.7rem" }}>*</Box>
        </Box> */}
        {fields.map((item, index) => {
          return (
            <Box
              key={index}
              style={{
                display: "flex",
                width: "490px",
                gap: "3px",
                marginBottom: "10px",
                alignItems: "center"
              }}
            >
              <Box style={{ flexBasis: "30%" }}>
                <StyledTextField
                  {...register(`product.options[${index}].inches`, {
                    required: "inches is required"
                  })}
                  defaultValue={item.inches}
                  error={!!errors?.product?.options?.[index]?.inches}
                  placeholder="인치 입력"
                  type="text"
                />
              </Box>
              <Box style={{ flexBasis: "30%" }}>
                <StyledTextField
                  {...register(`product.options[${index}].amount`, {
                    required: "amount is required"
                  })}
                  // inputProps={{
                  //   step: 0.0001,
                  //   min: 0,
                  //   type: "number"
                  // }}
                  type="text"
                  onInput={(evt) => {
                    let inputValue = evt.target.value;
                    inputValue = inputValue.replace(/[^0-9.]/g, "");
                    if (
                      inputValue.indexOf(".") !== inputValue.lastIndexOf(".") &&
                      inputValue.endsWith(".")
                    ) {
                      inputValue = inputValue.slice(0, -1);
                    }
                    evt.target.value = inputValue;
                  }}
                  error={!!errors?.product?.options?.[index]?.amount}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  defaultValue={item.amount}
                  placeholder="인치별 가격 입력"
                  // type="number"
                />
              </Box>
              <Box style={{ flexBasis: "30%" }}>
                <StyledTextField
                  {...register(`product.options[${index}].weight`, {
                    required: "weight is required"
                  })}
                  error={!!errors?.product?.options?.[index]?.weight}
                  // inputProps={{
                  //   step: 0.0001,
                  //   min: 0,
                  //   type: "number"
                  // }}
                  type="text"
                  onInput={(evt) => {
                    let inputValue = evt.target.value;
                    inputValue = inputValue.replace(/[^0-9.]/g, "");
                    if (
                      inputValue.indexOf(".") !== inputValue.lastIndexOf(".") &&
                      inputValue.endsWith(".")
                    ) {
                      inputValue = inputValue.slice(0, -1);
                    }
                    evt.target.value = inputValue;
                  }}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  defaultValue={item.weight}
                  placeholder="롤의 무게 입력"
                  // type="number"
                />
              </Box>
              {/* <Box style={{ flexBasis: "24%" }}>
                <StyledTextFieldHeight
                  {...register(`product.options[${index}].inventory`)}
                  defaultValue={item.inventory}
                />
              </Box> */}
              <Box style={{ flexBasis: "4%", cursor: "pointer" }}>
                {index === 0 ? (
                  <AddIcon
                    fontSize="small"
                    onClick={() =>
                      append({
                        inches: "",
                        price_per_inches: "",
                        weight: ""
                      })
                    }
                  />
                ) : (
                  <RemoveIcon fontSize="small" onClick={() => remove(index)} />
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
