import React, { useState, useEffect, useRef } from "react";
import Layout from "components/Layout";
import { ManageUploader } from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button
} from "@mui/material";
import Popup2Action from "./components/Popup2Action";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& label": {
    paddingLeft: (theme) => theme.spacing(2)
  },
  "& input": {
    width: width ? width : "120px",
    height: height ? height : "13px",
    fontWeight: "bold",
    textAlign: "center"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    paddingLeft: (theme) => theme.spacing(2.5),
    borderRadius: 0
  }
}));

const StyledButtonList = styled(Button)({
  border: "1px solid #a5a5a5",
  textTransform: "capitalize",
  color: "#000",
  width: 150,
  marginLeft: 20,
  fontWeight: "bold"
});

function SponsorItemPrice({ showToast }) {
  const [sponsorPrice, setSponsorPrice] = useState({});
  const [priceChange, setPriceChange] = useState("");
  const [loading, setLoading] = useState(true);

  const prePrice = useRef(null);

  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageUploader.getSponsorsPrice();
        if (response?.data?.statusCode === 200) {
          const price = response?.data?.data ?? {};
          prePrice.current = price?.price ?? "";
          setSponsorPrice(price);
          setPriceChange(price?.price ?? "");
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data?.mgs ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePrice = async () => {
    try {
      const response = await ManageUploader.createSponsorsPrice({
        ...sponsorPrice,
        price: priceChange ? Number(priceChange) : 0,
        currency: "USD"
      });
      if (response?.data?.statusCode === 200) {
        setSponsorPrice({
          ...sponsorPrice,
          price: priceChange ? Number(priceChange) : 0
        });
        prePrice.current = priceChange;
        showToast(TOAST_TYPE.SUCCESS, "Update Success");
        setOpenPopup(false);
      } else {
        const errorData = response?.data?.message?.[0] ?? "Error";
        setOpenPopup(false);
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenPopup(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message?.[0] ?? "";

      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSave = () => {
    setOpenPopup(true);
  };

  const handleReset = () => {
    setPriceChange(prePrice.current);
  };

  return (
    <Layout header="Sponsor AD Item - 서비스 단가 설정">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column" style={{ border: "1px solid" }}>
          <Box
            sx={{ pt: 1, pb: 1, pl: 2 }}
            style={{ borderBottom: "1px solid" }}
          >
            서비스 단가 설정
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10% 20%"
            }}
          >
            <Box
              style={{
                border: "1px solid",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <Box style={{ textAlign: "right" }}>
                <Typography
                  style={{
                    display: "inline"
                  }}
                >
                  *&nbsp;
                </Typography>
                <Typography
                  style={{
                    // border: "1px solid",
                    display: "inline"
                  }}
                >
                  15 Days&nbsp;
                </Typography>
                <Typography
                  style={{
                    display: "inline"
                  }}
                >
                  당
                </Typography>
              </Box>
              <Box
                sx={{ mt: 4 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem"
                  }}
                >
                  현재:
                </Typography>
                <Typography
                  sx={{ ml: 2 }}
                  style={{
                    border: "1px solid",
                    width: "148px",
                    height: "33px",
                    display: "flex",
                    fontWeight: "bold",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {sponsorPrice?.price ? `${sponsorPrice.price} USD` : ""}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <ArrowDropDownIcon sx={{ width: 100, height: 68 }} />
              </Box>
              <Box
                sx={{ mb: 4 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography
                  style={{
                    display: "inline",
                    fontWeight: "bold",
                    fontSize: "1.2rem"
                  }}
                >
                  변경 값 :
                </Typography>
                <StyledTextField
                  sx={{ ml: 2 }}
                  height="0px"
                  value={priceChange}
                  onChange={(e) => {
                    const priceChange = e.target.value?.trim();
                    const lengthValue = priceChange?.length;
                    const lastChar = priceChange[lengthValue - 1];
                    if (lastChar) {
                      const arraypriceChangeValue = priceChange?.split('');
                      if (lengthValue === 1 && !/^[1-9]$/.test(lastChar)) return;
                      if (arraypriceChangeValue.filter((c) => c === '.').length > 1)
                        return;
                      if (!/^[.\d]$/.test(lastChar)) return;
                    }
                    setPriceChange(e.target.value)
                  }}
                  onFocus={(e) => {
                    if ([0, '0'].includes(e.target.value)) {
                      setPriceChange('')
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === '') setPriceChange(0);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{ mt: 4 }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center"
              }}
            >
              <StyledButtonList variant="outlined" onClick={handleSave}>
                저장
              </StyledButtonList>
              <StyledButtonList variant="outlined" onClick={handleReset}>
                초기화
              </StyledButtonList>
            </Box>
          </Box>
          <Popup2Action
            open={openPopup}
            setOpen={setOpenPopup}
            message="스폰서 광고 아이템의 가격을 변경하시겠습니까?"
            onClickConfirm={savePrice}
            onClickCancel={() => setOpenPopup(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(SponsorItemPrice);
