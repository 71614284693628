import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { withQuery, withState, withToast } from "HOC";
import { ManageCreditService, ManageUploader } from "services";
import { TOAST_TYPE, SUCCESS_STATUS } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Box
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
import { formatDate, formatNumber } from "utils/function";
import { useNavigate } from "react-router-dom";
import { ManageWithdrawService } from "services";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Popup2Action from "components/Popup2Action";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    cancel: createColor("#ff5454"),
    approve: createColor("#fff")
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 30,
    padding: "10px 20px",
    width: "150px",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    fontWeight: "bold"
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 30,
    padding: 5,
    width: "150px",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: "#ffff",
    backgroundColor: "#0468b4",
    fontWeight: "bold"
  }
}));

function DetailWithdrawalReceipt({ routeState, searchParams, showToast }) {
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState({});
  const [loadingProcess, setLoadingProcess] = useState({
    cancelling: false,
    approving: false
  });
  const [openPopupApprove, setOpenPopupApprove] = useState(false);
  const [openPopupCancel, setOpenPopupCancel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageCreditService.getListTransactionsById(
          searchParams.id
        );
        if (response?.data?.statusCode === 200) {
          setTransaction(response?.data ? response.data?.data : {} ?? {});
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data
            ? response.data?.message
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error.response.data?.message;
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
  }, []);

  const handleBack = () => {
    navigate("/uploader-management/withdrawal-receipt-list");
  };

  const handleCancelPayout = async () => {
    setLoadingProcess({ ...loadingProcess, cancelling: true });
    try {
      const response = await ManageUploader.updateStatusWithdrawal({
        transactionIds: [Number(searchParams.id)],
        status: "decline"
      });
      if (response?.data?.statusCode === 200) {
        showToast(TOAST_TYPE.SUCCESS, "Rejected");
        setOpenPopupCancel(false);
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
        // setOpenPopupCancel(false);
      }
      setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";

      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleApprovePayout = async () => {
    setLoadingProcess({ ...loadingProcess, approving: true });
    try {
      const response = await ManageUploader.updateStatusWithdrawal({
        transactionIds: [Number(searchParams.id)],
        status: "approved"
      });
      if (response?.data?.statusCode === 200) {
        showToast(TOAST_TYPE.SUCCESS, "Approved");
        setOpenPopupApprove(false);
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, approving: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, approving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Layout header="업로더 관리 - Profit 출금 접수 목록 - 상세보기">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontWeight: "bold"
          }}
        >
          <Box
            sx={{
              border: "1px solid #a5a5a5",
              borderRadius: 1,
              padding: "5px 10px",
              textAlign: "center",
              mt: 5
            }}
          >
            출금 요청 정보
          </Box>
          <Box sx={{ mt: 2 }}>
            <TableContainer style={{ border: "1px solid #a5a5a5" }}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <StyledTableCellLeft>출금자명</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction?.user?.nickName ?? ""}
                    </StyledTableCell>
                    {/* <StyledTableCell>{routeState?.nick ?? ""}</StyledTableCell> */}
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>출금 요청 일시</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction?.createdAt
                        ? formatDate(
                            transaction.createdAt,
                            "YYYY.MM.DD hh:mm a"
                          )
                        : ""}
                    </StyledTableCell>
                    {/* <StyledTableCell></StyledTableCell> */}
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>Profit 잔액</StyledTableCellLeft>
                    <StyledTableCell style={{ textTransform: "capitalize" }}>
                      {/* {transaction?.hoarding */}
                      {transaction?.balance
                        ? "$ " + formatNumber(Number(transaction.balance))
                        : ""}
                    </StyledTableCell>
                    {/* <StyledTableCell></StyledTableCell> */}
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>출금 금액</StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.amount
                        ? "$" +
                          formatNumber(Number(Math.abs(transaction.amount)))
                        : ""}
                    </StyledTableCell>
                    {/* <StyledTableCell></StyledTableCell> */}
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>요청 수단</StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.paymentMethod ?? ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>
                      Paypal 이메일 주소
                    </StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.email ? transaction.email : ""}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      {routeState?.ppEmail ?? ""}
                    </StyledTableCell> */}
                  </TableRow>
                  {/* <TableRow>
                    <StyledTableCellLeft>총 출금 금액</StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.amount
                        ? "$" +
                          formatNumber(Number(Math.abs(transaction.amount)))
                        : ""}
                    </StyledTableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
            {/* <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <ThemeProvider theme={theme}>
                <LoadingButton
                  style={{
                    textTransform: "capitalize",
                    border: "1px solid #a5a5a5",
                    width: 150,
                    fontWeight: "bold"
                  }}
                  color="cancel"
                  onClick={() => setOpenPopupCancel(true)}
                  loading={loadingProcess["cancelling"] ? true : false}
                  loadingIndicator="취소"
                  variant="contained"
                >
                  출금취소
                </LoadingButton>
                <LoadingButton
                  style={{
                    textTransform: "capitalize",
                    border: "1px solid #a5a5a5",
                    width: 150,
                    marginLeft: 20,
                    fontWeight: "bold"
                  }}
                  color="approve"
                  onClick={() => setOpenPopupApprove(true)}
                  loading={loadingProcess["approving"] ? true : false}
                  loadingIndicator="승인"
                  variant="contained"
                >
                  출금승인
                </LoadingButton>
              </ThemeProvider>
            </Box> */}
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                marginLeft: 20,
                flexBasis: "50%",
                fontWeight: "bold"
              }}
              onClick={handleBack}
            >
              목록
            </Button>
          </Box>
          <Popup2Action
            open={openPopupApprove}
            setOpen={setOpenPopupApprove}
            message="출금승인하시겠습니까?"
            onClickConfirm={handleApprovePayout}
            onClickCancel={() => setOpenPopupApprove(false)}
          />
          <Popup2Action
            open={openPopupCancel}
            setOpen={setOpenPopupCancel}
            message="출금승인을 취소하시겠습니까?"
            onClickConfirm={handleCancelPayout}
            onClickCancel={() => setOpenPopupCancel(false)}
          />
        </Box>
      )}
    </Layout>
  );
}

export default withState(withQuery(withToast(DetailWithdrawalReceipt)));
