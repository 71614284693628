import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  Button
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/Table";
import { formatDate, formatNumber } from "utils/function";
import {
  getListMember,
  resetMembers,
  updateMemberSuspension
} from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast, withParam } from "HOC";
import { ManageEmailService, ManageMemberService } from "services";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate } from "react-router-dom";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    suspend: createColor("#ff5454")
  }
});

function HistoryEmailSent({ showToast, params }) {
  const [loading, setLoading] = useState(true);
  const [listMember, setListMember] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const navigate = useNavigate();

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    let condition = { emailId: Number(params.id) };
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const res = await ManageEmailService.getEmailHistory(
        handleCreateCondition(isSearch, isResetting)
      );
      if (res?.data?.statusCode === 200) {
        let users = res?.data?.data ?? [];
        setCount(res?.data?.count ?? 0);
        console.log("users", users);
        setListMember([...users]);
        setLoading(false);
      } else {
        setLoading(false);
        showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      showToast(TOAST_TYPE.ERROR, message);
    }
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleBack = () => {
    navigate("/e-mail-management/list-emails");
  };

  const renderTable = () => {
    const sizeWidth = ["5%", "15%", "20%", "14%", "12%", "12%", "11%", "11%"];

    const headersTitle = [
      "No.",
      "성명",
      "이메일",
      "연락처",
      "국가",
      "회원등급",
      "가입일"
      // "발송 날짜"
    ];

    const tableData = listMember.map((member, index) => {
      return {
        ...member,
        index: index + 1 + rowsPerPage * page,
        subscription: member?.user?.createdAt
          ? formatDate(member?.user?.createdAt)
          : "",
        membershipLevel: member?.user?.level ?? "",
        nation: member?.user?.country ?? "",
        contact: member?.user?.phoneNumber ?? "",
        email: member?.user?.email ?? "",
        name: member?.user?.nickName ?? "",
        sentDate: member.createdAt ? formatDate(member.createdAt) : ""
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  return (
    <Layout header="이메일 전송 내역">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>발송 내역</Grid>
          <Grid item>{renderTable()}</Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2
            }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                fontWeight: "bold"
              }}
              onClick={handleBack}
            >
              목록
            </Button>
          </Box>
        </Grid>
      )}
    </Layout>
  );
}

export default withParam(withToast(HistoryEmailSent));
