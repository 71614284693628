import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Collapse } from "@mui/material";
import { SmallSizeLogoIcon as LogoIcon } from "components/icon";
import { routes } from "./routes";

const defaultMenuStyles = {
  display: "flex",
  alignItems: "center",
  padding: "0 32px",
  width: 258,
  height: 52,
  fontWeight: 400,
  cursor: "pointer",
};

export function Sidebar({ handleLogout }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigate = (route, childRoute) => {
    if (childRoute) {
      navigate(childRoute);
      return;
    }
    navigate(route);
  };

  return (
    <Box
      component="nav"
      sx={{
        width: 298,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "32px 0 20px",
        boxShadow: "2px 0px 4px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <LogoIcon style={{ marginBottom: "35px" }} />
        {routes.map((item) => (
          <Fragment key={item.id}>
            <Box
              key={item.id}
              sx={{
                ...defaultMenuStyles,
                fontSize: 16,
                ...(pathname.includes(item.route)
                  ? {
                      padding: "0 32px 0 28px",
                      background: "#ffe1ce",
                      borderLeft: "4px solid #ff6900",
                    }
                  : {}),
              }}
              onClick={() =>
                handleNavigate(
                  item.route,
                  item.children && item.children[0].route
                )
              }
            >
              {item.label}
            </Box>
            {item.children && (
              <Collapse in={pathname.includes(item.route)}>
                {item.children.map((sub) => (
                  <Fragment key={sub.id}>
                    <Box
                      sx={{
                        ...defaultMenuStyles,
                        fontSize: 14,
                        background: "#fff4ed",
                        color: pathname.includes(sub.route)
                          ? "#ff6900"
                          : undefined,
                      }}
                      onClick={() =>
                        handleNavigate(
                          sub.route,
                          sub.children && sub.children[0].route
                        )
                      }
                    >
                      {sub.label}
                    </Box>
                  </Fragment>
                ))}
              </Collapse>
            )}
          </Fragment>
        ))}
        <Typography
          sx={{
            width: 258,
            height: 52,
            display: "flex",
            alignItems: "center",
            padding: "0 32px",
            color: "#d70000",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          로그아웃
        </Typography>
      </Box>
    </Box>
  );
}
