export const authConstants = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  UPDATE_TOKEN: "UPDATE_TOKEN"
};

export const memberConstants = {
  GET_MEMBERS_SUCCESS: "GET_MEMBERS_SUCCESS",
  GET_MEMBERS_FAIL: "GET_MEMBERS_FAIL",
  UPDATE_MEMBER: "UPDATE_MEMBER",
  UPDATE_MEMBER_CREDIT: "UPDATE_MEMBER_CREDIT",
  UPDATE_MERCHANT_RIGHT: "UPDATE_MERCHANT_RIGHT",
  RESET_MEMBERS: "RESET_MEMBERS"
};

export const creditConstants = {
  GET_CREDITS_SUCCESS: "GET_CREDITS_SUCCESS",
  GET_CREDITS_FAIL: "GET_CREDITS_FAIL",
  UPDATE_CREDIT: "UPDATE_CREDIT"
};

export const orderConstants = {
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAIL: "GET_ORDER_FAIL",
  UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
  UPDATE_ORDER_SHIPPING: "UPDATE_ORDER_STATUS",
  RESET_ORDERS: "RESET_ORDERS"
};

export const couponConstants = {
  GET_COUPONS_SUCCESS: "GET_COUPONS_SUCCESS",
  GET_COUPONS_FAIL: "GET_COUPONS_FAIL",
  RESET_COUPONS: "RESET_COUPONS",
  DELETE_COUPONS: "DELETE_COUPONS",
  UPDATE_COUPON_STAGE: "UPDATE_COUPON_STAGE"
};

export const emailConstants = {
  GET_EMAILS_AUTO_SUCCESS: "GET_EMAILS_AUTO_SUCCESS",
  GET_EMAILS_AUTO_FAIL: "GET_EMAILS_AUTO_FAIL",
  RESET_EMAILS_AUTO: "RESET_EMAILS_AUTO",
  UPDATE_EMAIL_AUTO_STATUS: "UPDATE_EMAIL_AUTO_STATUS",
  GET_EMAILS_MANUAL_SUCCESS: "GET_EMAILS_MANUAL_SUCCESS",
  GET_EMAILS_MANUAL_FAIL: "GET_EMAILS_MANUAL_FAIL",
  RESET_EMAILS_MANUAL: "RESET_EMAILS_MANUAL",
  UPDATE_EMAIL_MANUAL_STATUS: "UPDATE_EMAIL_MANUAL_STATUS"
};

export const bannerConstants = {
  GET_BANNERS_SUCCESS: "GET_BANNERS_SUCCESS",
  GET_BANNERS_FAIL: "GET_BANNERS_FAIL",
  RESET_BANNERS: "RESET_BANNERS",
  DELETE_BANNERS: "DELETE_BANNERS",
  SET_BANNER: "SET_BANNER"
};

export const adminConstants = {
  GET_ADMINS_SUCCESS: "GET_ADMINS_SUCCESS",
  GET_ADMINS_FAIL: "GET_ADMINS_FAIL",
  RESET_ADMINS: "RESET_ADMINS",
  DELETE_ADMIN: "DELETE_ADMIN",
  CREATE_ADMIN: "CREATE_ADMIN",
  UPDATE_ADMIN: "UPDATE_ADMIN"
};
