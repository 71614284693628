import React, { useState } from "react";
import Layout from "components/Layout";
import { ManageBannerService, UploadService } from "services";
import { withToast } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button,
  InputAdornment,
  Checkbox
} from "@mui/material";
import { addMonths } from "utils/function";
import { useNavigate } from "react-router-dom";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useForm } from "react-hook-form";

import { tableCellClasses } from "@mui/material/TableCell";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ModalSubmit from "./components/ModalSubmit";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import { BANNER_CLASSIFICATION } from "./../constants";
import ClearIcon from "@mui/icons-material/Clear";

const type = [
  {
    id: "top banner",
    value: "상단 이미지",
    text: "상단 이미지"
  },
  {
    id: "low banner",
    value: "바닥글 이미지",
    text: "바닥글 이미지"
  }
];

const category = [
  {
    id: "main page",
    value: "메인페이지",
    text: "메인페이지"
  },
  {
    id: "pattern page",
    value: "패턴페이지",
    text: "패턴페이지"
  },
  {
    id: "ultrafit shop page",
    value: "울트라핏 샵 페이지",
    text: "울트라핏 샵 페이지"
  }
];

// const category = [
//   {
//     value: "middle category",
//     text: "중분류"
//   },
//   {
//     value: "subcategory",
//     text: "세분류"
//   },
//   {
//     value: "subdivision",
//     text: "소분류"
//   }
// ];

// const listSelectUrl = [
//   {
//     value: "url direct enterprise",
//     text: "URL 직접 기입"
//   }
// ];

// const listSelectLinkTarget = [
//   {
//     value: "same window",
//     text: "같은 창"
//   }
// ];

// const listSelectBannerType = [
//   {
//     value: "image",
//     text: "Image",
//   },
// ];

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    maxWidth: width ? `${width}` : "150px",
    minWidth: width ? `${width}` : "150px"
    // height: height ? `${height}` : "25px",
    // padding: "4px",
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

const StyledSelect = styled(Select)(({ width }) => ({
  borderRadius: "0",
  height: "40px",
  width: width ? width : "200px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: "0.9rem",
    height: 20,
    padding: 5,
    // textAlign: "center",
    // fontWeight: "bold",
    border: "1px solid rgb(110, 118, 118)"
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: "0.9rem",
    height: 20,
    padding: "5px 10px",
    // textAlign: "center",
    border: "1px solid rgb(110, 118, 118)"
  }
}));

function AddBanner({ showToast }) {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState({ H: "00", M: "00" });
  const [timeTo, setTimeTo] = useState({ H: "00", M: "00" });
  const [dateTo, setDateTo] = useState(addMonths(new Date()));
  const [selectedFile, setSelectedFile] = useState();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);

  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      banner: {
        name: "",
        // category: "",
        category1: "",
        category2: "",
        // category3: "",
        // category4: "",
        whetherToUse: "",
        // linkType: "url direct enterprise",
        // linkAdded: "same window",
        // type: "image",
        linkTarget: ""
      }
    }
  });
  const [whetherToUse, setWhetherToUse] = useState("");

  const onSubmit = async (data) => {
    let start = new Date(dateFrom);
    if (!isNaN(Number(timeFrom["H"])) && !isNaN(Number(timeFrom["M"]))) {
      start.setHours(Number(timeFrom["H"]), Number(timeFrom["M"]), 0, 0);
    } else {
      start.setHours(0, 0, 0, 0);
    }
    let end = new Date(dateTo);
    if (!isNaN(Number(timeTo["H"])) && !isNaN(Number(timeTo["M"]))) {
      end.setHours(Number(timeTo["H"]), Number(timeTo["M"]), 0, 0);
    } else {
      end.setHours(0, 0, 0, 0);
    }
    let body = {
      ...data.banner,
      category: `${data.banner.category1}, ${data.banner.category2}`,
      dateStart: isUnlimited ? null : start,
      dateEnd: isUnlimited ? null : end,
      file: selectedFile ?? "",
      status: whetherToUse,
      type: "banner"
    };
    try {
      const response = await ManageBannerService.setBanner({
        ...body
      });
      if (
        response.status === 201 &&
        response?.data?.status === SUCCESS_STATUS
      ) {
        showToast(TOAST_TYPE.SUCCESS, "Add success");
        setOpenModalAdd(false);
        navigate("/popup-banner-management/banner-list");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleCancel = () => {
    navigate("/popup-banner-management/banner-list");
  };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    let fileName = "";
    try {
      const form = new FormData();
      form.append("files", e.target.files[0]);
      const response = await UploadService.upload(form);
      if (response.status === 200) {
        fileName = response?.data?.files[0] ?? "";
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setSelectedFile(fileName);
  };

  return (
    <Layout header="배너 추가">
      <form onSubmit={handleSubmit(onSubmit)} id="hook-form-banner">
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCellLeft width="15%">배너명</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <StyledTextField
                    fullWidth
                    {...register("banner.name")}
                    size="small"
                    placeholder="배너명을 입력해주세요."
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCellLeft width="15%">배너 분류</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container>
                    <StyledSelect
                      MenuProps={{
                        disableScrollLock: true
                      }}
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category1")}
                    >
                      {category.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category2")}
                    >
                      {type.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    {/* <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category2")}
                    >
                      {listSelectMiddleCategory.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>

                    <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category3")}
                    >
                      {listSelectSubcategory.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>

                    <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category4")}
                    >
                      {listSelectSubdivision.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect> */}
                  </Grid>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCellLeft width="15%">기간</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container flexDirection="column">
                    <Box
                      style={{ display: "flex", alignItems: "center" }}
                      sx={{ mb: 1 }}
                    >
                      {/* <CheckBoxIcon /> */}
                      <Checkbox
                        checked={isUnlimited}
                        onChange={(e) => setIsUnlimited(e.target.checked)}
                      />
                      <Typography>무제한</Typography>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="yyyy/MM/dd"
                          value={dateFrom}
                          onChange={(value) => {
                            setDateFrom(value);
                          }}
                          disabled={isUnlimited}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              size="small"
                              height="19px"
                              width="100px"
                              disabled={isUnlimited}
                            />
                          )}
                        />
                        <span>&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeFrom["H"]}
                          onChange={(e) =>
                            setTimeFrom((prevState) => ({
                              ...prevState,
                              H: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;시&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeFrom["M"]}
                          onChange={(e) =>
                            setTimeFrom((prevState) => ({
                              ...prevState,
                              M: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;분&nbsp;</span>
                        <span>&nbsp;~&nbsp;</span>
                        <DesktopDatePicker
                          inputFormat="yyyy/MM/dd"
                          value={dateTo}
                          onChange={(value) => {
                            setDateTo(value);
                          }}
                          disabled={isUnlimited}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              size="small"
                              height="19px"
                              width="100px"
                              disabled={isUnlimited}
                            />
                          )}
                        />
                        <span>&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeTo["H"]}
                          onChange={(e) =>
                            setTimeTo((prevState) => ({
                              ...prevState,
                              H: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;시&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeTo["M"]}
                          onChange={(e) =>
                            setTimeTo((prevState) => ({
                              ...prevState,
                              M: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;분&nbsp;</span>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCellLeft width="15%">사용여부</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={whetherToUse}
                    onChange={(e) => setWhetherToUse(e.target.value)}
                  >
                    <FormControlLabel
                      // value="used"
                      value="사용함"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.9rem"
                            }
                          }}
                        />
                      }
                      label="사용함"
                    />
                    <FormControlLabel
                      // value="disabled"
                      value="사용안함"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.9rem"
                            }
                          }}
                        />
                      }
                      label="사용안함"
                    />
                  </RadioGroup>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCellLeft width="15%">링크</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container>
                    {/* <StyledSelect
                      MenuProps={{
                        disableScrollLock: true
                      }}
                      width="120px"
                      defaultValue={"url direct enterprise"}
                      {...register("banner.linkType")}
                    >
                      {listSelectUrl.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect> */}
                    <StyledTextField
                      width="400px"
                      size="small"
                      {...register("banner.linkTarget")}
                    />
                  </Grid>
                </StyledTableCell>
              </TableRow>
              {/* <TableRow>
                <StyledTableCellLeft width="15%">링크 타겟</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <StyledSelect
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    width="120px"
                    defaultValue={"same window"}
                    {...register("banner.linkAdded")}
                  >
                    {listSelectLinkTarget.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </StyledTableCell>
              </TableRow> */}
              {/* <TableRow>
                <StyledTableCellLeft width="15%">
                  Banner type
                </StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <StyledSelect
                    width="120px"
                    {...register("banner.type")}
                    defaultValue={"image"}
                  >
                    {listSelectBannerType.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </StyledTableCell>
              </TableRow> */}
              <TableRow>
                <StyledTableCellLeft width="15%">
                  파일 업로드
                </StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container alignItems="center" sx={{ mt: 1, mb: 1 }}>
                    {/* <Typography>Normal files:&nbsp;</Typography>
                    <Button
                      variant="outlined"
                      component="label"
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "0",
                        height: "36px",
                        color: "#000",
                        borderColor: "rgb(110, 118, 118)",
                      }}
                    >
                      file selection
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleSelectFile(e)}
                      />
                    </Button>
                    <Typography>
                      &nbsp;&nbsp;
                      {selectedFile ? selectedFile : "No file selected"}
                    </Typography> */}
                    <Button
                      component="label"
                      variant="outlined"
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "0",
                        height: "40px",
                        color: "#000",
                        borderColor: "rgb(110, 118, 118)",
                        marginRight: 5
                        // padding: 5
                        // maxWidth: "24px",
                        // minWidth: "24px",
                      }}
                    >
                      {/* <AttachFileIcon /> */}
                      파일위치
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleSelectFile(e)}
                      />
                    </Button>
                    <StyledTextField
                      width="400px"
                      label={selectedFile ? "파일위치" : ""}
                      size="small"
                      value={selectedFile ? selectedFile : "파일위치"}
                      InputProps={
                        selectedFile
                          ? {
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setSelectedFile("")}
                                >
                                  <ClearIcon edge="end" />
                                </InputAdornment>
                              )
                            }
                          : {}
                      }
                    />
                    <input
                      type="file"
                      onChange={(e) => handleSelectFile(e)}
                      style={{
                        position: "absolute",
                        left: "205px",
                        width: "400px",
                        height: "40px",
                        opacity: 0
                      }}
                    />
                    {/* <Button
                      variant="outlined"
                      component="label"
                      style={{
                        textTransform: "capitalize",
                        height: "48px",
                        width: "400px",
                        color: "#000",
                        borderColor: "#c4c4c4",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      Select file
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleSelectFile(e)}
                      />
                    </Button> */}
                    {/* <fieldset
                      style={{
                        height: "48px",
                        width: "400px",
                        borderColor: "#c4c4c4",
                        borderRadius: "4px",
                      }}
                    >
                      <legend>Select file&nbsp; </legend>
                    </fieldset> */}

                    {/* <label
                      htmlFor="contained-button-file"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <StyledTextField
                        htmlFor="contained-button-file"
                        width="400px"
                        label="select file"
                        size="small"
                        type="file"
                      />
                    </label> */}
                    {/* <div
                      className="v-input v-input--hide-details v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-file-input"
                      style={{ maxWidth: "300px" }}
                    >
                      <div className="v-input__prepend-outer">
                        <div className="v-input__icon v-input__icon--prepend">
                          <button
                            type="button"
                            aria-label="prepend icon"
                            className="v-icon notranslate v-icon--link mdi mdi-paperclip theme--light"
                          />
                        </div>
                      </div>
                      <div className="v-input__control">
                        <div className="v-input__slot">
                          <fieldset aria-hidden="true">
                            <legend style={{ width: "0px" }}>
                              <span className="notranslate">​</span>
                            </legend>
                          </fieldset>
                          <div className="v-text-field__slot">
                            <label
                              htmlFor="input-187"
                              className="v-label theme--light"
                              style={{
                                left: "0px",
                                right: "auto",
                                position: "absolute",
                              }}
                            >
                              Select file
                            </label>
                            <div className="v-file-input__text" />
                            <input
                              id="input-187"
                              type="file"
                              style={{ opacity: 0 }}
                            />
                          </div>
                          <div className="v-input__append-inner">
                            <div />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </Grid>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "capitalize",
              borderRadius: "0",
              height: "36px",
              marginRight: "20px"
            }}
            onClick={() => setOpenModalAdd(true)}
          >
            확인
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "capitalize",
              borderRadius: "0",
              height: "36px"
            }}
            onClick={handleCancel}
          >
            취소
          </Button>
          <ModalSubmit
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            message=" 베너기 추기되었습니다."
            // onClickConfirm={handleAdd}
            onClickCancel={() => setOpenModalAdd(false)}
          />
        </Grid>
      </form>
    </Layout>
  );
}

export default withToast(AddBanner);
