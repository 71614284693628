import { ManageBannerService } from "services";
import { bannerConstants } from "../constants";
import { SUCCESS_STATUS } from "utils/constants";

export const getListBanner = (condition = {}) => {
  return async (dispatch) => {
    try {
      const res = await ManageBannerService.getBannerList(condition);
      if (res?.data?.statusCode === 200) {
        dispatch({
          type: bannerConstants.GET_BANNERS_SUCCESS,
          payload: res?.data?.data ?? []
        });
      } else {
        dispatch({
          type: bannerConstants.GET_BANNERS_FAIL,
          payload: res?.data?.message ?? "Error"
        });
      }
    } catch (error) {
      console.log("error", error);
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "System error";
      dispatch({
        type: bannerConstants.GET_BANNERS_FAIL,
        payload: message
      });
    }
  };
};

export const updateBannerStatus = (data, condition = {}) => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.SET_BANNER,
      payload: data
    });
    dispatch(getListBanner(condition));
  };
};

export const deleteBanners = (ids, condition = {}) => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.DELETE_BANNERS,
      payload: ids
    });
    dispatch(getListBanner(condition));
  };
};

export const resetBanners = () => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.RESET_BANNERS
    });
  };
};
