import { emailConstants } from "../constants";

const initState = {
  loadingAuto: true,
  loadingManual: true,
  emailsAuto: [],
  countAuto: 0,
  emailsManual: [],
  countManual: 0,
  errorAuto: null,
  errorManual: null
};

const emailReducer = (state = initState, action) => {
  switch (action.type) {
    case emailConstants.GET_EMAILS_AUTO_SUCCESS:
      return {
        ...state,
        loadingAuto: false,
        emailsAuto: action.payload.emails,
        countAuto: action.payload.count,
        errorAuto: null
      };
    case emailConstants.GET_EMAILS_AUTO_FAIL:
      return {
        ...state,
        loadingAuto: false,
        errorAuto: action.payload
      };

    case emailConstants.UPDATE_EMAIL_AUTO_STATUS: {
      const { id = "", type = "" } = action.payload;
      const { emailsAuto = [] } = state;
      const newEmails = emailsAuto.map((email) => {
        if (id === email.id) {
          return { ...email, type: type };
        }
        return email;
      });

      return {
        ...state,
        emails: newEmails
      };
    }

    case emailConstants.RESET_EMAILS_AUTO: {
      return {
        ...state,
        loadingAuto: true,
        emailsAuto: [],
        countAuto: 0,
        errorAuto: null
      };
    }

    case emailConstants.GET_EMAILS_MANUAL_SUCCESS:
      return {
        ...state,
        loadingManual: false,
        emailsManual: action.payload.emails,
        countManual: action.payload.count,
        errorManual: null
      };
    case emailConstants.GET_EMAILS_MANUAL_FAIL:
      return {
        ...state,
        loadingManual: false,
        errorManual: action.payload
      };
    case emailConstants.RESET_EMAILS_MANUAL: {
      return {
        ...state,
        loadingManual: true,
        emailsManual: [],
        countManual: 0,
        errorManual: null
      };
    }
    default:
      return state;
  }
};

export default emailReducer;
