import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/Table";
import {
  formatDate,
  formatNumber,
  formatDateSearch,
  formatDateSearchStart,
  formatDateSearchEnd
} from "utils/function";
import {
  getListMember,
  resetMembers,
  updateMemberSuspension
} from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withParam, withToast } from "HOC";
import { ManageCouponService, ManageMemberService } from "services";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "country", text: "국가" }
];

const listLevels = [
  { value: "beginner", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    suspend: createColor("#ff5454")
  }
});

function CouponDesignatedIssuance({ showToast, params }) {
  const [listChecked, setListChecked] = useState([]);
  const [listMemberIssued, setListMemberIssued] = useState([]);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    issuing: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState({
    type: "",
    search: "",
    levels: []
  });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [state, dispatch] = useGlobalStore();

  const {
    members = [],
    loading = true,
    error = null,
    count = 0
  } = useMemo(() => state?.member, [state]);

  // useEffect(() => {
  //   dispatch(getListMember());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    // if (type) {
    condition = {
      ...condition,
      type: type ? type : "all",
      search: search ? search.trim() : ""
    };
    // }
    if (levels.length) {
      condition = { ...condition, levels: levels.join(", ") };
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss")
      };
    }

    return condition;
  };

  async function fetchData() {
    try {
      const res = await ManageCouponService.getCouponUserList(params.id);
      if (res?.data?.statusCode === 200) {
        let users = res?.data?.data ?? [];
        users = users.map((item) => item.userId);
        setListMemberIssued([...users]);
      } else {
        showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText || error.message || "";
      showToast(TOAST_TYPE.ERROR, message);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getListMember(handleCreateCondition()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetMembers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectUser = (e, userId) => {
    if (e.target.checked) {
      setListChecked([...listChecked, userId]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== userId);
      setListChecked(newListChecked);
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    // let body = {};

    // if (dateFrom && dateTo) {
    //   body = { ...body, dateFrom, dateTo };
    // }
    // if (searchTerms.sorts && searchTerms.search) {
    //   body = { ...body, type: searchTerms.sorts, search: searchTerms.search };
    // }

    // if (searchTerms.levels && searchTerms.levels.length > 0) {
    //   body = { ...body, levels: searchTerms.levels };
    // }
    //
    await dispatch(getListMember(handleCreateCondition(true, false)));
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "", search: "", levels: [] });
    setDateFrom(null);
    setDateTo(null);
    await dispatch(getListMember(handleCreateCondition(false, true)));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  // const handleGetActiveMember = async () => {
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: true });
  //   await dispatch(getListMember({ status: "active" }));
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: false });
  // };

  const handleIssuingCoupon = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No members selected");
      return;
    }

    setLoadingProcess({ ...loadingProcess, suspending: true });
    try {
      const data = {
        userIds: listChecked,
        couponId: Number(params.id)
      };

      await ManageCouponService.issueCoupon(data);
      await fetchData();
      setLoadingProcess({ ...loadingProcess, issuing: false });
      setListChecked([]);
      showToast(TOAST_TYPE.SUCCESS, "Success");
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, issuing: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
  };

  // useEffect(() => {
  //   console.log("hello");
  // }, [searchTerms.sorts]);

  const renderTable = () => {
    const sizeWidth = [
      "9%",
      "3%",
      "10%",
      "16%",
      "10%",
      "7%",
      "7%",
      "7%",
      "10%",
      "12%",
      "9%"
    ];

    const headersTitle = [
      "대상선택",
      "NO.",
      "성명",
      "이메일",
      "국가",
      "회원등급",
      "보유 Credit",
      "보유 Profit",
      "가입일",
      "상태",
      ""
    ];

    const tableData = members.map((member, index) => {
      return {
        ...member,
        index: index + 1 + rowsPerPage * page,
        checkbox: (
          <Checkbox
            checked={listChecked.includes(member.id)}
            onChange={(e) => handleSelectUser(e, member.id)}
          />
        ),
        createdAt: member.createdAt ? formatDate(member.createdAt) : "",
        suspendedTime: member.suspendedTime
          ? formatDate(member.suspendedTime)
          : null,
        issued: listMemberIssued.includes(member.id) ? "출판됨" : ""
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item style={{ fontWeight: "bold" }}>
          검색필터
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  회원가입일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ marginRight: "20px" }}>
              <Grid container alignItems="center">
                <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px"
                    }}
                  >
                    회원등급
                  </Box>
                </FormControl>
                <FormControl>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px"
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem"
                              }
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important"
                          }
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "140px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="쿠폰 지정발급">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              {/* <Grid item style={{ fontWeight: "bold" }}>
                <span>총 </span>
                <span style={{ color: "#0468b4" }}>
                  {count ? formatNumber(count) : 0}
                </span>
                <span>명</span>
              </Grid> */}
              <Grid item>
                <ThemeProvider theme={theme}>
                  <LoadingButton
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "2",
                      width: 150
                    }}
                    // color="suspend"
                    onClick={handleIssuingCoupon}
                    loading={loadingProcess["issuing"] ? true : false}
                    loadingIndicator="쿠폰 발급"
                    variant="contained"
                  >
                    쿠폰 발급
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
        </Grid>
      )}
    </Layout>
  );
}

export default withParam(withToast(CouponDesignatedIssuance));
