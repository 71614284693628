import axiosClient from "../axiosClient";
import URL from "./url";

class ManageUploader {
  getWithdrawalReceiptList(condition = { page: 1, limit: 5 }) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_WITHDRAW_RECEIPT_LIST + query;
    return axiosClient.get(url);
  }
  getProfitHistory(condition = { page: 1, limit: 5 }) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_PROFIT_HISTORY + query;
    return axiosClient.get(url);
  }
  getSponsorsList(condition = { page: 1, limit: 5 }) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_SPONSOR_LIST + query;
    return axiosClient.get(url);
  }
  getSponsorsPrice() {
    const url = URL.GET_SPONSOR_PRICE;
    return axiosClient.get(url);
  }
  createSponsorsPrice(body = {}) {
    const url = URL.CREATE_SPONSOR_PRICE;
    return axiosClient.post(url, body);
  }
  stopUploader(body = {}) {
    const url = URL.STOP_UPLOADER;
    return axiosClient.put(url, body);
  }
  reStartUploader(body = {}) {
    const url = URL.RESTART_UPLOADER;
    return axiosClient.put(url, body);
  }
  approveTransactions(body = {}) {
    const url = URL.APPROVE_TRANSACTION;
    return axiosClient.put(url, body);
  }

  rejectTransactions(body) {
    const url = URL.REJECT_TRANSACTION;
    return axiosClient.put(url, body);
  }
  updateStatusWithdrawal(body = {}) {
    const url = URL.UPDATE_STATUS_WITHDRAWAL;
    return axiosClient.put(url, body);
  }
  approveWithdrawal(body = {}) {
    const url = URL.APPROVE_WITHDRAWAL;
    return axiosClient.put(url, body);
  }
}

export default new ManageUploader();
