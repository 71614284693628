import { PrivateRouter } from "HOC";
import MerchantManagement from "../MerchantManagement";
import TermAndCondition from "../TermAndCondition";
const routeManageMerchant = [
  {
    path: "/merchant-management/merchant-list",
    element: (
      <PrivateRouter>
        <MerchantManagement />
      </PrivateRouter>
    ),
  },
  {
    path: "/merchant-management/term-condition",
    element: (
      <PrivateRouter>
        <TermAndCondition />
      </PrivateRouter>
    ),
  },
];

export default routeManageMerchant;
