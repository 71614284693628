import { ManageUltrafitShopService } from "services";
import { orderConstants } from "../constants";

export const getListOrder = (condition = {}, getProduct = false) => {
  return async (dispatch) => {
    try {
      const res = await ManageUltrafitShopService.getOrder(condition);
      console.log("res", res);
      if (res?.data?.statusCode === 200) {
        let orders = res?.data?.data ?? [];
        // console.log(
        //   "orders",
        //   orders,
        //   orders.length,
        //   !!orders.length,
        //   !!!orders.length
        // );
        // let newOrders = [...orders];
        // if (!!orders.length) {
        dispatch({
          type: orderConstants.GET_ORDER_SUCCESS,
          payload: {
            orders,
            count: res?.data?.count ?? 0
          }
        });
        //   return;
        // }
        // if (getProduct) {
        //   orders.forEach(async (order, index) => {
        //     let listProduct = [];
        //     if (order.product1) {
        //       listProduct.push({ name: "product1", id: order.product1 });
        //     }
        //     if (order.product2) {
        //       listProduct.push({ name: "product2", id: order.product2 });
        //     }
        //     if (order.product3) {
        //       listProduct.push({ name: "product3", id: order.product3 });
        //     }
        //     if (order.product4) {
        //       listProduct.push({ name: "product4", id: order.product4 });
        //     }
        //     if (order.product5) {
        //       listProduct.push({ name: "product5", id: order.product5 });
        //     }
        //     Promise.all(
        //       listProduct.map(async (item) => {
        //         const response =
        //           await ManageUltrafitShopService.getUltrafitList({
        //             code: item.id,
        //           });
        //         return {
        //           name: item.name,
        //           product: response?.data?.ult[0] ?? {},
        //         };
        //       })
        //     )
        //       .then((responses) => {
        //         return Promise.all(
        //           responses.map(function (response) {
        //             return response;
        //           })
        //         );
        //       })
        //       .then((responses) => {
        //         responses.forEach((item) => {
        //           const { name = "", product = {} } = item;
        //           order[name] = product;
        //           orders[index] = { ...order };
        //           newOrders = [...orders];
        //         });
        //         dispatch({
        //           type: orderConstants.GET_ORDER_SUCCESS,
        //           payload: newOrders,
        //         });
        //       })
        //       .catch(function (error) {
        //         const errorStatusText = error?.response
        //           ? error.response?.statusText
        //           : "";
        //         const errorDataMessage = error?.response
        //           ? error.response?.data
        //             ? error.response.data?.message
        //             : ""
        //           : "";
        //         const errorMessage =
        //           errorDataMessage ?? errorStatusText ?? "System Error";
        //         dispatch({
        //           type: orderConstants.GET_ORDER_FAIL,
        //           payload: errorMessage,
        //         });
        //       });
        //     // const responses = await Promise.all(
        //     //   listProduct.map(async (item) => {
        //     //     const response =
        //     //       await ManageUltrafitShopService.getUltrafitList({
        //     //         code: item.id,
        //     //       });
        //     //     return {
        //     //       name: item.name,
        //     //       product: response?.data?.ult[0] ?? {},
        //     //     };
        //     //   })
        //     // );
        //     // console.log("responses", responses);
        //     // responses.forEach((item) => {
        //     //   // order[item.name] = item.product;s
        //     //   orders[index][item.name] = item.product;
        //     //   newOrders = [...orders];
        //     // });
        //   });
        // } else {
        //   dispatch({
        //     type: orderConstants.GET_ORDER_SUCCESS,
        //     payload: orders,
        //   });
        // }
      } else {
        dispatch({
          type: orderConstants.GET_ORDER_FAIL,
          payload: res?.data?.message ?? ""
        });
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      dispatch({
        type: orderConstants.GET_ORDER_FAIL,
        payload: message
      });
    }
  };
};

export const updateOrderStatus = (data, condition = {}, getProduct = false) => {
  return (dispatch) => {
    dispatch({
      type: orderConstants.UPDATE_ORDER_STATUS,
      payload: data
    });
    dispatch(getListOrder(condition, getProduct));
  };
};

export const updateOrderShipping = (data) => {
  return (dispatch) => {
    dispatch({
      type: orderConstants.UPDATE_ORDER_SHIPPING,
      payload: data
    });
    dispatch(getListOrder());
  };
};

export const resetOrders = () => {
  return (dispatch) => {
    dispatch({
      type: orderConstants.RESET_ORDERS
    });
  };
};
