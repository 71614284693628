import React, { useState, useMemo, useEffect } from "react";
import withToast from "HOC/withToast";
import { Box, Button } from "@mui/material";
import DropAndDragVehicle from "./DropAndDragVehicle";
import { ManagePatternService } from "services";
import { TOAST_TYPE } from "utils/constants";
import { listGroupBy, sortList } from "utils/function";
import LoadingPage from "components/LoadingPage";
import * as XLSX from "xlsx";

function TabVehicleManagement({ showToast }) {
  const [loading, setLoading] = useState(true);
  const [timeReCall, setTimeReCall] = useState(0);
  const [listCategory, setListCategory] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  const [selectedSubModel, setSelectedSubModel] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManagePatternService.getCategoryList();
        if (response?.data?.statusCode === 200) {
          const listCategoryGroupByType = listGroupBy(
            response?.data?.data ?? [],
            "type"
          );
          const categoryFirstColumn = listCategoryGroupByType["vehicle"].filter(
            (category) => category.column === "region"
          );
          setListCategory(categoryFirstColumn);
          setLoading(false);
        } else {
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeReCall]);

  const listYear = useMemo(() => {
    if (listCategory.length > 0 && selectedRegion) {
      const region = listCategory.find(
        (category) => Number(category.id) === Number(selectedRegion)
      );
      return region?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selectedRegion]);

  const listBrand = useMemo(() => {
    if (listCategory.length > 0 && selectedRegion && selectedYear) {
      const region = listCategory.find(
        (category) => Number(category.id) === Number(selectedRegion)
      );
      const subRegion = region?.subCategory ?? [];
      const year = subRegion.find(
        (category) => Number(category.id) === Number(selectedYear)
      );
      return year?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selectedRegion, selectedYear]);

  const listModel = useMemo(() => {
    if (
      listCategory.length > 0 &&
      selectedRegion &&
      selectedYear &&
      selectedBrand
    ) {
      const region = listCategory.find(
        (category) => Number(category.id) === Number(selectedRegion)
      );
      const subRegion = region?.subCategory ?? [];
      const year = subRegion.find(
        (category) => Number(category.id) === Number(selectedYear)
      );
      const subYear = year?.subCategory ?? [];
      const branch = subYear.find(
        (category) => Number(category.id) === Number(selectedBrand)
      );
      return branch?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selectedRegion, selectedYear, selectedBrand]);

  const listSubModel = useMemo(() => {
    if (
      listCategory.length > 0 &&
      selectedRegion &&
      selectedYear &&
      selectedBrand &&
      selectedModel
    ) {
      const region = listCategory.find(
        (category) => Number(category.id) === Number(selectedRegion)
      );
      const subRegion = region?.subCategory ?? [];
      const year = subRegion.find(
        (category) => Number(category.id) === Number(selectedYear)
      );
      const subYear = year?.subCategory ?? [];
      const branch = subYear.find(
        (category) => Number(category.id) === Number(selectedBrand)
      );
      const subBranch = branch?.subCategory ?? [];
      const model = subBranch.find(
        (category) => Number(category.id) === Number(selectedModel)
      );
      return model?.subCategory ?? [];
    }
    return [];
  }, [
    listCategory,
    selectedRegion,
    selectedYear,
    selectedBrand,
    selectedModel,
  ]);

  const listSeries = useMemo(() => {
    if (
      listCategory.length > 0 &&
      selectedRegion &&
      selectedYear &&
      selectedBrand &&
      selectedModel &&
      selectedSubModel
    ) {
      const region = listCategory.find(
        (category) => Number(category.id) === Number(selectedRegion)
      );
      const subRegion = region?.subCategory ?? [];
      const year = subRegion.find(
        (category) => Number(category.id) === Number(selectedYear)
      );
      const subYear = year?.subCategory ?? [];
      const branch = subYear.find(
        (category) => Number(category.id) === Number(selectedBrand)
      );
      const subBranch = branch?.subCategory ?? [];
      const model = subBranch.find(
        (category) => Number(category.id) === Number(selectedModel)
      );
      const subOfModel = model?.subCategory ?? [];
      const subModel = subOfModel.find(
        (category) => Number(category.id) === Number(selectedSubModel)
      );
      return subModel?.subCategory ?? [];
    }
    return [];
  }, [
    listCategory,
    selectedRegion,
    selectedYear,
    selectedBrand,
    selectedModel,
    selectedSubModel,
  ]);

  const handleSelectedRegion = (id) => {
    setSelectedRegion(id);
    setSelectedYear("");
    setSelectedBrand("");
    setSelectedModel("");
    setSelectedSubModel("");
  };

  const handleSelectedYear = (id) => {
    setSelectedYear(id);
    setSelectedBrand("");
    setSelectedModel("");
    setSelectedSubModel("");
  };

  const handleSelectedBrand = (id) => {
    setSelectedBrand(id);
    setSelectedModel("");
    setSelectedSubModel("");
  };

  const handleSelectedModel = (id) => {
    setSelectedModel(id);
    setSelectedSubModel("");
  };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined);
      return;
    }

    setUploading(true);
    const fileName = e.target.files[0].name;
    if (fileName?.includes(".xlsx") || fileName?.includes(".xls")) {
      setSelectedFile(fileName);

      const promiseReadData = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const ws = wb.Sheets["Sheet1"];

          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
          setUploading(false);
        };
      });

      let regionIndex = 1;
      let currentParentRegionId = null;
      const regionLength = sortList(listCategory, "order")?.length || 0;

      let yearIndex = 1;
      let currentParentYearId = null;
      const yearLength = sortList(listYear, "order")?.length || 0;

      let brandIndex = 1;
      let currentParentBrandId = null;
      const brandLength = sortList(listBrand, "order")?.length || 0;

      let modelIndex = 1;
      let currentParentModelId = null;
      const modelLength = sortList(listModel, "order")?.length || 0;

      let submodelIndex = 1;
      let currentParentSubModelId = null;
      const submodelLength = sortList(listSubModel, "order")?.length || 0;

      let seriesIndex = 1;
      let currentParentSeriesId = null;
      const seriesLength = sortList(listSeries, "order")?.length || 0;

      await promiseReadData
        .then(async (listRow) => {

          for (let rowi = 1; rowi < listRow?.length; rowi++) {
            const [region, year, brand, model, submodel, series] =
              listRow[rowi];
            if (region || !currentParentRegionId) {
              let body = {
                column: "region",
                category: String(region),
                order: regionLength + regionIndex,
                type: "vehicle",
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentRegionId = response.data.data?.id;
                regionIndex++;
                yearIndex = 1;
                brandIndex = 1;
                modelIndex = 1;
                submodelIndex = 1;
                seriesIndex = 1;
              }
            }

            if (year) {
              let body = {
                column: "year",
                category: String(year),
                order: yearLength + yearIndex,
                type: "vehicle",
                parentId: Number(currentParentRegionId),
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentYearId = response.data.data?.id;
                yearIndex++;
                brandIndex = 1;
                modelIndex = 1;
                submodelIndex = 1;
                seriesIndex = 1;
              }
            }

            if (brand) {
              let body = {
                column: "brand",
                category: String(brand),
                order: brandLength + brandIndex,
                type: "vehicle",
                parentId: Number(currentParentYearId),
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentBrandId = response.data.data?.id;
                brandIndex++;
                modelIndex = 1;
                submodelIndex = 1;
                seriesIndex = 1;
              }
            }

            if (model) {
              let body = {
                column: "model",
                category: String(model),
                order: modelLength + modelIndex,
                type: "vehicle",
                parentId: Number(currentParentBrandId),
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentModelId = response.data.data?.id;
                modelIndex++;
                submodelIndex = 1;
                seriesIndex = 1;
              }
            }

            if (submodel) {
              let body = {
                column: "sub_model",
                category: String(submodel),
                order: submodelLength + submodelIndex,
                type: "vehicle",
                parentId: Number(currentParentModelId),
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentSubModelId = response.data.data?.id;
                submodelIndex++;
                seriesIndex = 1;
              }
            }

            if (series) {
              let body = {
                column: "series",
                category: String(series),
                order: seriesLength + seriesIndex,
                type: "vehicle",
                parentId: Number(currentParentSubModelId),
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentSeriesId = response.data.data?.id;
                seriesIndex++;
              }
              console.log('currentParentSeriesId', currentParentSeriesId)
            }
          }

          setTimeReCall((prevState) => prevState + 1);
          showToast(TOAST_TYPE.SUCCESS, "Add success");
        })
        .catch((err) => {
          // showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
          showToast(TOAST_TYPE.ERROR, err?.message || "Wrong file");
          setUploading(false);
        });
      setUploading(false);
    } else {
      showToast(TOAST_TYPE.ERROR, "Wrong file extension");
      return;
    }
  };

  if (loading || uploading) return <LoadingPage />;

  return (
    <Box style={{ width: "100%", position: "relative" }}>
      <Button
        variant="contained"
        style={{
          textTransform: "capitalize",
          borderRadius: "0",
          width: 180,
          height: 48,
          marginLeft: "auto",
          color: "#fff !important",
          position: "absolute",
          right: 0,
          top: -73,
        }}
      >
        엑셀 파일 갖고오기
        <input
          type="file"
          disabled={uploading}
          onChange={(e) => {
            handleSelectFile(e);
            e.target.value = null;
          }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            cursor: 'pointer',
            opacity: 0,
          }}
        />
      </Button>
      <Box
        sx={{
          height: "auto",
          whiteSpace: "nowrap",
          width: "100%",
          overflowX: "scroll",
        }}
      >
        <Box sx={{ display: "inline-block" }}>
          <DropAndDragVehicle
            title="Region"
            titleShow="Location"
            selected={selectedRegion}
            setSelected={handleSelectedRegion}
            listItems={sortList(listCategory, "order")}
            setTimeReCall={setTimeReCall}
            showToast={showToast}
          />
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <DropAndDragVehicle
            title="Year"
            titleShow="Year"
            selected={selectedYear}
            hasParent={!!selectedRegion}
            parent={selectedRegion}
            setSelected={handleSelectedYear}
            listItems={sortList(listYear, "order")}
            setTimeReCall={setTimeReCall}
            showToast={showToast}
          />
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <DropAndDragVehicle
            title="Brand"
            titleShow="Brand"
            selected={selectedBrand}
            hasParent={!!selectedYear}
            parent={selectedYear}
            setSelected={handleSelectedBrand}
            listItems={sortList(listBrand, "order")}
            setTimeReCall={setTimeReCall}
            showToast={showToast}
          />
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <DropAndDragVehicle
            title="Model"
            titleShow="Model"
            selected={selectedModel}
            hasParent={!!selectedBrand}
            parent={selectedBrand}
            setSelected={handleSelectedModel}
            listItems={sortList(listModel, "order")}
            setTimeReCall={setTimeReCall}
            showToast={showToast}
          />
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <DropAndDragVehicle
            title="Sub Model"
            titleShow="Sub Model"
            selected={selectedSubModel}
            hasParent={!!selectedModel}
            parent={selectedModel}
            setSelected={setSelectedSubModel}
            listItems={sortList(listSubModel, "order")}
            setTimeReCall={setTimeReCall}
            showToast={showToast}
          />
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <DropAndDragVehicle
            title="Series"
            titleShow="Series"
            hasParent={!!selectedSubModel}
            parent={selectedSubModel}
            listItems={sortList(listSeries, "order")}
            setTimeReCall={setTimeReCall}
            showToast={showToast}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default withToast(TabVehicleManagement);
