import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { withParam, withToast } from "HOC";
import { ManagePatternService } from "services";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Box,
  Grid,
  TextField,
  Typography
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
// import { formatDate } from "utils/function";
import { useNavigate } from "react-router-dom";
import { blobToFile, formatDate, formatNumber } from "utils/function";
// import { Buffer } from "buffer";
import Popup from "components/Popup";
import Popup2Action from "./components/Popup2Action";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 20,
    padding: 5,
    minWidth: "230px",
    textAlign: "center",
    border: "1px solid #a5a5a5",
    fontWeight: "bold"
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 20,
    padding: 5,
    minWidth: "230px",
    textAlign: "center",
    border: "1px solid #a5a5a5",
    color: "#ffff",
    backgroundColor: "#0468b4",
    fontWeight: "bold"
  }
}));

const StyledBoxContent = styled(Box)({
  border: "1px solid #a5a5a5",
  borderRadius: "5px",
  padding: "2px",
  width: 200,
  textAlign: "center",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxImageTitle = styled(Box)({
  fontSize: "0.9rem",
  height: "32px",
  padding: 5,
  width: "230px",
  textAlign: "center",
  border: "1px solid #a5a5a5",
  color: "#ffff",
  backgroundColor: "#0468b4",
  fontWeight: "bold"
});

const StyledBoxImageContent = styled(Box)({
  fontSize: "0.9rem",
  height: "160px",
  padding: 5,
  width: "230px",
  textAlign: "center",
  border: "1px solid #a5a5a5",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxIntroductionTitle = styled(Box)({
  fontSize: "0.9rem",
  height: "70px",
  width: "230px",
  border: "1px solid #a5a5a5",
  color: "#ffff",
  backgroundColor: "#0468b4",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxIntroductionContent = styled(Box)({
  fontSize: "0.9rem",
  height: "70px",
  width: "100%",
  textAlign: "center",
  border: "1px solid #a5a5a5",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxPatternFile = styled(Box)({
  fontSize: "0.9rem",
  height: "70px",
  width: "100%",
  padding: "0 0 0 10px",
  border: "1px solid #a5a5a5",
  display: "flex",
  alignItems: "center"
});

const StyledButtonEdit = styled(Button)({
  textTransform: "capitalize",
  border: "1px solid #a5a5a5",
  padding: 0
});

const StyledButtonList = styled(Button)({
  border: "1px solid #a5a5a5",
  textTransform: "capitalize",
  color: "#000",
  width: 150,
  marginLeft: 20,
  fontWeight: "bold"
});

const StyledLoadingButton = styled(LoadingButton)({
  textTransform: "capitalize",
  border: "1px solid #a5a5a5",
  width: 150,
  fontWeight: "bold"
});

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& label": {
    paddingLeft: (theme) => theme.spacing(2)
  },
  "& input": {
    width: width ? width : "100%",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    paddingLeft: (theme) => theme.spacing(2.5),
    borderRadius: 0
  }
}));

function DetailApproveRegistration({ params, showToast }) {
  const [loading, setLoading] = useState(true);
  const [pattern, setPattern] = useState({});
  const [loadingProcess, setLoadingProcess] = useState({
    cancelling: false,
    approving: false,
    rejecting: false
  });
  const [openApproveConfirm, setOpenApproveConfirm] = useState(false);
  const [openApproveComplete, setOpenApproveComplete] = useState(false);
  const [openRejectConfirm, setOpenRejectConfirm] = useState(false);
  const [openRejectComplete, setOpenRejectComplete] = useState(false);
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  const [openCancelReason, setOpenCancelReason] = useState(false);
  const [openCancelComplete, setOpenCancelComplete] = useState(false);
  const [openUpdateAreaConfirm, setOpenUpdateAreaConfirm] = useState(false);
  const [reason, setReason] = useState("");
  const [valueArea, setValueArea] = useState(null);
  // const [timeReCall, setTimeReCall] = useState(0);

  const navigate = useNavigate();

  async function fetchData() {
    try {
      const response = await ManagePatternService.getPatternById(params.id);
      if (response?.data?.statusCode === 200) {
        let data = response?.data ? response.data?.data : {} ?? {};
        if (data?.sqft && Number(data?.sqft)) {
          data = { ...data, sqft: Number(data?.sqft) };
        }
        setPattern(data);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    // async function fetchData() {
    //   try {
    //     const response = await ManagePatternService.getPatternById(params.id);
    //     if (response.status === 201) {
    //       setPattern(response?.data ? response.data?.patterns : {} ?? {});
    //       setLoading(false);
    //     } else {
    //       setLoading(false);
    //       const errorData = response?.data
    //         ? response.data?.msg
    //         : "Error" ?? "Error";
    //       showToast(TOAST_TYPE.ERROR, errorData);
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //     const errorStatusText = error?.response
    //       ? error.response?.statusText
    //       : "";
    //     const errorDataMessage = error?.response
    //       ? error.response?.data
    //         ? error.response.data?.message
    //         : ""
    //       : "";
    //     const errorMessage =
    //       errorDataMessage ?? errorStatusText ?? "System Error";
    //     showToast(TOAST_TYPE.ERROR, errorMessage);
    //   }
    // }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate("/pattern-management/approve-registration");
  };

  const handleRejectRejectionPattern = async () => {
    const data = {
      status: "waiting_approve"
    };
    setLoadingProcess({ ...loadingProcess, rejecting: true });
    try {
      const response = await ManagePatternService.updatePatternStatus(
        params.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        setOpenRejectConfirm(false);
        setOpenRejectComplete(true);
        navigate("/pattern-management/approve-registration");
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, rejecting: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, rejecting: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";

      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleCancelPattern = async () => {
    const data = {
      status: "reject",
      reason: reason
    };
    setLoadingProcess({ ...loadingProcess, cancelling: true });
    try {
      const response = await ManagePatternService.updatePatternStatus(
        params.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        setOpenCancelReason(false);
        setOpenCancelComplete(true);
        setReason("");
        // setTimeReCall((prevState) => prevState + 1);
        // await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleApprovePattern = async () => {
    const data = {
      status: "approved"
    };
    setLoadingProcess({ ...loadingProcess, approving: true });
    try {
      const response = await ManagePatternService.updatePatternStatus(
        params.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        setOpenApproveConfirm(false);
        setOpenApproveComplete(true);
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, approving: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, approving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const srcImg = (svg) => {
    // const base64String = new Buffer.from(svg.data).toString("base64");
    // return `data:image/png;base64,${base64String}`;
    const patternImgBlob = new Blob([new Uint8Array(svg.data)], {
      type: "image/svg+xml"
    });
    const imgBlobToFile = blobToFile(patternImgBlob, "pattenrImg");
    const imgFromFile = URL.createObjectURL(imgBlobToFile);
    return imgFromFile;
    // const bufferData = svg.data;
    // const hexString = Buffer.from(bufferData).toString("hex");
    // const imageUrl = `data:image/png;base64,${hexString}`;
    // console.log("imageUrl", imageUrl);
    // return imageUrl;
  };

  const getCategory = (type, column) => {
    if (type === "models") {
      const listModels = pattern?.models ?? [];
      const modelColumn =
        listModels.find((item) => item.column === column) || null;
      return modelColumn?.category ?? "";
    }
    if (type === "vehicles") {
      const listModels = pattern?.vehicles ?? [];
      const vehicleColumn =
        listModels.find((item) => item.column === column) || null;
      return vehicleColumn?.category ?? "";
    }
  };

  const handleUpdateArea = async (value) => {
    // const regexPattern = /^\d+(\.\d+)?\.Sq\.ft \(\d+(\.\d+)?\.Sq\.m\)$/;
    // if (!regexPattern.test(value)) {
    //   showToast(TOAST_TYPE.ERROR, "Wrong area format");
    //   return;
    // }
    // const areaNumber = value.split(".Sq.ft")[0];
    if (Number(value) <= 0) {
      showToast(TOAST_TYPE.ERROR, "Area must be a positive number");
      return;
    }
    // if (pattern.unitPrice <= 0) {
    //   showToast(TOAST_TYPE.ERROR, "UnitPrice must be a positive number");
    //   return;
    // }
    const data = {
      sqft: Number(value),
      price: Number(value) * pattern.unitPrice
    };
    // setLoadingProcess({ ...loadingProcess, cancelling: true });
    try {
      const response = await ManagePatternService.updatePatternPrice(
        params.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        // setOpenStopReason(false);
        // setOpenStopComplete(true);
        showToast(TOAST_TYPE.SUCCESS, "Update Success");
        setValueArea(null);
        setOpenUpdateAreaConfirm(false);
        // setReason("");
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.message?.[0]
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      // setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      // setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const getCategoryList = (pattern) => {
    const listModels = pattern?.models ?? [];
    const listModelsCategory = listModels.map((item) => item.category);
    const listVehicles = pattern?.vehicles ?? [];
    const listVehiclesCategory = listVehicles.map((item) => item.category);
    return [...listModelsCategory, ...listVehiclesCategory].join(" > ");
  };

  const handleOpenEditor = () => {
    const token = window.localStorage.getItem("token");
    const currentRefreshToken = localStorage.getItem("refreshToken");
    // const windowOpen =
    window.open(
      `${process.env.REACT_APP_EDITOR_URL}?patternId=${pattern.patternId}&token=${token}&refreshToken=${currentRefreshToken}`,
      "_blank"
    );

    // windowOpen.localStorage.setItem("token", token);
  };

  return (
    <Layout header="패턴관리 - 패턴 승인/등록 - 패턴 보기">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <StyledBoxContent sx={{ mb: 2 }}>업로더 정보</StyledBoxContent>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            style={{
              flexWrap: "wrap",
              gap: "12px"
            }}
          >
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>Uploader</StyledTableCellLeft>
                      <StyledTableCell>
                        {pattern.nickName ? pattern.nickName : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Company</StyledTableCellLeft>
                      <StyledTableCell>
                        {pattern.companyName ? pattern.companyName : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Country</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.country ? pattern.country : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Join Date</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.createdAt ? formatDate(pattern.createdAt) : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Contact Email</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.email ? pattern.email : ""}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <Box style={{ display: "flex" }}>
                <StyledBoxImageTitle>CI/BI Image</StyledBoxImageTitle>
                <StyledBoxImageContent>
                  {pattern.avatar ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                        width: "100px"
                      }}
                    >
                      <img
                        src={pattern.avatar}
                        style={{
                          borderRadius: "50%",
                          objectFit: "cover",
                          height: "100px",
                          width: "100px"
                        }}
                        alt=""
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </StyledBoxImageContent>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 2 }}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <StyledBoxIntroductionTitle>
              Introduction
            </StyledBoxIntroductionTitle>
            <StyledBoxIntroductionContent>
              {pattern?.introduction && pattern?.introduction.trim()
                ? pattern.introduction
                : "X"}
            </StyledBoxIntroductionContent>
          </Box>

          <Box
            style={{ display: "flex", alignItems: "flex-end" }}
            sx={{ mt: 2 }}
          >
            <StyledBoxContent sx={{ mb: 2, mr: 2 }}>패턴 정보</StyledBoxContent>
            <StyledBoxContent
              sx={{
                mb: 2,
                fontSize: "0.9rem",
                height: "20px",
                padding: "0",
                width: "auto"
              }}
            >
              {/* {pattern.patternId ? pattern.patternId : ""} */}
              {getCategoryList(pattern)}
            </StyledBoxContent>
          </Box>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            style={{
              flexWrap: "wrap",
              gap: "12px"
            }}
          >
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>Region</StyledTableCellLeft>
                      <StyledTableCell>
                        {getCategory("models", "region")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Maker</StyledTableCellLeft>
                      <StyledTableCell>
                        {/* {pattern.make ? pattern.make : ""} */}
                        {getCategory("models", "brand")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Year</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.year ? pattern.year : ""} */}
                        {getCategory("models", "year")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Model</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.model ? pattern.model : ""} */}
                        {getCategory("models", "model")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Sub model</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.subModel ? pattern.subModel : ""} */}
                        {getCategory("models", "sub_model")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Series</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.series ? pattern.series : "-"} */}
                        {getCategory("models", "series")}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>Side</StyledTableCellLeft>
                      <StyledTableCell>
                        {/* {pattern.side ? pattern.side : ""} */}
                        {getCategory("vehicles", "part_section")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Pattern Part</StyledTableCellLeft>
                      <StyledTableCell>
                        {/* {pattern.part ? pattern.part : ""} */}
                        {getCategory("vehicles", "pattern_part")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Sub part</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.subPart ? pattern.subPart : ""} */}
                        {getCategory("vehicles", "sub_part")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Tag</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.tags ? pattern.tags : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Price</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.unitPrice && Number(pattern.unitPrice)
                          ? "$ " + formatNumber(Number(pattern.unitPrice))
                          : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Area</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.area ? pattern.area + "ft" : "-"} */}
                        <TextField
                          // autoFocus
                          inputProps={{
                            step: 0.0001,
                            min: 0,
                            type: "number",
                            style: { textAlign: "center" }
                          }}
                          defaultValue={pattern?.sqft ?? ""}
                          placeholder="Sq.ft"
                          onBlur={(e) => {
                            setValueArea(e.target.value);
                            setTimeout(() => {
                              setOpenUpdateAreaConfirm(true);
                            }, 0);
                          }}
                          margin="dense"
                          fullWidth
                          variant="standard"
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 2 }}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <StyledButtonEdit variant="outline" onClick={handleOpenEditor}>
              Editor
            </StyledButtonEdit>
          </Box>
          <Box
            sx={{ mt: 2 }}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <StyledBoxIntroductionTitle>
              Pattern File
            </StyledBoxIntroductionTitle>
            <StyledBoxPatternFile>
              {pattern.svg ? (
                <img
                  style={{
                    height: "65px"
                  }}
                  src={pattern.svg ? pattern.svg : srcImg(pattern.plt)}
                  alt=""
                />
              ) : (
                ""
              )}
            </StyledBoxPatternFile>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-start" }}>
            <StyledBoxIntroductionTitle>
              Description Memo
            </StyledBoxIntroductionTitle>
            <StyledBoxPatternFile>
              {pattern.description ? pattern.description : "X"}
            </StyledBoxPatternFile>
          </Box>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            {pattern && pattern.status !== "reject" ? (
              <React.Fragment>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <StyledLoadingButton
                    style={{
                      textTransform: "capitalize",
                      border: "1px solid #a5a5a5",
                      width: 150,
                      marginRight: 20,
                      fontWeight: "bold"
                    }}
                    color="cancel"
                    onClick={() => setOpenApproveConfirm(true)}
                    loading={loadingProcess["approving"] ? true : false}
                    loadingIndicator="승인"
                    variant="contained"
                  >
                    패턴승인
                  </StyledLoadingButton>
                  <StyledLoadingButton
                    color="approve"
                    onClick={() => setOpenCancelConfirm(true)}
                    loading={loadingProcess["cancelling"] ? true : false}
                    loadingIndicator="승인거절"
                    variant="contained"
                  >
                    승인거절
                  </StyledLoadingButton>
                </Box>
              </React.Fragment>
            ) : (
              <StyledLoadingButton
                color="cancel"
                onClick={() => setOpenRejectConfirm(true)}
                loading={loadingProcess["rejecting"] ? true : false}
                loadingIndicator="거절"
                variant="contained"
              >
                거절철회
              </StyledLoadingButton>
            )}
            <StyledButtonList variant="outlined" onClick={handleBack}>
              목록
            </StyledButtonList>
          </Box>
          {/*
                Approve popup
          */}
          <Popup2Action
            open={openApproveConfirm}
            setOpen={setOpenApproveConfirm}
            message="패턴을 승인하시겠습니까?"
            onClickConfirm={handleApprovePattern}
            onClickCancel={() => setOpenApproveConfirm(false)}
          />

          <Popup
            open={openApproveComplete}
            setOpen={setOpenApproveComplete}
            button="확인"
            message="패턴 승인이 완료되었습니다."
            onClick={() => setOpenApproveComplete(false)}
          />
          {/*
            Rejected
          */}
          <Popup2Action
            open={openCancelConfirm}
            setOpen={setOpenCancelConfirm}
            message="패턴 승인 요청을 거절하시겠습니까?"
            onClickConfirm={() => {
              setOpenCancelConfirm(false);
              setOpenCancelReason(true);
            }}
            onClickCancel={() => setOpenCancelConfirm(false)}
          />

          <Popup
            open={openCancelReason}
            setOpen={setOpenCancelReason}
            message="사유를 입력해주세요."
            button="확인"
            onClick={handleCancelPattern}
          >
            <Box
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                flexWrap: "nowrap",
                gap: "5px",
                width: "80%",
                textAlign: "center"
              }}
            >
              <Typography style={{ fontSize: "0.9rem" }}>
                *업로더에게 거절 사유로 보여집니다.
              </Typography>
              <StyledTextField
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Box>
          </Popup>
          <Popup
            open={openCancelComplete}
            setOpen={setOpenCancelComplete}
            button="확인"
            message="패턴 승인 요청 거절이 완료되었습니다."
            onClick={() => {
              setOpenCancelComplete(false);
              navigate("/pattern-management/approve-registration");
            }}
          />
          {/*
                Reject Rejection popup
          */}
          <Popup2Action
            open={openRejectConfirm}
            setOpen={setOpenRejectConfirm}
            message="패턴 승인 거절을 철회하시겠습니까?"
            onClickConfirm={handleRejectRejectionPattern}
            onClickCancel={() => setOpenRejectConfirm(false)}
          />

          <Popup
            open={openRejectComplete}
            setOpen={setOpenRejectComplete}
            button="확인"
            message="패턴 승인 거절 철회가 완료되었습니다."
            onClick={() => setOpenRejectComplete(false)}
          />
          <Popup2Action
            open={openUpdateAreaConfirm}
            setOpen={setOpenUpdateAreaConfirm}
            message="Are you sure about the data you entered?"
            onClickConfirm={() => handleUpdateArea(valueArea)}
            onClickCancel={() => setOpenUpdateAreaConfirm(false)}
            confirmText="Yes"
            closeText="No"
          />
        </Box>
      )}
    </Layout>
  );
}

export default withParam(withToast(DetailApproveRegistration));
