import React, { useState, useEffect, useRef } from "react";
import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import withToast from "HOC/withToast";
import { Box, Button, TextareaAutosize } from "@mui/material";
import { ManageMemberService } from "services";
import { TOAST_TYPE } from "utils/constants";

function TermAndCondition({ showToast }) {
  const [loading, setLoading] = useState(true);
  // const [loadingProcess, setLoadingProcess] = useState({
  //   saving: false,
  // });
  const [policy, setPolicy] = useState("");
  const preData = useRef();
  // const textareaRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageMemberService.getPolicy({
          country: "t&c"
        });
        if (response.status === 201) {
          const resData = response?.data ? response?.data : {};
          const { policy: resPolicy = "" } = resData?.policy ?? {};
          setPolicy(resPolicy);
          preData.current = resPolicy;
          // if (textareaRef.current) {
          //   textareaRef.current.focus();
          // }
          setLoading(false);
        } else {
          showToast(TOAST_TYPE.ERROR, response?.data?.message ?? "Error");
          setLoading(false);
        }
      } catch (error) {
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
        setLoading(false);
      }
    }
    fetchData();
  }, [showToast]);

  const handleSave = async () => {
    // setLoadingProcess({ ...loadingProcess, saving: true });
    try {
      await ManageMemberService.setPolicy({
        country: "t&c",
        policy: policy
      });
      // setLoadingProcess({ ...loadingProcess, saving: false });
      preData.current = policy;
      showToast(TOAST_TYPE.SUCCESS, "Update success");
    } catch (error) {
      // setLoadingProcess({ ...loadingProcess, saving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleRest = () => {
    setPolicy(preData.current);
  };

  return (
    <Layout header="판매자 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box sx={{ flexDirection: "column" }}>
          <Box
            style={{
              backgroundColor: "#1565c0",
              color: "#ffff",
              width: "200px",
              padding: "0 10px"
            }}
          >
            T&C (계약조건)
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextareaAutosize
              // ref={textareaRef}
              // onFocus={(e) => (e.currentTarget.selectionStart = 0)}
              value={policy}
              onChange={(e) => setPolicy(e.target.value)}
              aria-label="minimum height"
              minRows={29}
              style={{ width: "100%", padding: "5px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 1
            }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                marginLeft: 20,
                fontWeight: "bold"
              }}
              onClick={handleSave}
              // disabled={loadingProcess["saving"] ? true : false}
            >
              {/* {loadingProcess["saving"] ? true : false ? "Save..." : "Save"} */}
              저장
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                marginLeft: 20,
                fontWeight: "bold"
              }}
              onClick={handleRest}
            >
              초기화
            </Button>
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default withToast(TermAndCondition);
