import {
  Draggable,
  moveItems,
  useDraggable,
  useDraggableContext
} from "react-tiny-dnd";

import { useAutoScroll } from "react-tiny-autoscroll";

import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Typography, styled, TextField } from "@mui/material";
import { PreferencesService } from "services";
import ModalRegion from "./ModalRegion";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import Popup2Action from "components/Popup2Action";
import DropDownButton from "./DropDownButton";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)({
  padding: 0,
  textTransform: "capitalize",
  borderRadius: 0,
  fontSize: "0.7rem",
  minWidth: "30px"
});

const StyledItem = {
  fontSize: "0.rem",
  border: "1px solid #a5a5a5",
  borderRadius: 5,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0",
  width: "100%"
};

function Item({
  index,
  item,
  listeners,
  isDragging,
  selected,
  setSelected,
  handleUpdate,
  handleDelete,
  style = {}
}) {
  const opacity = isDragging ? 0.5 : 1;
  const color = selected === item.id ? "#0468b4" : "";

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        margin: "0 5px",
        padding: "5px 3px",
        opacity,
        color,
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "-moz-none",
        "-o-user-select": "none",
        "user-select": "none",
        ...style,
      }}
    >
      <Box style={StyledItem}>
        <Typography
          sx={{
            display: "block",
            height: "auto",
            wordWrap: "break-word",
            whiteSpace: "initial",
            fontSize: "0.8rem",
            padding: "0 4px"
          }}
        >
          <Typography
            {...listeners}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              cursor: "move",
              wordBreak: "break-all"
            }}
            variant="body1"
            component={"span"}
          >
            {item.country}
          </Typography>
        </Typography>
        <Box style={{ display: "flex" }}>
          {/* <MoreVertIcon
            {...listeners}
            style={{ cursor: "move" }}
            fontSize="small"
          />
          <EditIcon
            style={{ cursor: "pointer", display: "block" }}
            fontSize="small"
            // onClick={() => handleUpdate(item)}
          />
          <DeleteForeverIcon
            style={{ cursor: "pointer", display: "block" }}
            fontSize="small"
            // onClick={() => handleDelete(item)}
          /> */}
          <DropDownButton
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            data={item}
          />
        </Box>
      </Box>
    </Box>
  );
}

const DraggableItem = ({
  index,
  context,
  item,
  selected,
  setSelected,
  handleUpdate,
  handleDelete
}) => {
  const { listeners, isDragging } = useDraggable(context, index);

  return (
    <Draggable
      context={context}
      key={item.id}
      index={index}
      {...{
        preview: (
          <Item
            item={item}
            index={index}
            listeners={listeners}
            isDragging={false}
            selected={selected}
            setSelected={setSelected}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            style={{
              margin: "10px 0px 0px -50px",
              padding: 0,
              "-webkit-user-select": "none",
              "-khtml-user-select": "none",
              "-moz-user-select": "-moz-none",
              "-o-user-select": "none",
              "user-select": "none",
            }}
          />
        )
      }}
    >
      <Item
        item={item}
        index={index}
        listeners={listeners}
        isDragging={isDragging}
        selected={selected}
        setSelected={setSelected}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </Draggable>
  );
};

function DropAndDrag(props) {
  const {
    listItems = [],
    regionDetail = {},
    selected = "",
    setSelected = () => {},
    area = "",
    setTimeReCall = () => {},
    showToast
  } = props;

  const [items, setItems] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [regionAdded, setRegionAdded] = useState("");
  const [regionEdited, setRegionEdited] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [regionDelete, setRegionDelete] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setItems(listItems);
  }, [listItems]);

  const onDrop = async (dragIndex, overIndex) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    const listChangeIndex = [];
    nextItems.forEach((item, index) => {
      listChangeIndex.push({ ...item, order: index });
    });
    setItems(nextItems);
    // console.log(listChangeIndex);
    // await Promise.all(
    //   listChangeIndex.map((item) =>
    //     PreferencesService.setShippingRegion({
    //       code: item.id,
    //       region: item.region,
    //       area: item.area,
    //       index: item.index
    //     })
    //   )
    // );
    await PreferencesService.updateShippingRegion(regionDetail.id, {
      ...regionDetail,
      countries: [...listChangeIndex]
    });
  };

  const containerRef = useRef();

  const context = useDraggableContext({
    onDrop
  });

  useAutoScroll({ containerRef, skip: !context.isDragging });

  const handleAdd = () => {
    setOpenModalAdd(true);
  };

  const handleSaveCountryAdded = async () => {
    if (!regionAdded || (regionAdded && !regionAdded.trim())) {
      showToast(TOAST_TYPE.WARN, "Country is blank");
      return;
    }
    try {
      // const response = await PreferencesService.setShippingRegion({
      //   // dept: title,
      //   // name: nameCategoryAdded,
      //   // parent: `${parent}` ?? "",
      //   // index: items.length,
      //   area: area,
      //   region: regionAdded,
      //   index: items.length
      // });
      const response = await PreferencesService.updateShippingRegion(
        regionDetail.id,
        {
          ...regionDetail,
          countries: [
            ...regionDetail.countries,
            {
              order: regionDetail.countries.length,
              country: regionAdded.trim()
            }
          ]
        }
      );
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        setOpenModalAdd(false);
        setRegionAdded("");
        showToast(TOAST_TYPE.SUCCESS, "Add success");
      } else {
        setOpenModalAdd(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenModalAdd(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleUpdate = (item) => {
    setOpenModalEdit(true);
    console.log("item", item);
    setRegionEdited({ ...item });
  };

  const handleSaveCountryEdited = async () => {
    if (
      !regionEdited.country ||
      (regionEdited.country && !regionEdited.country.trim())
    ) {
      showToast(TOAST_TYPE.WARN, "Country is blank");
      return;
    }
    try {
      // const response = await PreferencesService.setShippingRegion(
      //   // categoryEdited.id,
      //   // {
      //   //   name: categoryEdited.name,
      //   //   dept: categoryEdited.dept,
      //   //   index: categoryEdited.index,
      //   //   parent: categoryEdited.parent,
      //   // }
      //   {
      //     code: regionEdited.id,
      //     region: regionEdited.region,
      //     area: area,
      //     index: regionEdited.index
      //   }
      // );
      // if (
      //   response.status === 201 &&
      //   response?.data?.status === SUCCESS_STATUS
      // )
      const countries = regionDetail.countries;
      const index = countries.findIndex(
        (item) => item.order === regionEdited.order
      );
      countries[index] = {
        ...regionEdited,
        country: regionEdited.country.trim()
      };
      const response = await PreferencesService.updateShippingRegion(
        regionDetail.id,
        {
          ...regionDetail,
          countries: [...countries]
        }
      );
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        setOpenModalEdit(false);
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        setOpenModalEdit(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenModalEdit(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDelete = (item) => {
    setRegionDelete(item);
    setOpenModalConfirm(true);
  };

  const handleDeleteArea = async () => {
    try {
      const response = await PreferencesService.deleteShippingRegion(
        regionDetail.id
      );
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        setOpenModalDelete(false);
        showToast(TOAST_TYPE.SUCCESS, "Delete success");
      } else {
        setOpenModalDelete(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenModalDelete(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDeleteCountry = async () => {
    try {
      // const response = await PreferencesService.deleteShippingRegion(
      //   regionDelete.id
      // );
      // if (response.status === 201 && response?.data?.message === "Deleted") {
      const countries = regionDetail.countries;
      const index = countries.findIndex(
        (item) => item.order === regionDelete.order
      );
      if (index > -1) {
        countries.splice(index, 1);
      }
      const response = await PreferencesService.updateShippingRegion(
        regionDetail.id,
        {
          ...regionDetail,
          countries: [...countries]
        }
      );
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        setOpenModalConfirm(false);
        showToast(TOAST_TYPE.SUCCESS, "Delete success");
      } else {
        setOpenModalConfirm(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenModalConfirm(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleEditArea = () => {
    navigate("/preferences/setting-the-shipping-fee/weight");
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: "30px",
        width: "200px",
        paddingBottom: "20px"
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #a5a5a5",
          borderBottom: "none",
          backgroundColor: "#0468b4",
          padding: "10px",
          color: "#fff"
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{`${area} 지역`}</Typography>
        <Box>
          <StyledButton
            variant="contained"
            color="add"
            onClick={handleAdd}
            sx={{ mr: 0.5 }}
          >
            추가
          </StyledButton>
          {/* <StyledButton
            variant="contained"
            color="add"
            sx={{ mr: 0.5 }}
            onClick={handleEditArea}
          >
            수정
          </StyledButton> */}
          <StyledButton
            variant="contained"
            color="add"
            sx={{ mr: 0.5 }}
            onClick={() => setOpenModalDelete(true)}
          >
            삭제
          </StyledButton>
        </Box>
      </Box>
      <Box
        ref={containerRef}
        style={{
          border: "1px solid #a5a5a5",
          height: "400px",
          overflowY: "scroll"
        }}
      >
        {items.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <DraggableItem
                context={context}
                index={i}
                item={item}
                setSelected={setSelected}
                selected={selected}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
            </React.Fragment>
          );
        })}
      </Box>
      <ModalRegion
        open={openModalAdd}
        setOpen={setOpenModalAdd}
        title={`지역 추가 (${area} 지역)`}
        onClickSave={handleSaveCountryAdded}
      >
        <TextField
          autoFocus
          value={regionAdded}
          onChange={(e) => setRegionAdded(e.target.value)}
          margin="dense"
          label="지역"
          fullWidth
          variant="standard"
        />
      </ModalRegion>
      <ModalRegion
        open={openModalEdit}
        setOpen={setOpenModalEdit}
        title="지역 편집"
        onClickSave={handleSaveCountryEdited}
      >
        <TextField
          autoFocus
          value={regionEdited?.country}
          onChange={(e) =>
            setRegionEdited({ ...regionEdited, country: e.target.value })
          }
          margin="dense"
          label="지역"
          fullWidth
          variant="standard"
        />
      </ModalRegion>
      <Popup2Action
        open={openModalConfirm}
        setOpen={setOpenModalConfirm}
        message="지역을 삭제하시겠습니까?"
        onClickConfirm={handleDeleteCountry}
        onClickCancel={() => setOpenModalConfirm(false)}
      />
      <Popup2Action
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        message={`정말로 ${area} 지역 을 삭제하시겠습니까?`}
        onClickConfirm={handleDeleteArea}
        onClickCancel={() => setOpenModalDelete(false)}
      />
    </Box>
  );
}

export default React.memo(DropAndDrag);
