import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import Popup2Action from "components/Popup2Action";
// import { LoginService } from "services";
// import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import { signOut } from "redux/actions/auth";
import { useDispatch } from "react-redux";
import { withToast } from "HOC";
import { Sidebar } from "./Sidebar";
import { Hedaer } from "./Header";

function ResponsiveDrawer(props) {
  const { header } = props;
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setOpenModalConfirm(false);
    dispatch(signOut());
    navigate("/login");
    // try {
    //   const logOutRes = await LoginService.logout();
    //   console.log("logOutRes", logOutRes);
    //   if (
    //     logOutRes.status === 201 &&
    //     logOutRes?.data?.status === SUCCESS_STATUS
    //   ) {
    //     setOpenModalConfirm(false);
    //     dispatch(signOut());
    //     navigate("/login");
    //   } else {
    //     setOpenModalConfirm(false);
    //     const errorMessage =
    //       logOutRes?.data?.msg ?? "Please check your Login/Password";
    //     props.showToast(TOAST_TYPE.ERROR, errorMessage);
    //   }
    // } catch (error) {
    //   setOpenModalConfirm(false);
    //   const errorStatusText = error?.response ? error.response?.statusText : "";
    //   const errorDataMessage = error?.response
    //     ? error.response?.data
    //       ? error.response.data?.message
    //       : ""
    //     : "";
    //   const errorMessage =
    //     errorDataMessage ?? errorStatusText ?? "System Error";
    //   props.showToast(TOAST_TYPE.ERROR, errorMessage);
    // }
  };

  return (
    <Box
      sx={{ display: "flex", width: "100%", height: "100%" }}
      style={{ position: "relative" }}
    >
      <CssBaseline />
      <Sidebar handleLogout={() => setOpenModalConfirm(true)} />
      <Box
        sx={{
          width: "calc(100% - 298px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Hedaer header={header} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 8,
            width: "100%",
            position: "relative",
            height: "100vh",
            overflow: "auto",
          }}
        >
          {props.children}
        </Box>
      </Box>
      <Popup2Action
        open={openModalConfirm}
        setOpen={setOpenModalConfirm}
        message="Are you sure you want to log out?"
        onClickConfirm={handleLogout}
        onClickCancel={() => setOpenModalConfirm(false)}
      />
    </Box>
  );
}

export default withToast(ResponsiveDrawer);
