import { Grid } from "@mui/material";
import React from "react";
import Layout from "../../components/Layout";

export default function NotFound() {
  return (
    <Layout header="Not found">
      <Grid container direction="column">
        <Grid item style={{ padding: "12px 20px" }}>
          <span style={{ fontSize: "2rem", display: "block" }}>
            Page not found :(
          </span>
          <span style={{ display: "block" }}>
            Maybe the page you are looking for has been removed, or you typed in
            the wrong URL
          </span>
        </Grid>
      </Grid>
    </Layout>
  );
}
