import * as React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material";

const StyledTabList = styled(TabList)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiButtonBase-root": {
    textTransform: "capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    margin: "0 12px 0 0",
    minWidth: "unset",
    fontSize: "1rem",
    color: "#000 !important",
  },
  "& .Mui-selected": {
    color: "#000 !important",
    fontWeight: "bold",
    backgroundColor: "#7777",
  },
});

const StyledTabPanel = styled(TabPanel)({
  paddingLeft: 0,
});

export default function LabTabs(props) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <StyledTabList onChange={handleChange} aria-label="lab API tabs example">
        <Tab
          label="메인 카테고리"
          value="1"
          style={{
            border: "1px solid #222",
            paddingInline: "15px",
          }}
        />
        <Tab
          label="서브 카테고리"
          value="2"
          style={{ border: "1px solid #222", paddingInline: "15px" }}
        />
      </StyledTabList>
      <StyledTabPanel value="1">{props.tab1Content}</StyledTabPanel>
      <StyledTabPanel value="2">{props.tab2Content}</StyledTabPanel>
    </TabContext>
  );
}
