import { CircularProgress, Box } from "@mui/material";
import React from "react";

export default function LoadingOneLine() {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress />
    </Box>
  );
}
