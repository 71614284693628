import { Button, Modal, Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "1px solid #c4c4c4",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "40px 0 0 0",
};

export default function Popup(props) {
  const {
    open = false,
    setOpen = () => {},
    button,
    message,
    onClick = () => {},
  } = props;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <Typography>{message}</Typography>
        <Box>
          <Button
            variant="outline"
            style={{
              border: "1px solid #c4c4c4",
              textTransform: "capitalize",
              margin: "20px 10px 20px 0",
            }}
            onClick={onClick}
          >
            {button}
          </Button>
          <Button
            variant="outline"
            style={{
              border: "1px solid #c4c4c4",
              textTransform: "capitalize",
              margin: "20px 0",
            }}
            onClick={() => setOpen(false)}
          >
            취소
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
