const URL = Object.freeze({
  GET_EMAIL_LIST: "/emails",
  UPDATE_EMAIL: "/emails",
  CREATE_EMAIL: "/emails",
  SET_EMAIL: "/admin/emailCreateUpdate",
  GET_EMAIL: "/emails",
  DELETE_EMAIL: "/emails",
  SEND_EMAIL: "/emails/send",
  GET_EMAIL_HISTORY: "/emails/history",
  GET_EMAIL_CONTACT_US: "/contact-us",
  DELETE_EMAIL_CONTACT_US: "/contact-us/delete",
  SEND_NOTIFICATION: "/contact-us/send-notification"
});

export default URL;
