// import axios from "../helpers/axios";
import { authConstants } from "./../constants";

const initState = {
  token: null,
  user: {
    name: "",
    email: "",
    picture: ""
  },
  authenticate: false
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        authenticate: true
      };
    case authConstants.LOGOUT_SUCCESS:
      return {
        ...initState
      };
    case authConstants.UPDATE_TOKEN: {
      return {
        ...state,
        token: action.payload.token
      };
    }
    default:
      return state;
  }
};

export default authReducer;
