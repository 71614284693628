const URL = Object.freeze({
  GET_PATTERN_LIST: "/patterns/admin",
  GET_PATTERN_INFO: "/patternInfo",
  GET_PATTERN_BY_ID: "/patterns",
  DELETE_RATTING: "/ratings",
  UPDATE_PATTERN_STATUS: "/patterns/status",
  UPDATE_PATTERN: "/patterns",
  UPDATE_PATTERN_PRICE: "/patterns/price",
  GET_PATTERN_COMMENT: "/patternComments",
  GET_ULTRAFIT_CATEGORY_LIST: "admin/getUltrafitCategoryList",
  GET_CATEGORY_LIST: "/categories",
  CREATE_CATEGORY: "/categories",
  UPDATE_CATEGORY: "/categories",
  DELETE_CATEGORY: "/categories",
  CREATE_CAR_MODEL: "/addCarModel",
  GET_CAR_MODEL: "/admin/getCarModelColumn",
  DELETE_CAR_MODEL: "/carModels/delete",
  UPDATE_CAR_MODEL: "/carModels",
  UPDATE_CAR_MODEL_COLUMN: "carModels"
});

export default URL;
