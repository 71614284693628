import React, { useState, useMemo, useEffect } from "react";
import withToast from "HOC/withToast";
import Layout from "components/Layout";
import { Box, Button } from "@mui/material";
import DropAndDrag from "./components/DropAndDrag";
import { ManagePatternService } from "services";
import { TOAST_TYPE } from "utils/constants";
import { listGroupBy, sortList } from "utils/function";
import LoadingPage from "components/LoadingPage";
import * as XLSX from "xlsx";

function Category({ showToast }) {
  // console.log("defaultItems", defaultItems);
  const [loading, setLoading] = useState(true);
  const [listCategory, setListCategory] = useState({});

  const [selected1Deft, setSelected1Deft] = useState(null);
  const [selected2Deft, setSelected2Deft] = useState(null);
  const [timeReCall, setTimeReCall] = useState(0);

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  // const [selectedSubPart, setSelectedSubPart] = useState(null);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await ManagePatternService.getUltrafitCategoryList();
  //       if (response.status === 201) {
  //         console.log(
  //           'listGroupBy(response?.data?.category ?? [], "dept")',
  //           listGroupBy(response?.data?.category ?? [], "dept")
  //         );
  //         setListCategory(listGroupBy(response?.data?.category ?? [], "dept"));
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //         const errorData = response?.data
  //           ? response.data?.msg
  //           : "Error" ?? "Error";
  //         showToast(TOAST_TYPE.ERROR, errorData);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       const errorStatusText = error?.response
  //         ? error.response?.statusText
  //         : "";
  //       const errorDataMessage = error?.response
  //         ? error.response?.data
  //           ? error.response.data?.message
  //           : ""
  //         : "";
  //       const errorMessage =
  //         errorDataMessage ?? errorStatusText ?? "System Error";
  //       showToast(TOAST_TYPE.ERROR, errorMessage);
  //     }
  //   }
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timeReCall]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManagePatternService.getCategoryList();
        if (response?.data?.statusCode === 200) {
          const listCategoryGroupByType = listGroupBy(
            response?.data?.data ?? [],
            "type"
          );
          const categoryFirstColumn = listCategoryGroupByType?.[
            "depth"
          ]?.filter((category) => category.column === "depth_1");
          setListCategory(categoryFirstColumn ?? []);
          setLoading(false);
        } else {
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeReCall]);

  const list2Dept = useMemo(() => {
    if (listCategory.length > 0 && selected1Deft) {
      const part1Deft = listCategory.find(
        (category) => Number(category.id) === Number(selected1Deft)
      );
      return part1Deft?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selected1Deft]);

  const list3Dept = useMemo(() => {
    if (listCategory.length > 0 && selected1Deft && selected2Deft) {
      const part1Deft = listCategory.find(
        (category) => Number(category.id) === Number(selected1Deft)
      );
      const subPart1Deft = part1Deft?.subCategory ?? [];
      const part2Deft = subPart1Deft.find(
        (category) => Number(category.id) === Number(selected2Deft)
      );
      return part2Deft?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selected1Deft, selected2Deft]);

  const handleSelectedPart1Dept = (id) => {
    setSelected1Deft(id);
    setSelected2Deft("");
  };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined);
      return;
    }

    setUploading(true);
    const fileName = e.target.files[0].name;
    if (fileName?.includes(".xlsx") || fileName?.includes(".xls")) {
      setSelectedFile(fileName);

      const promiseReadData = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const ws = wb.Sheets["Sheet1"];

          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
          setUploading(false);
        };
      });

      let depth1Index = 1;
      let currentParentDepth1Id = null;
      const depth1Length = sortList(listCategory, "order")?.length || 0;

      let depth2Index = 1;
      let currentParentDepth2Id = null;
      const depth2Length = sortList(list2Dept, "order")?.length || 0;

      let depth3Index = 1;
      let currentParentDepth3Id = null;
      const depth3Length = sortList(list3Dept, "order")?.length || 0;

      await promiseReadData
        .then(async (listRow) => {
          for (let rowi = 0; rowi < listRow?.length - 1; rowi++) {
            const [dataDepth1, dataDepth2, dataDepth3] = listRow[rowi];
            if (String(dataDepth1)?.trim() || !currentParentDepth1Id) {
              let body = {
                column: "depth_1",
                category: String(dataDepth1)?.trim(),
                order: depth1Length + depth1Index,
                type: "depth",
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentDepth1Id = response.data.data?.id;
                depth1Index++;
                depth2Index = 1;
                depth3Index = 1;
              }
            }
            if (String(dataDepth2)?.trim()) {
              let body = {
                column: "depth_2",
                category: String(dataDepth2)?.trim(),
                order: depth2Length + depth2Index,
                type: "depth",
                parentId: Number(currentParentDepth1Id),
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentDepth2Id = response.data.data?.id;
                depth2Index++;
                depth3Index = 1;
              }
            }
            if (String(dataDepth3)?.trim()) {
              let body = {
                column: "depth_3",
                category: String(dataDepth3)?.trim(),
                order: depth3Length + depth3Index,
                type: "depth",
                parentId: Number(currentParentDepth2Id),
              };
              const response = await ManagePatternService.createCategory(body);
              if (response.status === 200) {
                currentParentDepth3Id = response.data.data?.id;
                depth3Index++;
              }
            }
          }

          setTimeReCall((prevState) => prevState + 1);
          showToast(TOAST_TYPE.SUCCESS, "Add success");
        })
        .catch((err) => {
          // showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
          showToast(TOAST_TYPE.ERROR, err?.message || "Wrong file");
          setUploading(false);
        });
      setUploading(false);
    } else {
      showToast(TOAST_TYPE.ERROR, "Wrong file extension");
      setUploading(false);
      return;
    }
  };

  return (
    <Layout header="Shop 카테고리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box style={{ width: "100%", position: "relative" }}>
          <Button
            variant="contained"
            style={{
              textTransform: "capitalize",
              borderRadius: "0",
              width: 180,
              height: 48,
              marginLeft: "auto",
              color: "#fff !important",
              position: "absolute",
              right: 0,
              top: -60,
            }}
          >
            엑셀 파일 갖고오기
            <input
              type="file"
              disabled={uploading}
              onChange={(e) => {
                handleSelectFile(e);
                e.target.value = null;
              }}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                cursor: "pointer",
                opacity: 0,
              }}
            />
          </Button>
          <Box
            sx={{
              height: "100%",
              whiteSpace: "nowrap",
              width: "100%",
              overflowX: "scroll",
            }}
          >
            <Box sx={{ display: "inline-block" }}>
              <DropAndDrag
                setTimeReCall={setTimeReCall}
                title="1"
                selected={selected1Deft}
                setSelected={handleSelectedPart1Dept}
                listItems={sortList(listCategory, "order")}
                showToast={showToast}
              />
            </Box>
            <Box sx={{ display: "inline-block" }}>
              <DropAndDrag
                setTimeReCall={setTimeReCall}
                title="2"
                selected={selected2Deft}
                hasParent={!!selected1Deft}
                parent={selected1Deft}
                setSelected={setSelected2Deft}
                listItems={sortList(list2Dept, "order")}
                showToast={showToast}
              />
            </Box>
            <Box sx={{ display: "inline-block" }}>
              <DropAndDrag
                setTimeReCall={setTimeReCall}
                title="3"
                hasParent={!!selected2Deft}
                parent={selected2Deft}
                listItems={sortList(list3Dept, "order")}
                showToast={showToast}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default withToast(Category);
