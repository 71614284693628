import { orderConstants } from "../constants";

const initState = {
  loading: true,
  orders: [],
  count: 0,
  error: null
};

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case orderConstants.GET_ORDER_SUCCESS:
      console.log("GET_ORDER_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        count: action.payload.count ?? 0,
        error: null
      };
    case orderConstants.GET_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case orderConstants.UPDATE_ORDER_STATUS: {
      const { id = "", status = null } = action.payload;
      const { orders } = state;
      const newOrders = orders.map((order) => {
        if (id === order.id) {
          return { ...order, status: status };
        }
        return order;
      });

      return {
        ...state,
        orders: newOrders
      };
    }
    case orderConstants.UPDATE_ORDER_SHIPPING: {
      const { id = "", shippingURL = "", status = "" } = action.payload;
      const { orders } = state;
      const newOrders = orders.map((order) => {
        if (id === order.id) {
          return { ...order, shippingURL: shippingURL, status: status };
        }
        return order;
      });

      return {
        ...state,
        orders: newOrders
      };
    }
    case orderConstants.RESET_ORDERS: {
      return {
        loading: true,
        orders: [],
        error: null
      };
    }
    default:
      return state;
  }
};

export default orderReducer;
