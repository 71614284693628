import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import { ManageCouponService } from "services";
import { withToast, withParam } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Select,
  MenuItem
} from "@mui/material";
import {
  formatDateSearch,
  formatDateSearchEnd,
  isValidDate
} from "utils/function";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const listConditionAutomaticIssuance = [
  { value: "purchase_over_amount", text: "$이상 구매시" },
  { value: "purchasing_more_than", text: "~회 이상 구매시" },
  { value: "congratulation", text: "회원가입 시" }
];

const listCouponCategory = [
  { value: "discount", unit: "fixed_price", text: "$할인" },
  { value: "discount", unit: "percent", text: "%할인" },
  { value: "free_shipping", unit: "percent", text: "배송비 무료" }
];

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "auto",
    height: height ? `${height}` : "auto",
    padding: "4px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

const StyledSelect = styled(Select)(({ width, height }) => ({
  borderRadius: "0",
  width: width ? width : "auto",
  height: height ? height : "auto",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

function EditCoupon({ params, showToast }) {
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [isInfDate, setIsInfDate] = useState(true);

  const [benifitType, setBenifitType] = useState("discount");

  const [conditionIssuance, setConditionIssuance] = useState(
    "purchase_over_amount"
  );
  const [amountType, setAmountType] = useState("percent");

  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue
  } = useForm({
    defaultValues: {
      coupon: {
        name: "",
        description: "",
        discount: "",
        issuance: "",
        credit: "",
        greaterThanAmount: ""
        // dateFrom: "",
        // dateTo: ""
      },
      mode: "all"
    }
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await ManageCouponService.getCoupon(params.id);
        console.log("res", res);
        if (res?.data.statusCode === 200) {
          //   setDetailCoupon(res?.data?.data ?? {});
          const {
            name = "",
            description = "",
            benefitUnit = "",
            issuanceType = "",
            benefit: benifitCategory = "",
            start = "",
            end = "",
            discount = "",
            unit = "",
            greaterThanAmount = null,
            greaterThanQuantity = null
          } = res?.data?.data;
          setValue("coupon.name", name);
          setValue("coupon.description", description);
          // setValue(
          //   "coupon.benifitAmount",
          //   benifitAmount ? Number(benifitAmount) : ""
          // );
          setAmountType(benefitUnit);
          setBenifitType(benifitCategory);
          if (start && end) {
            setDateFrom(new Date(start));
            // setValue("coupon.dateFrom", moment.utc(start).format("YYYY/MM/DD"));
            // setValue("coupon.dateTo", moment.utc(end).format("YYYY/MM/DD"));
            setDateTo(new Date(formatDateSearch(end, "YYYY-MM-DD HH:mm:ss")));
            setIsInfDate(false);
          } else {
            setIsInfDate(true);
          }
          setConditionIssuance(issuanceType);
          setValue(
            "coupon.greaterThanAmount",
            greaterThanAmount
              ? Number(greaterThanAmount)
              : greaterThanQuantity
              ? Number(greaterThanQuantity)
              : ""
          );
          // if (benifitCategory === "credit_presentation") {
          //   setValue("coupon.credit", discount);
          // } else {
          //   setValue("coupon.discount", discount);
          // }
          setValue("coupon.discount", discount);
          setAmountType(unit);

          setLoading(false);
        } else {
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, res?.data?.message ?? "Error");
        }
      } catch (error) {
        setLoading(false);
        const message = error?.response
          ? error.response?.statusText
          : error?.statusText ?? "";
        showToast(TOAST_TYPE.ERROR, message);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    // const dateFrom = data.coupon.dateFrom;
    // const dateTo = data.coupon.dateTo;
    // if (!isValidDate(dateFrom) && !isInfDate) {
    //   showToast(TOAST_TYPE.ERROR, "Date must be in YYYY/MM/DD format");
    //   setError("coupon.dateFrom", {
    //     type: "invalid"
    //   });
    //   return;
    // } else {
    //   clearErrors(["coupon.dateFrom"]);
    // }
    // if (!isValidDate(dateTo) && !isInfDate) {
    //   showToast(TOAST_TYPE.ERROR, "Date must be in YYYY/MM/DD format");
    //   setError("coupon.dateTo", {
    //     type: "invalid"
    //   });
    //   return;
    // } else {
    //   clearErrors(["coupon.dateTo"]);
    // }
    // if (new Date(dateTo) < new Date(dateFrom)) {
    //   showToast(
    //     TOAST_TYPE.ERROR,
    //     "The expiration date end must be greater than the expiration date start."
    //   );
    //   setError("coupon.dateTo", {
    //     type: "invalid"
    //   });
    //   setError("coupon.dateFrom", {
    //     type: "invalid"
    //   });
    //   return;
    // }
    setSaving(true);
    if (!isInfDate && new Date(dateTo) < new Date(dateFrom)) {
      showToast(
        TOAST_TYPE.ERROR,
        "The expiration date end must be greater than the expiration date start."
      );
      setSaving(false);
      return;
    }
    let start = dateFrom
      ? formatDateSearch(dateFrom, "YYYY-MM-DD HH:mm:ss")
      : null;
    let end = dateTo ? formatDateSearchEnd(dateTo, "YYYY-MM-DD") : null;
    // let start = dateFrom ? moment.utc(dateFrom).format("YYYY-MM-DD") : null;
    // let end = dateTo ? moment.utc(dateTo).format("YYYY-MM-DD") : null;
    const issuanceClassification = conditionIssuance ?? "";

    const greaterThanAmount =
      conditionIssuance === "purchase_over_amount"
        ? data.coupon.greaterThanAmount
        : null;

    const greaterThanQuantity =
      conditionIssuance === "purchasing_more_than"
        ? data.coupon.greaterThanAmount
        : null;

    const discountAmount =
      benifitType !== "credit_presentation"
        ? data.coupon.discount
        : data.coupon.credit;

    const body = {
      name: data.coupon.name,
      description: data.coupon.description,
      greaterThanAmount: greaterThanAmount ? Number(greaterThanAmount) : null,
      greaterThanQuantity: greaterThanQuantity
        ? Number(greaterThanQuantity)
        : null,
      discount: discountAmount ? Number(discountAmount) : null,
      benefit: benifitType,
      issuanceType: issuanceClassification || null,
      unit: amountType,
      issuance: issuanceClassification ? "automatic" : "normal",
      start: !isInfDate ? start : null,
      end: !isInfDate ? end : null
    };
    try {
      const response = await ManageCouponService.updateCoupon(params.id, {
        ...body
      });
      if (response?.data?.statusCode === 200) {
        setSaving(false);
        showToast(TOAST_TYPE.SUCCESS, "Update success");
        navigate("/coupon-management/coupon-list");
      } else {
        setSaving(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setSaving(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Layout header="쿠폰 수정">
      {loading ? (
        <LoadingPage />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid component="div" container flexDirection="column">
            <Grid item component="div">
              <Grid component="div" container flexDirection="column">
                <Grid
                  item
                  style={{
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    height: "40px",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: "10px"
                  }}
                >
                  기본설정
                </Grid>
                <Grid item sx={{ mt: 1 }}>
                  <Grid container alignItems="center" sx={{ mb: 1 }}>
                    <Grid
                      item
                      sm={1.5}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      <Typography>쿠폰 명</Typography>
                    </Grid>
                    <Grid item sm={10.5} sx={{ pl: 1 }}>
                      <StyledTextField
                        width="400px"
                        placeholder="쿠폰명을 입력해주세요."
                        // {...register("coupon.name")}
                        {...register("coupon.name", {
                          required: "name is required"
                        })}
                        error={!!errors?.coupon?.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" sx={{ mb: 1 }}>
                    <Grid
                      item
                      sm={1.5}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      <Typography>쿠폰 설명</Typography>
                    </Grid>
                    <Grid item sm={10.5} sx={{ pl: 1 }}>
                      <StyledTextField
                        width="400px"
                        placeholder="쿠폰설명을 입력해주세요."
                        // {...register("coupon.description")}
                        {...register("coupon.description", {
                          required: "description is required"
                        })}
                        error={!!errors?.coupon?.description}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sm={1.5}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      <Typography>발급 구분</Typography>
                    </Grid>
                    <Grid item sm={10.5} sx={{ pl: 1 }}>
                      <Grid container>
                        <Box
                          style={{
                            border: "1px solid #c4c4c4",
                            height: "32px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0 5px"
                          }}
                        >
                          조건부 자동발급
                        </Box>

                        <StyledSelect
                          sx={{ ml: "28px" }}
                          MenuProps={{
                            disableScrollLock: true
                          }}
                          width="250px"
                          height="32px"
                          value={conditionIssuance}
                          onChange={(e) => {
                            const value = e.target.value;
                            setConditionIssuance(value);
                          }}
                        >
                          {listConditionAutomaticIssuance.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" sx={{ mt: 1 }}>
                    <Grid
                      item
                      sm={1.5}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      <Typography>사용 기준</Typography>
                    </Grid>
                    <Grid item sx={{ pl: 1 }}>
                      <StyledTextField
                        width="400px"
                        placeholder="숫자를 입력해주세요."
                        {...register(
                          "coupon.greaterThanAmount",
                          conditionIssuance &&
                            conditionIssuance !== "congratulation"
                            ? {
                                required: "greaterThanAmount is required"
                              }
                            : { required: false }
                        )}
                        error={
                          !!errors?.coupon?.greaterThanAmount &&
                          conditionIssuance !== "congratulation"
                        }
                        type="number"
                        disabled={conditionIssuance === "congratulation"}
                      />
                    </Grid>
                    <Grid item sx={{ pl: 1 }}>
                      금액($) / 횟수 이상 구매
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" sx={{ mt: 1 }}>
                    <Grid
                      item
                      sm={1.5}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      <Typography>혜택 구분</Typography>
                    </Grid>
                    <Grid item sm={10.5} sx={{ pl: 1 }}>
                      {listCouponCategory.map((item, index) => (
                        <Typography
                          key={index}
                          style={{
                            color: "white",
                            height: "33px",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #a4a4a4",
                            display: "inline-flex",
                            width: "calc(408px / 3)",
                            backgroundColor:
                              benifitType === item.value &&
                              amountType === item.unit
                                ? "#1565c0"
                                : "#c4c4c4",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            setBenifitType(item.value);
                            setAmountType(item.unit);
                          }}
                        >
                          {item.text}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" sx={{ mt: 1 }}>
                    <Grid
                      item
                      sm={1.5}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      <Typography>금액 할인</Typography>
                    </Grid>
                    <Grid item sm={10.5} sx={{ pl: 1 }}>
                      <Grid container>
                        <StyledTextField
                          width="400px"
                          placeholder="숫자만 입력가능"
                          // {...register("coupon.discount")}
                          {...register(
                            "coupon.discount",
                            benifitType !== "free_shipping"
                              ? {
                                  required: "discount is required"
                                }
                              : { required: false }
                          )}
                          error={
                            !!errors?.coupon?.discount &&
                            benifitType !== "free_shipping"
                          }
                          type="number"
                          disabled={benifitType === "free_shipping"}
                        />
                        <Typography
                          style={{
                            color: "white",
                            height: "31px",
                            width: "40px",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 5px",
                            border: "1px solid #a4a4a4",
                            display: "inline-flex",
                            backgroundColor:
                              amountType === "fixed_price" &&
                              benifitType !== "free_shipping"
                                ? "#1565c0"
                                : "#c4c4c4"
                            // cursor: "pointer"
                          }}
                          // onClick={() => setAmountType("fixed_price")}
                        >
                          $
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            height: "31px",
                            width: "40px",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 5px",
                            border: "1px solid #a4a4a4",
                            display: "inline-flex",
                            backgroundColor:
                              amountType === "percent" &&
                              benifitType !== "free_shipping"
                                ? "#1565c0"
                                : "#c4c4c4"
                            // cursor: "pointer"
                          }}
                          // onClick={() => setAmountType("percent")}
                        >
                          %
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" sx={{ mt: 1 }}>
                    <Grid
                      item
                      sm={1.5}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      사용기한
                    </Grid>
                    <Grid item sm={10.5}>
                      <Grid container alignItems="center">
                        <Typography
                          style={{
                            color: "white",
                            height: "33px",
                            display: "flex",
                            alignItems: "center",
                            padding: "0 5px",
                            backgroundColor: isInfDate ? "#1565c0" : "#c4c4c4",
                            cursor: "pointer"
                          }}
                          onClick={() => setIsInfDate(!isInfDate)}
                        >
                          무기한
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            height: "33px",
                            display: "flex",
                            alignItems: "center",
                            padding: "0 5px",
                            cursor: "pointer",
                            marginRight: "20px",
                            backgroundColor: !isInfDate ? "#1565c0" : "#c4c4c4"
                          }}
                          onClick={() => setIsInfDate(!isInfDate)}
                        >
                          기한설정
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="yyyy/MM/dd"
                            value={dateFrom}
                            maxDate={dateTo || new Date("2200-01-01")}
                            onChange={(value) => {
                              setDateFrom(value);
                            }}
                            disabled={isInfDate}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                size="small"
                                width="100px"
                              />
                            )}
                          />
                          <span>&nbsp;-&nbsp;</span>
                          <DesktopDatePicker
                            inputFormat="yyyy/MM/dd"
                            value={dateTo}
                            minDate={dateFrom || new Date("1970-01-01")}
                            disabled={isInfDate}
                            onChange={(value) => {
                              setDateTo(value);
                            }}
                            renderInput={(params) => (
                              <StyledTextField
                                {...params}
                                size="small"
                                width="100px"
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <StyledTextField
                          width="100px"
                          placeholder="yyyy/mm/dd"
                          {...register("coupon.dateFrom")}
                          error={!!errors?.coupon?.dateFrom}
                          onChange={() => {
                            clearErrors(["coupon.dateFrom", "coupon.dateTo"]);
                          }}
                          type="text"
                          disabled={isInfDate}
                        />
                        <span>&nbsp;-&nbsp;</span>
                        <StyledTextField
                          width="100px"
                          placeholder="yyyy/mm/dd"
                          {...register("coupon.dateTo")}
                          error={!!errors?.coupon?.dateTo}
                          onChange={() => {
                            clearErrors(["coupon.dateFrom", "coupon.dateTo"]);
                          }}
                          type="text"
                          disabled={isInfDate}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  loading={saving}
                  loadingIndicator="쿠폰수정"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "0",
                    height: "35px",
                    width: "120px"
                  }}
                  type="submit"
                >
                  쿠폰수정
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Layout>
  );
}

export default withParam(withToast(EditCoupon));
