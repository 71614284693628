import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManageEmailService } from "services";
import { withToast, withParam } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Editor from "./components/Editor";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EmailEditor from "../EmailManualAdd/components/EmailEditor";
import Popup2Action from "components/Popup2Action";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "0px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function EmailManualEdit({ showToast, params }) {
  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState({});
  const [openModalDelete, setOpenModalDelete] = useState(false);
  // const [detailType, setDetailType] = useState({
  //   signUp: 0,
  //   dormantAccount: 0,
  //   stopAccount: 0,
  //   shippingInfo: 0,
  // });
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await ManageEmailService.getEmail(params.id);
        if (res?.data?.statusCode === 200) {
          const data = res?.data?.data ?? {};
          // const userIds = [data.userId];
          // delete data.userId;
          // setEmail({ ...data, userIds });
          setEmail(data);
          setLoading(false);
        } else {
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, res?.data?.message ?? "Error");
        }
      } catch (error) {
        setLoading(false);
        const message = error?.response
          ? error.response?.statusText
          : error?.statusText ?? "";
        showToast(TOAST_TYPE.ERROR, message);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = async () => {
    try {
      const response = await ManageEmailService.updateEmail(params.id, {
        ...email
        // code: params.id
      });
      if (response?.data?.statusCode === 200) {
        showToast(TOAST_TYPE.SUCCESS, "Update success");
        navigate("/e-mail-management/list-emails");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await ManageEmailService.deleteEmail(params.id);
      if (response?.data?.statusCode === 200) {
        navigate("/e-mail-management/list-emails");
        setOpenModalDelete(false);
        showToast(TOAST_TYPE.SUCCESS, "Delete success");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Layout header="지정 발송 이메일 수정">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column">
          <Grid item>
            <Box
              style={{
                border: "1px solid #c4c4c4",
                display: "flex",
                flexDirection: "column",
                padding: "10px"
              }}
            >
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ mr: 2 }}>제목</Typography>
                <StyledTextField
                  height="0px"
                  value={email?.title ? email.title : ""}
                  onChange={(e) => {
                    setEmail({ ...email, title: e.target.value });
                  }}
                />
              </Grid>
              <EmailEditor data={email?.body} setData={setEmail} />
            </Box>
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Button
                onClick={handleEdit}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px"
                }}
              >
                수정하기
              </Button>
              <DeleteSweepIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenModalDelete(true)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Popup2Action
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        message="삭제하시겠습니까?"
        onClickConfirm={handleDelete}
        onClickCancel={() => setOpenModalDelete(false)}
      />
    </Layout>
  );
}

export default withParam(withToast(EmailManualEdit));
