import React, { useState } from "react";
import Layout from "components/Layout";
import { ManageEmailService } from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Editor from "./components/Editor";
import EmailEditor from "./components/EmailEditor";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "0px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function EmailManualAdd({ showToast }) {
  const [email, setEmail] = useState({
    signUp: 0,
    dormantAccount: 0,
    stopAccount: 0,
    shippingInfo: 0,
    name: "",
    body: `<div
        class="header"
        style="
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        padding: 0 30px;
        height: 80px;
      "
      >
        <img
          src="https://plofix-file.s3.us-east-2.amazonaws.com/f214dd99-a4f3-47b8-9800-de94b081202f-header.png"
          style="
          margin: 0;
          padding: 0;
          font-family: 'Montserrat', sans-serif;
          font-size: 22px;
          width: 113.93px;
          height: 37.54px;
          padding-top: 21.23px;
        "
          width="113.93"
          height="37.54"
        />
      </div>

      <div style="margin-top: 40px; margin-bottom: 40px; padding: 0 30px">
        여기에 내용을 적어주세요
      </div>

      <div
        class="footer"
        style="
          padding: 24px 22.5px;
          font-family: 'Montserrat', sans-serif;
          font-size: 22px;
          max-height: 153px;
          background-color: #0c0c35;
          margin-top: 40px;
          text-align: center;
        "
      >
        <img
          src="https://plofix-file.s3.us-east-2.amazonaws.com/042ba445-2e53-4206-a828-808f1ac47e22-footer.png"
          style="
            font-family: 'Montserrat', sans-serif;
            font-size: 22px;
            margin-bottom: 12px;
            display: inline-block;
          "
        />
        <p
          style="
            margin: 0;
            padding: 0;
            font-family: 'Montserrat', sans-serif;
            font-size: 11px;
            text-align: center;
            color: white;
          "
        >
          This account email has been sent to you as part of your PLOFIX
          membership.
        </p>
        <p
          style="
            margin: 0;
            padding: 0;
            font-family: 'Montserrat', sans-serif;
            font-size: 11px;
            text-align: center;
            color: white;
          "
        >
          Please do not reply to this email, as we are unable to respond from this
          email address.
        </p>
        <p
          style="
            margin: 0;
            padding: 0;
            font-family: 'Montserrat', sans-serif;
            font-size: 11px;
            text-align: center;
            color: white;
          "
        >
          If you need help or would like to contact us, at admin@plofix.com
        </p>
        <p
          style="
            margin: 0;
            padding: 0;
            font-family: 'Montserrat', sans-serif;
            font-size: 11px;
            text-align: center;
            color: white;
            margin-top: 5px;
          "
        >
          Copyright c. 2023, FLOFIX
        </p>
    </div>
      `
  });
  const [emailId, setEmailId] = useState("");

  const navigate = useNavigate();

  const handleAdd = async () => {
    try {
      const response = await ManageEmailService.createEmail({
        ...email,
        sendType: "manual",
        type: "welcome"
      });
      if (response?.data?.statusCode === 200) {
        // setEmailId(response?.data?.msg?.id ?? "");
        showToast(TOAST_TYPE.SUCCESS, "Add success");
        navigate("/e-mail-management/list-emails");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Layout header="지정 발송 이메일 생성">
      <Grid container flexDirection="column">
        <Grid item>
          <Box
            style={{
              border: "1px solid #c4c4c4",
              display: "flex",
              flexDirection: "column",
              padding: "10px"
            }}
          >
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              <Typography sx={{ mr: 2 }}>제목</Typography>
              <StyledTextField
                height="0px"
                value={email?.title ? email.title : ""}
                onChange={(e) => {
                  setEmail({ ...email, title: e.target.value });
                }}
              />
            </Grid>
            <EmailEditor data={email?.body} setData={setEmail} />
          </Box>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Button
              onClick={handleAdd}
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px"
              }}
            >
              생성하기
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default withToast(EmailManualAdd);
