import React, { useRef, useState } from "react";
import Layout from "components/Layout";
import { ManageBannerService, UploadService } from "services";
import { withToast } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button,
  InputAdornment,
  Checkbox
} from "@mui/material";
import { addMonths } from "utils/function";
import { useNavigate } from "react-router-dom";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useForm } from "react-hook-form";

import { tableCellClasses } from "@mui/material/TableCell";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ModalSubmit from "./components/ModalSubmit";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import { BANNER_CLASSIFICATION } from "./../constants";
import ClearIcon from "@mui/icons-material/Clear";
import { omit } from "lodash";

const type = [
  {
    value: "top image",
    text: "상단 이미지"
  },
  {
    value: "visual image",
    text: "시각적 이미지"
  },
  {
    value: "footer image",
    text: "바닥글 이미지"
  }
];

const category = [
  {
    value: "메인페이지",
    text: "메인페이지"
  },
  {
    value: "패턴페이지",
    text: "패턴페이지"
  },
  { value: "Pricing 페이지", text: "Pricing 페이지" },
  {
    value: "울트라핏샵",
    text: "울트라핏샵"
  }
];

// const category = [
//   {
//     value: "middle category",
//     text: "중분류"
//   },
//   {
//     value: "subcategory",
//     text: "세분류"
//   },
//   {
//     value: "subdivision",
//     text: "소분류"
//   }
// ];

// const listSelectUrl = [
//   {
//     value: "url direct enterprise",
//     text: "URL 직접 기입"
//   }
// ];

// const listSelectLinkTarget = [
//   {
//     value: "same window",
//     text: "같은 창"
//   }
// ];

// const listSelectBannerType = [
//   {
//     value: "image",
//     text: "Image",
//   },
// ];

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    // maxWidth: width ? `${width}` : "150px",
    // minWidth: width ? `${width}` : "150px"
    // height: height ? `${height}` : "25px",
    // padding: "4px",
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

const StyledSelect = styled(Select)(({ width }) => ({
  borderRadius: "0",
  height: "40px",
  width: width ? width : "200px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: "0.9rem",
    height: 20,
    padding: 5,
    border: "none"
    // textAlign: "center",
    // fontWeight: "bold",
    // border: "1px solid rgb(110, 118, 118)",
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: "0.9rem",
    height: 20,
    padding: "5px 10px",
    border: "none"
    // textAlign: "center",
    // border: "1px solid rgb(110, 118, 118)",
  }
}));

const StyledTableCellInner = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: "0.9rem",
    height: 20,
    padding: "5px",
    border: "none"
    // textAlign: "center",
    // border: "1px solid rgb(110, 118, 118)"
  }
}));

function AddPopup({ showToast }) {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState({ H: "00", M: "00" });
  const [timeTo, setTimeTo] = useState({ H: "00", M: "00" });
  const [dateTo, setDateTo] = useState(addMonths(new Date()));
  const [selectedFile, setSelectedFile] = useState();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);

  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  const navigate = useNavigate();

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      banner: {
        name: "",
        category: "",
        // category1: "",
        // category2: "",
        // category3: "",
        // category4: "",
        whetherToUse: "",
        // linkType: "url direct enterprise",
        // linkAdded: "same window",
        // type: "image",
        linkTarget: "",
        width: "",
        height: ""
      }
    }
  });
  const [whetherToUse, setWhetherToUse] = useState("");

  const onSubmit = async (data) => {
    let start = new Date(dateFrom);
    if (!isNaN(Number(timeFrom["H"])) && !isNaN(Number(timeFrom["M"]))) {
      start.setHours(Number(timeFrom["H"]), Number(timeFrom["M"]), 0, 0);
    } else {
      start.setHours(0, 0, 0, 0);
    }
    let end = new Date(dateTo);
    if (!isNaN(Number(timeTo["H"])) && !isNaN(Number(timeTo["M"]))) {
      end.setHours(Number(timeTo["H"]), Number(timeTo["M"]), 0, 0);
    } else {
      end.setHours(0, 0, 0, 0);
    }
    let body = {
      ...data.banner,
      // dateStart: isUnlimited ? null : start,
      // dateEnd: isUnlimited ? null : end,
      file: selectedFile ?? "",
      status: whetherToUse,
      type: "popup"
    };
    if (!isNaN(Number(data.banner.width)) && Number(data.banner.width) >= 0) {
      body = {
        ...body,
        option: {
          ...body.option,
          width: Number(data.banner.width)
        }
      };
    }
    if (!isNaN(Number(data.banner.height)) && Number(data.banner.height) >= 0) {
      body = {
        ...body,
        option: {
          ...body.option,
          height: Number(data.banner.height)
        }
      };
    }
    try {
      const response = await ManageBannerService.setBanner({
        ...omit(body, ["width", "height"])
      });
      if (response.status === 200) {
        showToast(TOAST_TYPE.SUCCESS, "Add success");
        setOpenModalAdd(false);
        navigate("/popup-banner-management/popup-list");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleCancel = () => {
    navigate("/popup-banner-management/popup-list");
  };

  const handleUploadFile = async (e) => {
    let fileName = "";
    try {
      const form = new FormData();
      form.append("file", e.target.files[0]);
      const response = await UploadService.upload(form);
      if (response.status === 200) {
        fileName = response?.data?.data ?? "";
      }
    } catch (error) {
      const errorStatusText = error?.response
        ? error?.response?.statusText
        : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setSelectedFile(fileName);
    inputRef.current.value = null;
    inputRef2.current.value = null;
  };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function (ev) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = ev.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        const bannerWidth = +getValues("banner.width") || 0;
        const bannerHeight = +getValues("banner.height") || 0;
        if (height > bannerHeight || width > bannerWidth) {
          showToast(
            TOAST_TYPE.ERROR,
            `Image's size is too big! (Uploaded image's size: width: ${width} / height: ${height})`
          );
          reader.abort();
          inputRef.current.value = null;
          inputRef2.current.value = null;
          return false;
        }
        handleUploadFile(e);
        return true;
      };
    };
  };

  return (
    <Layout header="팝업 추가">
      <form onSubmit={handleSubmit(onSubmit)} id="hook-form-banner">
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCellLeft width="15%">팝업명</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <StyledTextField
                    fullWidth
                    {...register("banner.name")}
                    size="small"
                    placeholder="팝업명을 입력해주세요."
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCellLeft width="15%">배너 분류</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container>
                    <StyledSelect
                      MenuProps={{
                        disableScrollLock: true
                      }}
                      height="25px"
                      width="180px"
                      defaultValue={getValues().banner.category}
                      {...register("banner.category")}
                    >
                      {category.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    {/* <StyledSelect
                        height="25px"
                        width="120px"
                        defaultValue={getValues().banner.category2}
                        {...register("banner.category2")}
                      >
                        {type.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </StyledSelect> */}
                    {/* <StyledTextField
                      {...register("banner.category")}
                      size="small"
                      placeholder="배너명을 입력해주세요."
                    /> */}
                  </Grid>
                </StyledTableCell>
              </TableRow>
              {/* <TableRow>
                <StyledTableCellLeft width="15%">배너 분류</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container>
                    <StyledSelect
                      MenuProps={{
                        disableScrollLock: true
                      }}
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category")}
                    >
                      {category.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.type")}
                    >
                      {type.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category2")}
                    >
                      {listSelectMiddleCategory.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>

                    <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category3")}
                    >
                      {listSelectSubcategory.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>

                    <StyledSelect
                      height="25px"
                      width="120px"
                      defaultValue={""}
                      {...register("banner.category4")}
                    >
                      {listSelectSubdivision.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Grid>
                </StyledTableCell>
              </TableRow> */}
              {/* <TableRow>
                <StyledTableCellLeft width="15%">기간</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container flexDirection="column">
                    <Box
                      style={{ display: "flex", alignItems: "center" }}
                      sx={{ mb: 1 }}
                    >
                      <Checkbox
                        checked={isUnlimited}
                        onChange={(e) => setIsUnlimited(e.target.checked)}
                      />
                      <Typography>무제한</Typography>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="yyyy/MM/dd"
                          value={dateFrom}
                          onChange={(value) => {
                            setDateFrom(value);
                          }}
                          disabled={isUnlimited}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              size="small"
                              height="19px"
                              width="100px"
                              disabled={isUnlimited}
                            />
                          )}
                        />
                        <span>&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeFrom["H"]}
                          onChange={(e) =>
                            setTimeFrom((prevState) => ({
                              ...prevState,
                              H: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;시&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeFrom["M"]}
                          onChange={(e) =>
                            setTimeFrom((prevState) => ({
                              ...prevState,
                              M: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;분&nbsp;</span>
                        <span>&nbsp;~&nbsp;</span>
                        <DesktopDatePicker
                          inputFormat="yyyy/MM/dd"
                          value={dateTo}
                          onChange={(value) => {
                            setDateTo(value);
                          }}
                          disabled={isUnlimited}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              size="small"
                              height="19px"
                              width="100px"
                              disabled={isUnlimited}
                            />
                          )}
                        />
                        <span>&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeTo["H"]}
                          onChange={(e) =>
                            setTimeTo((prevState) => ({
                              ...prevState,
                              H: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;시&nbsp;</span>
                        <StyledTextField
                          width="20px"
                          height="19px"
                          size="small"
                          value={timeTo["M"]}
                          onChange={(e) =>
                            setTimeTo((prevState) => ({
                              ...prevState,
                              M: e.target.value
                            }))
                          }
                          disabled={isUnlimited}
                        />
                        <span>&nbsp;분&nbsp;</span>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </StyledTableCell>
              </TableRow> */}
              <TableRow>
                <StyledTableCellLeft width="15%">사용여부</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={whetherToUse}
                    onChange={(e) => setWhetherToUse(e.target.value)}
                  >
                    <FormControlLabel
                      // value="used"
                      value="enable"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.9rem"
                            }
                          }}
                        />
                      }
                      label="사용함"
                    />
                    <FormControlLabel
                      // value="disabled"
                      value="disable"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.9rem"
                            }
                          }}
                        />
                      }
                      label="사용안함"
                    />
                  </RadioGroup>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCellLeft width="15%">링크</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid container>
                    {/* <StyledSelect
                      MenuProps={{
                        disableScrollLock: true
                      }}
                      width="120px"
                      defaultValue={"url direct enterprise"}
                      {...register("banner.linkType")}
                    >
                      {listSelectUrl.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect> */}
                    <StyledTextField
                      fullWidth
                      size="small"
                      {...register("banner.linkTarget")}
                    />
                  </Grid>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCellLeft width="15%">Size</StyledTableCellLeft>
                <StyledTableCell width="85%" alignItems="center">
                  <Grid container sx={{ mt: 1 }}>
                    {/* <StyledSelect
                      MenuProps={{
                        disableScrollLock: true
                      }}
                      width="120px"
                      defaultValue={"url direct enterprise"}
                      {...register("banner.linkType")}
                    >
                      {listSelectUrl.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </StyledSelect> */}
                    <StyledTableCellInner width="3%">W:</StyledTableCellInner>
                    <StyledTextField
                      fullWidth
                      size="small"
                      sx={{ flexBasis: "20%", mr: 1 }}
                      {...register("banner.width")}
                    />

                    {/* <StyledTableCellInner width="10%">
                      </StyledTableCellInner> */}

                    <StyledTableCellInner width="3%">H:</StyledTableCellInner>
                    <StyledTextField
                      fullWidth
                      size="small"
                      sx={{ flexBasis: "20%" }}
                      {...register("banner.height")}
                    />
                  </Grid>
                </StyledTableCell>
              </TableRow>
              {/* <TableRow>
                <StyledTableCellLeft width="15%">링크 타겟</StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <StyledSelect
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    width="120px"
                    defaultValue={"same window"}
                    {...register("banner.linkAdded")}
                  >
                    {listSelectLinkTarget.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </StyledTableCell>
              </TableRow> */}
              {/* <TableRow>
                <StyledTableCellLeft width="15%">
                  Banner type
                </StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <StyledSelect
                    width="120px"
                    {...register("banner.type")}
                    defaultValue={"image"}
                  >
                    {listSelectBannerType.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </StyledTableCell>
              </TableRow> */}
              <TableRow sx={{ position: "relative" }}>
                <StyledTableCellLeft width="15%">
                  파일 업로드
                </StyledTableCellLeft>
                <StyledTableCell width="85%">
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: 1, mb: 1 }}
                  >
                    <Button
                      component="label"
                      variant="outlined"
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "0",
                        height: "40px",
                        color: "#000",
                        borderColor: "rgb(110, 118, 118)",
                        marginRight: 5,
                        flexBasis: "17%"
                      }}
                    >
                      파일선택
                      <input
                        type="file"
                        ref={inputRef}
                        hidden
                        onChange={(e) => handleSelectFile(e)}
                      />
                    </Button>
                    <StyledTextField
                      // label={selectedFile ? "파일 선택" : ""}
                      size="small"
                      value={selectedFile ? selectedFile : "파일위치"}
                      disabled
                      fullWidth
                      sx={{ flexBasis: "80%" }}
                    />
                    <input
                      type="file"
                      ref={inputRef2}
                      onChange={(e) => handleSelectFile(e)}
                      style={{
                        position: "absolute",
                        right: "0",
                        width: "68%",
                        height: "40px",
                        opacity: 0
                      }}
                    />
                  </Grid>
                  <Typography sx={{ textAlign: "right" }}>
                    *Image size should be less than or equal to WxH
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "capitalize",
              borderRadius: "0",
              height: "36px",
              marginRight: "20px"
            }}
            onClick={() => setOpenModalAdd(true)}
          >
            확인
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "capitalize",
              borderRadius: "0",
              height: "36px"
            }}
            onClick={handleCancel}
          >
            취소
          </Button>
          <ModalSubmit
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            message="팝업이 추가되었습니다."
            // onClickConfirm={handleAdd}
            onClickCancel={() => setOpenModalAdd(false)}
          />
        </Grid>
      </form>
    </Layout>
  );
}

export default withToast(AddPopup);
