import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManageEmailService, UploadService } from "services";
import { withToast, withParam, withState } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/function";
import LoadingPage from "components/LoadingPage";
import Popup2Action from "components/Popup2Action";
import ModalImage from "react-modal-image";
import { isEmpty } from "lodash";
// import DownloadIcon from '@mui/icons-material/Download';
// import { fileDownload } from "utils/function";
// import Editor from "./components/Editor";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "0px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function EmailReceivedDetail({ params, showToast, routeState }) {
  // const [email, setEmail] = useState({
  //   signUp: 0,
  //   dormantAccount: 0,
  //   stopAccount: 0,
  //   shippingInfo: 0,
  //   name: "",
  //   content: ""
  // });
  // const [emailId, setEmailId] = useState("");
  const [emailDetail, setEmailDetail] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await ManageEmailService.getEmailContactUsById(params.id);
        if (res?.data?.statusCode === 200) {
          // const listEmail = res?.data?.data ?? [];
          // const email = listEmail.find((item) => item.id === Number(params.id));
          const email = res?.data?.data ?? {};
          setEmailDetail(email ?? {});
          setSelectedFile(email?.file ?? "");
          setLoading(false);
        } else {
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, res?.data?.message ?? "Error");
        }
      } catch (error) {
        setLoading(false);
        const message = error?.response
          ? error.response?.statusText
          : error?.statusText ?? "";
        showToast(TOAST_TYPE.ERROR, message);
      }
      // setEmailDetail({
      //   ...routeState
      // });
      // setSelectedFile(routeState.fileName);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const handleAdd = async () => {
  //     try {
  //       const response = await ManageEmailService.setEmail({
  //         ...email
  //       });
  //       if (response.status === 201) {
  //         setEmailId(response?.data?.msg?.id ?? "");
  //         showToast(TOAST_TYPE.SUCCESS, "Add success");
  //       } else {
  //         showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
  //       }
  //     } catch (error) {
  //       const errorStatusText = error?.response?.statusText ?? "";
  //       const errorDataMessage = error?.response?.data?.message ?? "";
  //       const errorMessage =
  //         errorDataMessage ?? errorStatusText ?? "System Error";
  //       showToast(TOAST_TYPE.ERROR, errorMessage);
  //     }
  //   };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    let fileName = "";
    try {
      const form = new FormData();
      form.append("files", e.target.files[0]);
      const response = await UploadService.upload(form);
      if (response.status === 200) {
        fileName = response?.data?.files[0] ?? "";
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setSelectedFile(fileName);
  };

  const handleReply = () => {
    navigate(`/e-mail-management/received-emails/reply/${params.id}`);
  };

  const handleDelete = async () => {
    try {
      const response = await ManageEmailService.deleteEmailContactUs({
        contactIds: [Number(params.id)]
      });
      if (response?.data?.statusCode === 200) {
        navigate("/e-mail-management/received-emails");
        setOpenModalDelete(false);
        showToast(TOAST_TYPE.SUCCESS, "Deleted");
      } else {
        showToast(TOAST_TYPE.ERROR, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const getFileName = (url) => {
    const parts = url.split("/");

    const fileName = parts[parts.length - 1];

    const fileParts = fileName.split("-");
    const actualFileName = fileParts[fileParts.length - 1];

    return actualFileName ? decodeURIComponent(actualFileName) : "";
  };

  return (
    <Layout header="받은 이메일 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column">
          <Grid item>
            <Box
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 28,
                  lineHeight: "30px",
                  mb: 2
                }}
              >
                받은 이메일
              </Typography>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ flexBasis: "20%" }}>문의종류</Typography>
                <Typography>{emailDetail?.type ?? ""}</Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ flexBasis: "20%" }}>제목</Typography>
                {/* <StyledTextField
                fullWidth
                height="0px"
                value={email?.name ? email.name : ""}
                onChange={(e) => {
                  setEmail({ ...email, name: e.target.value });
                }}
                sx={{ flexBasis: "80%" }}
              /> */}
                <Typography>{emailDetail?.title ?? ""}</Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ flexBasis: "20%" }}>성명</Typography>
                <Typography>{emailDetail?.user?.nickName ?? ""}</Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ flexBasis: "20%" }}>회원등급</Typography>
                <Typography>{emailDetail?.user?.level ?? ""}</Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ flexBasis: "20%" }}>연락처</Typography>
                <Typography>{emailDetail?.user?.phoneNumber ?? ""}</Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ flexBasis: "20%" }}>발신자 이메일</Typography>
                <Typography>{emailDetail?.email ?? ""}</Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography sx={{ flexBasis: "20%" }}>수신 날짜</Typography>
                <Typography>
                  {emailDetail.createdAt
                    ? formatDate(emailDetail.createdAt)
                    : ""}
                </Typography>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <Typography>내용</Typography>
              </Grid>
              {/* <Editor data={emailDetail?.content} setData={setEmail} /> */}

              <Grid
                container
                flexDirection="column"
                // alignItems="center"
                sx={{ mb: 2, border: "1px solid #c4c4c4", p: "20px" }}
              >
                {/* <Typography sx={{ mb: 6 }}>{emailDetail.content}</Typography> */}
                <div
                  dangerouslySetInnerHTML={{ __html: emailDetail.content }}
                />
                {emailDetail.file && (
                  <Box
                    style={{
                      width: "250px",
                      minHeight: "150px",
                      border: "1px solid #c4c4c4",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10
                    }}
                  >
                    {/* <IconButton
                      aria-label="more"
                      id="long-button"
                      sx={{
                        "&.MuiButtonBase-root": {
                          padding: 0,
                        },
                        position: 'fixed',
                        top: 10,
                        left: 10,
                        zIndex: 10000,
                        color: 'white'
                      }}
                      onClick={() => fileDownload(emailDetail.file, 'image')}
                    >
                      <DownloadIcon fontSize="medium" />
                    </IconButton> */}
                    <ModalImage
                      small={emailDetail.file}
                      large={emailDetail.file}
                      alt=""
                    />
                    {/* <img
                      src={emailDetail.file}
                      height="140px"
                      width="240px"
                      alt=""
                      style={{
                        objectFit: "cover",
                        margin: "auto"
                      }}
                      ref={imageRef}
                      onClick={onClickImage}
                    /> */}
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
          {!isEmpty(emailDetail?.reply) ? (
            <Grid item sx={{ mt: 2 }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: 28,
                    lineHeight: "30px",
                    mb: 2
                  }}
                >
                  답장 이메일
                </Typography>
                <Grid container alignItems="center" sx={{ mb: 2 }}>
                  <Typography sx={{ flexBasis: "20%" }}>제목</Typography>
                  {/* <StyledTextField
                fullWidth
                height="0px"
                value={email?.name ? email.name : ""}
                onChange={(e) => {
                  setEmail({ ...email, name: e.target.value });
                }}
                sx={{ flexBasis: "80%" }}
              /> */}
                  <Typography>{emailDetail?.reply?.title ?? ""}</Typography>
                </Grid>
                <Grid container alignItems="center" sx={{ mb: 2 }}>
                  <Typography sx={{ flexBasis: "20%" }}>
                    발신자 이메일
                  </Typography>
                  <Typography>{emailDetail?.reply?.email ?? ""}</Typography>
                </Grid>
                <Grid container alignItems="center" sx={{ mb: 2 }}>
                  <Typography sx={{ flexBasis: "20%" }}>수신 날짜</Typography>
                  <Typography>
                    {emailDetail.replyAt ? formatDate(emailDetail.replyAt) : ""}
                  </Typography>
                </Grid>
                <Grid container alignItems="center" sx={{ mb: 2 }}>
                  <Typography>내용</Typography>
                </Grid>
                {/* <Editor data={emailDetail?.content} setData={setEmail} /> */}

                <Grid
                  container
                  flexDirection="column"
                  // alignItems="center"
                  sx={{ mb: 2, border: "1px solid #c4c4c4", p: "20px" }}
                >
                  {/* <Typography sx={{ mb: 6 }}>
                    {emailDetail?.reply?.content || ""}
                  </Typography> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: emailDetail?.reply?.content
                    }}
                  />
                  {emailDetail?.reply?.file && (
                    <Box
                      style={{
                        width: "250px",
                        minHeight: "150px",
                        border: "1px solid #c4c4c4",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10
                      }}
                    >
                      <ModalImage
                        small={emailDetail?.reply?.file}
                        large={emailDetail?.reply?.file}
                        alt=""
                      />
                      {/* <img
                      src={emailDetail.file}
                      height="140px"
                      width="240px"
                      alt=""
                      style={{
                        objectFit: "cover",
                        margin: "auto"
                      }}
                      ref={imageRef}
                      onClick={onClickImage}
                    /> */}
                    </Box>
                  )}
                </Grid>
              </Box>
            </Grid>
          ) : null}
          <Grid item sx={{ mt: 2 }}>
            <Grid container alignItems="center" sx={{ position: "relative" }}>
              {/* <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px",

                  width: 90,
                  marginRight: 5
                }}
              >
                첨부파일 */}
              {/* <input
                type="file"
                // hidden
                onChange={(e) => handleSelectFile(e)}
                style={{
                  position: "absolute",
                  left: "0px",
                  width: "90px",
                  height: "36px",
                  opacity: 0,
                  cursor: "pointer"
                }}
              /> */}
              {/* </Button> */}
              {/* <StyledTextField
                width="150px"
                height="19px"
                size="small"
                value={
                  emailDetail.file ? getFileName(emailDetail.file) : "파일 선택"
                }
                disabled
              /> */}
              {/* <input
              type="file"
              onChange={(e) => handleSelectFile(e)}
              style={{
                position: "absolute",
                left: "95px",
                width: "150px",
                opacity: 0
              }}
            /> */}
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <Grid container alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px",
                  marginRight: 5
                }}
                onClick={handleReply}
              >
                답변
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px"
                }}
                onClick={() => setOpenModalDelete(true)}
              >
                삭제
              </Button>
            </Grid>
          </Grid>
          <Popup2Action
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            message="삭제 하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenModalDelete(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withState(withParam(withToast(EmailReceivedDetail)));
