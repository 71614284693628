const URL = Object.freeze({
  GET_COUPON_LIST: "/coupons",
  GET_COUPON: "/coupons",
  ADD_COUPON: "/coupons",
  DELETE_COUPON: "/coupons/delete",
  UPDATE_COUPON: "/coupons",
  GET_COUPON_USER_LIST: "/coupons/user",
  GET_COUPON_USER_LIST_2: "/coupons/issue",
  ISSUE_COUPON: "/coupons/users"
});

export default URL;
