import React from "react";
import { useLocation } from "react-router-dom";

function withState(Component) {
  return (props) => {
    const { state } = useLocation();
    console.log("useLocation()", useLocation());
    return <Component {...props} routeState={state} />;
  };
}

export default withState;
