import React, { useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import { CREDIT_PRODUCT_CATEGORY } from "utils/constants";
import { mapOrder } from "utils/function";
import { ManageCreditService } from "services";
import { updateCredit } from "redux/actions/credit";
import { TOAST_TYPE } from "./../../../../utils/constants";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #a5a5a5",
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(2),
    "&.Mui-selected .MuiTabs-indicator": {
      display: "none",
    },
    "&.Mui-selected": {
      color: "#1890ff",
      borderBottom: "0",
    },

    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: 5,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function Item(props) {
  const { credit = {}, dispatch, showToast } = props;
  const [dataCredit, setDataCredit] = useState(null);
  const [defaultDataCredit, setDefaultDataCredit] = useState(null);

  useEffect(() => {
    setDataCredit({ ...credit });
    setDefaultDataCredit({ ...credit });
  }, [credit]);

  // console.log("default data", defaultDataCredit);
  // console.log("changable data", dataCredit);

  const handleReset = () => {
    setDataCredit(defaultDataCredit);
  };

  const handleSave = async () => {
    const {
      name = "",
      cost = "",
      recharge = "",
      bonus = "",
      id = "",
    } = dataCredit;

    try {
      const data = {
        name,
        cost: Number(cost),
        recharge: Number(recharge),
        bonus: Number(bonus),
      };
      if (id) {
        await ManageCreditService.updateCredit(id, data);
      } else {
        await ManageCreditService.createCredit(data);
      }
      dispatch(updateCredit(data));
      setDefaultDataCredit(data);
      showToast(TOAST_TYPE.SUCCESS, "Update success");
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Box flexDirection="column" style={{ fontWeight: "bold" }}>
      <Box
        sx={{
          display: "flex",
          columnGap: 2,
          rowGap: 2,
          mt: 2,
          mb: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography style={{ flexBasis: "50%", fontWeight: "bold" }}>
          충전금액 ($)
        </Typography>
        <TextField
          size="small"
          sx={{
            "& label": {
              paddingLeft: (theme) => theme.spacing(2),
            },
            "& input": {
              paddingLeft: (theme) => theme.spacing(3.5),
            },
            "& fieldset": {
              paddingLeft: (theme) => theme.spacing(2.5),
              borderRadius: 0,
            },
          }}
          inputProps={{
            style: {
              height: "30px",
              padding: "0 5px",
              borderRadius: 0,
              outline: "none",
              width: "140px",
              textAlign: "center",
            },
          }}
          type="text"
          value={dataCredit?.recharge}
          onChange={(e) => {
            const creditRecharge = e.target.value?.trim();
            const lengthValue = creditRecharge?.length;
            const lastChar = creditRecharge[lengthValue - 1];
            if (lastChar) {
              const arrayCreditRechargeValue = creditRecharge?.split("");
              if (lengthValue === 1 && !/^[1-9]$/.test(lastChar)) return;
              if (arrayCreditRechargeValue.filter((c) => c === ".").length > 1)
                return;
              if (!/^[.\d]$/.test(lastChar)) return;
            }
            setDataCredit({ ...dataCredit, recharge: e.target.value });
          }}
          onFocus={(e) => {
            if ([0, "0"].includes(e.target.value)) {
              setDataCredit({ ...dataCredit, recharge: "" });
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "")
              setDataCredit({ ...dataCredit, recharge: 0 });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          columnGap: 2,
          mt: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography style={{ flexBasis: "50%", fontWeight: "bold" }}>
          충전 Credit
        </Typography>
        <TextField
          size="small"
          sx={{
            "& label": {
              paddingLeft: (theme) => theme.spacing(2),
            },
            "& input": {
              paddingLeft: (theme) => theme.spacing(3.5),
            },
            "& fieldset": {
              paddingLeft: (theme) => theme.spacing(2.5),
              borderRadius: 0,
            },
          }}
          type="text"
          inputProps={{
            style: {
              height: "30px",
              padding: "0 5px",
              borderRadius: 0,
              outline: "none",
              width: "140px",
              textAlign: "center",
            },
          }}
          value={dataCredit?.cost}
          onChange={(e) => {
            const creditCost = e.target.value?.trim();
            const lengthValue = creditCost?.length;
            const lastChar = creditCost[lengthValue - 1];
            if (lastChar) {
              const arrayCreditCostValue = creditCost?.split("");
              if (lengthValue === 1 && !/^[1-9]$/.test(lastChar)) return;
              if (arrayCreditCostValue.filter((c) => c === ".").length > 1)
                return;
              if (!/^[.\d]$/.test(lastChar)) return;
            }
            setDataCredit({ ...dataCredit, cost: e.target.value });
          }}
          onFocus={(e) => {
            if ([0, "0"].includes(e.target.value)) {
              setDataCredit({ ...dataCredit, cost: "" });
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "")
              setDataCredit({ ...dataCredit, cost: 0 });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          columnGap: 2,
          mt: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography style={{ flexBasis: "50%", fontWeight: "bold" }}>
          보너스 Credit
        </Typography>
        <TextField
          size="small"
          sx={{
            "& label": {
              paddingLeft: (theme) => theme.spacing(2),
            },
            "& input": {
              paddingLeft: (theme) => theme.spacing(3.5),
            },
            "& fieldset": {
              paddingLeft: (theme) => theme.spacing(2.5),
              borderRadius: 0,
            },
          }}
          type="text"
          inputProps={{
            style: {
              height: "30px",
              padding: "0 5px",
              borderRadius: 0,
              outline: "none",
              width: "140px",
              textAlign: "center",
            },
          }}
          value={dataCredit?.bonus}
          onChange={(e) => {
            const creditBonus = e.target.value?.trim();
            const lengthValue = creditBonus?.length;
            const lastChar = creditBonus[lengthValue - 1];
            if (lastChar) {
              const arrayCreditBonusValue = creditBonus?.split("");
              if (lengthValue === 1 && !/^[1-9]$/.test(lastChar)) return;
              if (arrayCreditBonusValue.filter((c) => c === ".").length > 1)
                return;
              if (!/^[.\d]$/.test(lastChar)) return;
            }
            setDataCredit({ ...dataCredit, bonus: e.target.value });
          }}
          onFocus={(e) => {
            if ([0, "0"].includes(e.target.value)) {
              setDataCredit({ ...dataCredit, bonus: "" });
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "")
              setDataCredit({ ...dataCredit, bonus: 0 });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          columnGap: 4,
          mt: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography style={{ flexBasis: "50%", fontWeight: "bold" }}>
          총 Credit
        </Typography>
        <Typography style={{ flexBasis: "50%", textAlign: "center" }}>
          {Number(dataCredit?.cost ? dataCredit.cost : 0) +
            Number(dataCredit?.bonus ? dataCredit.bonus : 0)}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", columnGap: 2, mt: 15 }}>
        <Button
          variant="outlined"
          style={{
            border: "1px solid #a5a5a5",
            textTransform: "capitalize",
            color: "#000",
            width: 150,
            flexBasis: "50%",
            fontWeight: "bold",
          }}
          onClick={handleSave}
        >
          저장
        </Button>
        <Button
          variant="outlined"
          style={{
            border: "1px solid #a5a5a5",
            textTransform: "capitalize",
            color: "#000",
            width: 150,
            marginLeft: 20,
            flexBasis: "50%",
            fontWeight: "bold",
          }}
          onClick={handleReset}
        >
          초기화
        </Button>
      </Box>
    </Box>
  );
}

export default function StyledTabPanel(props) {
  const { credits = [], dispatch, showToast } = props;
  const [value, setValue] = React.useState(0);
  const order = [
    CREDIT_PRODUCT_CATEGORY.STANDARD,
    CREDIT_PRODUCT_CATEGORY.PLUS,
    CREDIT_PRODUCT_CATEGORY.PREMIUM,
    CREDIT_PRODUCT_CATEGORY.GOLD,
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", border: "1px solid #a5a5a5" }}>
      <Box sx={{ bgcolor: "#fff" }}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <StyledTab value={0} label="Standard" />
          <StyledTab value={1} label="Plus" />
          <StyledTab value={2} label="Premium" />
          <StyledTab value={3} label="Gold" />
        </AntTabs>
      </Box>
      <Box>
        {mapOrder(credits, order, "name").map((credit, index) => (
          <TabPanel key={index} value={value} index={index}>
            <Item credit={credit} dispatch={dispatch} showToast={showToast} />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
