import React, { useState, useMemo, useEffect } from "react";
import withToast from "HOC/withToast";
import { Box } from "@mui/material";
import DropAndDrag from "./DropAndDrag";
import { ManagePatternService } from "services";
import { TOAST_TYPE } from "utils/constants";
import { listGroupBy, sortList } from "utils/function";
import LoadingPage from "components/LoadingPage";

function TabDetailedCategory({ showToast }) {
  const [loading, setLoading] = useState(true);
  const [timeReCall, setTimeReCall] = useState(0);
  const [listCategory, setListCategory] = useState([]);

  const [selectedPartSection, setSelectedPartSection] = useState(null);
  const [selectedPatternPart, setSelectedPatternPart] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManagePatternService.getCategoryList();
        if (response?.data?.statusCode === 200) {
          const listCategoryGroupByType = listGroupBy(
            response?.data?.data ?? [],
            "type"
          );
          const categoryFirstColumn = listCategoryGroupByType[
            "category"
          ].filter((category) => category.column === "part_section");
          setListCategory(categoryFirstColumn);
          setLoading(false);
        } else {
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeReCall]);

  const listPatternPart = useMemo(() => {
    if (listCategory.length > 0 && selectedPartSection) {
      const partSection = listCategory.find(
        (category) => Number(category.id) === Number(selectedPartSection)
      );
      return partSection?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selectedPartSection]);

  const listSubPart = useMemo(() => {
    if (listCategory.length > 0 && selectedPartSection && selectedPatternPart) {
      const partSection = listCategory.find(
        (category) => Number(category.id) === Number(selectedPartSection)
      );
      const subPartSection = partSection?.subCategory ?? [];
      const patternPart = subPartSection.find(
        (category) => Number(category.id) === Number(selectedPatternPart)
      );
      return patternPart?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selectedPartSection, selectedPatternPart]);

  const handleSelectedPartSection = (id) => {
    setSelectedPartSection(id);
    setSelectedPatternPart("");
  };

  if (loading) return <LoadingPage />;

  return (
    <Box
      sx={{
        height: "100%",
        whiteSpace: "nowrap",
        width: "100%",
        overflowX: "scroll"
      }}
    >
      <Box sx={{ display: "inline-block" }}>
        <DropAndDrag
          title="Part Section"
          selected={selectedPartSection}
          setSelected={handleSelectedPartSection}
          listItems={sortList(listCategory, "order")}
          setTimeReCall={setTimeReCall}
          showToast={showToast}
        />
      </Box>
      <Box sx={{ display: "inline-block" }}>
        <DropAndDrag
          title="Pattern Part"
          selected={selectedPatternPart}
          hasParent={!!selectedPartSection}
          parent={selectedPartSection}
          setSelected={setSelectedPatternPart}
          listItems={sortList(listPatternPart, "order")}
          setTimeReCall={setTimeReCall}
          showToast={showToast}
        />
      </Box>
      <Box sx={{ display: "inline-block" }}>
        <DropAndDrag
          title="Sub Part"
          hasParent={!!selectedPatternPart}
          parent={selectedPatternPart}
          listItems={sortList(listSubPart, "order")}
          setTimeReCall={setTimeReCall}
          showToast={showToast}
        />
      </Box>
    </Box>
  );
}

export default withToast(TabDetailedCategory);
