import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManageCreditService } from "services";
import { withToast } from "HOC";
import { CREDIT_PRODUCT_CATEGORY, TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  formatDate,
  formatDateSearch,
  formatNumber,
  formatDateSearchStart,
  formatDateSearchEnd,
} from "utils/function";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Popup from "./components/Popup";
import Excel from "exceljs";

// const listChargingInformation = [
//   { value: CREDIT_PRODUCT_CATEGORY.STANDARD, text: "Standard" },
//   { value: CREDIT_PRODUCT_CATEGORY.PLUS, text: "Plus" },
//   { value: CREDIT_PRODUCT_CATEGORY.PREMIUM, text: "Premium" },
//   { value: CREDIT_PRODUCT_CATEGORY.GOLD, text: "Gold" },
// ];

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
];

function ChargingCompleteList({ showToast }) {
  const [listTransaction, setListTransaction] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(null);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [dateTo, setDateTo] = useState(null);
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: "",
  });
  const [products, setProducts] = useState([
    {
      id: 1,
      productName: CREDIT_PRODUCT_CATEGORY.STANDARD,
      isChecked: false,
    },
    {
      id: 2,
      productName: CREDIT_PRODUCT_CATEGORY.PLUS,
      isChecked: false,
    },
    {
      id: 3,
      productName: CREDIT_PRODUCT_CATEGORY.PREMIUM,
      isChecked: false,
    },
    {
      id: 4,
      productName: CREDIT_PRODUCT_CATEGORY.GOLD,
      isChecked: false,
    },
  ]);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "" } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = { ...condition, page: page + 1, limit: rowsPerPage };
    }
    // if (type) {
    condition = {
      ...condition,
      type: type ? type : "all",
      search: search ? search.trim() : "",
    };
    // }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss"),
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss"),
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const response = await ManageCreditService.getListTransactionsCharge(
        handleCreateCondition(isSearch, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListTransaction(response?.data?.data ?? []);
        setCount(response?.data?.count ?? 0);

        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };
  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "" });
    setDateFrom(null);
    setDateTo(null);
    // setPage(0);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleProductsCheckChange = (id) => {
    setProducts((prevProductsState) => {
      const newProductsState = prevProductsState.map((product) => {
        product.isChecked = false;

        if (product.id === id) {
          return { ...product, isChecked: true };
        }

        return product;
      });

      return newProductsState;
    });
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
  };

  const handleCreateExcel = async (transactions) => {
    const fileName = "Credit 관리 - 충전 완료 목록";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("Credit 관리 - 충전 완료 목록");

    const headersTitle = [
      "NO.",
      "충전일",
      "성명",
      "이메일",
      "상품명",
      "충전 Credit",
      "Paypal",
      "카드",
      "총 보유 Credit",
      "Paypal email 주소",
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const columnWidth = [10, 30, 20, 20, 20, 20, 20, 20, 20, 30];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // const calculateFee = (netAmount, amount) => {
    //   if (!netAmount || !amount) return "";
    //   return Number(netAmount) - Number(amount);
    // };

    const calculatePayment = (payment, amount) => {
      if (payment) return payment;
      if (amount) return amount;
      return "";
    };

    // add value, style cell // body
    transactions.forEach((transaction, index) => {
      const charge = transaction?.netAmount;
      const payment = calculatePayment(
        transaction?.payment,
        transaction?.amount
      );
      const deposited = transaction?.amount;
      ws.getCell(`A${index + 2}`).value = index + 1;
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`B${index + 2}`).value = transaction.createdAt
        ? formatDate(transaction.createdAt)
        : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`C${index + 2}`).value = transaction?.user?.nickName ?? "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`D${index + 2}`).value = transaction?.user?.email ?? "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`E${index + 2}`).value = transaction?.product?.name ?? "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`F${index + 2}`).value = transaction?.amount ?? 0;
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`G${index + 2}`).value =
        transaction?.paymentMethod === "paypal"
          ? Math.abs(transaction?.amount)
          : 0;
      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`H${index + 2}`).value =
        transaction?.paymentMethod === "paypal"
          ? 0
          : Math.abs(transaction?.amount);
      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`I${index + 2}`).value = transaction?.amount
        ? Math.abs(transaction?.amount)
        : 0;
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`J${index + 2}`).value = transaction?.email ?? "";
      ws.getCell(`J${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      // ws.getCell(`I${index + 2}`).value = transaction.externalId
      //   ? transaction.externalId
      //   : "";
      // ws.getCell(`I${index + 2}`).style = {
      //   alignment: { vertical: "center", horizontal: "center" },
      // };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    let condition = {};
    if (isDownloadFilter) {
      // condition = {
      //   ...searchTerms,
      //   dateFrom: dateFrom,
      //   dateTo: dateTo,
      //   description: "PayPal Order"
      // };
      const { type = "", search = "" } = searchTerms;

      if (type) {
        condition = { ...condition, type };
      }

      if (search) {
        condition = { ...condition, search: search ? search.trim() : "" };
      }

      if (dateFrom) {
        condition = {
          ...condition,

          from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss"),
        };
      }

      if (dateTo) {
        condition = {
          ...condition,
          to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss"),
        };
      }
    }
    let listTransaction = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageCreditService.getListTransactionsCharge(
        condition
      );
      if (response?.data?.statusCode === 200) {
        const transactions = response?.data ? response.data?.data : [] ?? [];
        listTransaction = [...transactions];
        handleCreateExcel(listTransaction);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const renderTable = () => {
    const sizeWidth = [
      "3%",
      "6%",
      "10%",
      "20%",
      "9%",
      // "8%",
      "9%",
      "9%",
      "7%",
      "12%",
      "20%",
    ];

    const headersTitle = [
      "NO.",
      "충전일",
      "성명",
      "이메일",
      "상품명",
      // "패턴",
      "충전 Credit",
      "Paypal",
      "카드",
      "총 보유 Credit",
      "Paypal email 주소",
    ];

    const calculateFee = (netAmount, amount) => {
      if (!netAmount || !amount) return "";
      return Number(netAmount) - Number(amount);
    };

    const calculatePayment = (payment, amount) => {
      if (payment) return payment;
      if (amount) return amount;
      return "";
    };

    const tableData = listTransaction.map((transaction, index) => {
      return {
        ...transaction,
        index: index + 1 + rowsPerPage * page,
        rechargeDate: transaction.createdAt
          ? formatDate(transaction.createdAt, "YYYY.MM.DD")
          : "",
        name: transaction?.user?.nickName ?? "",
        email: transaction?.user?.email ?? "",
        productName: transaction?.product?.name ?? "",
        // pattern: transaction?.pattern?.id,
        rechargeCredit: transaction?.amount ?? 0,
        paypal:
          transaction?.paymentMethod === "paypal"
            ? Math.abs(transaction?.amount)
            : 0,
        card:
          transaction?.paymentMethod === "paypal"
            ? 0
            : Math.abs(transaction?.amount),
        totalCredit: transaction?.balance ? Math.abs(transaction?.balance) : 0,
        ppEmail: transaction?.email ?? "",
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  // const renderSearchArea = () => {
  //   return (
  //     <Grid container direction="column" spacing={3}>
  //       <Grid item>검색필터</Grid>
  //       <Grid item>
  //         <Grid container direction="row" justifyContent="flex-start">
  //           <Grid item style={{ margin: "10px 10px 10px 0" }}>
  //             <Box sx={{ display: "flex", flexDirection: "row" }}>
  //               <Box
  //                 sx={{
  //                   fontSize: "0.7rem",
  //                   height: 30,
  //                   backgroundColor: "#1565c0",
  //                   color: "#fff",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                   padding: "0 2px",
  //                   minWidth: "76px",
  //                 }}
  //               >
  //                 충전자명
  //               </Box>
  //               <TextField
  //                 size="small"
  //                 sx={{
  //                   "& label": {
  //                     paddingLeft: (theme) => theme.spacing(2),
  //                   },
  //                   "& input": {
  //                     width: "80px",
  //                     height: "13px",
  //                   },
  //                   "& fieldset": {
  //                     paddingLeft: (theme) => theme.spacing(2.5),
  //                     borderRadius: 0,
  //                   },
  //                 }}
  //                 value={searchTerms["name"]}
  //                 onChange={(e) =>
  //                   handleChangeSearchTerms("name", e.target.value)
  //                 }
  //               />
  //             </Box>
  //           </Grid>
  //           <Grid item style={{ margin: "10px 10px 10px 0" }}>
  //             <Box sx={{ display: "flex", flexDirection: "row" }}>
  //               <Box
  //                 sx={{
  //                   fontSize: "0.7rem",
  //                   height: 30,
  //                   backgroundColor: "#1565c0",
  //                   color: "#fff",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                   padding: "0 2px",
  //                   minWidth: "76px",
  //                 }}
  //               >
  //                 충전일
  //               </Box>
  //               <LocalizationProvider dateAdapter={AdapterDateFns}>
  //                 <DesktopDatePicker
  //                   inputFormat="yyyy/MM/dd"
  //                   value={dateFrom}
  //                   onChange={(value) => {
  //                     setDateFrom(value);
  //                   }}
  //                   renderInput={(params) => (
  //                     <TextField
  //                       {...params}
  //                       size="small"
  //                       sx={{
  //                         "& label": {
  //                           paddingLeft: (theme) => theme.spacing(2),
  //                         },
  //                         "& input": {
  //                           width: "80px",
  //                           height: "13px",
  //                         },
  //                         "& fieldset": {
  //                           paddingLeft: (theme) => theme.spacing(2.5),
  //                           borderRadius: 0,
  //                         },
  //                       }}
  //                     />
  //                   )}
  //                 />
  //                 <span>&nbsp;-&nbsp;</span>
  //                 <DesktopDatePicker
  //                   inputFormat="yyyy/MM/dd"
  //                   value={dateTo}
  //                   onChange={(value) => {
  //                     setDateTo(value);
  //                   }}
  //                   renderInput={(params) => (
  //                     <TextField
  //                       {...params}
  //                       size="small"
  //                       sx={{
  //                         "& label": {
  //                           paddingLeft: (theme) => theme.spacing(2),
  //                         },
  //                         "& input": {
  //                           width: "80px",
  //                           height: "13px",
  //                         },
  //                         "& fieldset": {
  //                           paddingLeft: (theme) => theme.spacing(2.5),
  //                           borderRadius: 0,
  //                         },
  //                       }}
  //                     />
  //                   )}
  //                 />
  //               </LocalizationProvider>
  //             </Box>
  //           </Grid>
  //           <Grid item style={{ margin: "10px 10px 10px 0" }}>
  //             <Box sx={{ display: "flex", flexDirection: "row" }}>
  //               <Box
  //                 sx={{
  //                   fontSize: "0.7rem",
  //                   height: 30,
  //                   backgroundColor: "#1565c0",
  //                   color: "#fff",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                   padding: "0 2px",
  //                   minWidth: "76px",
  //                 }}
  //               >
  //                 충전정보
  //               </Box>
  //               <Box
  //                 style={{
  //                   border: "1px solid #c4c4c4",
  //                   height: "30px",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                   padding: "0 0 0 2px",
  //                 }}
  //               >
  //                 {products.map((product) => (
  //                   <FormControlLabel
  //                     key={product.id}
  //                     control={
  //                       <Checkbox
  //                         checked={product.isChecked}
  //                         sx={{
  //                           "& .MuiSvgIcon-root": {
  //                             fontSize: "0.7rem",
  //                           },
  //                         }}
  //                         onClick={() => handleProductsCheckChange(product.id)}
  //                       />
  //                     }
  //                     sx={{
  //                       "& .MuiTypography-root": {
  //                         fontSize: "0.7rem !important",
  //                       },
  //                     }}
  //                     label={product.productName}
  //                   />
  //                 ))}
  //               </Box>
  //             </Box>
  //           </Grid>
  //           <Grid item style={{ margin: "10px 10px 10px 0" }}>
  //             <Box sx={{ display: "flex", flexDirection: "row" }}>
  //               <Box
  //                 sx={{
  //                   fontSize: "0.7rem",
  //                   height: 30,
  //                   backgroundColor: "#1565c0",
  //                   color: "#fff",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                   padding: "0 2px",
  //                   minWidth: "76px",
  //                 }}
  //               >
  //                 {/* Code */}
  //                 ID
  //               </Box>
  //               <TextField
  //                 size="small"
  //                 sx={{
  //                   "& label": {
  //                     paddingLeft: (theme) => theme.spacing(2),
  //                   },
  //                   "& input": {
  //                     width: "80px",
  //                     height: "13px",
  //                   },
  //                   "& fieldset": {
  //                     paddingLeft: (theme) => theme.spacing(2.5),
  //                     borderRadius: 0,
  //                   },
  //                 }}
  //                 value={searchTerms["code"]}
  //                 onChange={(e) =>
  //                   handleChangeSearchTerms("code", e.target.value)
  //                 }
  //               />
  //             </Box>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //       <Grid item>
  //         <Grid container justifyContent="center">
  //           <Stack spacing={2} direction="row">
  //             <LoadingButton
  //               onClick={handleSearch}
  //               loading={loadingProcess["searching"] ? true : false}
  //               loadingIndicator="검색"
  //               variant="contained"
  //               style={{
  //                 textTransform: "capitalize",
  //                 borderRadius: "0",
  //               }}
  //             >
  //               검색
  //             </LoadingButton>
  //             <LoadingButton
  //               onClick={handleReset}
  //               loading={loadingProcess["resetting"] ? true : false}
  //               loadingIndicator="초기화"
  //               variant="contained"
  //               style={{
  //                 textTransform: "capitalize",
  //                 borderRadius: "0",
  //                 width: 90,
  //               }}
  //             >
  //               초기화
  //             </LoadingButton>
  //           </Stack>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>검색필터</Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px",
                  }}
                >
                  충전일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            width: "80px",
                            height: "13px",
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0,
                          },
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            width: "80px",
                            height: "13px",
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <FormControl size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px",
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px",
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2),
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5),
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0,
                    },
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px",
                    },
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90,
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="Credit 관리 - 충전 완료 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              {/* <Grid item>
                <span>Total </span>
                <span style={{ color: "#0468b4" }}>
                  {formatNumber(count) ? formatNumber(count) : 0}
                </span>
              </Grid> */}
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem",
                            },
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important",
                        },
                        marginRight: 0,
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(ChargingCompleteList);
