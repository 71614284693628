import {
  Draggable,
  moveItems,
  useDraggable,
  useDraggableContext
} from "react-tiny-dnd";

import { useAutoScroll } from "react-tiny-autoscroll";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DropDownButton from "./DropDownButton";

import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  styled,
  TextField
} from "@mui/material";
import { ManagePatternService } from "services";
import ModalCategory from "./ModalCategory";
import { TOAST_TYPE } from "utils/constants";
import Popup2Action from "components/Popup2Action";

const StyledButtonAdd = styled(Button)({
  padding: 0,
  textTransform: "lowercase",
  borderRadius: 0
});

const StyledItem = {
  fontSize: "0.rem",
  border: "1px solid",
  borderRadius: 5,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "0"
};

function Item({
  index,
  item,
  listeners,
  isDragging,
  selected,
  setSelected,
  handleUpdate,
  handleDelete,
  style = {},
}) {
  const opacity = isDragging ? 0.5 : 1;
  const color = selected === item.id ? "#0468b4" : "";

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(async () => {
  //   if (index !== item.index) {
  //     try {
  //       await ManagePatternService.updateCategory({
  //         name: item.name,
  //         dept: item.dept,
  //         parent: item.parent,
  //         index: index,
  //       });
  //       // setTimeReCall((prevState) => prevState + 1);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [index, item.index]);

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        margin: "0 5px",
        padding: "5px 10px",
        fontWeight: "bold",
        opacity,
        color,
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "-moz-none",
        "-o-user-select": "none",
        "user-select": "none",
        ...style,
      }}
    >
      <Typography style={{ fontWeight: "bold" }}>{index + 1} &emsp;</Typography>

      <Box style={StyledItem}>
        <Typography
          {...listeners}
          sx={{
            display: "block",
            cursor: "pointer",
            maxWidth: "120px",
            height: "auto",
            wordWrap: "break-word",
            whiteSpace: "initial",
            fontWeight: "bold"
          }}
          onClick={() => setSelected(item.id)}
        >
          {item.category}
        </Typography>
        <Box style={{ display: "flex" }}>
          {/* <MoreVertIcon
            {...listeners}
            style={{ cursor: "move" }}
            fontSize="small"
          />
          <EditIcon
            style={{ cursor: "pointer", display: "block" }}
            fontSize="small"
            onClick={() => handleUpdate(item)}
          />
          <DeleteForeverIcon
            style={{ cursor: "pointer", display: "block" }}
            fontSize="small"
            onClick={() => handleDelete(item)}
          /> */}
          <DropDownButton
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            data={item}
          />
        </Box>
      </Box>
    </Box>
  );
}

const DraggableItem = ({
  index,
  context,
  item,
  selected,
  setSelected,
  handleUpdate,
  handleDelete
}) => {
  const { listeners, isDragging } = useDraggable(context, index);

  return (
    <Draggable
      context={context}
      key={item.id}
      index={index}
      {...{
        preview: (
          <Item
            item={item}
            index={index}
            listeners={listeners}
            isDragging={false}
            selected={selected}
            setSelected={setSelected}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            style={{
              margin: "10px 0px 0px -50px",
              padding: 0,
              "-webkit-user-select": "none",
              "-khtml-user-select": "none",
              "-moz-user-select": "-moz-none",
              "-o-user-select": "none",
              "user-select": "none",
            }}
          />
        )
      }}
    >
      <Item
        item={item}
        index={index}
        listeners={listeners}
        isDragging={isDragging}
        selected={selected}
        setSelected={setSelected}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </Draggable>
  );
};

function DropAndDrag(props) {
  const {
    listItems = [],
    hasParent = true,
    parent = "",
    selected = "",
    setSelected = () => {},
    title = "",
    setTimeReCall = () => {},
    showToast
  } = props;

  const [items, setItems] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [nameCategoryAdded, setNameCategoryAdded] = useState("");
  const [categoryEdited, setCategoryEdited] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [categoryDelete, setCategoryDelete] = useState(null);

  useEffect(() => {
    setItems(listItems);
  }, [listItems]);

  const onDrop = async (dragIndex, overIndex) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    const listChangeIndex = [];
    nextItems.forEach((item, index) => {
      if (item.index !== index) {
        listChangeIndex.push({ ...item, order: index });
      }
    });
    setItems(nextItems);
    await Promise.all(
      listChangeIndex.map((item) =>
        ManagePatternService.updateCategory(item.id, {
          category: item.category,
          column: item.column,
          parentId: item.parentId,
          order: item.order,
          type: item.type
        })
      )
    );
  };

  const containerRef = useRef();

  const context = useDraggableContext({
    onDrop
  });

  useAutoScroll({ containerRef, skip: !context.isDragging });

  const handleAdd = () => {
    if (!hasParent) {
      showToast(TOAST_TYPE.WARN, "Please select parent");
      return;
    }
    setOpenModalAdd(true);
  };

  const handleSaveCategoryAdded = async () => {
    if (
      !nameCategoryAdded ||
      (nameCategoryAdded && !nameCategoryAdded.trim())
    ) {
      showToast(TOAST_TYPE.WARN, "Name category is blank");
      return;
    }
    const listCate = items?.map?.(i => i?.category?.toLowerCase?.()) || []
    if (listCate?.includes(nameCategoryAdded?.trim?.()?.toLowerCase?.())) {
      showToast(TOAST_TYPE.WARN, "Name category exists!");
      return;
    }
    let body = {
      column: `depth_${title}`,
      category: nameCategoryAdded.trim(),
      // parentId: `${parent}` ?? null,
      order: items.length,
      type: "depth"
    };
    if (parent) {
      body = {
        ...body,
        parentId: Number(parent)
      };
    }
    try {
      const response = await ManagePatternService.createCategory(body);
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        setOpenModalAdd(false);
        setNameCategoryAdded("");
        showToast(TOAST_TYPE.SUCCESS, "Add success");
      } else {
        setOpenModalAdd(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenModalAdd(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleUpdate = (item) => {
    setOpenModalEdit(true);
    setCategoryEdited({ ...item });
  };

  const handleSaveCategoryEdited = async () => {
    if (
      !categoryEdited.category ||
      (categoryEdited.category && !categoryEdited.category.trim())
    ) {
      showToast(TOAST_TYPE.WARN, "Name category is blank");
      return;
    }
    const listCate = items?.map?.(i => i?.category?.toLowerCase?.()) || []
    if (listCate?.includes(categoryEdited.category?.trim()?.toLowerCase?.())) {
      showToast(TOAST_TYPE.WARN, "Name category exists!");
      return;
    }
    try {
      const response = await ManagePatternService.updateCategory(
        categoryEdited.id,
        {
          category: categoryEdited.category.trim(),
          column: categoryEdited.column,
          order: categoryEdited.order,
          parentId: categoryEdited.parentId,
          type: categoryEdited.type
        }
      );
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        setOpenModalEdit(false);
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        setOpenModalEdit(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenModalEdit(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDelete = (item) => {
    setCategoryDelete(item);
    setOpenModalConfirm(true);
  };

  const handleDeleteCategory = async () => {
    try {
      const response = await ManagePatternService.deleteCategory(
        categoryDelete.id
      );
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        setOpenModalConfirm(false);
        showToast(TOAST_TYPE.SUCCESS, "Delete success");
      } else {
        setOpenModalConfirm(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenModalConfirm(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message;

      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: "5px",
        width: "250px",
        paddingBottom: "40px"
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #a5a5a5",
          borderBottom: "none",
          color: "#ffff",
          backgroundColor: "#0468b4",
          padding: "10px"
        }}
      >
        <Typography style={{ flexBasis: "50%" }}>{title} Dept</Typography>
        <StyledButtonAdd variant="contained" color="add" onClick={handleAdd}>
          + Add
        </StyledButtonAdd>
      </Box>
      <Box
        ref={containerRef}
        style={{
          border: "1px solid #a5a5a5",
          height: "400px",
          overflowY: "scroll"
        }}
      >
        {!hasParent ? (
          <Box
            sx={{ mt: 5 }}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ArticleIcon style={{ fontSize: "2.5rem" }} />
            <Typography style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
              Please select a parent category
            </Typography>
          </Box>
        ) : (
          <>
            {items.map((item, i) => {
              return (
                <React.Fragment key={item.id}>
                  <DraggableItem
                    context={context}
                    index={i}
                    item={item}
                    setSelected={setSelected}
                    selected={selected}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                  />
                  <Divider sx={{ margin: "0 5px", border: "1px solid #000" }} />
                </React.Fragment>
              );
            })}
          </>
        )}
      </Box>
      <ModalCategory
        open={openModalAdd}
        setOpen={setOpenModalAdd}
        title={`Add Category (${title} Dept)`}
        onClickSave={handleSaveCategoryAdded}
      >
        <TextField
          autoFocus
          value={nameCategoryAdded}
          onChange={(e) => setNameCategoryAdded(e.target.value)}
          margin="dense"
          label="Name category"
          fullWidth
          variant="standard"
        />
      </ModalCategory>
      <ModalCategory
        open={openModalEdit}
        setOpen={setOpenModalEdit}
        title="Edit Category"
        onClickSave={handleSaveCategoryEdited}
      >
        <TextField
          autoFocus
          value={categoryEdited?.category}
          onChange={(e) =>
            setCategoryEdited({ ...categoryEdited, category: e.target.value })
          }
          margin="dense"
          label="Name category"
          fullWidth
          variant="standard"
        />
      </ModalCategory>
      <Popup2Action
        open={openModalConfirm}
        setOpen={setOpenModalConfirm}
        message="Are you sure delete the category?"
        onClickConfirm={handleDeleteCategory}
        onClickCancel={() => setOpenModalConfirm(false)}
      />
    </Box>
  );
}

export default React.memo(DropAndDrag);
