import moment from "moment";
import Big from "big.js";
import Axios from "axios";
import paper from "paper";

export function fileDownload(url, filename) {
  Axios.get(url, {
    responseType: "blob"
  }).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${filename}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  });
}

export const formatNumber = (number) => {
  if (!number) return "";
  return new Intl.NumberFormat("en-IN").format(Number(number));
};

export const formatDate = (date, format = "YYYY.MM.DD") => {
  // return moment(new Date(date)).format(format);
  // return moment.utc(date).format(format);
  return moment(new Date(date)).format(format);
};

export const formatDateSearch = (date, format = "YYYY.MM.DD") => {
  return moment(new Date(date)).format(format);
};

export const formatDateSearchStart = (date, format = "YYYY.MM.DD") => {
  if (!date) return "";
  const d = new Date(date);

  d.setHours(0, 0, 0, 0);

  return d.toISOString().split("T").join(" ").replace("Z", "");

  // return moment(new Date(date).toUTCString()).format(format) + " 00:00:00";
};

export const formatDateSearchEnd = (date, format = "YYYY.MM.DD") => {
  if (!date) return "";
  const d = new Date(date);

  d.setHours(23, 59, 59);

  return d.toISOString().split("T").join(" ").replace("Z", "");
  // return moment(new Date(date).toUTCString()).format(format) + " 23:59:59";
};

export const formatDateTime = (date) => {
  return moment(new Date(date)).format("YYYY.MM.DD hh:mm a");
};

export const addMonths = (date, addValue = 1) =>
  moment(new Date(date)).add(addValue, "months");

export const subDays = (date, value = 1) =>
  moment(new Date(date)).subtract(value, "days");

export const monthDifference = (endDate, startDate) =>
  moment(new Date(endDate)).diff(new Date(startDate), "months", true);
/**
 * Sort array of objects based on another array
 */
export function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}

export const sortListByDate = (list, key, asc = 1) => {
  return list.sort((a, b) => asc * (new Date(a[key]) - new Date(b[key])));
};

export const sortListByAmount = (list, key, asc = 1) => {
  return list.sort((a, b) => asc * (Number(a[key]) - Number(b[key])));
};

export const sortList = (list, key, asc = 1) => {
  if (!list || !list.length || list === undefined) return [];
  return list.sort((a, b) => asc * (Number(a[key]) - Number(b[key])));
};

export const listGroupBy = (list, key) => {
  return list.reduce((group, item) => {
    group[item[key]] = [...(group[item[key]] || []), item];
    return group;
  }, {});
};

export const sortObjectByKey = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
};

export const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

export const getBaseUrl = (file) => {
  var reader = new FileReader();
  var baseString;
  reader.onloadend = function () {
    baseString = reader.result;
    console.log(baseString);
  };
  reader.readAsDataURL(file);
};

export const getTimeFromToInToDay = () => {
  let start = new Date();
  start.setHours(0, 0, 0, 0);
  let end = new Date();
  end.setHours(23, 59, 59, 999);

  return [start, end];
};

export const getTimeFromToInYesterday = () => {
  let start = new Date();
  start.setDate(start.getDate() - 1);
  start.setHours(0, 0, 0, 0);

  let end = new Date();
  end.setDate(end.getDate() - 1);
  end.setHours(23, 59, 59, 999);

  return [start, end];
};

export const startAndEndOfWeek = (date = new Date()) => {
  const now = date ? new Date(date) : new Date().setHours(0, 0, 0, 0);
  const monday = new Date(now);
  monday.setDate(monday.getDate() - monday.getDay() + 1);
  monday.setHours(0, 0, 0, 0);
  const sunday = new Date(now);
  sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
  sunday.setHours(23, 59, 59, 999);
  return [monday, sunday];
};

export const startAndEndOfMonth = () => {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  lastDay.setHours(23, 59, 59, 999);
  return [firstDay, lastDay];
};

// export const generateImageSrc = (fileName) => {
//   return `${process.env.REACT_APP_API_URL}/${fileName}`;
// };

export const generateImageSrc = (fileName) => {
  return `http://43.202.63.232:3000/${fileName}`;
};

//check email
export const containSpace = (value) => {
  const spaceRegex = /^$|\s+/;
  console.log("spaceRegex.test(value)", spaceRegex.test(value));
  return value && spaceRegex.test(value);
};

export const nullValue = (value) => {
  console.log(
    "typeof value === string && value.trim().length === 0;",
    typeof value === "string" && value.trim().length === 0
  );
  return typeof value === "string" && value.trim().length === 0;
};

export const isEmail = (value) => {
  const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  console.log("emailRegex.test(value)", emailRegex.test(value));
  return emailRegex.test(value);
};

//check password
export const containNumber = (value) => {
  const numberRegex = /\d/;
  return numberRegex.test(value);
};

export const containLowerLetter = (value) => {
  const lowerCaseRegex = /[a-z]+/;
  return lowerCaseRegex.test(value);
};

export const containUpperLetter = (value) => {
  const upperCaseRegex = /[A-Z]+/;
  return upperCaseRegex.test(value);
};

export const containSpecialCharacter = (value) => {
  const specialCharacterRegex = /[.@#$%&=!?+]/;
  return specialCharacterRegex.test(value);
};

export const splitTime = (string) => {
  const day = new Date(string);
  const date = moment(day).format("YYYY/MM/DD");
  const hour = moment(day).hour();
  const minutes = moment(day).minutes();
  return [date, hour, minutes];
};

export const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

export function flattenCategory(data) {
  const result = [];

  function flatten(categories) {
    categories.forEach((category) => {
      // Clone category object to avoid modifying the original data
      const categoryCopy = { ...category };

      // Remove the 'subCategory' property, since we are flattening it
      delete categoryCopy.subCategory;

      // Add the current category to the result array
      result.push(categoryCopy);

      // If the current category has subCategories, recursively flatten them
      if (category.subCategory.length > 0) {
        flatten(category.subCategory);
      }
    });
  }

  // Start flattening from the top-level data
  flatten(data);

  return result;
}

// merge overwrite object
export function mergeArrays(arr1, arr2) {
  let mergedArray = [];

  // Clone arr2 to avoid modifying the original data
  const arr2Copy = [...arr2];

  for (const item1 of arr1) {
    const matchingIndex = arr2Copy.findIndex(
      (item2) => item2.weight === item1.weight
    );

    if (matchingIndex !== -1) {
      // If same weight, add item in arr1, remove item in arr2
      mergedArray.push(item1);
      arr2Copy.splice(matchingIndex, 1);
    } else {
      mergedArray.push(item1);
    }
  }

  // add rest item of arr2
  mergedArray.push(...arr2Copy);

  mergedArray = mergedArray
    .sort((a, b) => a.weight - b.weight)
    .map((item, index) => ({
      cost: item.cost,
      weight: item.weight,
      index: index
    }));

  return mergedArray;
}

export function updateArr2(arr1, arr2) {
  // Clone arr2
  const updatedArr2 = [...arr2];

  for (const item1 of arr1) {
    const matchingIndex = updatedArr2.findIndex(
      (item2) => item2.weight === item1.weight
    );

    if (matchingIndex !== -1) {
      // update index
      updatedArr2[matchingIndex].index = item1.index;
    }
  }

  return updatedArr2;
}

export function addDecimalNumber(a, b) {
  const bigA = new Big(a);
  const bigB = new Big(b);
  const result = bigA.plus(bigB);
  return result.toString();
}

export function subtractDecimalNumber(a, b) {
  const bigA = new Big(a);
  const bigB = new Big(b);
  const result = bigA.minus(bigB);
  return result.toString();
}

export const isValidDate = (dateString) => {
  if (!dateString || !dateString.trim()) return false;
  const pattern = /^\d{4}\/\d{2}\/\d{2}$/;
  const date = moment(dateString);
  return date.isValid() && pattern.test(dateString);
};

export async function getSvgContentBoundsWithPaper(url) {
  try {
    // Create a Paper.js scope
    const paperScope = new paper.PaperScope();
    paperScope.setup(document.createElement("canvas")); // Set up Paper.js on a hidden canvas
    // Fetch the SVG content
    const response = await fetch(url);
    const svgText = await response.text();
    // Import SVG content into Paper.js
    const svgItem = paperScope.project.importSVG(svgText);
    // Get the bounding box of the imported SVG content
    const bounds = svgItem.bounds;
    const contentWidth = bounds.width;
    const contentHeight = bounds.height;
    // Clean up by clearing the Paper.js project
    paperScope.project.clear();
    console.log("Exact Content Bounds with Paper.js:", {
      width: contentWidth,
      height: contentHeight
    });
    return { width: contentWidth, height: contentHeight };
  } catch (error) {
    console.error("Error fetching or processing SVG with Paper.js:", error);
    return null;
  }
}

export function svgImport(url, callback) {
  const paperScope = new paper.PaperScope();
  paperScope.setup(document.createElement("canvas"));
  const timestampedUrl = `${url}?v=${new Date().getTime()}`;

  paperScope.project.importSVG(timestampedUrl, {
    insert: false,
    onLoad: function (svg) {
      return callback({
        size: svg.bounds,
        svg: svg
      });
    },
    onError: function (error) {
      console.error("Error importing SVG:", error);
      callback({ error: "Failed to import SVG" });
    }
  });
  paperScope.project.clear();
}

export async function trimSvgContent(url) {
  try {
    const container = document.createElement("div");
    container.style.visibility = "hidden";
    container.style.position = "absolute";
    document.body.appendChild(container);

    const response = await fetch(url);
    const svgText = await response.text();
    container.innerHTML = svgText;

    const svgElement = container.querySelector("svg");

    let combinedBoundingBox = null;

    let minX = Infinity,
      maxX = 0,
      minY = Infinity,
      maxY = 0;

    svgElement.querySelectorAll("*").forEach((element) => {
      if (typeof element.getBBox === "function") {
        const box = element.getBBox();
        console.log("box", box.x, box.y);
        minX = Math.min(box.x, minX);
        minY = Math.min(box.y, minY);
        maxX = Math.max(box.x, maxX);
        maxY = Math.max(box.y, maxY);
        if (combinedBoundingBox) {
          combinedBoundingBox.x = Math.min(combinedBoundingBox.x, box.x);
          combinedBoundingBox.y = Math.min(combinedBoundingBox.y, box.y);
          combinedBoundingBox.width = Math.max(
            combinedBoundingBox.width,
            box.x + box.width - combinedBoundingBox.x
          );
          combinedBoundingBox.height = Math.max(
            combinedBoundingBox.height,
            box.y + box.height - combinedBoundingBox.y
          );
        } else {
          combinedBoundingBox = box;
        }
      }
    });

    if (!combinedBoundingBox) {
      throw new Error("No graphical content found in SVG.");
    }

    const contentX = combinedBoundingBox.x;
    const contentY = combinedBoundingBox.y;
    const contentWidth = combinedBoundingBox.width;
    const contentHeight = combinedBoundingBox.height;

    svgElement.setAttribute(
      "viewBox",
      `${contentX} ${contentY} ${contentWidth} ${contentHeight}`
    );
    svgElement.setAttribute("width", contentWidth);
    svgElement.setAttribute("height", contentHeight);

    const serializer = new XMLSerializer();
    const trimmedSvgString = serializer.serializeToString(svgElement);

    document.body.removeChild(container);

    console.log("Trimmed SVG Content Dimensions:", {
      width: contentWidth,
      height: contentHeight
    });
    return {
      width: contentWidth,
      height: contentHeight,
      svg: trimmedSvgString,
      width1: maxX - minX,
      height1: maxY - minY
    };
  } catch (error) {
    console.error("Error fetching or processing SVG:", error);
    return null;
  }
}

export async function removeWhitespaceFromSVG(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch the SVG");
    }

    const svgText = await response.text();

    console.log("svgText", svgText);

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = svgText;

    const svgElement = tempDiv.querySelector("svg");
    console.log("svgElement", svgElement);
    if (!svgElement) {
      throw new Error("No SVG element found in the provided URL");
    }

    let width = svgElement.getAttribute("width");
    let height = svgElement.getAttribute("height");

    return { width, height };
  } catch (error) {
    console.error("Error processing SVG:", error);
  }
}
