import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  // Select,
  Button,
  Stack,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import Popup from "components/Popup";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TableData from "./components/TableData";
import {
  formatDate,
  formatDateSearch,
  formatDateSearchEnd,
  formatDateSearchStart,
  formatNumber,
} from "utils/function";
import {
  getListMember,
  resetMembers,
  updateMemberCredit,
} from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageCreditService } from "services";
import Popup2Action from "components/Popup2Action";

// const listStatus = [
//   // { value: "all", text: "전체 회원" },
//   // { value: "active", text: "활성 회원" },
//   // { value: "suspended", text: "정지 회원" },
//   // { value: "secession", text: "탈퇴 회원" }
//   { value: "1", text: "활성 회원" },
//   { value: "3", text: "정지 회원" },
//   { value: "4", text: "탈퇴 회원" }
// ];

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "country", text: "국가" },
];

const listLevels = [
  { value: "beginner", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" },
];

function GiftCreditManagement({ showToast }) {
  const [listChecked, setListChecked] = useState([]);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    gifting: false,
    fetchingMember: false,
  });
  // const [searchTerms, setSearchTerms] = useState({
  //   search: "",
  //   levels: [],
  //   status: "1",
  //   type: "all"
  // });
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: "",
    levels: [],
  });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [amount, setAmount] = useState({ variable: "" });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [state, dispatch] = useGlobalStore();

  const {
    members = [],
    loading = true,
    error = null,
    count = 0,
  } = useMemo(() => state?.member, [state]);

  // useEffect(() => {
  //   dispatch(getListMember());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageCreditService.getFloatVariablesByType(
          "gift_amount"
        );
        if (response?.data?.statusCode === 200) {
          const resData = response?.data ? response.data?.data : [] ?? [];
          setAmount(resData.length > 0 ? resData[resData.length - 1] : {});
        } else {
          const errorData = response?.data
            ? response.data?.message
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage,
      };
    }
    // if (type) {
    condition = {
      ...condition,
      type: type ? type : "all",
      search: search ? search.trim() : "",
    };
    // }
    if (levels.length) {
      condition = { ...condition, levels: levels.join(", ") };
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss"),
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss"),
      };
    }

    return condition;
  };

  useEffect(() => {
    dispatch(getListMember(handleCreateCondition()));
    setListChecked([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetMembers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectUser = (e, userId) => {
    if (e.target.checked) {
      setListChecked([...listChecked, userId]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== userId);
      setListChecked(newListChecked);
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await dispatch(getListMember(handleCreateCondition(true, false)));
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "", levels: [] });
    setDateFrom(null);
    setDateTo(null);
    await dispatch(getListMember(handleCreateCondition(false, true)));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleSaveAmount = async () => {
    if (!amount?.variable) {
      showToast(TOAST_TYPE.WARN, "Amount is blank");
      return;
    }
    try {
      let response = "";
      if (amount.id) {
        response = await ManageCreditService.updateFloatVariables(amount.id, {
          ...amount,
          variable: Number(amount.variable),
        });
      } else {
        response = await ManageCreditService.createFloatVariables({
          variable: Number(amount.variable),
          type: "gift_amount",
          unit: "",
          currency: "credit",
          level: "",
        });
      }
      if (response?.data?.statusCode === 200) {
        setLoadingProcess({ ...loadingProcess, confirming: false });
        showToast(TOAST_TYPE.SUCCESS, "Success");
        setAmount(response?.data?.data ?? {});
      } else {
        const errorData = response?.data?.message?.[0] ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleConfirm = () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No members selected");
      return;
    }
    if (!amount?.variable) {
      showToast(TOAST_TYPE.WARN, "Amount is blank");
      return;
    }
    setOpenConfirm(true);
  };

  const handelSetAmount = async () => {
    const data = {
      userIds: listChecked,
      amount: amount?.variable ? Number(amount?.variable) : 0,
    };

    setLoadingProcess({ ...loadingProcess, gifting: true });

    try {
      const res = await ManageCreditService.createGiftCredit(data);
      dispatch(updateMemberCredit(data, handleCreateCondition()));
      setListChecked([]);
      setOpenConfirm(false);
      showToast(TOAST_TYPE.SUCCESS, "Success");
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }

    setLoadingProcess({ ...loadingProcess, gifting: false });
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const renderCheckboxAll = () => {
    return (
      <Checkbox
        checked={listChecked?.length === members?.length}
        onChange={(e) => {
          if (listChecked?.length === members?.length) {
            setListChecked([])
          } else {
            setListChecked(members?.map(m => m?.id))
          }
        }}
      />
    );
  };

  const renderTable = () => {
    const sizeWidth = [
      "9%",
      "3%",
      "10%",
      "16%",
      "10%",
      "7%",
      "13%",
      "10%",
      "10%",
      "12%",
    ];

    const headersTitle = [
      "대상선택",
      "NO.",
      "성명",
      "이메일",
      "국가",
      "회원등급",
      "보유 Credit",
      "보유 Profit",
      "가입일",
      "상태",
    ];

    const tableData = members.map((member, index) => {
      return {
        ...member,
        index: index + 1 + rowsPerPage * page,
        checkbox: (
          <Checkbox
            checked={listChecked.includes(member.id)}
            onChange={(e) => handleSelectUser(e, member.id)}
          />
        ),
        createdAt: member.createdAt ? formatDate(member.createdAt) : "",
        suspendedTime: member.suspendedTime
          ? formatDate(member.suspendedTime)
          : null,
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
        renderCheckboxAll={renderCheckboxAll}
      />
    );
  };

  const renderSetAmount = () => {
    return (
      <Grid container direction="column" spacing={1}>
        <Grid item>증정 Credit 금액 설정</Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item style={{ marginRight: "20px" }}>
              <Box
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "2px 20px",
                }}
              >
                직접 입력
              </Box>
            </Grid>
            <Grid item style={{ marginRight: "20px" }}>
              <TextField
                size="small"
                sx={{
                  "& label": {
                    paddingLeft: (theme) => theme.spacing(2),
                  },
                  "& input": {
                    width: "140px",
                    height: "13px",
                  },
                  "& fieldset": {
                    paddingLeft: (theme) => theme.spacing(2.5),
                    borderRadius: 0,
                  },
                }}
                value={amount?.variable ?? ""}
                onChange={(e) =>
                  setAmount({ ...amount, variable: e.target.value })
                }
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  borderRadius: "0",
                  textTransform: "capitalize",
                  height: "30px",
                  fontSize: "0.7rem",
                }}
                onClick={handleSaveAmount}
              >
                확정
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              sx={{ fontSize: "0.9rem", mt: 2 }}
              container
              justifyContent="center"
              alignItems="center"
            >
              * 증정 Credit은 유저에게 Bonus 로 지급되는 Credit 입니다.
            </Grid>
          </Grid>
          {/* <Grid item>
            <Grid
              sx={{ fontSize: "0.9rem" }}
              container
              justifyContent="center"
              alignItems="center"
            >
              * 상품을 선택하였을시 Bonus 크레딧은 제외됩니다.
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item style={{ fontWeight: "bold" }}>
          회원 검색
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px",
                  }}
                >
                  회원가입일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            width: "80px",
                            height: "13px",
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0,
                          },
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            width: "80px",
                            height: "13px",
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ marginRight: "20px" }}>
              <Grid container alignItems="center">
                <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px",
                    }}
                  >
                    회원등급
                  </Box>
                </FormControl>
                <FormControl>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px",
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem",
                              },
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important",
                          },
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px",
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px",
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2),
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5),
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0,
                    },
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px",
                    },
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90,
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="Credit 관리 - 증정 Credit 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSetAmount()}</Grid>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <span>총 </span>
                <span style={{ color: "#0468b4", fontWeight: "bold" }}>
                  {count ? formatNumber(count) : 0}
                </span>
                <span>명</span>
              </Grid>
              <Grid item>
                {/* <ThemeProvider theme={theme}> */}
                <LoadingButton
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "0",
                    width: 190,
                  }}
                  // color="suspend"
                  onClick={handleConfirm}
                  loading={loadingProcess["gifting"] ? true : false}
                  loadingIndicator="Credit 증정"
                  variant="contained"
                >
                  Credit 증정
                </LoadingButton>
                {/* </ThemeProvider> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup2Action
            open={openConfirm}
            setOpen={setOpenConfirm}
            message='선택한 회원에게 "Credit을 부여하시겠습니까?'
            onClickConfirm={handelSetAmount}
            onClickCancel={() => setOpenConfirm(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(GiftCreditManagement);
