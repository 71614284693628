import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManagePatternService } from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  styled,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import {
  formatDate,
  // formatNumber,
  // addMonths,
  // sortListByDate,
  // formatDateSearch,
  // listGroupBy,
  // flattenCategory,
  formatDateSearchEnd,
  formatDateSearchStart
} from "utils/function";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Popup2Action from "components/Popup2Action";
import Popup from "components/Popup";

// const listSortBy = [
//   { value: "ascending", text: "오름차순" },
//   { value: "descending", text: "내림차순" }
// ];

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "category", text: "카테고리" }
];

const listLevels = [
  { value: "beginner", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

// const listStatus = [
//   { value: "approval", text: "승인" },
//   { value: "reject", text: "거절" },
//   { value: "underReview", text: "검토중" }
// ];

// const listStatus = [
//   { value: "approved", text: "승인" },
//   { value: "reject", text: "거절" },
//   { value: "waiting_approve", text: "검토중" }
// ];

// const mapStatusKoToEn = (status) => {
//   switch (status) {
//     case "승인":
//       return "approved";
//     case "거절":
//       return "reject";
//     case "검토중":
//       return "waiting_approve";
//     default:
//       return "";
//   }
// };

const mapStatusEnToKo = (status) => {
  switch (status) {
    case "approved":
      return "승인";
    case "reject":
      return "거절";
    case "waiting_approve":
      return "검토중";
    default:
      return "";
  }
};

// const InputLabel = styled(Box)({
//   fontSize: "0.7rem",
//   height: "30px",
//   backgroundColor: "#1565c0",
//   color: "#fff",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   padding: "0 2px",
//   minWidth: "76px"
// });

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& label": {
    paddingLeft: (theme) => theme.spacing(2)
  },
  "& input": {
    width: width ? width : "100%",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    paddingLeft: (theme) => theme.spacing(2.5),
    borderRadius: 0
  }
}));

function ApproveRegistration({ showToast }) {
  const [listPattern, setListPattern] = useState([]);
  // const [listCategory, setListCategory] = useState([]);
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  // const [cancelId, setCancelId] = useState("");
  const [openCancelReason, setOpenCancelReason] = useState(false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(true);

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: "",
    levels: []
  });
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    approving: false,
    cancelling: false
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = { status: "approved,reject,waiting_approve" };
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1
        ? { page: 1, limit: rowsPerPage }
        : { ...condition };
    }
    if (rowsPerPage !== -1) {
      condition = { ...condition, page: page + 1, limit: rowsPerPage };
    }
    if (type !== "category") {
      condition = {
        ...condition,
        type: type ? type : "all",
        search: search ? search.trim() : ""
      };
    } else {
      // if (search && mapStatusKoToEn(search.trim())) {
      //   condition = { ...condition, status: mapStatusKoToEn(search.trim()) };
      // }
      // const getIdCategory = (value) => {
      //   if (!search) return "undefine";
      //   const valueSearch = value.trim().toLowerCase();
      //   const listId = listCategory
      //     .map((category) => {
      //       const categoryName = category?.category ?? "";
      //       if (
      //         categoryName &&
      //         categoryName.length >= valueSearch.length &&
      //         categoryName.toLowerCase().includes(valueSearch)
      //       ) {
      //         return category.id;
      //       }
      //       return null;
      //     })
      //     .filter((item) => item);
      //   return listId.length > 0 ? listId[0] : "undefine";
      // };
      // if (getIdCategory(search)) {
      //   condition = { ...condition, category: getIdCategory(search) };
      // }
      condition = { ...condition, category: search?.trim() };
    }
    if (levels.length > 0) {
      condition = { ...condition, levels: levels.join(",") };
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD")
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const response = await ManagePatternService.getListPattern(
        handleCreateCondition(isSearch, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListPattern(response?.data?.data?.data ?? []);
        setCount(response?.data?.count ?? 0);

        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await ManagePatternService.getCategoryList();
  //       if (response?.data?.statusCode === 200) {
  //         // const listCategoryGroupByType = listGroupBy(
  //         //   response?.data?.data ?? [],
  //         //   "type"
  //         // );
  //         // const categoryFirstColumn = listCategoryGroupByType["vehicle"].filter(
  //         //   (category) => category.column === "region"
  //         // );
  //         // setListCategory(categoryFirstColumn);
  //         setListCategory(flattenCategory(response?.data?.data ?? []));
  //         setLoading(false);
  //       } else {
  //         const errorData = response?.data
  //           ? response.data?.msg
  //           : "Error" ?? "Error";
  //         setLoading(false);
  //         showToast(TOAST_TYPE.ERROR, errorData);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       const errorStatusText = error?.response
  //         ? error.response?.statusText
  //         : "";
  //       const errorDataMessage = error.response.data?.message ?? "";

  //       const errorMessage =
  //         errorDataMessage ?? errorStatusText ?? "System Error";
  //       showToast(TOAST_TYPE.ERROR, errorMessage);
  //     }
  //   }
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "", levels: [] });
    setDateFrom(null);
    setDateTo(null);
    // setPage(0);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
  };

  // const handleChangeStatus = async (id, status) => {
  //   if (status === "reject") {
  //     setCancelId(id);
  //     setOpenCancelConfirm(true);
  //     return;
  //   }
  //   try {
  //     const response = await ManagePatternService.updatePatternStatus(id, {
  //       status,
  //       reason: ""
  //     });
  //     if (response?.data?.statusCode === 200) {
  //       await fetchData();
  //       showToast(TOAST_TYPE.SUCCESS, "Success");
  //     }
  //   } catch (error) {
  //     setLoadingProcess({ ...loadingProcess, suspending: false });
  //     const errorStatusText = error?.response ? error.response?.statusText : "";
  //     const errorDataMessage = error?.response?.data?.message ?? "";
  //     const errorMessage =
  //       errorDataMessage ?? errorStatusText ?? "System Error";
  //     showToast(TOAST_TYPE.ERROR, errorMessage);
  //   }
  // };

  const handleCancelPattern = async () => {
    const data = {
      status: "reject",
      reason: reason
    };
    setLoadingProcess({ ...loadingProcess, cancelling: true });
    try {
      const response = await ManagePatternService.updatePatternStatus(
        '',
        data
      );
      if (response?.data?.statusCode === 200) {
        setOpenCancelReason(false);
        setReason("");
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Success");
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = [
      "5%",
      "10%",
      "20%",
      "11%",
      "14%",
      "10%",
      "10%",
      "10%",
      "10%"
    ];
    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "등급",
      "카테고리",
      "업로드날짜",
      "면적(ft)",
      "Price",
      "상태"
    ];

    // const generateClass = (region, make, year, model, submodel, side) => {
    //   const nullValue = (value) => (value ? value : "");
    //   return `${nullValue(region)} - ${nullValue(make)} - ${nullValue(
    //     year
    //   )} - ${nullValue(model)} - ${nullValue(submodel)} - ${nullValue(side)}`;
    // };

    const getCategory = (pattern) => {
      const listModels = pattern?.models ?? [];
      const listModelsCategory = listModels.map((item) => item.category);
      const listVehicles = pattern?.vehicles ?? [];
      const listVehiclesCategory = listVehicles.map((item) => item.category);
      return [...listModelsCategory, ...listVehiclesCategory].join(" - ");
    };

    const tableData = listPattern.map((pattern, index) => {
      return {
        ...pattern,
        index: index + 1 + rowsPerPage * page,
        name: pattern?.nickName ?? "",
        email: pattern?.email ?? "",
        rating: pattern?.level ?? "",
        // category:
        //   pattern?.vehicles?.length > 0 ? pattern?.vehicles[0]?.category : "",
        category: getCategory(pattern),
        dateOfRequestForApproval: pattern?.createdAt
          ? formatDate(pattern?.createdAt)
          : "",
        area:
          pattern?.sqft && Number(pattern?.sqft) ? Number(pattern?.sqft) : "",
        price:
          pattern?.unitPrice && Number(pattern?.unitPrice)
            ? Number(pattern?.unitPrice)
            : "",
        status: mapStatusEnToKo(pattern?.status ?? "")
        // status: (
        //   <Select
        //     MenuProps={{
        //       disableScrollLock: true
        //     }}
        //     fullWidth
        //     value={pattern?.status ?? ""}
        //     onChange={(e) => {
        //       e.stopPropagation();
        //       handleChangeStatus(pattern.patternId, e.target.value);
        //     }}
        //     style={{
        //       borderRadius: "0",
        //       height: "30px"
        //     }}
        //   >
        //     {listStatus.map((item) => (
        //       <MenuItem key={item.value} value={item.value}>
        //         {item.text}
        //       </MenuItem>
        //     ))}
        //   </Select>
        // )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item style={{ fontWeight: "bold" }}>
          회원 검색
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  업로드 날짜
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ marginRight: "20px" }}>
              <Grid container alignItems="center">
                <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px"
                    }}
                  >
                    회원등급
                  </Box>
                </FormControl>
                <FormControl>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px"
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem"
                              }
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important"
                          }
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px",
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="패턴관리 - 패턴 승인/등록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          {/* <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography sx={{ mr: 2 }}>정렬기준</Typography>
                  <Select
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      borderRadius: "0",
                      height: "30px",
                      width: "120px"
                    }}
                  >
                    {listSortBy.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item>{renderTable()}</Grid>
          <Popup2Action
            open={openCancelConfirm}
            setOpen={setOpenCancelConfirm}
            message="패턴 승인 요청을 거절하시겠습니까?"
            onClickConfirm={() => {
              setOpenCancelConfirm(false);
              setOpenCancelReason(true);
            }}
            onClickCancel={() => setOpenCancelConfirm(false)}
          />
          <Popup
            open={openCancelReason}
            setOpen={setOpenCancelReason}
            message="사유를 입력해주세요."
            button="확인"
            onClick={handleCancelPattern}
          >
            <Box
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                justifyContent: 'center',
                flexDirection: "column",
                flexWrap: "nowrap",
                gap: "5px",
                width: '80%',
                textAlign: 'center'
              }}
            >
              <Typography style={{ fontSize: "0.9rem" }}>
                *업로더에게 거절 사유로 보여집니다.
              </Typography>
              <StyledTextField
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Box>
          </Popup>
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(ApproveRegistration);
