import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  // Select,
  // MenuItem,
  Checkbox,
  Stack,
  FormControlLabel,
  Button,
} from "@mui/material";
import {
  formatDate,
  formatDateSearch,
  formatDateSearchEnd,
} from "utils/function";

import TableData from "./components/TableData";
import { useGlobalStore } from "hooks";
import {
  deleteCoupons,
  getListCoupon,
  resetCoupons,
  // updateCouponStage
} from "redux/actions/coupon";
import { ManageCouponService } from "services";
// import { BENEFIT_TYPE } from "../constants";
import Popup from "components/Popup";
import Excel from "exceljs";
import { useNavigate } from "react-router-dom";
import Popup2Action from "components/Popup2Action";

// const listBenefitClassification = [
//   { value: "amount discount", text: "Amount Discount" },
//   { value: "free shipping", text: "Free Shipping" },
//   { value: "percent discount", text: "% Discount" },
// ];

// const listIssuanceClassification = [
//   { value: "Automatic issuance", text: "자동 발급" },
//   { value: "Conditional issuance", text: "Conditional issuance" }
// ];

// const stateCoupon = [
//   { value: "issuing", text: "발급중" },
//   { value: "stop", text: "발급 중지" }
// ];

// const listCouponCategory = [
//   { value: "discount", text: "금액할인" },
//   { value: "free_shipping", text: "배송비 무료" },
//   { value: "discount_shipping", text: "금액할인 + 배송비 무료" },
//   { value: "credit_presentation", text: "Credit 증정" }
// ];

const listCouponCategory = [
  { value: "discount", unit: "fixed_price", text: "$할인" },
  { value: "discount", unit: "percent", text: "%할인" },
  { value: "free_shipping", unit: "percent", text: "배송비 무료" },
];

// const mapCouponCategory = {
//   discount: "금액할인",
//   free_shipping: "배송비 무료",
//   discount_shipping: "금액할인 + 배송비 무료",
//   credit_presentation: "Credit 증정"
// };

const mapCouponCategory = (type, unit) => {
  switch (type) {
    case "discount":
      if (unit === "percent") return "%할인";
      if (unit === "fixed_price") return "$할인";
      break;
    case "free_shipping":
      return "배송비 무료";
    default:
      return "";
  }
};

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "auto",
    height: height ? height : "25px",
    padding: "4px 10px",
  },
  "& input:focus": {
    outline: "none",
  },
  "& fieldset": {
    borderRadius: 0,
  },
}));

// const StyledSelect = styled(Select)({
//   borderRadius: "0",
//   height: "27px",
//   width: "170px",
//   marginRight: "5px",
//   backgroundColor: "#fff",
//   color: "#000"
// });

// const StyledSelectStatus = styled(Select)({
//   "& .MuiSvgIcon-root": {
//     right: 0
//   },
//   "& .MuiSelect-select": {
//     padding: "2px",
//     // fontSize: "0.8rem",
//     paddingRight: "18px !important"
//   },
//   borderRadius: "0",
//   height: "33px",
//   marginRight: "5px",
//   backgroundColor: "#fff",
//   color: "#000"
// });

const searchTermDefault = {
  name: "",
  couponType: "",
  couponUnit: "",
};

function CouponList({ showToast }) {
  const [state, dispatch] = useGlobalStore();

  const [listChecked, setListChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isInfDate, setIsInfDate] = useState(null);

  // const [date, setDate] = useState(null);
  const [dateUseFrom, setDateUseFrom] = useState(null);
  const [dateUseTo, setDateUseTo] = useState(null);
  const [dateCreateFrom, setDateCreateFrom] = useState(null);
  const [dateCreateTo, setDateCreateTo] = useState(null);
  const [searchTerms, setSearchTerms] = useState({ ...searchTermDefault });
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    deleting: false,
  });

  const navigate = useNavigate();

  const {
    coupons = [],
    loading = true,
    count = 0,
    error = null,
  } = useMemo(() => state?.coupon, [state]);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { name = "", couponType = "", couponUnit = "" } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage,
      };
    }
    if (couponType) {
      condition = { ...condition, benefit: couponType };
    }
    if (name) {
      condition = { ...condition, name: name ? name.trim() : "" };
    }
    if (couponUnit) {
      condition = { ...condition, unit: couponUnit };
    }
    if (dateCreateFrom) {
      condition = {
        ...condition,
        registration_from: formatDateSearch(
          dateCreateFrom,
          "YYYY-MM-DD HH:mm:ss"
        ),
      };
    }

    if (dateCreateTo) {
      condition = {
        ...condition,
        registration_to: formatDateSearchEnd(dateCreateTo, "YYYY-MM-DD"),
      };
    }

    if (dateUseFrom && isInfDate === false) {
      condition = {
        ...condition,
        start: formatDateSearch(dateUseFrom, "YYYY-MM-DD HH:mm:ss"),
      };
    }

    if (dateUseTo && isInfDate === false) {
      condition = {
        ...condition,
        end: formatDateSearchEnd(dateUseTo, "YYYY-MM-DD"),
      };
    }

    if (isInfDate === true) {
      condition = {
        ...condition,
        indefinite: true,
        // start: "",
        // end: ""
        // from: dateFrom,
        // to: dateTo
      };
    }

    return condition;
  };

  useEffect(() => {
    dispatch(getListCoupon(handleCreateCondition()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    dispatch(resetCoupons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  const handleSelectCoupon = (e, tid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, tid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== tid);
      setListChecked(newListChecked);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      coupons.forEach((transaction) => {
        newListChecked.push(transaction.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleDelete = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No coupons selected");
      return;
    }

    const data = {
      couponIds: listChecked,
    };

    setLoadingProcess({ ...loadingProcess, deleting: true });

    try {
      const response = await ManageCouponService.deleteCoupon(data);
      if (response?.data?.statusCode === 200) {
        dispatch(deleteCoupons(data, handleCreateCondition()));
        setLoadingProcess({ ...loadingProcess, deleting: false });
        setListChecked([]);
        setIsCheckAll(false);
        // setOpenDeleteConfirm(false);
        showToast(TOAST_TYPE.SUCCESS, "Deleted coupon");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, deleting: false });
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setOpenDeleteConfirm(false);
  };

  const handleSearch = async () => {
    setLoadingProcess({
      ...loadingProcess,
      searching: true,
    });
    await dispatch(getListCoupon(handleCreateCondition(true, false)));
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleReset = async () => {
    setSearchTerms({ ...searchTermDefault });
    setDateUseFrom(null);
    setDateUseTo(null);
    setDateCreateFrom(null);
    setDateCreateTo(null);
    setIsInfDate(null);
    setPage(0);
    setLoadingProcess({ ...loadingProcess, resetting: true });
    await dispatch(getListCoupon(handleCreateCondition(false, true)));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  // const handleChangeState = async (e, id) => {
  //   try {
  //     const response = await ManageCouponService.updateCoupon(id, {
  //       stage: e.target.value
  //     });
  //     if (response.status === 200) {
  //       dispatch(updateCouponStage({ id: id, stage: e.target.value }));
  //       showToast(TOAST_TYPE.SUCCESS, "Update success");
  //     } else {
  //       showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
  //     }
  //   } catch (error) {
  //     const errorStatusText = error?.response?.statusText ?? "";
  //     const errorDataMessage = error?.response?.data?.message ?? "";
  //     const errorMessage =
  //       errorDataMessage ?? errorStatusText ?? "System Error";
  //     showToast(TOAST_TYPE.ERROR, errorMessage);
  //   }
  // };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "name") {
      setSearchTerms((prevState) => ({ ...prevState, name: value }));
    }
    if (key === "couponType") {
      setSearchTerms((prevState) => ({ ...prevState, ...value }));
    }
  };

  const handleCreateExcel = async (coupons) => {
    const fileName = "쿠폰 목록";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("쿠폰 목록");

    const headersTitle = [
      "쿠폰번호",
      "쿠폰명",
      "혜택 구분",
      "사용기한",
      "등록날짜",
    ];

    const columnExcel = ["A", "B", "C", "D", "E"];
    const columnWidth = [10, 20, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // const calculateFee = (netAmount, amount) => {
    //   if (!netAmount || !amount) return "";
    //   return Number(netAmount) - Number(amount);
    // };

    // add value, style cell // body
    coupons.forEach((coupon, index) => {
      const dateOfUse = (start, end) => {
        if (!start || !end) {
          return "무기한";
        }

        return `${formatDate(start, "YY.MM.DD")} - ${formatDate(
          end,
          "YY.MM.DD"
        )}`;
      };
      ws.getCell(`A${index + 2}`).value = coupon.id ? coupon.id : "";
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`B${index + 2}`).value = coupon.name ? coupon.name : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`C${index + 2}`).value = coupon?.benefit
        ? mapCouponCategory(coupon?.benefit, coupon?.unit)
        : "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`D${index + 2}`).value = dateOfUse(coupon.start, coupon.end);
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`E${index + 2}`).value = coupon.createdAt
        ? formatDate(coupon.createdAt)
        : "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    let condition = {};
    if (isDownloadFilter) {
      const { name = "", couponType = "", couponUnit = "" } = searchTerms;
      if (couponType) {
        condition = { ...condition, benefit: couponType };
      }
      if (name) {
        condition = { ...condition, name: name ? name.trim() : "" };
      }
      if (couponUnit) {
        condition = { ...condition, unit: couponUnit };
      }
      if (dateCreateFrom) {
        condition = {
          ...condition,
          registration_from: formatDateSearch(
            dateCreateFrom,
            "YYYY-MM-DD HH:mm:ss"
          ),
        };
      }

      if (dateCreateTo) {
        condition = {
          ...condition,
          registration_to: formatDateSearchEnd(dateCreateTo, "YYYY-MM-DD"),
        };
      }

      if (dateUseFrom && isInfDate === false) {
        condition = {
          ...condition,
          start: formatDateSearch(dateUseFrom, "YYYY-MM-DD HH:mm:ss"),
        };
      }

      if (dateUseTo && isInfDate === false) {
        condition = {
          ...condition,
          end: formatDateSearchEnd(dateUseTo, "YYYY-MM-DD"),
        };
      }

      if (isInfDate === true) {
        condition = {
          ...condition,
          indefinite: true,
          // start: "",
          // end: ""
          // from: dateFrom,
          // to: dateTo
        };
      }
    }
    try {
      const res = await ManageCouponService.getCouponList(condition);
      if (res?.data?.statusCode === 200) {
        handleCreateExcel(res?.data?.data ?? []);
      } else {
        showToast(res?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setOpenPopupAll(false);
    setOpenPopupFilter(false);
  };

  // const renderSearchArea = () => (
  //   <Grid
  //     container
  //     flexDirection="column"
  //     style={{ flexFlow: "wrap", border: "1px solid #c4c4c4" }}
  //     // style={{}}
  //   >
  //     <Box
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         // border: "1px solid #c4c4c4",
  //         width: "100%"
  //       }}
  //     >
  //       <Box
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           backgroundColor: "#1565c0",
  //           color: "#fff",
  //           padding: "10px 5px"
  //         }}
  //       >
  //         발급쿠폰검색
  //       </Box>
  //       <Grid container style={{}}>
  //         <Grid item sm={6} style={{ borderRight: "1px solid #c4c4c4" }}>
  //           <Box
  //             style={{
  //               display: "flex",
  //               flexDirection: "column",
  //               padding: "10px 10px"
  //             }}
  //           >
  //             <Box style={{ display: "flex", alignItems: "center" }}>
  //               <Typography sx={{ mr: 2, flexBasis: "120px" }}>
  //                 쿠폰명
  //               </Typography>
  //               <StyledTextField
  //                 height="18px"
  //                 value={searchTerms["name"]}
  //                 onChange={(e) =>
  //                   handleChangeSearchTerms("name", e.target.value)
  //                 }
  //               />
  //             </Box>
  //             <Box
  //               style={{ display: "flex", alignItems: "center" }}
  //               sx={{ mt: 2 }}
  //             >
  //               <Typography sx={{ mr: 2, flexBasis: "120px" }}>
  //                 생성일자 전체
  //               </Typography>
  //               {/* <StyledSelect
  //                 height="0px"
  //                 //   value={searchTerms["status"]}
  //                 //   onChange={(e) =>
  //                 //     handleChangeSearchTerms("status", e.target.value)
  //                 //   }
  //               >
  //                 {listSearchField.map((item) => (
  //                   <MenuItem key={item.value} value={item.value}>
  //                     {item.text}
  //                   </MenuItem>
  //                 ))}
  //               </StyledSelect> */}
  //               <LocalizationProvider dateAdapter={AdapterDateFns}>
  //                 <DesktopDatePicker
  //                   inputFormat="yyyy/MM/dd"
  //                   value={date}
  //                   onChange={(value) => {
  //                     setDate(value);
  //                   }}
  //                   renderInput={(params) => (
  //                     <StyledTextField
  //                       {...params}
  //                       size="small"
  //                       height="19px"
  //                       width="100px"
  //                     />
  //                   )}
  //                 />
  //               </LocalizationProvider>
  //             </Box>
  //           </Box>
  //         </Grid>
  //         <Grid item sm={6}>
  //           <Box
  //             style={{
  //               display: "flex",
  //               flexDirection: "column",
  //               padding: "10px 10px"
  //             }}
  //           >
  //             <Box style={{ display: "flex", alignItems: "center" }}>
  //               <Typography sx={{ mr: 2, width: "190px" }}>혜택구분</Typography>
  //               <StyledSelect
  //                 MenuProps={{
  //                   disableScrollLock: true
  //                 }}
  //                 height="0px"
  //                 value={searchTerms["issuanceMethod"]}
  //                 onChange={(e) =>
  //                   handleChangeSearchTerms("issuanceMethod", e.target.value)
  //                 }
  //                 displayEmpty
  //               >
  //                 <MenuItem value="">전체</MenuItem>
  //                 {listIssuanceClassification.map((item) => (
  //                   <MenuItem key={item.value} value={item.value}>
  //                     {item.text}
  //                   </MenuItem>
  //                 ))}
  //               </StyledSelect>
  //             </Box>
  //             <Box
  //               style={{ display: "flex", alignItems: "center" }}
  //               sx={{ mt: 2 }}
  //             >
  //               <Typography sx={{ mr: 2, width: "190px" }}>발급구분</Typography>
  //               <StyledSelect
  //                 displayEmpty
  //                 MenuProps={{
  //                   disableScrollLock: true
  //                 }}
  //                 height="0px"
  //                 value={searchTerms["benifitType"]}
  //                 onChange={(e) =>
  //                   handleChangeSearchTerms("benifitType", e.target.value)
  //                 }
  //               >
  //                 <MenuItem value="">전체</MenuItem>
  //                 {Object.keys(BENEFIT_TYPE).map((item) => (
  //                   <MenuItem key={item} value={item}>
  //                     {BENEFIT_TYPE[item]}
  //                   </MenuItem>
  //                 ))}
  //               </StyledSelect>
  //             </Box>
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //   </Grid>
  // );

  const renderSearchArea = () => (
    <Grid
      container
      flexDirection="column"
      style={{ flexFlow: "wrap", border: "1px solid #c4c4c4" }}
      // style={{}}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          // border: "1px solid #c4c4c4",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#1565c0",
            color: "#fff",
            padding: "10px 5px",
          }}
        >
          쿠폰 검색
        </Box>
        <Grid container alignItems="center" sx={{ p: 1 }}>
          <Grid item sm={1.5}>
            쿠폰명
          </Grid>
          <Grid item sm={10.5}>
            <StyledTextField
              // height="18px"
              value={searchTerms["name"]}
              placeholder="쿠폰명을 입력해주세요."
              onChange={(e) => handleChangeSearchTerms("name", e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={{ p: 1 }}>
          <Grid item sm={1.5}>
            사용기한
          </Grid>
          <Grid item sm={10.5}>
            <Grid container alignItems="center">
              <Typography
                style={{
                  color: "white",
                  height: "33px",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 5px",
                  border: "1px solid #a4a4a4",
                  backgroundColor: isInfDate === true ? "#1565c0" : "#c4c4c4",
                  cursor: "pointer",
                }}
                onClick={() => setIsInfDate(true)}
              >
                무기한
              </Typography>
              <Typography
                style={{
                  color: "white",
                  height: "33px",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 5px",
                  cursor: "pointer",
                  marginRight: "20px",
                  border: "1px solid #a4a4a4",
                  backgroundColor: isInfDate === false ? "#1565c0" : "#c4c4c4",
                }}
                onClick={() => setIsInfDate(false)}
              >
                기한설정
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="yyyy/MM/dd"
                  value={dateUseFrom}
                  maxDate={dateUseTo || new Date("2200-01-01")}
                  onChange={(value) => {
                    setDateUseFrom(value);
                  }}
                  disabled={isInfDate === true || isInfDate === null}
                  renderInput={(params) => (
                    <StyledTextField {...params} size="small" width="100px" />
                  )}
                />
                <span>&nbsp;-&nbsp;</span>
                <DesktopDatePicker
                  inputFormat="yyyy/MM/dd"
                  value={dateUseTo}
                  minDate={dateUseFrom || new Date("1970-01-01")}
                  disabled={isInfDate === true || isInfDate === null}
                  onChange={(value) => {
                    setDateUseTo(value);
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} size="small" width="100px" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" sx={{ p: 1 }}>
          <Grid item sm={1.5}>
            등록날짜
          </Grid>
          <Grid item sm={10.5}>
            <Grid container alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="yyyy/MM/dd"
                  value={dateCreateFrom}
                  maxDate={dateCreateTo || new Date("2200-01-01")}
                  onChange={(value) => {
                    setDateCreateFrom(value);
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} size="small" width="100px" />
                  )}
                />
                <span>&nbsp;-&nbsp;</span>
                <DesktopDatePicker
                  inputFormat="yyyy/MM/dd"
                  value={dateCreateTo}
                  minDate={dateCreateFrom || new Date("1970-01-01")}
                  onChange={(value) => {
                    setDateCreateTo(value);
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} size="small" width="100px" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={{ p: 1 }}>
          <Grid item sm={1.5}>
            혜택 구분
          </Grid>
          <Grid item sm={10.5}>
            <Grid container alignItems="center">
              {listCouponCategory.map((item, index) => (
                <Typography
                  key={index}
                  style={{
                    color: "white",
                    height: "33px",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 5px",
                    border: "1px solid #a4a4a4",
                    backgroundColor:
                      searchTerms["couponType"] === item.value &&
                      searchTerms["couponUnit"] === item.unit
                        ? "#1565c0"
                        : "#c4c4c4",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleChangeSearchTerms("couponType", {
                      couponType: item.value,
                      couponUnit: item.unit,
                    })
                  }
                >
                  {item.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container style={{}}>
          <Grid item sm={2} style={{ borderRight: "1px solid #c4c4c4" }}>
            <Typography sx={{ mr: 2, flexBasis: "120px" }}>쿠폰명</Typography>

            <Box
              style={{ display: "flex", alignItems: "center" }}
              sx={{ mt: 2 }}
            >
              <Typography sx={{ mr: 2, flexBasis: "120px" }}>
                생성일자 전체
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 10px"
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ mr: 2, width: "190px" }}>혜택구분</Typography>
                <StyledSelect
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  height="0px"
                  value={searchTerms["issuanceMethod"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("issuanceMethod", e.target.value)
                  }
                  displayEmpty
                >
                  <MenuItem value="">전체</MenuItem>
                  {listIssuanceClassification.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Box>
              <Box
                style={{ display: "flex", alignItems: "center" }}
                sx={{ mt: 2 }}
              >
                <Typography sx={{ mr: 2, width: "190px" }}>발급구분</Typography>
                <StyledSelect
                  displayEmpty
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  height="0px"
                  value={searchTerms["benifitType"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("benifitType", e.target.value)
                  }
                >
                  <MenuItem value="">전체</MenuItem>
                  {Object.keys(BENEFIT_TYPE).map((item) => (
                    <MenuItem key={item} value={item}>
                      {BENEFIT_TYPE[item]}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Box>
            </Box>
          </Grid>
        </Grid> */}
      </Box>
    </Grid>
  );

  const renderTable = () => {
    const sizeWidth = ["5%", "10%", "15%", "15%", "19%", "10%", "13%", "13%"];
    const headersTitle = [
      <Checkbox checked={isCheckAll} onChange={(e) => handleSelectAll(e)} />,
      "쿠폰번호",
      "쿠폰명",
      "혜택 구분",
      "사용기한",
      "등록날짜",
      "",
      "쿠폰 수정",
    ];

    const dateOfUse = (start, end) => {
      if (!start || !end) {
        return "무기한";
      }

      return `${formatDate(start, "YY.MM.DD")} - ${formatDate(
        end,
        "YY.MM.DD"
      )}`;
    };

    // const generateBenefit = (benifitType) => {
    //   return BENEFIT_TYPE[benifitType];
    // };

    // const newCoupons = [
    //   {
    //     id: 3,
    //     name: "test aazz",
    //     benifitType: "금액할인 + 배송비 무료",
    //     benifitAmount: 12,
    //     benefitUnit: null,
    //     startUse: "2023-07-12T22:00:00.000Z",
    //     endUse: "2023-07-28T22:00:00.000Z",
    //     issuanceClassification: "신규가입 시",
    //     issuanceMethod: null,
    //     status: null,
    //     area: null,
    //     minimumIssued: null,
    //     stage: "issuing",
    //     issuance: null,
    //     minimumPurchase: "12.00",
    //     DCPrice: null,
    //     description: "test aazz",
    //     dept1CategoryId: null,
    //     dept2CategoryId: null,
    //     dept3CategoryId: null,
    //     createdAt: "2023-07-06T08:47:44.000Z",
    //     updatedAt: "2023-07-06T08:47:44.000Z",
    //     deletedAt: null
    //   },
    //   {
    //     id: 5,
    //     name: "test 4",
    //     benifitType: "배송비 무료",
    //     benifitAmount: 12,
    //     benefitUnit: null,
    //     startUse: null,
    //     endUse: null,
    //     issuanceClassification: "신규가입 시",
    //     issuanceMethod: null,
    //     status: null,
    //     area: null,
    //     minimumIssued: null,
    //     stage: "issuing",
    //     issuance: null,
    //     minimumPurchase: "12.00",
    //     DCPrice: null,
    //     description: "test 4",
    //     dept1CategoryId: null,
    //     dept2CategoryId: null,
    //     dept3CategoryId: null,
    //     createdAt: "2023-07-06T10:40:49.000Z",
    //     updatedAt: "2023-07-06T10:40:49.000Z",
    //     deletedAt: null
    //   }
    // ];

    const tableData = coupons.map((coupon, index) => {
      return {
        ...coupon,
        check: (
          <Checkbox
            checked={listChecked.includes(coupon.id) ? true : false}
            onChange={(e) => handleSelectCoupon(e, coupon.id)}
          />
        ),
        no: coupon.id ? coupon.id : "",
        benefit: coupon?.benefit
          ? mapCouponCategory(coupon?.benefit, coupon?.unit)
          : "",
        // state: (
        //   <StyledSelectStatus
        //     MenuProps={{
        //       disableScrollLock: true
        //     }}
        //     fullWidth
        //     value={coupon?.stage ?? ""}
        //     onChange={(e) => handleChangeState(e, coupon.id)}
        //   >
        //     {stateCoupon.map((item) => (
        //       <MenuItem key={item.value} value={item.value}>
        //         {item.text}
        //       </MenuItem>
        //     ))}
        //   </StyledSelectStatus>
        // ),
        dateOfUse: dateOfUse(coupon.start, coupon.end),
        createdAt: coupon.createdAt ? formatDate(coupon.createdAt) : "",
        amount: coupon.benifitAmount ? Number(coupon.benifitAmount) : "",
        edit: (
          <Button
            onClick={() =>
              navigate(`/coupon-management/coupon-list/edit/${coupon.id}`)
            }
            variant="outlined"
            style={{
              border: "1px solid #a5a5a5",
              color: "#000",
            }}
          >
            수정
          </Button>
        ),
        designatedIssuance: (
          <>
            <Button
              onClick={() =>
                navigate(
                  "/coupon-management/coupon-list/designated-issuance" +
                    "/" +
                    coupon.id
                )
              }
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                color: "#000",
              }}
            >
              지정발급
            </Button>
          </>
        ),
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  return (
    <Layout header="쿠폰 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column">
          {/* <Grid item>
            <Box
              style={{
                height: "40px",
                backgroundColor: "#1565c0",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "120px"
              }}
            >
              쿠폰목록
            </Box>
          </Grid> */}
          <Grid item sx={{ mt: 1, mb: 1 }}>
            {renderSearchArea()}
          </Grid>
          <Grid item>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Stack spacing={2} direction="row">
                <LoadingButton
                  onClick={handleSearch}
                  loading={loadingProcess["searching"] ? true : false}
                  loadingIndicator="검색"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "0",
                  }}
                >
                  검색
                </LoadingButton>
                <LoadingButton
                  onClick={handleReset}
                  loading={loadingProcess["resetting"] ? true : false}
                  loadingIndicator="초기화"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "0",
                    width: 90,
                  }}
                >
                  초기화
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem",
                            },
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important",
                        },
                        marginRight: 0,
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            {renderTable()}
          </Grid>
          <Grid item style={{}}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                onClick={() => setOpenDeleteConfirm(true)}
                loading={loadingProcess["deleting"] ? true : false}
                loadingIndicator="삭제"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "30px",
                }}
              >
                삭제
              </LoadingButton>
            </Box>
          </Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup2Action
            open={openDeleteConfirm}
            setOpen={setOpenDeleteConfirm}
            message="해당 쿠폰을 삭제하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenDeleteConfirm(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(CouponList);
