import { PrivateRouter } from "HOC";
import ApproveRegistration from "../ApproveRegistration";
import DetailApproveRegistration from "../DetailApproveRegistration";
import DetailPattern from "../DetailPattern";
import PatternList from "../PatternList";
import Category from "../Category";
// import MissCut from "../MissCut";

const routerManagePattern = [
  {
    path: "/pattern-management/approve-registration",
    element: (
      <PrivateRouter>
        <ApproveRegistration />
      </PrivateRouter>
    )
  },
  {
    path: "/pattern-management/approve-registration/detail/:id",
    element: (
      <PrivateRouter>
        <DetailApproveRegistration />
      </PrivateRouter>
    )
  },
  {
    path: "/pattern-management/pattern-list",
    element: (
      <PrivateRouter>
        <PatternList />
      </PrivateRouter>
    )
  },
  {
    path: "/pattern-management/pattern-list/detail/:id",
    element: (
      <PrivateRouter>
        <DetailPattern />
      </PrivateRouter>
    )
  },
  {
    path: "/pattern-management/category",
    element: (
      <PrivateRouter>
        <Category />
      </PrivateRouter>
    )
  }
  // {
  //   path: "/pattern-management/miss-cut",
  //   element: (
  //     <PrivateRouter>
  //       <MissCut />
  //     </PrivateRouter>
  //   ),
  // },
];

export default routerManagePattern;
