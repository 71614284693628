import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  styled,
  Button,
  FormControlLabel,
  TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/TableData";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import {
  ManageBannerService,
  UploadService,
  ManageUltrafitShopService
} from "services";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import Popup2Action from "components/Popup2Action";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

import * as XLSX from "xlsx";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  width: "400px",
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function GenuineAuthNumberList({ showToast }) {
  const [listChecked, setListChecked] = useState([]);
  const [listNumberAuth, setListNumberAuth] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState("");

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState();

  const [state, dispatch] = useGlobalStore();

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return {
        ...condition,
        page: 1,
        limit: rowsPerPage
      };
    }

    if (rowsPerPage !== -1) {
      condition = { ...condition, page: page + 1, limit: rowsPerPage };
    }

    if (searchTerms) {
      condition = {
        ...condition,
        code: searchTerms ? searchTerms.trim() : searchTerms
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const response = await ManageUltrafitShopService.getActivationNumber(
        handleCreateCondition(isSearch, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListNumberAuth(response?.data?.data ?? []);
        setCount(response?.data?.count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSelectNumber = (e, bid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, bid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== bid);
      setListChecked(newListChecked);
    }
  };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    let fileName = "";
    // try {
    //   const form = new FormData();
    //   form.append("files", e.target.files[0]);
    //   const response = await UploadService.upload(form);
    //   if (response.status === 200) {
    //     fileName = response?.data?.files[0] ?? "";
    //   }
    // } catch (error) {
    //   const errorStatusText = error?.response ? error.response?.statusText : "";
    //   const errorDataMessage = error?.response
    //     ? error.response?.data
    //       ? error.response.data?.message
    //       : ""
    //     : "";
    //   const errorMessage =
    //     errorDataMessage ?? errorStatusText ?? "System Error";
    //   showToast(TOAST_TYPE.ERROR, errorMessage);
    // }
    fileName = e.target.files[0].name;
    setSelectedFileName(fileName);
    setSelectedFile(e.target.files[0]);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      listNumberAuth.forEach((number) => {
        newListChecked.push(number.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleDelete = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No item selected");
      return;
    }
    try {
      const response = await ManageUltrafitShopService.deleteActivationNumber({
        ids: listChecked
      });
      if (response?.data?.statusCode === 200) {
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }

    await fetchData();
    setOpenModalDelete(false);
    setListChecked([]);
    setIsCheckAll(false);
  };

  const handleDeleteConfirm = () => {
    if (!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No item selected");
      return;
    }
    setOpenModalDelete(true);
  };

  const handleAdd = async () => {
    if (selectedFile) {
      const promiseReadData = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(selectedFile);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const ws = wb.Sheets["Sheet1"];

          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: ""
          });

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
          setUploading(false);
        };
      });

      await promiseReadData
        .then(async (listRow) => {
          const dataFillDB = [];
          for (let rowi = 0; rowi < listRow.length; rowi++) {
            const row = listRow[rowi];
            // dataFillDB.push({
            //   carriers: ".",
            //   trackingNumber: row[0],
            //   information: ""
            // });
            if (row[0]) {
              dataFillDB.push(`${row[0]}-${row[1]}-${row[2]}-${row[3]}`);
            }
          }
          // await Promise.all(
          //   dataFillDB.map((item) => {
          //     return ManageUltrafitShopService.createShippingInfo({
          //       ...item,
          //     });
          //   })
          // ).catch(function (err) {
          //   showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
          //   setUploading(false);
          //   setOpenModalAdd(false);
          // });
          try {
            const response =
              await ManageUltrafitShopService.createListActivationNumber({
                codes: dataFillDB
              });
            if (response?.data?.statusCode === 200) {
              await fetchData();
              setSelectedFileName("");
              setSelectedFile(undefined);
            } else {
              const errorData = response?.data
                ? response.data?.msg
                : "Error" ?? "Error";
              showToast(TOAST_TYPE.ERROR, errorData);
            }
          } catch (error) {
            const errorStatusText = error?.response
              ? error.response?.statusText
              : "";
            const errorDataMessage = error.response.data?.message?.[0] ?? "";
            const errorMessage =
              errorDataMessage ?? errorStatusText ?? "System Error";
            showToast(TOAST_TYPE.ERROR, errorMessage);
          }
        })
        .catch((err) => {
          showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
          setUploading(false);
          setOpenModalAdd(false);
        });
      setUploading(false);
      setOpenModalAdd(false);
    } else {
      showToast(TOAST_TYPE.WARN, "Please select file to import");
    }
  };

  const renderTable = () => {
    const sizeWidth = ["100%"];

    const headersTitle = [
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControlLabel
          label="사용가능 인증번호 목록"
          labelPlacement="end"
          control={
            <Checkbox
              checked={isCheckAll}
              onChange={(e) => handleSelectAll(e)}
            />
          }
        />
        <Box style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="add"
            //   onClick={handleAdd}
            sx={{
              mr: 0.5,
              borderRadius: 0,
              textTransform: "capitalize",
              position: "relative",
              color: uploading ? "#c3c3c3c4" : ""
            }}
            disabled={uploading}
            onClick={() => setOpenModalAdd(true)}
          >
            Excel파일로 추가하기
          </Button>
          <Button
            variant="contained"
            color="add"
            disabled={!listChecked.length}
            onClick={handleDeleteConfirm}
            sx={{ mr: 0.5, borderRadius: 0 }}
          >
            삭제
          </Button>
        </Box>
      </Box>
    ];

    const tableData = listNumberAuth.map((number, index) => {
      return {
        ...number,
        index: index + 1 + rowsPerPage * page,
        code: (
          <FormControlLabel
            label={number?.code}
            labelPlacement="end"
            control={
              <Checkbox
                checked={listChecked.includes(number.id) ? true : false}
                onChange={(e) => handleSelectNumber(e, number.id)}
              />
            }
          />
        )
      };
    });

    // const tableData = [
    //   {
    //     index: 1,
    //     code: (
    //       <FormControlLabel
    //         label="PADS-6015-1181-1183"
    //         labelPlacement="end"
    //         control={
    //           <Checkbox
    //             checked={listChecked.includes(1) ? true : false}
    //             onChange={(e) => handleSelectBanner(e, 1)}
    //           />
    //         }
    //       />
    //     )
    //   },
    //   {
    //     index: 2,
    //     code: (
    //       <FormControlLabel
    //         label="PADS-6015-1181-1183"
    //         labelPlacement="end"
    //         control={
    //           <Checkbox
    //             checked={listChecked.includes(2) ? true : false}
    //             onChange={(e) => handleSelectBanner(e, 2)}
    //           />
    //         }
    //       />
    //     )
    //   }
    // ];

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="row" justifyContent="flex-start">
        <Grid item>
          <Box
            style={{
              display: "flex",
              gap: "12px"
            }}
          >
            <StyledTextField
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon edge="end" />
                  </InputAdornment>
                )
              }}
              onKeyDown={handleKeyDown}
              placeholder="검색어를 입력해주세요."
            />
            <LoadingButton
              onClick={handleSearch}
              loading={loadingProcess["searching"] ? true : false}
              loadingIndicator="검색"
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "46px"
              }}
            >
              검색
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="정품인증 번호 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item sx={{ mb: "50px" }}>
            {renderSearchArea()}
          </Grid>
          <Grid item>{renderTable()}</Grid>
          {/* <Grid item>
            <Grid container alignItems="center" justifyContent="center">
              <Button
                onClick={() => setOpenModalAdd(true)}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px",
                  marginRight: "20px"
                }}
              >
                추가
              </Button>

              <Button
                onClick={() => setOpenModalDelete(true)}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px"
                }}
              >
                선택 삭제
              </Button>
            </Grid>
          </Grid>*/}
          <Popup2Action
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            message="Excel 파일을 업로드해주세요."
            onClickConfirm={handleAdd}
            onClickCancel={() => setOpenModalAdd(false)}
          >
            <Box
              style={{
                border: "1px solid #c4c4c4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "225px",
                height: "42px",
                position: "relative"
              }}
            >
              <input
                type="file"
                style={{
                  opacity: 0,
                  position: "absolute",
                  width: "225px",
                  height: "42px",
                  left: 0,
                  top: 0
                }}
                disabled={uploading}
                onChange={(e) => {
                  handleSelectFile(e);
                  e.target.value = null;
                }}
              />
              {selectedFileName}
            </Box>
          </Popup2Action>

          <Popup2Action
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            message="인증번호를 삭제 하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenModalDelete(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(GenuineAuthNumberList);
