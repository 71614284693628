import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material";

const StyledButtonList = styled(Button)({
  textTransform: "capitalize",
  width: 80,
  marginLeft: 20,
  fontWeight: "bold"
});

export default function ModalRegion(props) {
  const {
    open = false,
    setOpen = () => {},
    title = "",
    onClickSave = () => {}
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        PaperProps={{
          style: {
            minHeight: "200px",
            minWidth: "400px"
          }
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          <StyledButtonList onClick={onClickSave}>저장</StyledButtonList>
          <StyledButtonList color="cancel" onClick={() => setOpen(!open)}>
            취소
          </StyledButtonList>
        </DialogActions>
      </Dialog>
    </div>
  );
}
