import React, { useState, useCallback } from "react";
import Modal from '../components/Modal/Modal'

export const ModalContext = React.createContext({
  content: {
    message: "",
    callback: () => {},
  },
  addContent: ({ message, callback }) => {},
  removeContent: () => {},
});

export default function ModalProvider({ children }) {
  const [content, setContent] = useState(null);

  const removeContent = () => setContent(null);

  const addContent = ({ message, callback }) =>
    setContent({
      message,
      callback,
    });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    content,
    addContent: useCallback(
      ({ message, callback }) =>
        addContent({
          message,
          callback,
        }),
      []
    ),
    removeContent: useCallback(() => removeContent(), []),
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <Modal />
    </ModalContext.Provider>
  );
}
