import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { UploadService } from "services";
// import { generateImageSrc } from "utils/function";
import "./style.css";

export default function EmailEditor(props) {
  const { data = {}, setData = () => {} } = props;

  return (
    <Editor
      apiKey="8qnqyymsjpobi15bk2wz9zld2966k91vq1f3lmpdo9yox2gp"
      onEditorChange={(content) =>
        setData((prevState) => ({ ...prevState, body: content }))
      }
      value={data ?? ""}
      init={{
        height: 500,
        selector: "textarea",
        font_family_formats: "Arial",
        menubar: true,
        branding: false,
        plugins: ["link", "image", "lists", "wordcount", "table"],
        toolbar:
          "undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code",
        images_upload_handler: async (blobInfo) => {
          const body = new FormData();
          body.append("file", blobInfo.blob());
          return new Promise((resolve, reject) => {
            UploadService.upload(body)
              .then((res) => {
                resolve(res?.data?.data);
              })
              .catch((e) => {
                reject(e);
              });
          });
        }
      }}
    />
  );
}
