import React, { useEffect, useState } from "react";
import { Box, FormGroup, FormControlLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "redux/actions/auth";
import { useNavigate } from "react-router-dom";
import { LoginService } from "services";
// import { SUCCESS_STATUS } from "utils/constants";
import AlertDialog from "./components/AlertDialog";
import { LogoIcon, UserNameIcon, PasswordIcon } from "components/icon";
import {
  CustomInput,
  LoginBtn,
  CustomCheckbox,
  ErrorTypo
} from "./components/styles";
// import { containSpace, isEmail, nullValue } from "utils/function";

export default function Login() {
  const [isAutoLogin, setIsAutoLogin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState("");
  const [contentDialog, setContentDialog] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    // trigger,
    formState: { errors }
    // setError,
  } = useForm({ mode: "all" });

  useEffect(() => {
    if (auth.authenticate) {
      navigate("/member-management/list-member");
    }
  }, [auth]);

  const handleSubmitData = async (data) => {
    try {
      const logInRes = await LoginService.login({
        email: data.email.trim(),
        password: data.password
      });

      const {
        token = null,
        refreshToken = null,
        admin = null
      } = logInRes?.data?.data ?? {};

      if (logInRes?.data?.statusCode === 200) {
        localStorage.clear();
        dispatch(signIn(token, refreshToken, admin, isAutoLogin));
        navigate("/member-management/list-member");
        window.location.reload(false);
      } else {
        setTitleDialog("INCORRECT");
        let errorMessage =
          logInRes?.data?.msg ?? "Please check your ID/Password";
        if (logInRes?.data?.errorStatus === 404) {
          errorMessage = "User not exist.";
        }
        setContentDialog(errorMessage);
        setOpenDialog(true);
      }
    } catch (err) {
      let errorText = err?.response?.data?.message ?? "System error";
      if (err?.response?.data?.errorStatus === 404) {
        errorText = "User not exist.";
      }
      setTitleDialog("ERROR");
      setContentDialog(errorText);
      setOpenDialog(true);
    }
  };

  const handleCheck = () => {
    setIsAutoLogin(!isAutoLogin);
  };

  // const handleChangeEmail = (email) => {
  //   const spaceRegex = /^$|\s+/;
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   if (typeof email === "string" && email.trim().length === 0) {
  //     setError("email", { type: "required", message: "Email is required" });
  //   } else if (spaceRegex.test(email)) {
  //     setError("email", {
  //       type: "space",
  //       message: "Email contains space",
  //     });
  //   } else if (!emailRegex.test(email)) {
  //     setError("email", {
  //       type: "invalid",
  //       message: "Invalid email address",
  //     });
  //   } else {
  //     // const { email, ...ignoreEmail } = errors;
  //     // setError({ ...ignoreEmail });
  //     setError("email", {});
  //   }
  // };

  // const handleChangePassword = (password) => {
  //   const spaceRegex = /^$|\s+/;
  //   const numberRegex = /\d/;
  //   const lowerCaseRegex = /[a-z]+/;
  //   const upperCaseRegex = /[A-Z]+/;
  //   const specialCharacterRegex = /[.@#$%&=!?+]/;
  //   if (typeof password === "string" && password.trim().length === 0) {
  //     setError("password", {
  //       type: "required",
  //       message: "Password is required",
  //     });
  //   } else if (spaceRegex.test(password)) {
  //     setError("password", {
  //       type: "invalid",
  //       message: "Password contains space",
  //     });
  //   } else if (password.length < 8) {
  //     setError("password", {
  //       type: "invalid",
  //       message: "Minimum 8 characters",
  //     });
  //   } else if (!numberRegex.test(password)) {
  //     setError("password", {
  //       type: "invalid",
  //       message: "Must contain at least one number",
  //     });
  //   } else if (!lowerCaseRegex.test(password)) {
  //     setError("password", {
  //       type: "invalid",
  //       message: "Must contain lower case letters",
  //     });
  //   } else if (!upperCaseRegex.test(password)) {
  //     setError("password", {
  //       type: "invalid",
  //       message: "Must contain upper case letters",
  //     });
  //   } else if (!specialCharacterRegex.test(password)) {
  //     setError("password", {
  //       type: "invalid",
  //       message:
  //         "Must contain at least one of the following. specialist. characters: '.@#$%&=!?+*'",
  //     });
  //   } else {
  //     // const { email, ...ignoreEmail } = errors;
  //     // setError({ ...ignoreEmail });
  //     setError("password", {});
  //   }
  // };

  // const errorMsgEmail = (error) => {
  //   if (error?.email?.type === "null") {
  //     return "Email is require";
  //   }
  //   if (error?.email?.type === "space") {
  //     return "Email contains space";
  //   }
  //   if (error?.email?.type === "valid") {
  //     return "Invalid email";
  //   }
  //   return error?.email?.message ?? "";
  // };

  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <LogoIcon style={{ marginBottom: "100px" }} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CustomInput
              placeholder="이메일"
              startAdornment={<UserNameIcon />}
              // {...register("email")}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[\s*A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z\s*]{2,}$/i,
                  message: "Invalid email address"
                }
              })}
              // onBlur={() => {
              //   trigger("email");
              // }}
              // onChange={(e) => handleChangeEmail(e.target.value)}
              // {...register("email", {
              //   validate: {
              //     null: (value) => !nullValue(value),
              //     space: (value) => !containSpace(value.trim()),
              //     valid: (value) => isEmail(value),
              //   },
              // })}
            />
            {!!errors?.email && <ErrorTypo>{errors?.email.message}</ErrorTypo>}
            <CustomInput
              type="password"
              placeholder="비밀번호"
              startAdornment={<PasswordIcon />}
              sx={{ marginTop: "12px" }}
              // {...register("password")}
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password should contain 6 characters"
                }
              })}
              // onBlur={() => {
              //   trigger("password");
              // }}
              // onChange={(e) => handleChangePassword(e.target.value)}
            />
            {!!errors?.password && (
              <ErrorTypo>{errors?.password.message}</ErrorTypo>
            )}
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={isAutoLogin}
                  sx={{ marginTop: "-6px", marginRight: "4px" }}
                  onChange={handleCheck}
                />
              }
              label="자동 로그인"
              sx={{
                margin: "16px 0 40px -11px",
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "22px",
                color: "#555555"
              }}
            />
          </FormGroup>
          <LoginBtn type="submit">로그인</LoginBtn>
        </form>
      </Box>
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={titleDialog}
        content={contentDialog}
      />
    </Box>
  );
}
