import { PrivateRouter } from "HOC";
import SponsorList from "../SponsorList";
import SponsorItemPrice from "../SponsorItemPrice";

const routerManageSponsor = [
  {
    path: "/sponsor-management/sponsor-list",
    element: (
      <PrivateRouter>
        <SponsorList />
      </PrivateRouter>
    ),
  },
  {
    path: "/sponsor-management/sponsor-item-price",
    element: (
      <PrivateRouter>
        <SponsorItemPrice />
      </PrivateRouter>
    ),
  },
];

export default routerManageSponsor;
