import axiosClient from "../axiosClient";
import URL from "./url";

class ProfileService {
  getAdminProfile() {
    const url = URL.GET_PROFILE;
    return axiosClient.get(url);
  }
}

export default new ProfileService();
