import { ManageEmailService } from "services";
import { emailConstants } from "../constants";

export const getListEmailAuto = (condition = {}) => {
  return async (dispatch) => {
    try {
      const res = await ManageEmailService.getEmailList(condition);
      if (res?.data?.statusCode === 200) {
        console.log("res?.data?.data", res?.data?.data);
        dispatch({
          type: emailConstants.GET_EMAILS_AUTO_SUCCESS,
          payload: {
            emails: res?.data?.data ?? [],
            count: res?.data?.count ?? 0
          }
        });
      } else {
        dispatch({
          type: emailConstants.GET_EMAILS_AUTO_FAIL,
          payload: res?.data?.message ?? "Error"
        });
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "System error";
      dispatch({
        type: emailConstants.GET_EMAILS_AUTO_FAIL,
        payload: message
      });
    }
  };
};

export const getListEmailManual = (condition = {}) => {
  return async (dispatch) => {
    try {
      const res = await ManageEmailService.getEmailList(condition);
      if (res?.data?.statusCode === 200) {
        dispatch({
          type: emailConstants.GET_EMAILS_MANUAL_SUCCESS,
          payload: {
            emails: res?.data?.data ?? [],
            count: res?.data?.count ?? 0
          }
        });
      } else {
        dispatch({
          type: emailConstants.GET_EMAILS_MANUAL_FAIL,
          payload: res?.data?.message ?? "Error"
        });
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "System error";
      dispatch({
        type: emailConstants.GET_EMAILS_MANUAL_FAIL,
        payload: message
      });
    }
  };
};

export const updateEmailState = (data) => {
  return (dispatch) => {
    dispatch({
      type: emailConstants.UPDATE_EMAIL_AUTO_STATUS,
      payload: data
    });
    dispatch(getListEmailAuto());
  };
};

export const resetEmailsAuto = () => {
  return (dispatch) => {
    dispatch({
      type: emailConstants.RESET_EMAILS_AUTO
    });
  };
};

export const resetEmailsManual = () => {
  return (dispatch) => {
    dispatch({
      type: emailConstants.RESET_EMAILS_MANUAL
    });
  };
};
