import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import TableData from "./TableReceivedMember";
import { formatDate } from "utils/function";
import { getListMember, resetMembers } from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageEmailService } from "services";
import LoadingOneLine from "components/LoadingOneLine";

const listStatus = [
  { value: "all", text: "전체 회원" },
  { value: "active", text: "활성 회원" },
  { value: "suspended", text: "정지 회원" },
  { value: "secession", text: "탈퇴 회원" }
];

const listLevels = [
  { value: "begginer", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

function ListManualIssuingMember({ email, showToast }) {
  const [listChecked, setListChecked] = useState([]);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState({
    searchType: 0,
    search: "",
    levels: [],
    status: "all"
  });
  const [state, dispatch] = useGlobalStore();

  const {
    members = [],
    loading = true,
    error = null
  } = useMemo(() => state?.member, [state]);

  useEffect(() => {
    dispatch(getListMember());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetMembers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectUser = (e, userEmail) => {
    if (e.target.checked) {
      setListChecked([...listChecked, userEmail]);
    } else {
      const newListChecked = listChecked.filter((mail) => mail !== userEmail);
      setListChecked(newListChecked);
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await dispatch(
      getListMember({
        ...searchTerms,
        status: searchTerms.status === "all" ? "" : searchTerms.status
      })
    );
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ searchType: 0, search: "", levels: [], status: "all" });
    await dispatch(getListMember({}));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "searchType") {
      setSearchTerms((prevState) => ({ ...prevState, searchType: value }));
    }
    if (key === "status") {
      setSearchTerms((prevState) => ({ ...prevState, status: value }));
    }
  };

  const handleSendEmail = async () => {
    try {
      const response = await ManageEmailService.sendEmail({
        emails: [listChecked],
        subject: email.name,
        html: email.content
      });
      if (response.status === 201) {
        showToast(TOAST_TYPE.SUCCESS, "Send success");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["10%", "10%", "20%", "13%", "10%", "20%", "10%", "7%"];

    const headersTitle = [
      "Code.",
      "성명",
      "이메일",
      "연락처",
      "국가",
      "회원등급",
      "가입일",
      <Button
        variant="outline"
        sx={{
          border: "1px solid #a5a5a5",
          textTransform: "capitalize",
          padding: "0 5px"
        }}
        onClick={handleSendEmail}
      >
        발급
      </Button>
    ];

    const tableData = members.map((member) => {
      return {
        ...member,
        createdAt: member.createdAt ? formatDate(member.createdAt) : "",
        issued: (
          <Checkbox
            checked={listChecked.includes(member.email)}
            onChange={(e) => handleSelectUser(e, member.email)}
          />
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item style={{ marginRight: "10px", fontSize: "0.875rem" }}>
          받는 대상 검색:
        </Grid>
        <Grid item>
          <FormControl sx={{ mt: 1, mb: 1 }} size="small">
            <Box
              sx={{
                fontSize: "0.7rem",
                height: 30,
                backgroundColor: "#1565c0",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0 5px"
              }}
            >
              검색어
            </Box>
          </FormControl>
          <FormControl sx={{ mt: 1, mb: 1, ml: 1 }} size="small">
            <Select
              MenuProps={{
                disableScrollLock: true
              }}
              value={searchTerms["status"]}
              onChange={(e) =>
                handleChangeSearchTerms("status", e.target.value)
              }
              style={{
                borderRadius: "0",
                height: "30px",
                width: "90px"
              }}
            >
              {listStatus.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }} size="small">
            <TextField
              size="small"
              sx={{
                "& label": {
                  paddingLeft: (theme) => theme.spacing(2)
                },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1),
                  width: "100px",
                  height: "30px",
                  padding: "0 5px"
                },
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(2.5),
                  borderRadius: 0
                }
              }}
              value={searchTerms["search"]}
              onChange={(e) =>
                handleChangeSearchTerms("search", e.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginLeft: "5px" }}>
          <Grid container alignItems="center">
            <FormControl sx={{ m: 1 }} size="small">
              <Box
                sx={{
                  fontSize: "0.7rem",
                  height: 30,
                  backgroundColor: "#1565c0",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 5px"
                }}
              >
                회원등급
              </Box>
            </FormControl>
            <FormControl>
              <Grid
                container
                alignItems="center"
                style={{
                  border: "1px solid rgb(118, 118, 118)",
                  height: "30px",
                  padding: "0 0px 0 12px"
                }}
              >
                {listLevels.map((level) => (
                  <FormControlLabel
                    key={level.value}
                    control={
                      <Checkbox
                        checked={searchTerms["levels"].includes(level.value)}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "0.7rem"
                          },
                          "&.MuiCheckbox-root": {
                            padding: "0 !important"
                          }
                        }}
                        onChange={() =>
                          handleChangeSearchTerms("levels", level.value)
                        }
                      />
                    }
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "0.7rem !important"
                      }
                    }}
                    label={level.text}
                  />
                ))}
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item>
          <LoadingButton
            onClick={handleSearch}
            loading={loadingProcess["searching"] ? true : false}
            loadingIndicator="검색"
            variant="contained"
            sx={{ m: 1 }}
            style={{
              textTransform: "capitalize",
              borderRadius: "0",
              height: "30px",
              padding: 0
            }}
          >
            검색
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            onClick={handleReset}
            loading={loadingProcess["resetting"] ? true : false}
            loadingIndicator="초기화"
            variant="contained"
            sx={{ mt: 1, mb: 1 }}
            style={{
              textTransform: "capitalize",
              borderRadius: "0",
              height: "30px",
              padding: 0,
              width: 90
            }}
          >
            초기화
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingOneLine />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>{renderTable()}</Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default withToast(ListManualIssuingMember);
