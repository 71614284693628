import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import {
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  styled,
  Button
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
import { ManageMemberService } from "services";
import { TOAST_TYPE } from "utils/constants";
import withToast from "HOC/withToast";
import withParam from "HOC/withParam";
import LoadingPage from "components/LoadingPage";
import { formatDate, generateImageSrc } from "utils/function";
import { useNavigate } from "react-router-dom";
import { withQuery } from "HOC";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 43,
    padding: 10,
    width: "150px",
    textAlign: "center",
    border: "1px solid #a5a5a5",
    fontWeight: "bold"
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 43,
    padding: 10,
    width: "150px",
    textAlign: "center",
    border: "1px solid #a5a5a5",
    color: "#ffff",
    backgroundColor: "#0468b4",
    fontWeight: "bold"
  }
}));

const StyledTableCellDoubleHeight = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    height: 43 * 2,
    padding: 10,
    textAlign: "center",
    border: "1px solid #a5a5a5",
    fontWeight: "bold"
  }
}));

function MemberDetail({ params, searchParams, showToast }) {
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState("");
  const [user, setUser] = useState({});

  console.log("searchParams", searchParams);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageMemberService.getUser(params.id);
        if (response?.data?.statusCode === 200) {
          let data = response?.data ? response?.data?.data : {};
          setUser(data);
          setLoading(false);
        } else {
          showToast(TOAST_TYPE.ERROR, response?.data?.message ?? "Error");
          setLoading(false);
          // setError(response?.data?.message ?? "Error");
        }
      } catch (error) {
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
        setLoading(false);
        // setError(errorMessage);
      }
    }
    fetchData();
  }, [params.id, showToast]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Layout header="회원관리 - 회원 조회/관리 - 회원 정보 조회">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box sx={{ flexDirection: "column", fontWeight: "bold" }}>
          <Box
            sx={{
              border: "1px solid #a5a5a5",
              borderRadius: 1,
              padding: "2px",
              width: 200,
              textAlign: "center"
            }}
          >
            기본 정보
          </Box>
          <Box sx={{ mt: 2 }}>
            <Grid container direction="row" wrap="nowrap">
              <Grid item xs={5}>
                <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <StyledTableCellLeft>First Name</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.firstName ? user.firstName : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft>Sur Name</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.lastName ? user.lastName : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft>Display Name</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.nickName ? user.nickName : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft>Phone Number</StyledTableCellLeft>
                        <StyledTableCell width="50%">
                          {user?.phoneNumber ? user.phoneNumber : ""}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <TableContainer
                  style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                >
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <StyledTableCellLeft>이메일 주소</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.email ? user.email : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft>최초 가입일</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.createdAt ? formatDate(user.createdAt) : ""}
                        </StyledTableCell>
                      </TableRow>
                      {(user?.withdrawTime || user?.suspendedTime) && (
                        <TableRow>
                          <StyledTableCellLeft>탈퇴 / 정지</StyledTableCellLeft>
                          <StyledTableCell>
                            {user?.withdrawTime
                              ? formatDate(user?.withdrawTime)
                              : formatDate(user?.suspendedTime)}
                          </StyledTableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <StyledTableCellLeft>프로필 사진</StyledTableCellLeft>
                        <StyledTableCellDoubleHeight align="center">
                          {user?.avatar ? (
                            <img
                              // src={generateImageSrc(user.avatar)}
                              src={user?.avatar}
                              alt=""
                              style={{
                                borderRadius: "50%",
                                width: "70px",
                                height: "70px",
                                objectFit: "cover"
                              }}
                            />
                          ) : null}
                        </StyledTableCellDoubleHeight>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              border: "1px solid #a5a5a5",
              borderRadius: 1,
              padding: "2px",
              width: 200,
              textAlign: "center",
              mt: 2
            }}
          >
            주소지
          </Box>
          <Box sx={{ mt: 2 }}>
            <Grid container direction="row">
              <Grid item xs={5}>
                <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <StyledTableCellLeft>국가</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.country ? user.country : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft>주소</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.address1 ? user.address1 : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft>주소2</StyledTableCellLeft>
                        <StyledTableCell>
                          {user?.address2 ? user.address2 : ""}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <StyledTableCellLeft width="50%">
                          우편번호
                        </StyledTableCellLeft>
                        <StyledTableCell width="50%">
                          {user?.postalCode ? user.postalCode : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft width="50%">
                          도시
                        </StyledTableCellLeft>
                        <StyledTableCell width="50%">
                          {user?.city ? user.city : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft width="50%">
                          주 / 대륙
                        </StyledTableCellLeft>
                        <StyledTableCell width="50%">
                          {user?.stateRegion ? user.stateRegion : ""}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCellLeft width="50%">
                          회사명
                        </StyledTableCellLeft>
                        <StyledTableCell width="50%">
                          {user?.companyName ? user.companyName : ""}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2
            }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                fontWeight: "bold"
              }}
              onClick={handleBack}
            >
              목록
            </Button>
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default withQuery(withParam(withToast(MemberDetail)));
