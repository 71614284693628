import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Select,
  MenuItem,
  styled,
  Button,
  FormControlLabel
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/TableData";
import { generateImageSrc } from "utils/function";
import { useGlobalStore } from "hooks";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageBannerService } from "services";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import Popup2Action from "./components/Popup2Action";
import { useNavigate } from "react-router-dom";
import {
  deleteBanners,
  getListBanner,
  resetBanners,
  updateBannerStatus
} from "redux/actions/banner";
import { BANNER_CLASSIFICATION } from "../constants";

const listSelectSearch = [
  {
    value: "popup name",
    text: "팝업명",
    id: 1
  },
  {
    value: "popup being applied",
    text: "적용 중인 팝업",
    id: 3
  }
];

const StyledSelect = styled(Select)(({ width, height }) => ({
  borderRadius: "0",
  height: height ? height : "33px",
  width: width ? width : "200px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "150px",
    height: height ? height : "25px",
    padding: "4px 10px",
    fontSize: "0.9rem"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function ListPopup({ showToast }) {
  const [listChecked, setListChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState({
    search: "",
    typeSearch: 1
  });

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [state, dispatch] = useGlobalStore();

  const navigate = useNavigate();

  const {
    banners = [],
    loading = true,
    error = null
  } = useMemo(() => state?.banner, [state]);

  useEffect(() => {
    dispatch(getListBanner({ type: "popup" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetBanners());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectBanner = (e, bid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, bid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== bid);
      setListChecked(newListChecked);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      banners.forEach((transaction) => {
        newListChecked.push(transaction.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await dispatch(
      getListBanner({
        ...searchTerms,
        type: "popup"
      })
    );
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ search: "", typeSearch: 1 });
    await dispatch(getListBanner({ type: "popup" }));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  // const handleGetActiveMember = async () => {
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: true });
  //   await dispatch(getListMember({ status: "active" }));
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: false });
  // };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "typeSearch") {
      setSearchTerms((prevState) => ({ ...prevState, typeSearch: value }));
    }
  };

  const handleDelete = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No popup selected");
      return;
    }
    try {
      const response = await ManageBannerService.deleteBanner({
        bannerIds: [...listChecked]
      });
      if (response?.data?.statusCode === 200) {
        dispatch(deleteBanners(listChecked, { type: "popup" }));
        showToast(TOAST_TYPE.SUCCESS, "Delete success");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setOpenModalDelete(false);
    setListChecked([]);
    setIsCheckAll(false);
  };

  const handleAdd = () => {
    navigate("/popup-banner-management/popup-list/add");
  };

  const handleAppear = async (id, appear = true) => {
    const body = {
      status: appear ? "enable" : "disable"
    };
    try {
      const response = await ManageBannerService.updateBanner(id, {
        ...body
      });
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateBannerStatus(
            { id: id, status: appear ? "enable" : "disable" },
            { type: "popup" }
          )
        );
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleEditPopup = (id) => {
    navigate(`/popup-banner-management/popup-list/edit/${id}`);
  };

  const renderCheckboxAll = () => {
    return (
      <Checkbox
        checked={listChecked?.length === banners?.length}
        onChange={(e) => {
          if (listChecked?.length === banners?.length) {
            setListChecked([]);
          } else {
            setListChecked(banners?.map((m) => m?.id));
          }
        }}
      />
    );
  };

  const renderTable = () => {
    const sizeWidth = ["12%", "3%", "10%", "12%", "18%", "20%", "25%"];

    const headersTitle = [
      // <FormControlLabel
      //   label="전체신"
      //   labelPlacement="start"
      //   control={
      //     <Checkbox
      //       checked={isCheckAll}
      //       onChange={(e) => handleSelectAll(e)}
      //       sx={{
      //         "& .MuiSvgIcon-root": {
      //           color: "#fff"
      //         }
      //       }}
      //     />
      //   }
      // />,
      "팝업선택",
      "NO",
      "분류",
      "팝업명",
      "사용",
      "미리보기",
      "수정"
    ];

    const tableData = banners.map((banner, index) => {
      return {
        ...banner,
        index: index + 1,
        // category: banner.category ? BANNER_CLASSIFICATION[banner.category] : "",
        check: (
          <Checkbox
            checked={listChecked.includes(banner.id) ? true : false}
            onChange={(e) => handleSelectBanner(e, banner.id)}
          />
        ),
        use: (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CheckBoxOutlinedIcon
              color={banner.status === "enable" ? "primary" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleAppear(banner.id, true)}
            />
            <DisabledByDefaultOutlinedIcon
              sx={
                banner.status === "disable"
                  ? { color: "#ff5454", ml: 1 }
                  : { ml: 1 }
              }
              style={{ cursor: "pointer" }}
              onClick={() => handleAppear(banner.id, false)}
            />
          </Box>
        ),
        preview: banner?.file ? banner?.file : null,
        edit: (
          <Button
            onClick={() => handleEditPopup(banner.id)}
            variant="outlined"
            style={{
              border: "1px solid #a5a5a5",
              fontWeight: "bold",
              color: "#000"
            }}
          >
            수정
          </Button>
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        renderCheckboxAll={renderCheckboxAll}
      />
    );
  };

  // const renderSearchArea = () => {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       alignItems="center"
  //       style={{ padding: "7px 10px" }}
  //     >
  //       <StyledSelect
  //         width="150px"
  //         MenuProps={{
  //           disableScrollLock: true
  //         }}
  //         value={searchTerms["typeSearch"]}
  //         onChange={(e) =>
  //           handleChangeSearchTerms("typeSearch", e.target.value)
  //         }
  //       >
  //         {listSelectSearch.map((item) => (
  //           <MenuItem value={item.value} key={item.value}>
  //             {item.text}
  //           </MenuItem>
  //         ))}
  //       </StyledSelect>
  //       <StyledTextField
  //         value={searchTerms["search"]}
  //         onChange={(e) => handleChangeSearchTerms("search", e.target.value)}
  //         width="580px"
  //         placeholder="검색어를 입력해주세요."
  //       />

  //       <LoadingButton
  //         onClick={handleSearch}
  //         loading={loadingProcess["searching"] ? true : false}
  //         loadingIndicator="검색"
  //         variant="contained"
  //         style={{
  //           textTransform: "capitalize",
  //           borderRadius: "0",
  //           marginLeft: 5,
  //           height: "33px"
  //         }}
  //       >
  //         검색
  //       </LoadingButton>
  //       <LoadingButton
  //         onClick={handleReset}
  //         loading={loadingProcess["resetting"] ? true : false}
  //         loadingIndicator="초기화"
  //         variant="contained"
  //         style={{
  //           textTransform: "capitalize",
  //           borderRadius: "0",
  //           width: 90,
  //           marginLeft: 5,
  //           height: "33px"
  //         }}
  //       >
  //         초기화
  //       </LoadingButton>
  //     </Grid>
  //   );
  // };

  return (
    <Layout header="팝업 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          {/* <Grid item>{renderSearchArea()}</Grid> */}
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Button
                onClick={() => handleAdd()}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px",
                  marginRight: "20px"
                }}
              >
                추가
              </Button>

              <Button
                onClick={() => setOpenModalDelete(true)}
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  height: "36px"
                }}
              >
                삭제
              </Button>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>

          <Popup2Action
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            message="배너를 추가하시겠습ᅵ까?"
            onClickConfirm={handleAdd}
            onClickCancel={() => setOpenModalAdd(false)}
          />

          <Popup2Action
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            message="선택한 팝업을 정말 삭제하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenModalDelete(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(ListPopup);
