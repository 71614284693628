const URL = Object.freeze({
  GET_WITHDRAW_RECEIPT_LIST: "/transactions/withdrawal",
  GET_PROFIT_HISTORY: "/transactions/profit/history",
  GET_SPONSOR_PRICE: "/sponsors/price",
  GET_SPONSOR_LIST: "/sponsors",
  CREATE_SPONSOR_PRICE: "/sponsors/price",
  STOP_UPLOADER: "/admins/uploader/stop",
  RESTART_UPLOADER: "/admins/uploader/restart",
  APPROVE_TRANSACTION: "/transactions/approve",
  REJECT_TRANSACTION: "/transactions/reject",
  UPDATE_STATUS_WITHDRAWAL: "/transactions/withdrawal/status",
  APPROVE_WITHDRAWAL: "/transactions/withdrawal/approve",
});

export default URL;
