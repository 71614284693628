import URL from "./url";
import axiosClient from "../axiosClient";

class ManageEmailService {
  getEmailList(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_EMAIL_LIST + query;
    return axiosClient.get(url);
  }
  updateEmail(id, body = {}) {
    const url = URL.UPDATE_EMAIL + "/" + id;
    return axiosClient.put(url, body);
  }
  createEmail(body = {}) {
    const url = URL.CREATE_EMAIL;
    return axiosClient.post(url, body);
  }
  setEmail(body = {}) {
    const url = URL.SET_EMAIL;
    return axiosClient.post(url, body);
  }
  getEmail(id) {
    const url = URL.GET_EMAIL + "/" + id;
    return axiosClient.get(url);
  }
  deleteEmail(id) {
    const url = URL.DELETE_EMAIL + "/" + id;
    return axiosClient.delete(url);
  }
  sendEmail(body = {}) {
    const url = URL.SEND_EMAIL;
    return axiosClient.post(url, body);
  }
  getEmailContactUs(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_EMAIL_CONTACT_US + query;
    return axiosClient.get(url);
  }
  getEmailContactUsById(id) {
    const url = URL.GET_EMAIL_CONTACT_US + "/" + id;
    return axiosClient.get(url);
  }
  deleteEmailContactUs(body = {}) {
    const url = URL.DELETE_EMAIL_CONTACT_US;
    return axiosClient.post(url, body);
  }
  getEmailHistory(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_EMAIL_HISTORY + query;
    return axiosClient.get(url);
  }
  sendNotification(body = {}) {
    const url = URL.SEND_NOTIFICATION;
    return axiosClient.post(url, body);
  }
}

export default new ManageEmailService();
