import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { ManageCreditService } from "services";
import { withToast } from "HOC";
import { /*CREDIT_PRODUCT_CATEGORY,*/ TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  // MenuItem,
  // Select,
  Stack,
  TextField,
  FormControl,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import {
  formatDate,
  // addMonths,
  formatDateSearch,
  formatDateSearchStart,
  formatDateSearchEnd,
} from "utils/function";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Popup from "./components/Popup";
import Excel from "exceljs";

// PayPal amount: netAmount
// Charge: charge
//

// const listChargingInformation = [
//   { value: CREDIT_PRODUCT_CATEGORY.STANDARD, text: "Standard" },
//   { value: CREDIT_PRODUCT_CATEGORY.PLUS, text: "Plus" },
//   { value: CREDIT_PRODUCT_CATEGORY.PREMIUM, text: "Premium" },
//   { value: CREDIT_PRODUCT_CATEGORY.GOLD, text: "Gold" },
// ];

// const listFilter = [
//   { value: "all", text: "전체" },
//   { value: "pending", text: "승인대기" },
//   { value: "approval", text: "승인" },
// ];

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "status", text: "상태" },
];

// const listStatus = [
//   { value: "receipt", text: "접수" },
//   { value: "complete", text: "완료" }
// ];

const listStatus = [
  { value: "pending", text: "접수" },
  { value: "approved", text: "완료" },
];

const mapStatusEnToKo = (status) => {
  switch (status) {
    case "approved":
      return "완료";
    case "pending":
      return "접수";
    default:
      return "";
  }
};
const mapStatusKoToEn = (status) => {
  switch (status) {
    case "완료":
      return "approved";
    case "접수":
      return "pending";
    default:
      return status;
  }
};

function RefundManagementList({ showToast }) {
  const [listTransaction, setListTransaction] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(null);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [dateTo, setDateTo] = useState(null);
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: "",
    status: "",
  });
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleCreateCondition = (isSearching = false, isResetting = false) => {
    const { type = "", search = "", status = "" } = searchTerms;
    let condition = {};
    if (isSearching || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return { page: 1, limit: rowsPerPage };
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearching ? 1 : page + 1,
        limit: rowsPerPage,
      };
    }
    if (type !== "status") {
      condition = {
        ...condition,
        type: type ? type : "all",
        search: search ? search.trim() : "",
      };
    } else {
      if (status) {
        condition = { ...condition, status: mapStatusKoToEn(status.trim()) };
      }
    }
    // if (type) {
    //   condition = {
    //     ...condition,
    //     type,
    //   };
    // }

    // if (search) {
    //   condition = { ...condition, search: search.trim() };
    // }

    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss"),
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss"),
      };
    }

    return condition;
  };

  async function fetchData(isSearching = false, isResetting = false) {
    try {
      const response = await ManageCreditService.getListTransactionsRefund(
        handleCreateCondition(isSearching, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListTransaction(response?.data ? response.data?.data : [] ?? []);
        setCount(response?.data?.count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };
  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "" });
    setDateFrom(null);
    setDateTo(null);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "status") {
      setSearchTerms((prevState) => ({ ...prevState, status: value }));
    }
  };

  const handleCreateExcel = async (transactions) => {
    const fileName = "Credit 관리 - 환불 관리 목록";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("Credit 관리 - 환불 관리 목록");

    const headersTitle = [
      "NO.",
      "충전일",
      // "환불요청일",
      "환불완료일",
      "성명",
      "이메일",
      "상품명",
      "충전 Credit",
      "적립 Credit",
      "Paypal",
      "카드/pay",
      "상태",
      "Paypal email 주소",
    ];

    const columnExcel = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      // "M"
    ];
    const columnWidth = [
      10, 20, 20, 20, 20, 30, 20, 20, 20, 20, 20, 20, 20, 20,
      // , 30
    ];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // add value, style cell // body
    transactions.forEach((transaction, index) => {
      ws.getCell(`A${index + 2}`).value = index + 1;
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`B${index + 2}`).value = transaction?.parent?.createdAt
        ? formatDate(transaction?.parent?.createdAt)
        : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`B${index + 2}`).value = transaction?.createdAt
        ? formatDate(transaction.createdAt)
        : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      // ws.getCell(`C${index + 2}`).value = transaction?.approvedAt
      //   ? formatDate(transaction.approvedAt)
      //   : "";
      // ws.getCell(`C${index + 2}`).style = {
      //   alignment: { vertical: "center", horizontal: "center" },
      // };
      ws.getCell(`D${index + 2}`).value = transaction?.user?.nickName ?? "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`E${index + 2}`).value = transaction?.user?.email ?? "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`F${index + 2}`).value = transaction?.product?.name ?? "";
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`G${index + 2}`).value = transaction?.amount ?? "";
      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`H${index + 2}`).value = "";
      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`I${index + 2}`).value =
        transaction?.paymentMethod === "paypal" ? transaction?.amount ?? 0 : 0;
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`J${index + 2}`).value =
        transaction?.paymentMethod === "paypal" ? 0 : transaction?.amount ?? 0;
      ws.getCell(`J${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };

      ws.getCell(`K${index + 2}`).value = mapStatusEnToKo(
        transaction?.status ?? ""
      );
      ws.getCell(`K${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`L${index + 2}`).value = transaction?.email;
      ws.getCell(`L${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" },
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    const { type = "", search = "" } = searchTerms;
    let condition = {};
    if (isDownloadFilter) {
      // if (type) {
      //   condition = { ...condition, type, search };
      // }
      if (type !== "status") {
        condition = {
          ...condition,
          type: type ? type : "all",
          search: search ? search.trim() : "",
        };
      } else {
        if (search) {
          condition = { ...condition, status: mapStatusKoToEn(search.trim()) };
        }
      }
      if (dateFrom) {
        condition = {
          ...condition,
          from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss"),
        };
      }

      if (dateTo) {
        condition = {
          ...condition,
          to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss"),
        };
      }
    }
    let listTransaction = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageCreditService.getListTransactionsRefund(
        condition
      );
      if (response?.data?.statusCode === 200) {
        const transactions = response?.data ? response.data?.data : [] ?? [];
        listTransaction = [...transactions];
        handleCreateExcel(listTransaction);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  // const handleUpdateStatus = async (id, status) => {
  //   try {
  //     const response = await ManageCreditService.updateRefundStatus(id, {
  //       status,
  //     });
  //     if (response?.data?.statusCode === 200) {
  //       await fetchData();
  //       showToast(TOAST_TYPE.SUCCESS, "Success");
  //     }
  //   } catch (error) {
  //     setLoadingProcess({ ...loadingProcess, suspending: false });
  //     const errorStatusText = error?.response ? error.response?.statusText : "";
  //     const errorDataMessage = error?.response?.data?.message?.[0] ?? "";
  //     const errorMessage =
  //       errorDataMessage ?? errorStatusText ?? "System Error";
  //     showToast(TOAST_TYPE.ERROR, errorMessage);
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const renderTable = () => {
    const sizeWidth = [
      "3%",
      "9%",
      // "7%",
      "9%",
      "9%",
      "16%",
      "8%",
      "9%",
      "10%",
      "5%",
      "8%",
      "4%",
      "20%",
    ];

    const headersTitle = [
      "NO.",
      "충전일",
      // "환불요청일",
      "환불완료일",
      "성명",
      "이메일",
      "상품명",
      "충전 Credit",
      "적립 Credit",
      "Paypal",
      "카드/pay",
      "상태",
      "Paypal email 주소",
    ];

    const tableData = listTransaction.map((transaction, index) => {
      return {
        ...transaction,
        index: index + 1 + rowsPerPage * page,
        rechargeDate: transaction?.parent?.createdAt
          ? formatDate(transaction?.parent?.createdAt)
          : "",
        refundRequestDate: transaction?.createdAt
          ? formatDate(transaction.createdAt)
          : "",
        refundCompletionDate: transaction?.approvedAt
          ? formatDate(transaction.approvedAt)
          : "",
        name: transaction?.user?.nickName ?? "",
        email: transaction?.user?.email ?? "",
        productName: transaction?.product?.name ?? "",
        rechargeCredit: transaction?.amount ?? "",
        earnCredit: "",
        paypal:
          transaction?.paymentMethod === "paypal"
            ? transaction?.amount ?? 0
            : 0,
        card:
          transaction?.paymentMethod === "paypal"
            ? 0
            : transaction?.amount ?? 0,
        ppEmail: transaction?.email ?? "",
        status: mapStatusEnToKo(transaction?.status ?? ""),
        // status: (
        //   <Select
        //     MenuProps={{
        //       disableScrollLock: true
        //     }}
        //     fullWidth
        //     value={transaction?.status ?? ""}
        //     onChange={(e) => {
        //       e.stopPropagation();
        //       // handleChangeSearchTerms("sorts", e.target.value);
        //       handleUpdateStatus(transaction.id, e.target.value);
        //     }}
        //     style={{
        //       borderRadius: "0",
        //       height: "30px"
        //     }}
        //   >
        //     {listStatus.map((item) => (
        //       <MenuItem key={item.value} value={item.value}>
        //         {item.text}
        //       </MenuItem>
        //     ))}
        //   </Select>
        // )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>검색필터</Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px",
                  }}
                >
                  {/* 환불요청일 */}
                  환불완료일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            width: "80px",
                            height: "13px",
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0,
                          },
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            width: "80px",
                            height: "13px",
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <FormControl size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px",
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px",
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                {searchTerms["type"] === "status" ? (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={searchTerms["status"]}
                    onChange={(e) =>
                      handleChangeSearchTerms("status", e.target.value)
                    }
                    style={{
                      borderRadius: "0",
                      height: "30px",
                      width: "150px",
                    }}
                  >
                    {listStatus.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    size="small"
                    sx={{
                      "& label": {
                        paddingLeft: (theme) => theme.spacing(2),
                      },
                      "& input": {
                        paddingLeft: (theme) => theme.spacing(3.5),
                      },
                      "& fieldset": {
                        paddingLeft: (theme) => theme.spacing(2.5),
                        borderRadius: 0,
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "30px",
                        padding: "0 5px",
                        borderRadius: 0,
                        outline: "none",
                        width: "300px",
                      },
                    }}
                    value={searchTerms["search"]}
                    onChange={(e) =>
                      handleChangeSearchTerms("search", e.target.value)
                    }
                    onKeyDown={handleKeyDown}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90,
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="Credit 관리 - 환불 관리 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              {/* <Grid item>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{
                    borderRadius: "0",
                    height: "30px",
                    width: "120px"
                  }}
                >
                  {listFilter.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </Grid> */}
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem",
                            },
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important",
                        },
                        marginRight: 0,
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(RefundManagementList);
