import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import { ManageUltrafitShopService } from "services";
import { withToast } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import { Grid, Typography, Box } from "@mui/material";
import { formatDate, formatNumber } from "utils/function";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import TableData from "./components/TableData";
import OrderDetail from "./components/OrderDetail";
import {
  getListOrder,
  resetOrders,
  updateOrderStatus
} from "redux/actions/order";
import { useGlobalStore } from "hooks";

function OrderCancellation({ showToast }) {
  const [openDetail, setOpenDetail] = useState(false);

  const [dataDetail, setDataDetail] = useState({});

  const [state, dispatch] = useGlobalStore();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    orders = [],
    loading = true,
    count = 0,
    error = null
  } = useMemo(() => state?.order, [state]);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    let condition = {
      status: ["cancellation_pending", "cancellation_completed"]
    };
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return {
        page: 1,
        limit: rowsPerPage
      };
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }

    return condition;
  };

  useEffect(() => {
    dispatch(getListOrder(handleCreateCondition()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  const handleCancelApproval = async () => {
    const data = { status: "cancellation_completed" };
    try {
      const response = await ManageUltrafitShopService.updateOrderStatus(
        dataDetail.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateOrderStatus(
            { id: dataDetail.id, status: "cancellation_completed" },
            {
              status: ["cancellation_pending", "cancellation_completed"],
              page: page + 1,
              limit: rowsPerPage
            },
            true
          )
        );
        showToast(TOAST_TYPE.SUCCESS, "Changed status");
        setOpenDetail(false);
      } else {
        const error = response?.data?.msg ?? "";
        showToast(TOAST_TYPE.ERROR, error);
        setOpenDetail(false);
      }
    } catch (error) {
      setOpenDetail(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDenyApproval = async () => {
    const data = { status: "order_confirmation" };
    try {
      const response = await ManageUltrafitShopService.updateOrderStatus(
        dataDetail.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateOrderStatus(
            { id: dataDetail.id, status: "order_confirmation" },
            {
              status: ["cancellation_pending", "cancellation_completed"],
              page: page + 1,
              limit: rowsPerPage
            },
            true
          )
        );
        showToast(TOAST_TYPE.SUCCESS, "Changed status");
        setOpenDetail(false);
      } else {
        const error = response?.data?.msg ?? "";
        showToast(TOAST_TYPE.ERROR, error);
        setOpenDetail(false);
      }
    } catch (error) {
      setOpenDetail(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["10%", "20%", "13%", "15%", "15%", "12%", "15%"];
    const headersTitle = [
      "고객코드",
      "상품명",
      "구매옵션",
      "구매 날짜",
      "취소 날짜",
      "구매비용",
      "상태"
    ];

    // const newOrder = [
    //   ...orders,
    //   {
    //     id: "000001",
    //     product1: {
    //       file: "",
    //       name: "ULTRAFIT XP V5 35BK",
    //       inventory: 1,
    //       price: "$ 50"
    //     },
    //     product2: {
    //       file: "",
    //       name: "ULTRAFIT XP V5 35BK",
    //       inventory: 1,
    //       price: "$ 50"
    //     },
    //     amounts: 800,
    //     createdAt: "2023.01.11",
    //     updatedAt: "2023.06.11",
    //     status: "승인 대기"
    //   }
    // ];

    const getProductName = (orderDetail = []) => {
      const productName = [];
      orderDetail.forEach((item) => {
        productName.push(item.ulftrafits.name);
      });
      return productName;
    };

    const getProductQuantity = (orderDetail = []) => {
      const productQuantity = [];
      orderDetail.forEach((item) => {
        productQuantity.push(item.quantity);
      });
      return productQuantity;
    };

    const tableData = orders.map((order) => {
      const listProductName = getProductName(order?.orderDetail ?? []);
      const listProductQuantity = getProductQuantity(order?.orderDetail ?? []);
      return {
        ...order,
        productName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            {listProductName.map((item, index) => (
              <Typography key={index}>{item}</Typography>
            ))}
          </Box>
        ),
        // productName: (
        //   <Box>
        //     {order?.product1 && (
        //       <Typography style={{ display: "block" }}>
        //         {order?.product1?.name}
        //       </Typography>
        //     )}
        //     {order?.product2 && (
        //       <Typography style={{ display: "block" }}>
        //         {order?.product2?.name}
        //       </Typography>
        //     )}
        //     {order?.product3 && (
        //       <Typography style={{ display: "block" }}>
        //         {order.product3.name}
        //       </Typography>
        //     )}
        //     {order?.product4 && (
        //       <Typography style={{ display: "block" }}>
        //         {order.product4.name}
        //       </Typography>
        //     )}
        //     {order?.product5 && (
        //       <Typography style={{ display: "block" }}>
        //         {order.product5.name}
        //       </Typography>
        //     )}
        //   </Box>
        // ),
        purchaseOption: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            {listProductQuantity.map((item, index) => (
              <Typography key={index}>{item} EA</Typography>
            ))}
          </Box>
        ),
        createdAt: order.createdAt ? formatDate(order.createdAt) : "",
        updatedAt: order.updatedAt ? formatDate(order.updatedAt) : "",
        amounts: order.totalAmount
          ? "$ " + formatNumber(Number(order.totalAmount))
          : ""
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        setOpenDetail={setOpenDetail}
        setDataDetail={setDataDetail}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  return (
    <Layout header="주문취소">
      {false ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column">
          <Grid item>
            <Box
              style={{
                display: "flex",
                padding: "5px 10px",
                border: "1px solid #c4c4c4",
                justifyContent: "space-between",
                fontWeight: "bold !important",
                borderRadius: 5
              }}
            >
              <Box style={{ display: "flex" }}>
                <Typography>주문취소</Typography>
                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
              </Box>
              <Typography>* 리스트 클릭 시 상세보기</Typography>
            </Box>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            {renderTable()}
          </Grid>
          <OrderDetail
            open={openDetail}
            setOpen={setOpenDetail}
            data={dataDetail}
            handleDenyApproval={handleDenyApproval}
            handleCancelApproval={handleCancelApproval}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(OrderCancellation);
