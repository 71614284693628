import React, { useEffect, useMemo, useState } from "react";
import Layout from "components/Layout";
import // ManageUltrafitShopService,
// ManagePatternService,
// UploadService,
"services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { useForm } from "react-hook-form";
import {
  Grid,
  // TextField,
  Typography,
  // Box,
  styled,
  Button,
  Select,
  MenuItem,
  Box,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material";
// import { generateImageSrc, listGroupBy, sortList } from "utils/function";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import TableData from "./components/TableData";
import { useGlobalStore } from "hooks";
import { getListEmail, resetEmails } from "redux/actions/email";
import {
  formatDate,
  formatDateSearch,
  formatDateSearchEnd
} from "utils/function";
import { ManageEmailService } from "services";
import Popup2Action from "components/Popup2Action";

const listStatus = [
  { value: "all", text: "전체" },
  { value: "title", text: "제목" },
  { value: "email", text: "이메일" }
];

const listLevels = [
  { value: "beginner", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  border: "1px solid #c4c4c4",
  color: "#000"
});

function ListEmailReceived({ showToast }) {
  const [listChecked, setListChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [emails, setEmails] = useState([]);
  const [allEmails, setAllEmails] = useState([]);

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: "",
    levels: []
  });

  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState();

  const navigate = useNavigate();

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    if (type === "all" || type === "") {
      condition = { ...condition, all: search ? search.trim() : "" };
    }
    if (type === "title") {
      condition = { ...condition, title: search ? search.trim() : "" };
    }
    if (type === "email") {
      condition = { ...condition, email: search ? search.trim() : "" };
    }
    if (levels.length) {
      condition = { ...condition, levels: levels.join(",") };
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearch(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo)
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const res = await ManageEmailService.getEmailContactUs(
        handleCreateCondition(isSearch, isResetting)
      );
      if (res?.data?.statusCode === 200) {
        let data = res?.data?.data ?? [];
        setEmails([...data]);
        setCount(res?.data?.count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
        showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
      }
    } catch (error) {
      setLoading(false);
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      showToast(TOAST_TYPE.ERROR, message);
    }
  }

  async function fetchAllData(conditions) {
    try {
      if (conditions.page && conditions.limit) {
        delete conditions.page;
        delete conditions.limit;
      }
      const res = await ManageEmailService.getEmailContactUs(conditions);
      if (res?.data?.statusCode === 200) {
        let data = res?.data?.data ?? [];
        setAllEmails([...data]);
      } else {
        setAllEmails([]);
      }
    } catch (error) {
      setAllEmails([]);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchAllData(handleCreateCondition());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteConfirm = () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No items selected");
      return;
    }
    setOpenModalDelete(true);
  };

  const handleDelete = async () => {
    try {
      const response = await ManageEmailService.deleteEmailContactUs({
        contactIds: listChecked
      });
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Deleted");
        setOpenModalDelete(false);
        setListChecked([]);
      } else {
        showToast(TOAST_TYPE.ERROR, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleEdit = (id) => {
    navigate(`/e-mail-management/edit/${id}`);
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    await fetchAllData(handleCreateCondition(true, false));
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "", levels: [] });
    setDateFrom(null);
    setDateTo(null);
    await fetchData(false, true);
    await fetchAllData(handleCreateCondition(false, true));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      allEmails.forEach((email) => {
        newListChecked.push(email.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleSelectEmail = (e, bid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, bid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== bid);
      setListChecked(newListChecked);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["5%", "12%", "17%", "27%", "17%", "12%", "12%"];

    const headersTitle = [
      // <FormControlLabel
      //   label="대상 선택"
      //   labelPlacement="start"
      //   control={
      //     <Checkbox checked={isCheckAll} onChange={(e) => handleSelectAll(e)} />
      //   }
      // />,
      <Checkbox checked={isCheckAll} onChange={(e) => handleSelectAll(e)} />,
      "문의종류",
      "제목",
      // "내용",
      "발신자 이메일",
      "회원등급",
      "신날짜",
      "답장날짜"
    ];

    const tableData = emails.map((email) => {
      return {
        ...email,
        createdAt: email.createdAt ? formatDate(email.createdAt) : "",
        replyAt: email.replyAt ? formatDate(email.replyAt) : "",
        check: (
          <Checkbox
            checked={listChecked.includes(email.id) ? true : false}
            onChange={(e) => handleSelectEmail(e, email.id)}
          />
        ),
        content: (
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1
            }}
            variant="body1"
            component={"span"}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: email?.content ? email.content : ""
              }}
            />
          </Typography>
        ),
        senderEmail: email?.email ?? "",
        edit: (
          <StyledButton variant="outlined" onClick={() => handleEdit(email.id)}>
            수정
          </StyledButton>
        )
      };
    });

    // const tableData = [
    //   {
    //     check: (
    //       <Checkbox
    //         checked={listChecked.includes(1) ? true : false}
    //         // onChange={(e) => handleSelectEmail(e, 1)}
    //       />
    //     ),
    //     id: 1,
    //     name: "플로팅 오류",
    //     content: "플로팅시 오류가 발생했습니다. 이미지 참조 확인..",
    //     senderEmail: "sh53960@aiaracorp.com",
    //     createdAt: "23.01.06",
    //     attach: "https://static.thenounproject.com/png/101389-200.png",
    //     fileName: "attachment.png"
    //   },
    //   {
    //     check: (
    //       <Checkbox
    //         checked={listChecked.includes(2) ? true : false}
    //         // onChange={(e) => handleSelectEmail(e, 1)}
    //       />
    //     ),
    //     id: 2,
    //     name: "비밀번호 찾기",
    //     content: "오류 플로팅시 오류가 발생했습니다. 이미지 참조 확인...",
    //     senderEmail: "sh53960@aiaracorp.com",
    //     createdAt: "23.01.06",
    //     attach: "https://static.thenounproject.com/png/101389-200.png",
    //     fileName: "attachment.png"
    //   },
    //   {
    //     check: (
    //       <Checkbox
    //         checked={listChecked.includes(3) ? true : false}
    //         // onChange={(e) => handleSelectEmail(e, 1)}
    //       />
    //     ),
    //     name: "휴먼계정안내",
    //     id: 3,
    //     content: "플로팅시 오류가 발생했습니다. 이미지 참조 확인",
    //     senderEmail: "sh53960@aiaracorp.com",
    //     createdAt: "23.01.06",
    //     attach: "https://static.thenounproject.com/png/101389-200.png",
    //     fileName: "attachment.png"
    //   }
    // ];

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }} sx={{ mt: 1, mb: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  수신날짜
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ marginRight: "20px" }} sx={{ mt: 1, mb: 1 }}>
              <Grid container>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px",
                    mr: 1
                  }}
                >
                  검색어
                </Box>
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px",
                    width: "100px",
                    marginRight: "5px"
                  }}
                >
                  {listStatus.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      height: "13px",
                      width: "300px"
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ mt: 1, mb: 1 }}>
              <Grid container>
                <Grid item>
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px",
                      mr: 1
                    }}
                  >
                    회원등급
                  </Box>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px"
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem"
                              }
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important"
                          }
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <LoadingButton
              onClick={handleSearch}
              loading={loadingProcess["searching"] ? true : false}
              loadingIndicator="검색"
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "30px",
                marginLeft: 5
              }}
            >
              검색
            </LoadingButton>
            <LoadingButton
              onClick={handleReset}
              loading={loadingProcess["resetting"] ? true : false}
              loadingIndicator="초기화"
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                width: 90,
                height: "30px",
                marginLeft: 5
              }}
            >
              초기화
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="받은 이메일 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column">
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item sx={{ mt: 2 }}>
            {renderTable()}
          </Grid>
          <Grid item sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleDeleteConfirm}
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0"
              }}
            >
              삭제
            </Button>
          </Grid>
          <Popup2Action
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            message="삭제 하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenModalDelete(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(ListEmailReceived);
