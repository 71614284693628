import { PrivateRouter } from "HOC";
import ServiceUseUnitPrice from "../ServiceUseUnitPrice";
import AdminSetting from "../AdminSetting";
import SettingTheShippingFee from "../SettingTheShippingFee";
import ShippingWeightFee from "../ShippingWeightFee";

const routerPreferences = [
  {
    path: "/preferences/service-use-unit-price",
    element: (
      <PrivateRouter>
        <ServiceUseUnitPrice />
      </PrivateRouter>
    )
  },
  {
    path: "/preferences/admin-settings",
    element: (
      <PrivateRouter>
        <AdminSetting />
      </PrivateRouter>
    )
  },
  {
    path: "/preferences/region",
    element: (
      <PrivateRouter>
        <SettingTheShippingFee />
      </PrivateRouter>
    )
  },
  {
    path: "/preferences/weight",
    element: (
      <PrivateRouter>
        <ShippingWeightFee />
      </PrivateRouter>
    )
  }
];

export default routerPreferences;
