import React, { useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import { SUCCESS_STATUS } from "utils/constants";
import {
  ManageSponsorService,
  PreferencesService,
  ManageCreditService
} from "services";
import { TOAST_TYPE } from "../../../../utils/constants";
import Popup2Action from "components/Popup2Action";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LoadingOneLine from "components/LoadingOneLine";

const AntTabs = styled(Tabs)(({ color }) => ({
  borderBottom: "1px solid #a5a5a5",
  "& .MuiTabs-indicator": {
    display: "none"
  },
  "& .Mui-selected": {
    color: color ? `${color} !important` : ""
  }
}));

const StyledButton = styled(Button)({
  // border: "1px solid #a5a5a5",
  textTransform: "capitalize",
  // color: "#000",
  minWidth: 100,
  fontWeight: "bold"
});

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "150px",
    height: height ? `${height}` : "25px"
    // padding: "4px",
    // textAlign: "center",
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    // borderRadius: 0,
  }
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(2),
    "&.Mui-selected .MuiTabs-indicator": {
      display: "none"
    },
    "&.Mui-selected": {
      color: "#1890ff",
      borderBottom: "0"
    },

    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)"
    }
  })
);

function TabPanel(props) {
  const { children, value, index, spaceTop = true, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: spaceTop ? 5 : 0,
            mb: 5,
            ml: 5,
            mr: 5
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function Item(props) {
  const { showToast, type, level } = props;
  const preValue = useRef();
  const [dataPrice, setDataPrice] = useState({});
  const [priceDetail, setPriceDetail] = useState({ variable: "", type: type });
  const [priceChange, setPriceChange] = useState("");
  const [loading, setLoading] = useState(true);

  const [openPopup, setOpenPopup] = useState(false);

  async function fetchData() {
    try {
      const response = await ManageCreditService.getFloatVariablesByType(type);
      if (response?.data?.statusCode === 200) {
        const listPrice = response?.data?.data ?? [];
        const currPrice =
          listPrice.length > 0
            ? listPrice.find((item) => item.level === level)
            : null;
        setPriceDetail(currPrice ? currPrice : defaultValueEditor);
        preValue.current = currPrice?.variable;
        setDataPrice(currPrice?.variable ?? "");
        setPriceChange(currPrice?.variable ?? "");
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data?.mgs ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  const savePrice = async () => {
    try {
      //   if (dataPrice?.id) {
      //     await PreferencesService.updateFloatVariables(dataPrice.id, {
      //       value: Number(priceChange)
      //     });
      //   } else {
      //     await PreferencesService.createFloatVariables({
      //       value: Number(priceChange),
      //       name: dataPrice.name
      //     });
      //   }
      //   const newDataPrice = { ...dataPrice, value: Number(priceChange) };
      //   preValue.current = { ...newDataPrice };
      //   setDataPrice({ ...newDataPrice });
      //   setOpenPopup(false);
      //   showToast(TOAST_TYPE.SUCCESS, "Update success");
      let response = "";
      if (priceDetail?.id) {
        response = await ManageCreditService.updateFloatVariables(
          priceDetail.id,
          {
            variable: Number(priceChange),
            type: type
          }
        );
      } else {
        response = await ManageCreditService.createFloatVariables({
          variable: Number(priceChange),
          currency: "%",
          type: type,
          level
        });
      }
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Success");
        setOpenPopup(false);
      }
    } catch (error) {
      setOpenPopup(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSave = () => {
    setOpenPopup(true);
  };

  const handleReset = () => {
    setPriceChange(preValue.current);
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingOneLine />
      ) : (
        <Box flexDirection="column">
          <Box style={{ fontWeight: "bold" }} sx={{ pr: 1, pl: 1 }}>
            <Box style={{ textAlign: "right" }}>
              {/* <Typography
                style={{
                  display: "inline",
                }}
              >
                *
              </Typography>
              <Typography
                style={{
                  border: "1px solid",
                  display: "inline",
                }}
              >
                1 Canvas
              </Typography>
              <Typography
                style={{
                  display: "inline",
                }}
              >
                &nbsp;for each
              </Typography> */}
              {/* <Typography>* 1 Canvas for each</Typography> */}
            </Box>
            <Box
              sx={{ mt: 4 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem"
                }}
              >
                현재:
              </Typography>
              {/* <Typography
                sx={{ ml: 2 }}
                style={{
                  border: "1px solid #c4c4c4",
                  width: "328px",
                  height: "42px",
                  display: "flex",
                  fontWeight: "bold",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >{`${Number(dataPrice.value)} %`}</Typography> */}
              <StyledTextField
                sx={{
                  ml: 2,
                  Input: {
                    WebkitTextFillColor: "#000 !important",
                    fontWeight: "bold"
                    // -webkit-text-fill-color: "#000 !important",
                  }
                }}
                value={Number(dataPrice)}
                width="300px"
                size="small"
                label="%"
                disabled
                // onChange={(e) => setPriceChange(e.target.value)}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <ArrowDropDownIcon sx={{ width: 100, height: 68 }} />
            </Box>
            <Box
              sx={{ mb: 4 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  fontSize: "1.2rem"
                }}
              >
                변경 값:
              </Typography>
              <StyledTextField
                sx={{ ml: 2 }}
                value={priceChange}
                size="small"
                label="%"
                width="300px"
                onChange={(e) => setPriceChange(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              mt: 5,
              justifyContent: "center"
            }}
          >
            <StyledButton variant="contained" onClick={handleSave}>
              저장
            </StyledButton>
            <StyledButton variant="contained" onClick={handleReset}>
              초기화
            </StyledButton>
          </Box>
          <Popup2Action
            open={openPopup}
            setOpen={setOpenPopup}
            message="멤버십 등급별로 요금을 수정하시겠습니까?"
            onClickConfirm={savePrice}
            onClickCancel={() => setOpenPopup(false)}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

const defaultValuePattern = {
  type: "pattern_unit_price",
  variable: ""
};

function PatternUnitPrice(props) {
  const { showToast } = props;
  const preValue = useRef();
  const [dataPrice, setDataPrice] = useState({});
  const [loading, setLoading] = useState(true);

  const [openPopup, setOpenPopup] = useState(false);

  async function fetchData() {
    try {
      const response = await ManageCreditService.getFloatVariablesByType(
        "pattern_unit_price"
      );
      if (response?.data?.statusCode === 200) {
        let price = response?.data?.data ?? [];
        while (price.length < 4) {
          price.push({ ...defaultValuePattern, level: `${price.length}` });
        }
        preValue.current = [...price];
        setDataPrice(price);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRest = () => {
    setDataPrice([...preValue.current]);
  };

  const handleSave = async () => {
    Promise.all(
      dataPrice
        .filter((value) => !!value.variable)
        .map((item) => {
          if (item?.id) {
            return ManageCreditService.updateFloatVariables(item.id, {
              variable: Number(item.variable),
              type: item.type
            });
          } else {
            return ManageCreditService.createFloatVariables({
              variable: Number(item.variable),
              unit: "Per 1 Sq.f",
              currency: "credit",
              type: item.type,
              level: item.level
            });
          }
        })
    )
      .then(async () => {
        preValue.current = [...dataPrice];
        setOpenPopup(false);
        showToast(TOAST_TYPE.SUCCESS, "Success");
        await fetchData();
      })
      .catch((error) => {
        setOpenPopup(false);
        const errorStatusText = error?.response?.statusText ?? "";
        const errorDataMessage = error?.response?.data?.message ?? "";
        const errorMessage =
          errorDataMessage || errorStatusText || "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      });
  };

  const handleChangePrice = (level, value) => {
    const newDataPrice = dataPrice;

    const index = newDataPrice.findIndex((item) => item.level === level);

    newDataPrice[index] = { ...newDataPrice[index], variable: value };
    setDataPrice([...newDataPrice]);
  };

  const getVariableByLevel = (level) => {
    const data = dataPrice.find((item) => item.level === level);
    return data?.variable ?? "";
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingOneLine />
      ) : (
        <Box flexDirection="column">
          <Box style={{ fontWeight: "bold" }} sx={{ pr: 1, pl: 1 }}>
            <Box
              sx={{
                display: "flex",
                mt: 0.5,
                mb: 2,
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              {/* <Typography style={{ display: "inline" }}>*</Typography>
              <Typography
                style={{ display: "inline", border: "1px solid #a5a5a5" }}
              >
                1Sq.f
              </Typography>
              <Typography style={{ display: "inline" }}>
                &nbsp;for each
              </Typography> */}
              <Typography>* 1 Sq.f 당</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: 2,
                mb: 2,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography
                style={{ fontWeight: "bold", textAlign: "center" }}
                sx={{ mr: 4 }}
              >
                1.
              </Typography>
              <StyledTextField
                sx={{ ml: 2 }}
                size="small"
                label="Credit"
                width="300px"
                // value={dataPrice[0] ? dataPrice[0].value : ""}
                value={getVariableByLevel("0")}
                onChange={(e) => handleChangePrice("0", e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: 2,
                mb: 2,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography
                style={{ fontWeight: "bold", textAlign: "center" }}
                sx={{ mr: 4 }}
              >
                2.
              </Typography>
              <StyledTextField
                sx={{ ml: 2 }}
                size="small"
                label="Credit"
                width="300px"
                // value={dataPrice[1] ? dataPrice[1].value : ""}
                value={getVariableByLevel("1")}
                onChange={(e) => handleChangePrice("1", e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: 2,
                mb: 2,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography
                style={{ fontWeight: "bold", textAlign: "center" }}
                sx={{ mr: 4 }}
              >
                3.
              </Typography>
              <StyledTextField
                sx={{ ml: 2 }}
                size="small"
                label="Credit"
                width="300px"
                // value={dataPrice[2] ? dataPrice[2].value : ""}
                value={getVariableByLevel("2")}
                onChange={(e) => handleChangePrice("2", e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 2,
                mb: 2,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography
                style={{ fontWeight: "bold", textAlign: "center" }}
                sx={{ mr: 4 }}
              >
                4.
              </Typography>
              <StyledTextField
                sx={{ ml: 2 }}
                size="small"
                label="Credit"
                width="300px"
                // value={dataPrice[3] ? dataPrice[3].value : ""}
                value={getVariableByLevel("3")}
                onChange={(e) => handleChangePrice("3", e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              mt: 5,
              justifyContent: "center"
            }}
          >
            <StyledButton
              variant="contained"
              onClick={() => setOpenPopup(true)}
            >
              저장
            </StyledButton>
            <StyledButton variant="contained" onClick={handleRest}>
              초기화
            </StyledButton>
          </Box>
          <Popup2Action
            open={openPopup}
            setOpen={setOpenPopup}
            message="단가를 수정하시겠습니까?"
            onClickConfirm={handleSave}
            onClickCancel={() => setOpenPopup(false)}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

const defaultValueEditor = {
  type: "editor_subcription_fee",
  variable: ""
};

function EditorSubscriptionFee(props) {
  const { showToast } = props;
  const [price, setPrice] = useState("");
  const [priceDetail, setPriceDetail] = useState({ ...defaultValueEditor });
  const [priceChange, setPriceChange] = useState("");
  const [loading, setLoading] = useState(true);

  const prePrice = useRef(null);

  const [openPopup, setOpenPopup] = useState(false);

  async function fetchData() {
    try {
      const response = await ManageCreditService.getFloatVariablesByType(
        "editor_subcription_fee"
      );
      if (response?.data?.statusCode === 200) {
        const listPrice = response?.data?.data ?? [];
        const currPrice = listPrice.length > 0 ? listPrice[0]?.variable : "";
        setPriceDetail(
          listPrice.length > 0 ? listPrice[0] : defaultValueEditor
        );
        prePrice.current = currPrice;
        setPrice(currPrice);
        setPriceChange(currPrice);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data?.mgs ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePrice = async () => {
    try {
      let response = "";
      if (priceDetail?.id) {
        response = await ManageCreditService.updateFloatVariables(
          priceDetail.id,
          {
            variable: Number(priceChange),
            type: priceDetail.type
          }
        );
      } else {
        response = await ManageCreditService.createFloatVariables({
          variable: Number(priceChange),
          unit: "1 month",
          currency: "USD",
          type: priceDetail.type
        });
      }
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Success");
        setOpenPopup(false);
      }
    } catch (error) {
      setOpenPopup(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSave = () => {
    setOpenPopup(true);
  };

  const handleReset = () => {
    setPriceChange(prePrice.current);
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingOneLine />
      ) : (
        <Box flexDirection="column">
          <Box style={{ fontWeight: "bold" }} sx={{ pr: 1, pl: 1 }}>
            <Box style={{ textAlign: "right" }}>
              {/* <Typography
                style={{
                  display: "inline",
                }}
              >
                *
              </Typography>

              <Typography
                style={{
                  display: "inline",
                }}
              >
                for each:&nbsp;
              </Typography>
              <Typography
                style={{
                  border: "1px solid",
                  display: "inline",
                }}
              >
                1 month
              </Typography> */}
              <Typography>*기간 단위: 1 month</Typography>
            </Box>
            <Box
              sx={{ mt: 4 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem"
                }}
              >
                현재:
              </Typography>
              {/* <Typography
                sx={{ ml: 2 }}
                style={{
                  border: "1px solid #c4c4c4",
                  width: "328px",
                  height: "42px",
                  display: "flex",
                  fontWeight: "bold",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >{`${Number(price)} USD`}</Typography> */}
              <StyledTextField
                sx={{
                  ml: 2,
                  Input: {
                    WebkitTextFillColor: "#000 !important",
                    fontWeight: "bold"
                    // -webkit-text-fill-color: "#000 !important",
                  }
                }}
                value={Number(price)}
                width="300px"
                size="small"
                label="USD"
                disabled
                // onChange={(e) => setPriceChange(e.target.value)}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <ArrowDropDownIcon sx={{ width: 100, height: 68 }} />
            </Box>
            <Box
              sx={{ mb: 4 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  fontSize: "1.2rem"
                }}
              >
                변경 값:
              </Typography>
              <StyledTextField
                sx={{ ml: 2 }}
                value={priceChange}
                width="300px"
                size="small"
                label="USD"
                onChange={(e) => setPriceChange(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              mt: 5,
              justifyContent: "center"
            }}
          >
            <StyledButton variant="contained" onClick={handleSave}>
              저장
            </StyledButton>
            <StyledButton variant="contained" onClick={handleReset}>
              초기화
            </StyledButton>
          </Box>
          <Popup2Action
            open={openPopup}
            setOpen={setOpenPopup}
            message="편집자 구독료를 수정하시겠습니까?"
            onClickConfirm={savePrice}
            onClickCancel={() => setOpenPopup(false)}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

const defaultValueCutting = {
  type: "cutting_cost",
  variable: ""
};

function CuttingFee(props) {
  const { showToast } = props;
  const preValue = useRef();
  const [dataPrice, setDataPrice] = useState("");
  const [priceDetail, setPriceDetail] = useState({ ...defaultValueCutting });
  const [priceChange, setPriceChange] = useState("");
  const [loading, setLoading] = useState(true);

  const [openPopup, setOpenPopup] = useState(false);

  async function fetchData() {
    try {
      const response = await ManageCreditService.getFloatVariablesByType(
        "cutting_cost"
      );
      if (response?.data?.statusCode === 200) {
        const listPrice = response?.data?.data ?? [];
        const currPrice = listPrice.length > 0 ? listPrice[0]?.variable : "";
        setPriceDetail(
          listPrice.length > 0 ? listPrice[0] : defaultValueCutting
        );
        preValue.current = currPrice;
        setDataPrice(currPrice);
        setPriceChange(currPrice);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data?.mgs ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePrice = async () => {
    try {
      // if (dataPrice?.id) {
      //   await PreferencesService.updateFloatVariables(dataPrice.id, {
      //     value: Number(priceChange)
      //   });
      // } else {
      //   await PreferencesService.createFloatVariables({
      //     value: Number(priceChange),
      //     name: dataPrice.name
      //   });
      // }
      // const newDataPrice = { ...dataPrice, value: Number(priceChange) };
      // preValue.current = { ...newDataPrice };
      // setDataPrice({ ...newDataPrice });
      // setOpenPopup(false);
      // showToast(TOAST_TYPE.SUCCESS, "Update success");
      let response = "";
      if (priceDetail?.id) {
        response = await ManageCreditService.updateFloatVariables(
          priceDetail.id,
          {
            variable: Number(priceChange),
            type: priceDetail.type
          }
        );
      } else {
        response = await ManageCreditService.createFloatVariables({
          variable: Number(priceChange),
          unit: "1 Canvas 당",
          currency: "credit",
          type: priceDetail.type
        });
      }
      if (response?.data?.statusCode === 200) {
        await fetchData();
        showToast(TOAST_TYPE.SUCCESS, "Success");
        setOpenPopup(false);
      }
    } catch (error) {
      setOpenPopup(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSave = () => {
    setOpenPopup(true);
  };

  const handleReset = () => {
    setPriceChange(preValue.current);
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingOneLine />
      ) : (
        <Box flexDirection="column">
          <Box style={{ fontWeight: "bold" }} sx={{ pr: 1, pl: 1 }}>
            <Box style={{ textAlign: "right" }}>
              {/* <Typography
                style={{
                  display: "inline",
                }}
              >
                *
              </Typography>
              <Typography
                style={{
                  display: "inline",
                }}
              >
                1 Canvas
              </Typography>
              <Typography
                style={{
                  display: "inline",
                }}
              >
                &nbsp;for each
              </Typography> */}
              <Typography>* 1 Canvas 당</Typography>
            </Box>
            <Box
              sx={{ mt: 4 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem"
                }}
              >
                현재:
              </Typography>
              {/* <Typography
                sx={{ ml: 2 }}
                style={{
                  border: "1px solid #c4c4c4",
                  width: "328px",
                  height: "42px",
                  display: "flex",
                  fontWeight: "bold",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                }}
              >{`${Number(dataPrice.value)} Credit`}</Typography> */}
              <StyledTextField
                sx={{
                  ml: 2,
                  Input: {
                    WebkitTextFillColor: "#000 !important",
                    fontWeight: "bold"
                    // -webkit-text-fill-color: "#000 !important",
                  }
                }}
                value={Number(dataPrice)}
                width="300px"
                size="small"
                label="Credit"
                disabled
                // onChange={(e) => setPriceChange(e.target.value)}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <ArrowDropDownIcon sx={{ width: 100, height: 68 }} />
            </Box>
            <Box
              sx={{ mb: 4 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography
                style={{
                  display: "inline",
                  fontWeight: "bold",
                  fontSize: "1.2rem"
                }}
              >
                변경 값:
              </Typography>
              <StyledTextField
                sx={{ ml: 2 }}
                label="Credit"
                width="300px"
                size="small"
                value={priceChange}
                onChange={(e) => setPriceChange(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              mt: 5,
              justifyContent: "center"
            }}
          >
            <StyledButton variant="contained" onClick={handleSave}>
              저장
            </StyledButton>
            <StyledButton variant="contained" onClick={handleReset}>
              초기화
            </StyledButton>
          </Box>
          <Popup2Action
            open={openPopup}
            setOpen={setOpenPopup}
            message="절단 비용을 수정하시겠습니까?"
            onClickConfirm={savePrice}
            onClickCancel={() => setOpenPopup(false)}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

function ProfitFee(props) {
  const { showToast } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%"
      }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          style={{
            height: 32
          }}
        >
          <StyledTab style={{ width: "100px" }} value={0} label="Beginner" />
          <StyledTab style={{ width: "100px" }} value={1} label="Advanced" />
          <StyledTab style={{ width: "100px" }} value={2} label="Expert" />
          <StyledTab style={{ width: "100px" }} value={3} label="Master" />
        </AntTabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <Item showToast={showToast} type="profit_fee" level="beginner" />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Item showToast={showToast} type="profit_fee" level="advanced" />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Item showToast={showToast} type="profit_fee" level=" expert" />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Item showToast={showToast} type="profit_fee" level=" master" />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default function StyledTabPanel(props) {
  const { dispatch, showToast } = props;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", border: "1px solid #a5a5a5" }}>
      <Box sx={{ bgcolor: "#1565c0", color: "#fff" }}>
        <AntTabs
          color="#fff"
          value={value}
          onChange={handleChange}
          aria-label="ant example"
        >
          <StyledTab value={0} label="패턴 단가" />
          <StyledTab value={1} label="에디터 구독료" />
          {/* <StyledTab value={2} label="커팅 비용" /> */}
          <StyledTab value={2} label="등급별 Profit 수수료" />
        </AntTabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <PatternUnitPrice dispatch={dispatch} showToast={showToast} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditorSubscriptionFee dispatch={dispatch} showToast={showToast} />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <CuttingFee price={5} dispatch={dispatch} showToast={showToast} />
        </TabPanel> */}
        <TabPanel value={value} index={2} spaceTop={false}>
          <ProfitFee
            prices={[123, 2321, 23, 1]}
            dispatch={dispatch}
            showToast={showToast}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
