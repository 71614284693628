import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";

import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Login,
  // Manage member
  ListMember,
  MemberDetail,
  MembershipPolicy,
  EditorSubscriptionCustomers,
  //Mange credit charge
  CreditProductManagement,
  ChargingCompleteList,
  DetailChargeCompleted,
  CreditUsageHistory,
  DetailCreditHistory,
  RefundManagementList,
  DetailRefund,
  GiftCreditManagement,
  RouteManageMerchant,
  RouterManageWithdrawal,
  RouterManagePattern,
  RouterManageSponsor,
  RouterManageUltrafitShop,
  RouterManageCoupon,
  RouterManageEmail,
  RouterManageBanner,
  RouterPreferences,
  RouterManageUploader
} from "pages";
import { PrivateRouter } from "HOC";
import { isUserLoggedIn } from "./redux/actions/auth";
import NotFound from "./pages/NotFound";
import { MainCreditUsageHistory } from "pages/ManageCredit";

function RejectedRoute() {
  const auth = useSelector((state) => state.auth);
  return !auth.authenticate ? (
    <Outlet />
  ) : (
    <Navigate to="/member-management/list-member" replace />
  );
}

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.authenticate, dispatch]);

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/member-management/list-member"
          element={
            <PrivateRouter>
              <ListMember />
            </PrivateRouter>
          }
        />
        <Route
          path="/"
          element={<Navigate to="/member-management/list-member" replace />}
        />
        <Route
          path="/member-management/list-member/detail/:id"
          element={
            <PrivateRouter>
              <MemberDetail />
            </PrivateRouter>
          }
        />
        <Route
          path="/member-management/policy"
          element={
            <PrivateRouter>
              <MembershipPolicy />
            </PrivateRouter>
          }
        />
        <Route
          path="/member-management/editor-payment-history"
          element={
            <PrivateRouter>
              <EditorSubscriptionCustomers />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/credit-product-management"
          element={
            <PrivateRouter>
              <CreditProductManagement />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/charging-complete-list"
          element={
            <PrivateRouter>
              <ChargingCompleteList />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/charging-complete-list/detail"
          element={
            <PrivateRouter>
              <DetailChargeCompleted />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/charging-complete-list/credit-usage-history"
          element={
            <PrivateRouter>
              <CreditUsageHistory />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/charging-complete-list/credit-usage-history/:code"
          element={
            <PrivateRouter>
              <DetailCreditHistory />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/refund-management-list"
          element={
            <PrivateRouter>
              <RefundManagementList />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/refund-management-list/detail"
          element={
            <PrivateRouter>
              <DetailRefund />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/gift-credit-management"
          element={
            <PrivateRouter>
              <GiftCreditManagement />
            </PrivateRouter>
          }
        />
        <Route
          path="/credit-management/main-credit-usage-history"
          element={
            <PrivateRouter>
              <MainCreditUsageHistory />
            </PrivateRouter>
          }
        />
        {RouteManageMerchant.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManageWithdrawal.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManagePattern.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManageSponsor.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManageUltrafitShop.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManageCoupon.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManageEmail.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManageBanner.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterPreferences.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {RouterManageUploader.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
