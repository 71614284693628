import URL from "./url";
import axiosClient from "../axiosClient";

class PreferencesService {
  getShippingRegion() {
    const url = URL.GET_SHIPPING_REGION;
    return axiosClient.get(url);
  }
  updateShippingRegion(id, body = {}) {
    const url = URL.UPDATE_SHIPPING_REGION + "/" + id;
    return axiosClient.put(url, body);
  }
  createShippingRegion(body = {}) {
    const url = URL.CREATE_SHIPPING_REGION;
    return axiosClient.post(url, body);
  }
  setShippingRegion(body = {}) {
    const url = URL.SET_SHIPPING_REGION;
    return axiosClient.post(url, body);
  }
  // deleteShippingRegion(id, delete_area = false) {
  //   let url = URL.DELETE_SHIPPING_REGION + "/" + id;
  //   if (delete_area) {
  //     url += "?delete_area=true";
  //   }
  //   return axiosClient.delete(url);
  // }
  deleteShippingRegion(id) {
    let url = URL.DELETE_SHIPPING_REGION + "/" + id;
    return axiosClient.delete(url);
  }
  getShippingWeight() {
    const url = URL.GET_SHIPPING_WEIGHT;
    return axiosClient.post(url);
  }
  setShippingAreaWeight(body = {}) {
    const url = URL.SET_SHIPPING_WEIGHT;
    return axiosClient.post(url, body);
  }
  updateShippingAreaWeight(id, body = {}, update_weight = "") {
    let url = URL.UPDATE_SHIPPING_WEIGHT + "/" + id;
    if (update_weight) {
      url += `?update_weight=${update_weight}`;
    }
    return axiosClient.put(url, body);
  }
  createFloatVariables(body = {}) {
    const url = URL.CREATE_FLOAT_VARIABLES;
    return axiosClient.post(url, body);
  }
  getFloatVariablesByName(name) {
    const url = URL.GET_FLOAT_VARIABLES + "?name=" + name;
    return axiosClient.get(url);
  }
  updateFloatVariables(id, body = {}) {
    const url = URL.UPDATE_FLOAT_VARIABLES + "/" + id;
    return axiosClient.put(url, body);
  }
  getAdminsList() {
    const url = URL.GET_ADMIN;
    return axiosClient.get(url);
  }
  updateAdmin(id, body = {}) {
    const url = URL.UPDATE_ADMIN + "/" + id;
    return axiosClient.put(url, body);
  }
  createAdmin(body = {}) {
    const url = URL.CREATE_ADMIN;
    return axiosClient.post(url, body);
  }
  deleteAdmin(id) {
    const url = URL.DELETE_ADMIN + "/" + id;
    return axiosClient.delete(url);
  }
}

export default new PreferencesService();
