import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { withQuery, withToast } from "HOC";
import { ManageCreditService } from "services";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Box
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
import { formatDate } from "utils/function";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 20,
    padding: 5,
    width: "150px",
    textAlign: "center",
    fontWeight: "bold",
    border: "1px solid #a5a5a5"
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 20,
    padding: 5,
    width: "150px",
    textAlign: "center",
    border: "1px solid #a5a5a5",
    color: "#ffff",
    backgroundColor: "#0468b4",
    fontWeight: "bold"
  }
}));

function DetailChargeCompleted({ searchParams, showToast }) {
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageCreditService.getListTransactionsById(
          searchParams.id
        );
        if (response?.data?.statusCode === 200) {
          setTransaction(response?.data ? response.data?.data : {} ?? {});
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data
            ? response.data?.message
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate("/credit-management/charging-complete-list");
  };

  const handleGo = () => {
    navigate({
      pathname:
        "/credit-management/charging-complete-list/credit-usage-history",
      search: `?id=${transaction?.user?.id}`
    });
  };

  return (
    <Layout header="Credit 관리 - 충전 완료 목록 - 상세보기">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Box
            sx={{
              border: "1px solid #a5a5a5",
              borderRadius: 1,
              padding: "2px",
              width: 200,
              textAlign: "center",
              fontWeight: "bold"
            }}
          >
            충전정보
          </Box>
          <Box sx={{ mt: 2 }}>
            <TableContainer style={{ border: "1px solid #a5a5a5" }}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <StyledTableCellLeft>충전자명</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction?.user?.nickName ?? ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>충전일시</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction?.createdAt
                        ? formatDate(transaction.createdAt)
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>크레딧 충전 정보</StyledTableCellLeft>
                    <StyledTableCell style={{ textTransform: "capitalize" }}>
                      {transaction?.product?.name ?? ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>적립 Credit</StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.netAmount
                        ? Number(transaction.netAmount) + " Credit"
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>
                      충전자 보유 크레딧
                    </StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.balance
                        ? Number(transaction.balance) + " Credit"
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              border: "1px solid #a5a5a5",
              borderRadius: 1,
              padding: "2px",
              width: 200,
              textAlign: "center",
              mt: 2,
              fontWeight: "bold"
            }}
          >
            결제정보
          </Box>
          <Box sx={{ mt: 2 }}>
            <TableContainer style={{ border: "1px solid #a5a5a5" }}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <StyledTableCellLeft>결제수단</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction?.paymentMethod
                        ? transaction.paymentMethod
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>결제금액</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction.amount
                        ? "$ " + Number(transaction.amount)
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ mt: 8, display: "flex" }}>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                lineHeight: 1,
                flexBasis: "50%",
                fontWeight: "bold",
                padding: "10px 0"
              }}
              onClick={handleBack}
            >
              목록
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                lineHeight: 1,
                marginLeft: 20,
                flexBasis: "50%",
                fontWeight: "bold",
                padding: "10px 0"
              }}
              onClick={handleGo}
            >
              Credit 사용 내역
            </Button>
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default withQuery(withToast(DetailChargeCompleted));
