import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  styled,
  Checkbox
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: 0,
    borderRadius: 0,
    fontWeight: "bold",
    border: "1px solid rgb(110, 118, 118)"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 43,
    padding: 0,
    border: "1px solid rgb(110, 118, 118)"
  }
}));

export default function ListEmailAdmin(props) {
  const { detailType = {}, setDetailType = () => {} } = props;
  const [isCheckAll, setIsCheckAll] = useState(false);

  const onChangeCheckAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newDetailType = {
        signUp: 1,
        dormantAccount: 1,
        stopAccount: 1,
        shippingInfo: 1
      };
      setDetailType({ ...detailType, ...newDetailType });
    } else {
      setIsCheckAll(false);
      const newDetailType = {
        signUp: 0,
        dormantAccount: 0,
        stopAccount: 0,
        shippingInfo: 0
      };
      setDetailType({ ...detailType, ...newDetailType });
    }
  };

  const onChangeType = (e, type) => {
    if (e.target.checked) {
      detailType[type] = 1;
      setDetailType({ ...detailType });
    } else {
      detailType[type] = 0;
      setDetailType({ ...detailType });
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ width: "20%", fontWeight: "bold" }}
              align="center"
            >
              회원 가입
            </StyledTableCell>
            <StyledTableCell
              style={{ width: "20%", fontWeight: "bold" }}
              align="center"
            >
              회원 가입
            </StyledTableCell>
            <StyledTableCell
              style={{ width: "10%", fontWeight: "bold" }}
              align="center"
            >
              <Checkbox checked={isCheckAll} onChange={onChangeCheckAll} />
            </StyledTableCell>
            <StyledTableCell
              style={{ width: "50%", fontWeight: "bold" }}
              align="center"
            >
              관리자 이메일
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell align="center" rowSpan={3}>
              회원
            </StyledTableCell>
            <StyledTableCell align="center">회원 가입</StyledTableCell>
            <StyledTableCell align="center">
              <Checkbox
                checked={detailType["signUp"] ? true : false}
                onChange={(e) => {
                  onChangeType(e, "signUp");
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              inogrow@inogrow.com
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center">휴면 회원 전환</StyledTableCell>
            <StyledTableCell align="center">
              <Checkbox
                checked={detailType["dormantAccount"] ? true : false}
                onChange={(e) => {
                  onChangeType(e, "dormantAccount");
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              inogrow@inogrow.com
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center">회원 정지 안내</StyledTableCell>
            <StyledTableCell align="center">
              <Checkbox
                checked={detailType["stopAccount"] ? true : false}
                onChange={(e) => {
                  onChangeType(e, "stopAccount");
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              inogrow@inogrow.com
            </StyledTableCell>
          </TableRow>
          <TableRow style={{ height: 43 * 3 }}>
            <StyledTableCell align="center">주문</StyledTableCell>
            <StyledTableCell align="center">배송 시작 안내</StyledTableCell>
            <StyledTableCell align="center">
              <Checkbox
                checked={detailType["shippingInfo"] ? true : false}
                onChange={(e) => {
                  onChangeType(e, "shippingInfo");
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              inogrow@inogrow.com
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
