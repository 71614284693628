import React from "react";
import { useSearchParams } from "react-router-dom";

function withQuery(Component) {
  return (props) => {
    const [searchParams] = useSearchParams();
    return (
      <Component
        {...props}
        searchParams={Object.fromEntries([...searchParams])}
      />
    );
  };
}

export default withQuery;
