import axios from "axios";
import { authConstants } from "../redux/constants";
import store from "../redux/store/index";
import { updateToken } from "redux/actions/auth";

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: token ? `Bearer ${token}` : ""
  }
});

axiosInstance.interceptors.request.use((req) => {
  const { auth } = store.getState();
  if (auth.token) {
    req.headers.Authorization = `Bearer ${auth.token}`;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    // const status = error?.response ? error.response.status : 401;
    // if (status && status === 401) {
    //   localStorage.clear();
    //   store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
    // }
    // return Promise.reject(error);

    const originalConfig = error.config;

    if (error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          //   const a = "";
          const currentRefreshToken = localStorage.getItem("refreshToken");
          const currToken = localStorage.getItem("token");
          // const userLocal = getUserProfile();
          const isAutoLogin = JSON.parse(localStorage.getItem("isAutoLogin"));
          // console.log("currentRefreshToken", currentRefreshToken);
          // console.log("currToken", currToken);
          // console.log("isAutoLogin", isAutoLogin);
          if (currToken && currentRefreshToken) {
            if (!isAutoLogin) {
              // console.log("run isAutoLogin");
              localStorage.clear();
              store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
            } else {
              const rs = await axiosInstance.post("/getToken", {
                refreshToken: currentRefreshToken
              });

              const newToken = rs?.data?.data ?? "";
              if (!newToken) {
                localStorage.clear();
                store.dispatch({ type: authConstants.LOGOUT_SUCCESS });
              } else {
                store.dispatch(updateToken(newToken));
                // console.log("newToken", newToken);
                localStorage.setItem("token", newToken);
                return axiosInstance(originalConfig);
              }
            }
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
