import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  styled
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/Table";
import { formatDate, formatNumber } from "utils/function";
import { getListMember, resetMembers } from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageMemberService, ManageUploader } from "services";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Popup from "./components/Popup";
import Excel from "exceljs";

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" }
  // { value: "rating", text: "등급" }
];

const listLevels = [
  { value: "beginner", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    suspend: createColor("#ff5454")
  }
});

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  border: "1px solid #c4c4c4",
  color: "#000"
});

function UploaderList({ showToast }) {
  const [listChecked, setListChecked] = useState([]);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false,
    resettingRole: false
  });
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: "",
    levels: []
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [state, dispatch] = useGlobalStore();

  const {
    members = [],
    loading = true,
    error = null,
    count = 0
  } = useMemo(() => state?.member, [state]);

  // useEffect(() => {
  //   dispatch(getListMember());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = {
      role: "merchant",
      sortField: "nickName",
      sortType: "ASC"
    };
    // let condition = {};

    let newLevels = levels;

    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1
        ? { role: "merchant", page: 1, limit: rowsPerPage }
        : { role: "merchant" };
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    // if (type !== "rating") {
    condition = {
      ...condition,
      type: type ? type : "all",
      search: search ? search.trim() : ""
    };
    // } else {
    //   newLevels = [...newLevels, search];
    // }
    if (newLevels.length) {
      condition = { ...condition, levels: newLevels.join(", ") };
    }

    return condition;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    dispatch(getListMember(handleCreateCondition()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetMembers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectUser = (e, userId) => {
    if (e.target.checked) {
      setListChecked([...listChecked, userId]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== userId);
      setListChecked(newListChecked);
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await dispatch(getListMember(handleCreateCondition(true, false)));
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "", levels: [] });
    // setPage(0);
    // await dispatch(getListMember({page: 1, limit: rowsPerPage, role: "merchant"}));
    await dispatch(getListMember(handleCreateCondition(false, true)));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  // const handleGetActiveMember = async () => {
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: true });
  //   await dispatch(getListMember({ status: "active" }));
  //   setLoadingProcess({ ...loadingProcess, fetchingMember: false });
  // };

  const handleMemberSuspension = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No members selected");
      return;
    }

    setLoadingProcess({ ...loadingProcess, suspending: true });
    try {
      const data = {
        userIds: listChecked
      };

      const response = await ManageUploader.stopUploader(data);
      if (response?.data?.statusCode === 200) {
        dispatch(getListMember(handleCreateCondition()));
      }
      // console.log(data);

      // listChecked.forEach(async (userId) => {
      //   if (members.filter((member) => member.id === userId)[0].suspendedTime) {
      //     await ManageMemberService.unsuspendMember(userId);
      //     dispatch(
      //       updateMemberSuspension({ userId: userId, suspendedTime: null })
      //     );
      //     return;
      //   }

      //   await ManageMemberService.suspendMember({
      //     userIds: [userId],
      //     suspendedTime: new Date()
      //   });
      //   // dispatch(
      //   //   updateMemberSuspension({
      //   //     userId: userId,
      //   //     suspendedTime: new Date()
      //   //   })
      //   // );
      // });
      setLoadingProcess({ ...loadingProcess, suspending: false });
      setListChecked([]);
      showToast(TOAST_TYPE.SUCCESS, "Success");
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, suspending: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleRestore = async (id) => {
    try {
      const data = {
        userIds: [id]
      };

      const response = await ManageUploader.reStartUploader(data);
      if (response?.data?.statusCode === 200) {
        dispatch(getListMember(handleCreateCondition()));
      }
      showToast(TOAST_TYPE.SUCCESS, "Success");
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleRestoreSelected = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No members selected");
      return;
    }
    setLoadingProcess({ ...loadingProcess, resettingRole: true });
    try {
      const data = {
        userIds: listChecked
      };

      const response = await ManageUploader.reStartUploader(data);
      if (response?.data?.statusCode === 200) {
        dispatch(getListMember(handleCreateCondition()));
      }
      setListChecked([]);
      showToast(TOAST_TYPE.SUCCESS, "Success");
      setLoadingProcess({ ...loadingProcess, resettingRole: false });
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
      setLoadingProcess({ ...loadingProcess, resettingRole: false });
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
  };

  const handleCreateExcel = async (transactions) => {
    const fileName = "업로더 관리 - 업로더 관리";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("업로더 관리 - 업로더 관리");

    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "등급",
      "총 Profit",
      "출금 Profit",
      "잔여 Profit",
      "업로드패턴 수",
      "다운로드 수",
      ""
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const columnWidth = [10, 20, 30, 20, 20, 20, 20, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    console.log("transactions", transactions);

    // add value, style cell // body
    transactions.forEach((transaction, index) => {
      ws.getCell(`A${index + 2}`).value = index + 1;
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = transaction?.nickName ?? "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = transaction?.email ?? "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = transaction?.level ?? "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = transaction?.profitTotal ?? "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value = transaction?.totalWithdraw ?? "";
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`G${index + 2}`).value = transaction?.totalBalance ?? "";
      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`H${index + 2}`).value = transaction?.uploadTotal ?? "";
      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`I${index + 2}`).value = transaction?.downloadTotal ?? "";
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`J${index + 2}`).value =
        transaction?.status === 5 ? "업로더 정지" : "";
      ws.getCell(`J${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    // let condition = {};
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = {
      role: "merchant",
      sortField: "nickName",
      sortType: "ASC"
    };
    // if (type) {
    let newLevels = levels;
    if (isDownloadFilter) {
      // condition = {
      //   ...searchTerms,
      //   dateFrom: dateFrom,
      //   dateTo: dateTo,
      //   description: "PayPal Order"
      // };
      if (type !== "rating") {
        condition = {
          ...condition,
          type: type ? type : "all",
          search: search ? search.trim() : ""
        };
      } else {
        newLevels = [...newLevels, search];
      }
      // }
      if (newLevels.length) {
        condition = { ...condition, levels: newLevels.join(", ") };
      }
    }
    // let listTransaction = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageMemberService.getList(condition);
      if (response?.data?.statusCode === 200) {
        const transactions = response?.data?.data ?? [];
        // listTransaction = [...transactions];
        handleCreateExcel(transactions);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = [
      "5%",
      "3%",
      "10%",
      "16%",
      "8%",
      "7%",
      "11%",
      "10%",
      "10%",
      "12%",
      "8%"
    ];

    const headersTitle = [
      "선택",
      "NO.",
      "성명",
      "이메일",
      "등급",
      "총 Profit",
      "출금 Profit",
      "잔여 Profit",
      "업로드패턴 수",
      "다운로드 수",
      ""
    ];

    const tableData = members.map((member, index) => {
      return {
        ...member,
        index: index + 1 + rowsPerPage * page,
        checkbox: (
          <Checkbox
            checked={listChecked.includes(member.id)}
            onChange={(e) => handleSelectUser(e, member.id)}
          />
        ),
        createdAt: member.createdAt ? formatDate(member.createdAt) : "",
        suspendedTime: member.suspendedTime
          ? formatDate(member.suspendedTime)
          : null,
        totalProfit: member?.profitTotal ?? "",
        withdrawalProfit: member?.totalWithdraw ?? "",
        residualProfit: member?.profitTotal ?? "",
        numberOfUploadPatterns: member?.uploadTotal ?? "",
        numberOfDownloads: member?.downloadTotal ?? "",
        restore:
          member?.status === 5 ? (
            <StyledButton
              variant="outlined"
              onClick={() => handleRestore(member.id)}
            >
              복원
            </StyledButton>
          ) : null
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography
            style={{
              fontWeight: "bold",
              backgroundColor: "#003f77",
              color: "white",
              maxWidth: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "32px"
            }}
          >
            업로더 목록
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            {/* <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  기간검색
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid> */}
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
            <Grid item style={{ marginRight: "20px" }}>
              <Grid container alignItems="center">
                <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px"
                    }}
                  >
                    회원등급
                  </Box>
                </FormControl>
                <FormControl>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px"
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem"
                              }
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important"
                          }
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="업로더 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item style={{ fontWeight: "bold" }}>
                <span>총 </span>
                <span style={{ color: "#0468b4" }}>
                  {count ? formatNumber(count) : 0}
                </span>
                <span>명</span>
              </Grid>
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>

                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
                <Grid item sx={{ marginTop: "8px" }}>
                  {members.some((member) => member?.status === 5) ? (
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "2",
                          width: 150
                        }}
                        // color="suspend"
                        onClick={handleRestoreSelected}
                        loading={loadingProcess["resettingRole"] ? true : false}
                        loadingIndicator="복원"
                        variant="contained"
                      >
                        복원
                      </LoadingButton>
                    </ThemeProvider>
                  ) : null}
                </Grid>
                <ThemeProvider theme={theme}>
                  <LoadingButton
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "0",
                      width: "100%"
                    }}
                    sx={{ mt: 1 }}
                    color="suspend"
                    onClick={handleMemberSuspension}
                    loading={loadingProcess["suspending"] ? true : false}
                    loadingIndicator="업로더 정지"
                    variant="contained"
                  >
                    업로더 정지
                  </LoadingButton>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(UploaderList);
