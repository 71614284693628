import URL from "./url";
import axiosClient from "../axiosClient";

class UploadService {
  upload(body = {}) {
    const url = URL.UPLOAD;
    return axiosClient.post(url, body);
  }
}

export default new UploadService();
