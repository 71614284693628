export function UserNameIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#FF6900"
        fillRule="evenodd"
        d="M12 3.333a4.666 4.666 0 0 0-4.666 4.582 4.67 4.67 0 0 0 4.455 4.75c.14-.01.281-.01.422 0a4.666 4.666 0 0 0 4.455-4.75A4.67 4.67 0 0 0 12 3.333ZM12.233 14A5.999 5.999 0 0 0 18 7.891 6.003 6.003 0 0 0 12 2a6 6 0 0 0-5.999 5.89A6.003 6.003 0 0 0 11.778 14l.042.001.043-.004a1.534 1.534 0 0 1 .317.003h.053ZM12 16.666c-5.015 0-7.928 3.348-8.737 4.965a.667.667 0 1 1-1.193-.596c.97-1.938 4.278-5.702 9.93-5.702s8.96 3.764 9.93 5.702a.667.667 0 1 1-1.193.596c-.809-1.617-3.722-4.965-8.737-4.965Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function PasswordIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="#FF6900"
        fillRule="evenodd"
        d="M4.667 10.877a2 2 0 0 1 2-2h11.087a2 2 0 0 1 2 2V20a2 2 0 0 1-2 2H6.667a2 2 0 0 1-2-2v-9.123Zm2-.667a.667.667 0 0 0-.667.667V20c0 .368.298.667.667.667h11.087a.667.667 0 0 0 .667-.667v-9.123a.667.667 0 0 0-.667-.667H6.667Z"
        clipRule="evenodd"
      />
      <path
        fill="#FF6900"
        fillRule="evenodd"
        d="M7.614 6.596a4.596 4.596 0 0 1 9.193 0v2.948h-1.333V6.596a3.263 3.263 0 0 0-6.527 0v2.948H7.614V6.596Z"
        clipRule="evenodd"
      />
      <path
        fill="#FF6900"
        d="M14.175 15.438a1.965 1.965 0 1 1-3.93 0 1.965 1.965 0 0 1 3.93 0Z"
      />
    </svg>
  );
}
