import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import { ManageSponsorService } from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  styled,
  Button,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import {
  formatDate,
  formatNumber,
  addMonths,
  sortListByDate
} from "utils/function";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Excel from "exceljs";
import Popup from "components/Popup";

const listSortBy = [
  { value: "newest", text: "최신순" },
  { value: "oldest", text: "오래된" }
];

const InputLabel = styled(Box)({
  fontSize: "0.7rem",
  height: "30px",
  backgroundColor: "#1565c0",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 2px",
  minWidth: "76px"
});

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& label": {
    paddingLeft: (theme) => theme.spacing(2)
  },
  "& input": {
    width: width ? width : "120px",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    paddingLeft: (theme) => theme.spacing(2.5),
    borderRadius: 0
  }
}));

const defaultSearch = {
  region: "",
  make: "",
  year: "",
  model: "",
  subModel: "",
  series: "",
  code: "",
  nick: "",
  dateFrom: "",
  dateTo: ""
};

function SponsorList({ showToast }) {
  const [listPattern, setListPattern] = useState([]);
  const [sortBy, setSortBy] = useState("newest");
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(addMonths(new Date()));
  const [searchTerms, setSearchTerms] = useState({ ...defaultSearch });
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false
  });

  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);

  useEffect(() => {
    async function fetchData(body = {}) {
      try {
        const response = await ManageSponsorService.getListSponsor(body);
        if (response.status === 201) {
          setListPattern(response?.data?.data?.pats ?? []);
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataListPattern = useMemo(() => {
    if (sortBy === "newest")
      return sortListByDate(listPattern, "createdAt", -1);
    if (sortBy === "oldest") return sortListByDate(listPattern, "createdAt", 1);

    return listPattern;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPattern, sortBy]);

  const handleCreateExcel = async (patterns) => {
    const fileName = "Sponsor AD Item 목록";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("Sponsor AD Item 목록");

    const headersTitle = [
      "Code",
      "Uploader",
      "Pattern code",
      "Profit",
      "Credit",
      "Paypal",
      "Begin",
      "End",
      "등록횟수"
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const columnWidth = [10, 30, 40, 20, 20, 20, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    const generateClass = (region, make, year, model, submodel, side) => {
      const nullValue = (value) => (value ? value : "");
      return `${nullValue(region)} - ${nullValue(make)} - ${nullValue(
        year
      )} - ${nullValue(model)} - ${nullValue(submodel)} - ${nullValue(side)}`;
    };

    const dataExcel = patterns.map((pattern) => {
      return {
        ...pattern,
        patternCode: generateClass(
          pattern.region,
          pattern.make,
          pattern.year,
          pattern.model,
          pattern.submodel,
          pattern.side
        ),
        profit: pattern.profit ? formatNumber(Number(pattern.profit)) : "",
        credit: pattern.credit ? formatNumber(Number(pattern.credit)) : "",
        paypal: pattern.paypal ? formatNumber(Number(pattern.paypal)) : "",
        begin: pattern.start ? formatDate(pattern.start) : "",
        end: pattern.end ? formatDate(pattern.end) : ""
      };
    });

    // add value, style cell // body
    dataExcel.forEach((sponsor, index) => {
      ws.getCell(`A${index + 2}`).value = sponsor.id ? sponsor.id : "";
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = sponsor.nick ? sponsor.nick : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = sponsor.patternCode;
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = sponsor.profit;
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = sponsor.credit;

      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value = sponsor.paypal;
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`G${index + 2}`).value = sponsor.begin;

      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`H${index + 2}`).value = sponsor.end;

      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`I${index + 2}`).value = sponsor.registrations
        ? sponsor.registrations
        : "";
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    let condition = {};
    if (isDownloadFilter) {
      condition = { ...searchTerms, dateFrom: dateFrom, dateTo: dateTo };
    }
    let listPatterns = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageSponsorService.getListSponsor(condition);
      if (response.status === 201) {
        const patterns = response?.data?.data?.pats ?? [];
        listPatterns = [...patterns];
        handleCreateExcel(listPatterns);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    try {
      const condition = {
        ...searchTerms,
        dateFrom: dateFrom,
        dateTo: dateTo
      };
      const response = await ManageSponsorService.getListSponsor(condition);
      if (response.status === 201) {
        setListPattern(response?.data?.data?.pats ?? []);
        setLoadingProcess({ ...loadingProcess, searching: false });
      } else {
        setLoadingProcess({ ...loadingProcess, searching: false });
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, searching: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleReset = async () => {
    setSearchTerms({ ...defaultSearch });
    setDateFrom(new Date());
    setDateTo(addMonths(new Date()));
    setLoadingProcess({ ...loadingProcess, resetting: true });
    try {
      const response = await ManageSponsorService.getListSponsor({});
      if (response.status === 201) {
        setListPattern(response?.data?.data?.pats ?? []);
        setLoadingProcess({ ...loadingProcess, resetting: false });
      } else {
        setLoadingProcess({ ...loadingProcess, resetting: false });
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, resetting: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "region") {
      setSearchTerms((prevState) => ({ ...prevState, region: value }));
    }
    if (key === "make") {
      setSearchTerms((prevState) => ({ ...prevState, make: value }));
    }
    if (key === "year") {
      setSearchTerms((prevState) => ({ ...prevState, year: value }));
    }
    if (key === "model") {
      setSearchTerms((prevState) => ({ ...prevState, model: value }));
    }
    if (key === "submodel") {
      setSearchTerms((prevState) => ({ ...prevState, submodel: value }));
    }
    if (key === "series") {
      setSearchTerms((prevState) => ({ ...prevState, series: value }));
    }
    if (key === "code") {
      setSearchTerms((prevState) => ({ ...prevState, code: Number(value) }));
    }
    if (key === "nick") {
      setSearchTerms((prevState) => ({ ...prevState, nick: value }));
    }
  };

  const renderTable = () => {
    const sizeWidth = [
      "5%",
      "10%",
      "30%",
      "8%",
      "8%",
      "8%",
      "11%",
      "10%",
      "10%"
    ];
    const headersTitle = [
      "Code",
      "Uploader",
      "Pattern code",
      "Profit",
      "Credit",
      "Paypal",
      "Begin",
      "End",
      "등록횟수"
    ];

    const generateClass = (region, make, year, model, submodel, side) => {
      const nullValue = (value) => (value ? value : "");
      return `${nullValue(region)} - ${nullValue(make)} - ${nullValue(
        year
      )} - ${nullValue(model)} - ${nullValue(submodel)} - ${nullValue(side)}`;
    };

    const tableData = dataListPattern.map((pattern) => {
      return {
        ...pattern,
        patternCode: generateClass(
          pattern.region,
          pattern.make,
          pattern.year,
          pattern.model,
          pattern.submodel,
          pattern.side
        ),
        profit: pattern.profit ? formatNumber(Number(pattern.profit)) : "",
        credit: pattern.credit ? formatNumber(Number(pattern.credit)) : "",
        paypal: pattern.paypal ? formatNumber(Number(pattern.paypal)) : "",
        begin: pattern.start ? formatDate(pattern.start) : "",
        end: pattern.end ? formatDate(pattern.end) : ""
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column">
        <Grid item>검색필터</Grid>
        <Typography>회원 정보</Typography>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>등록자명</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  height="13px"
                  value={searchTerms["nick"]}
                  onChange={(e) => {
                    handleChangeSearchTerms("nick", e.target.value);
                  }}
                ></StyledTextField>
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>등록기간</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        size="small"
                        width="100px"
                        height="13px"
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        size="small"
                        width="100px"
                        height="13px"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>Code</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  value={searchTerms["code"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("code", e.target.value)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Typography>패턴 정보</Typography>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <InputLabel>Region</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  value={searchTerms["region"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("region", e.target.value)
                  }
                />
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>Maker</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  value={searchTerms["make"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("make", e.target.value)
                  }
                />
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>Year</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  value={searchTerms["year"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("year", e.target.value)
                  }
                />
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>Model</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  value={searchTerms["model"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("model", e.target.value)
                  }
                />
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>Sub Model</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  value={searchTerms["submodel"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("submodel", e.target.value)
                  }
                />
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <InputLabel>SERIES</InputLabel>
                <StyledTextField
                  size="small"
                  width="100px"
                  value={searchTerms["series"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("series", e.target.value)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="Sponsor AD Item 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography sx={{ mr: 2 }}>정렬기준</Typography>
                  <Select
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      borderRadius: "0",
                      height: "30px",
                      width: "120px"
                    }}
                  >
                    {listSortBy.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel파일로 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(SponsorList);
