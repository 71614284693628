import React from "react";
import { useToast } from "hooks";

function withToast(Component) {
  return (props) => {
    const showToast = useToast();
    return <Component {...props} showToast={showToast} />;
  };
}

export default withToast;
