import { ManageMemberService } from "services";
import { memberConstants } from "../constants";

export const getListMember = (condition = { page: 1, limit: 5 }) => {
  return async (dispatch) => {
    try {
      const res = await ManageMemberService.getList(condition);
      if (res?.data?.statusCode === 200) {
        dispatch({
          type: memberConstants.GET_MEMBERS_SUCCESS,
          payload: {
            members: res?.data?.data ?? [],
            count: res?.data?.count ?? 0
          }
        });
      } else {
        dispatch({
          type: memberConstants.GET_MEMBERS_FAIL,
          payload: res?.data?.message ?? ""
        });
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      dispatch({
        type: memberConstants.GET_MEMBERS_FAIL,
        payload: message
      });
    }
  };
};

export const updateMemberSuspension = (data, condition) => {
  return (dispatch) => {
    dispatch({
      type: memberConstants.UPDATE_MEMBER,
      payload: data
    });
    dispatch(getListMember(condition));
  };
};

export const updateMemberCredit = (data, condition) => {
  return (dispatch) => {
    dispatch({
      type: memberConstants.UPDATE_MEMBER_CREDIT,
      payload: data
    });
    dispatch(getListMember(condition));
  };
};

export const updateMerchantRight = (data) => {
  return (dispatch) => {
    dispatch({
      type: memberConstants.UPDATE_MERCHANT_RIGHT,
      payload: data
    });
    dispatch(getListMember({ merchant: true }));
  };
};

export const resetMembers = () => {
  return (dispatch) => {
    dispatch({
      type: memberConstants.RESET_MEMBERS
    });
  };
};
