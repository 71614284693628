import { creditConstants } from "../constants";
import { ManageCreditService } from "services";

export const getListCredit = () => {
  return async (dispatch, getState) => {
    try {
      const res = await ManageCreditService.getListCredit();
      if (res?.data?.statusCode === 200) {
        const { credit = {} } = getState();
        const { credits = [] } = credit;
        const products = res?.data?.data ?? [];
        const names = new Set(products.map((p) => p.name));
        dispatch({
          type: creditConstants.GET_CREDITS_SUCCESS,
          payload: [...products, ...credits.filter((c) => !names.has(c.name))]
        });
      } else {
        dispatch({
          type: creditConstants.GET_CREDITS_FAIL,
          payload: res?.data?.message ?? "Error"
        });
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      dispatch({
        type: creditConstants.GET_CREDITS_FAIL,
        payload: errorMessage
      });
    }
  };
};

export const updateCredit = (data) => {
  return (dispatch) => {
    dispatch({
      type: creditConstants.UPDATE_CREDIT,
      payload: data
    });
    dispatch(getListCredit());
  };
};
