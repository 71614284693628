import URL from "./url";
import axiosClient from "../axiosClient";

class ManageCouponService {
  getCouponList(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_COUPON_LIST + query;
    return axiosClient.get(url);
  }
  addCoupon(body = {}) {
    const url = URL.ADD_COUPON;
    return axiosClient.post(url, body);
  }
  getCoupon(id) {
    const url = URL.GET_COUPON + "/" + id;
    return axiosClient.get(url);
  }
  deleteCoupon(body = {}) {
    const url = URL.DELETE_COUPON;
    return axiosClient.post(url, body);
  }
  updateCoupon(id, body = {}) {
    const url = URL.UPDATE_COUPON + "/" + id;
    return axiosClient.put(url, body);
  }
  getCouponUserList(id, condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_COUPON_USER_LIST + "/" + id + query;
    return axiosClient.get(url);
  }
  getCouponUserList2(id) {
    const url = URL.GET_COUPON_USER_LIST_2 + "/" + id;
    console.log("url", id);
    return axiosClient.get(url, id);
  }
  issueCoupon(body = []) {
    const url = URL.ISSUE_COUPON;
    return axiosClient.post(url, body);
  }
}

export default new ManageCouponService();
