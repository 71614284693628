import { combineReducers } from "redux";
import authReducer from "./auth";
import memberReducer from "./member";
import creditReducer from "./credit";
import orderReducer from "./order";
import couponReducer from "./coupon";
import emailReducer from "./email";
import bannerReducer from "./banner";
import adminReducer from "./admin";

const combineReducer = combineReducers({
  auth: authReducer,
  member: memberReducer,
  credit: creditReducer,
  order: orderReducer,
  coupon: couponReducer,
  email: emailReducer,
  banner: bannerReducer,
  admin: adminReducer,
});

export default combineReducer;
