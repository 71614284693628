const URL = Object.freeze({
  GET_ULTRAFIT_LIST: "/ultrafit-shop/admin",
  SET_ULTRAFIT: "/admin/ultrafitCreateUpdate",
  CREATE_ULTRAFIT: "/ultrafit-shop",
  UPDATE_ULTRAFIT: "/ultrafit-shop",
  DELETE_ULTRAFIT: "/ultrafit-shop/delete",
  UPDATE_ULTRAFIT_STATUS: "/ultrafit-shop/status",
  GET_ULTRAFIT: "/ultrafit-shop",
  GET_ORDER: "/orders",
  UPDATE_ORDER_STATUS: "/orders/status",
  UPDATE_ORDER: "/orders",
  GET_SHIPPINGS_INFO_LIST: "/shippings/info",
  CREATE_SHIPPINGS_INFO: "/shippings/info",
  UPDATE_SHIPPINGS_INFO: "/shippings/info",
  DELETE_SHIPPINGS_INFO: "/shippings/info",
  GET_ACTIVATION_NUMBER: "/ultrafit-shop/activation-numbers",
  CREATE_LIST_ACTIVATION_NUMBER: "/ultrafit-shop/import/activation-numbers",
  DELETE_ACTIVATION_NUMBER: "/ultrafit-shop/delete/activation-numbers"
});

export default URL;
