import URL from "./url";
import axiosClient from "../axiosClient";
class ManageUltrafitShop {
  getUltrafitList(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_ULTRAFIT_LIST + query;
    return axiosClient.get(url);
  }
  // setUltrafit(body = {}) {
  //   const url = URL.SET_ULTRAFIT;
  //   return axiosClient.post(url, body);
  // }
  getUltrafitById(id) {
    const url = URL.GET_ULTRAFIT + "/" + id;
    return axiosClient.get(url);
  }

  createUltrafit(body = {}) {
    const url = URL.CREATE_ULTRAFIT;
    return axiosClient.post(url, body);
  }
  deleteUltrafit(body) {
    const url = URL.DELETE_ULTRAFIT;
    return axiosClient.post(url, body);
  }

  updateUltrafit(id, body = {}) {
    const url = URL.UPDATE_ULTRAFIT + "/" + id;
    return axiosClient.put(url, body);
  }
  updateUltrafitStatus(body = {}) {
    const url = URL.UPDATE_ULTRAFIT_STATUS;
    return axiosClient.post(url, body);
  }

  getOrder(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_ORDER + query;
    return axiosClient.get(url);
  }
  updateOrderStatus(id, body = {}) {
    const url = URL.UPDATE_ORDER_STATUS + "/" + id;
    return axiosClient.put(url, body);
  }
  updateOrder(id, body = {}) {
    const url = URL.UPDATE_ORDER + "/" + id;
    return axiosClient.put(url, body);
  }
  getActivationNumber(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_ACTIVATION_NUMBER + query;
    return axiosClient.get(url);
  }
  deleteActivationNumber(body = {}) {
    const url = URL.DELETE_ACTIVATION_NUMBER;
    return axiosClient.post(url, body);
  }
  createListActivationNumber(body = {}) {
    const url = URL.CREATE_LIST_ACTIVATION_NUMBER;
    return axiosClient.post(url, body);
  }
  getShippingsInfo(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_SHIPPINGS_INFO_LIST + query;
    return axiosClient.get(url);
  }
  createShippingInfo(body = {}) {
    const url = URL.CREATE_SHIPPINGS_INFO;
    return axiosClient.post(url, body);
  }
  updateShippingInfo(id, body = {}) {
    const url = URL.UPDATE_SHIPPINGS_INFO + "/" + id;
    return axiosClient.put(url, body);
  }
  deleteShippingInfo(id) {
    const url = URL.DELETE_SHIPPINGS_INFO + "/" + id;
    return axiosClient.delete(url);
  }
}

export default new ManageUltrafitShop();
