import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import {
  ManageUltrafitShopService,
  ManagePatternService,
  UploadService
} from "services";
import { withToast, withParam } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button,
  Select,
  MenuItem
} from "@mui/material";
import { generateImageSrc, listGroupBy, sortList } from "utils/function";
import FieldArray from "./components/FieldArray";
// import Editor from "./components/Editor";
import Introduction from "./components/Introduction";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& .MuiOutlinedInput-root": {
    width: width
  },
  "& input": {
    width: width ? width : "340px",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

const StyledTextFieldHeight = styled(TextField)(({ height, width }) => ({
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  },
  "& .MuiOutlinedInput-input": {
    paddingTop: "12px",
    paddingBottom: "12px"
  }
}));

const StyledSelect = styled(Select)(({ width }) => ({
  borderRadius: "0",
  height: "33px",
  width: width ? width : "200px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

const unitType = [
  {
    value: "fixed_price",
    text: "$"
  },
  {
    value: "percent",
    text: "%"
  }
];

const unitAmountType = [
  {
    value: "$",
    text: "$"
  },
  {
    value: "EA",
    text: "EA"
  }
];

function ProductEdit({ params, showToast }) {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [selectedFile, setSelectedFile] = useState();
  const [selectedUnit, setSelectedUnit] = useState("fixed_price");
  const [amountUnit, setAmountUnit] = useState("$");

  const [selected1Deft, setSelected1Deft] = useState("");
  const [selected2Deft, setSelected2Deft] = useState("");
  const [selected3Deft, setSelected3Deft] = useState("");

  const [introduction, setIntroduction] = useState("");
  const [listCategory, setListCategory] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      product: {
        name: "",
        // price: "",
        inventory: "",
        discount: "",
        // specialNumber: "",
        amount: "",
        categoryId: "",
        categoryId2: "",
        categoryId3: "",
        discountUnit: "fixed_price",
        // options: [{ name: "", price: "", weight: "", inventory: "" }]
        options: [{ inches: "", amount: "", weight: "" }]
      },
      mode: "all"
    }
  });

  useEffect(() => {
    Promise.all([
      ManageUltrafitShopService.getUltrafitById(params.id),
      ManagePatternService.getCategoryList()
    ])
      .then((responses) => {
        return Promise.all(
          responses.map(function (response) {
            return response;
          })
        );
      })
      .then((data) => {
        const [responseProduct = null, responseCategory = null] = data;
        if (responseProduct?.data?.statusCode === 200) {
          const {
            image = null,
            name = "",
            category1Id = "",
            category2Id = "",
            category3Id = "",
            price = "",
            discount = "",
            discountUnit = "",
            introduction = "",
            specialNumber = "",
            options = [
              {
                inches: "",
                amount: "",
                weight: ""
              }
            ]
          } = responseProduct?.data?.data ?? {};
          const newOptions = options.map((option) => ({
            inches: option.inches,
            amount: option.amount,
            weight: option.weight
          }));
          setSelectedFile(image);
          setSelectedUnit(discountUnit);
          setValue("product.name", name);
          setSelected1Deft(category1Id);
          setSelected2Deft(category2Id);
          setSelected3Deft(category3Id);
          setIntroduction(introduction);
          setValue("product.categoryId", category1Id);
          setValue("product.discount", discount);
          setValue("product.categoryId2", category2Id);
          setValue("product.categoryId3", category3Id);
          setValue("product.discountUnit", discountUnit);
          // setValue("product.price", Number(price));
          // setValue("product.specialNumber", Number(specialNumber));
          if (price) {
            setValue("product.amount", Number(price));
            setAmountUnit("$");
          } else {
            setValue("product.amount", Number(specialNumber));
            setAmountUnit("EA");
          }
          setValue(
            "product.options",
            !!newOptions.length
              ? newOptions
              : [
                  {
                    inches: "",
                    amount: "",
                    weight: ""
                  }
                ]
          );
        } else {
          const errorData = responseProduct?.data?.msg ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }

        if (responseCategory?.data?.statusCode === 200) {
          // const categories = responseCategory?.data?.category ?? [];
          // setListCategory({ ...listGroupBy(categories, "dept") });
          const listCategoryGroupByType = listGroupBy(
            responseCategory?.data?.data ?? [],
            "type"
          );
          const categoryFirstColumn = listCategoryGroupByType?.[
            "depth"
          ]?.filter((category) => category.column === "depth_1");
          setListCategory(categoryFirstColumn ?? []);
        } else {
          const errorData = responseCategory?.data?.msg ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        const errorStatusText = error.response?.statusText ?? "";
        const errorDataMessage = error?.response?.data?.message ?? "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list2Dept = useMemo(() => {
    if (listCategory.length > 0 && selected1Deft) {
      const part1Deft = listCategory.find(
        (category) => Number(category.id) === Number(selected1Deft)
      );
      return part1Deft?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selected1Deft]);

  const list3Dept = useMemo(() => {
    if (listCategory.length > 0 && selected1Deft && selected2Deft) {
      const part1Deft = listCategory.find(
        (category) => Number(category.id) === Number(selected1Deft)
      );
      const subPart1Deft = part1Deft?.subCategory ?? [];
      const part2Deft = subPart1Deft.find(
        (category) => Number(category.id) === Number(selected2Deft)
      );
      return part2Deft?.subCategory ?? [];
    }
    return [];
  }, [listCategory, selected1Deft, selected2Deft]);

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    let fileName = "";
    try {
      const form = new FormData();
      form.append("file", e.target.files[0]);
      const response = await UploadService.upload(form);
      if (response.status === 200) {
        fileName = response?.data?.data ?? "";
      }
    } catch (error) {
      setSaving(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
    setSelectedFile(fileName);
  };

  const onSubmit = async (data) => {
    setSaving(true);
    const options = data?.product?.options
      .filter((option) => !!option.inches)
      .map((item) => ({
        inches: item.inches,
        amount: Number(item.amount),
        weight: Number(item.weight)
      }));
    let body = {
      // ...data.product,
      // price: Number(data.product.price),
      name: data.product.name,
      // discount: Number(data.product.discount),
      // specialNumber: Number(data.product.specialNumber),
      image: selectedFile,
      category1Id: selected1Deft,
      // category2Id: selected2Deft,
      // category3Id: selected3Deft,
      introduction: introduction,
      options: options
    };
    if (data.product.discount) {
      body = { ...body, discount: Number(data.product.discount) }
    }
    if (selected2Deft) {
      body = { ...body, category2Id: selected2Deft };
    }
    if (selected3Deft) {
      body = { ...body, category3Id: selected3Deft };
    }
    if (data.product.amount) {
      if (amountUnit === "$") {
        body = { ...body, price: Number(data.product.amount), specialNumber: 0 };
      }
      if (amountUnit === "EA") {
        body = { ...body, specialNumber: Number(data.product.amount), price: 0 };
      }
    }
    console.log(body)

    try {
      const response = await ManageUltrafitShopService.updateUltrafit(
        params.id,
        {
          ...body
        }
      );
      if (response?.data?.statusCode === 200) {
        setSaving(false);
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        setSaving(false);
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setSaving(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response?.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const getFileName = (url) => {
    const parts = url.split("/");

    const fileName = parts[parts.length - 1];

    const fileParts = fileName.split("-");
    const actualFileName = fileParts[fileParts.length - 1];

    return actualFileName ? decodeURIComponent(actualFileName) : "";
  };

  return (
    <Layout header="상품관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>제품 편집</Grid>
                <Grid item>
                  <LoadingButton
                    loading={saving}
                    loadingIndicator="Save..."
                    variant="contained"
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "0",
                      height: "46px"
                    }}
                    type="submit"
                  >
                    상품 수정하기
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container style={{ flexWrap: "nowrap", gap: "30px" }}>
                <Grid item>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{
                        width: "350px",
                        height: "300px",
                        border: "1px solid",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {selectedFile ? (
                        <img
                          src={selectedFile}
                          alt=""
                          width="320px"
                          style={{
                            maxHeight: "290px",
                            maxWidth: "330px",
                            objectFit: "cover"
                          }}
                        />
                      ) : null}
                    </Box>
                    <Box
                      sx={{ mt: 1 }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "nowrap",
                        gap: "5px"
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "0"
                        }}
                      >
                        파일 선택
                        <input
                          type="file"
                          hidden
                          onChange={(e) => handleSelectFile(e)}
                        />
                      </Button>
                      {selectedFile ? getFileName(selectedFile) : "선택없음"}
                      <Typography></Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "400px"
                      }}
                    >
                      <Box sx={{ mb: 1 }} style={{ display: "flex" }}>
                        {/* <AcUnitIcon fontSize="small" /> */}
                        <Typography>※상품명을 입력해주세요.</Typography>
                      </Box>
                      <StyledTextField
                        fullWidth={false}
                        height="0px"
                        width="370px"
                        {...register("product.name", {
                          required: "Name is required"
                        })}
                        error={!!errors?.product?.name}
                        placeholder="상품명"
                      />
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ mt: 1 }} style={{ display: "flex" }}>
                        {/* <AcUnitIcon fontSize="small" /> */}
                        <Typography>
                          ※상품의 카테고리를 선택해주세요.
                        </Typography>
                      </Box>
                      <Box
                        sx={{ mt: 1 }}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography>1 Dept: </Typography>
                        <Select
                          // {...register("product.dept1")}
                          // value={getValues().product.dept1}
                          // onChange={(e) => {
                          //   setSelected1Deft(e.target.value);
                          //   setValue("product.dept1", e.target.value);
                          // }}
                          MenuProps={{
                            disableScrollLock: true
                          }}
                          value={selected1Deft}
                          {...register("product.categoryId", {
                            required: "1 Dept is required"
                          })}
                          error={!!errors?.product?.categoryId}
                          onChange={(e) => {
                            setSelected1Deft(e.target.value);
                            // setValue("product.dept2", e.target.value);
                          }}
                          sx={{ ml: 2 }}
                          style={{
                            borderRadius: "0",
                            height: "30px",
                            width: "300px"
                          }}
                        >
                          {listCategory && !!listCategory.length
                            ? sortList(listCategory, "order").map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.category}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </Box>
                      <Box
                        sx={{ mt: 1 }}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography>2 Dept: </Typography>
                        <Select
                          // {...register("product.dept2")}
                          MenuProps={{
                            disableScrollLock: true
                          }}
                          value={selected2Deft}
                          {...register(
                            "product.categoryId2"
                            // , {
                            //   required: "2 Dept is required"
                            // }
                          )}
                          // error={!!errors?.product?.categoryId2}
                          onChange={(e) => {
                            setSelected2Deft(e.target.value);
                            // setValue("product.dept2", e.target.value);
                          }}
                          sx={{ ml: 2 }}
                          style={{
                            borderRadius: "0",
                            height: "30px",
                            width: "300px"
                          }}
                        >
                          {list2Dept && !!list2Dept.length
                            ? sortList(list2Dept, "order").map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.category}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </Box>
                      <Box
                        sx={{ mt: 1 }}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography>3 Dept: </Typography>
                        <Select
                          // {...register("product.deptaaa")}
                          // value={getValues().product.deptaaa}
                          // onChange={(e) => {
                          //   console.log(" e.target.value", e.target.value);
                          //   setValue("product.deptaaa", e.target.value);
                          // }}
                          MenuProps={{
                            disableScrollLock: true
                          }}
                          sx={{ ml: 2 }}
                          style={{
                            borderRadius: "0",
                            height: "30px",
                            width: "300px"
                          }}
                          value={selected3Deft}
                          {...register(
                            "product.categoryId3"
                            // , {
                            // required: "2 Dept is required"
                            // }
                          )}
                          error={!!errors?.product?.categoryId3}
                          onChange={(e) => {
                            setSelected3Deft(e.target.value);
                            // setValue("product.dept2", e.target.value);
                          }}
                        >
                          {list3Dept && !!list3Dept.length
                            ? sortList(list3Dept, "order").map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.category}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </Box>
                    </Box>
                    <Box
                      sx={{ mt: 2 }}
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: "20px"
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        {/* <Typography style={{ fontSize: "0.7rem" }}>
                          Price
                        </Typography>
                        <StyledTextFieldHeight
                          height="0px"
                          width="200px"
                          {...register("product.price")}
                        ></StyledTextFieldHeight> */}
                        <Typography>혜택조건</Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <StyledTextField
                            // inputProps={{
                            //   step: 1,
                            //   min: 0,
                            //   type: "number"
                            // }}
                            type="text"
                            onInput={(evt) => {
                              let inputValue = evt.target.value;
                              evt.target.value = inputValue.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            height="0px"
                            width="150px"
                            placeholder="숫자만 입력 가능"
                            // {...register("product.price")}
                            {...register("product.amount", 
                            // {
                            //   required: "amount is required"
                            // }
                            )}
                            error={!!errors?.product?.amount}
                            // type="number"
                          />
                          {/* <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid #c4c4c4",
                              height: "33px",
                              width: "40px"
                            }}
                          >
                            $
                          </Box> */}
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end"
                        }}
                      >
                        <StyledSelect
                          MenuProps={{
                            disableScrollLock: true
                          }}
                          height="25px"
                          width="70px"
                          // defaultValue={""}
                          value={amountUnit}
                          // {...register("product.discountUnit", {
                          //   required: "Unit is required"
                          // })}
                          onChange={(e) => {
                            setAmountUnit(e.target.value);
                            // setValue("product.dept2", e.target.value);
                          }}
                        >
                          {unitAmountType.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end"
                        }}
                      >
                        <Typography sx={{ mb: "3px" }}>이상 구매시</Typography>
                      </Box>
                      {/* <Box
                        style={{
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <Typography style={{ fontSize: "0.7rem" }}>
                          재고*
                        </Typography>
                        <StyledTextFieldHeight
                          {...register("product.inventory")}
                          height="0px"
                          width="50px"
                        ></StyledTextFieldHeight>
                      </Box> */}
                      {/* <Box
                        style={{
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <Typography>할인</Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "33px"
                          }}
                        >
                          <StyledTextField
                            // {...register("product.discount")}
                            {...register("product.specialNumber")}
                            inputProps={{
                              step: 1,
                              min: 0,
                              type: "number"
                            }}
                            // type="number"
                            height="0px"
                            width="150px"
                            placeholder="숫자만 입력 가능"
                          />
                          <Typography sx={{ ml: 1 }}>개 구매시</Typography>
                        </Box>
                      </Box> */}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end"
                        }}
                      >
                        <StyledTextField
                          // {...register("product.discount")}
                          {...register("product.discount", 
                          // {
                          //   required: "discount is required"
                          // }
                          )}
                          // inputProps={{
                          //   step: 0.0001,
                          //   min: 0,
                          //   type: "number"
                          // }}
                          type="text"
                          onInput={(evt) => {
                            let inputValue = evt.target.value;
                            inputValue = inputValue.replace(/[^0-9.]/g, "");
                            if (
                              inputValue.indexOf(".") !==
                                inputValue.lastIndexOf(".") &&
                              inputValue.endsWith(".")
                            ) {
                              inputValue = inputValue.slice(0, -1);
                            }
                            evt.target.value = inputValue;
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          error={!!errors?.product?.discount}
                          // type="number"
                          height="0px"
                          width="150px"
                          placeholder="숫자만 입력 가능"
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end"
                        }}
                      >
                        <StyledSelect
                          MenuProps={{
                            disableScrollLock: true
                          }}
                          height="25px"
                          width="70px"
                          value={selectedUnit}
                          {...register("product.discountUnit", {
                            required: "Unit is required"
                          })}
                          onChange={(e) => {
                            setSelectedUnit(e.target.value);
                            // setValue("product.dept2", e.target.value);
                          }}
                        >
                          {unitType.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end"
                        }}
                      >
                        <Typography sx={{ mb: "3px" }}>할인</Typography>
                      </Box>
                    </Box>
                    {/*Option */}
                    <Typography sx={{ mt: 2 }}>옵션명</Typography>
                    <Box
                      sx={{ mt: 1 }}
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: "20px"
                      }}
                    >
                      <FieldArray
                        {...{
                          control,
                          register,
                          // defaultValues,
                          getValues,
                          setValue,
                          errors
                        }}
                      />
                      <Typography style={{ fontSize: "0.6rem" }}>
                        *표시는 관리자에게만 보입니다.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>상품소개</Grid>
            <Grid item>
              {/* Editor */}
              {/* <Editor data={introduction} setData={setIntroduction} /> */}
              <Introduction data={introduction} setData={setIntroduction} />
            </Grid>
          </Grid>
        </form>
      )}
    </Layout>
  );
}

export default withParam(withToast(ProductEdit));
