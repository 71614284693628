import { CREDIT_PRODUCT_CATEGORY } from "utils/constants";
import { creditConstants } from "../constants";

const initState = {
  loading: true,
  credits: [
    {
      name: CREDIT_PRODUCT_CATEGORY.STANDARD,
      cost: 0,
      recharge: 0,
      bonus: 0,
      id: null
    },
    {
      name: CREDIT_PRODUCT_CATEGORY.PLUS,
      cost: 0,
      recharge: 0,
      bonus: 0,
      id: null
    },
    {
      name: CREDIT_PRODUCT_CATEGORY.PREMIUM,
      cost: 0,
      recharge: 0,
      bonus: 0,
      id: null
    },
    {
      name: CREDIT_PRODUCT_CATEGORY.GOLD,
      cost: 0,
      recharge: 0,
      bonus: 0,
      id: null
    }
  ],
  error: null
};

const creditReducer = (state = initState, action) => {
  switch (action.type) {
    case creditConstants.GET_CREDITS_SUCCESS:
      return {
        ...state,
        loading: false,
        credits: action.payload,
        error: null
      };
    case creditConstants.GET_CREDITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case creditConstants.UPDATE_CREDIT: {
      const { name, cost, recharge, bonus } = action.payload;
      const { credits } = state;
      const newCredit = credits.map((credit) => {
        if (credit.name === name) {
          return { ...credit, cost: cost, recharge: recharge, bonus: bonus };
        }
        return credit;
      });

      return {
        ...state,
        credits: newCredit
      };
    }
    default:
      return state;
  }
};

export default creditReducer;
