import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { withToast, withQuery } from "HOC";
import { ManageCreditService } from "services";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import { Button, Grid, Typography } from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import TableData from "./components/TableData";
import { useNavigate } from "react-router-dom";
import { formatDate, formatDateSearch, formatNumber } from "utils/function";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import Popup from "./components/Popup";
import Excel from "exceljs";

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "history", text: "내역" }
];

const filterSearch = (string) => {
  return string
    .trim()
    .replace(/[\s,.-]+/gi, " ")
    .split(" ")
    .map((el) => el?.toLowerCase())
    .filter(Boolean);
};

const isMatchSearchCondition = (keyword, search) => {
  const transformSearch = filterSearch(search || "");
  const keywordList = (typeof keyword === "string" ? [keyword] : keyword).map(
    (el) => el?.toLowerCase()
  );

  if (
    transformSearch
      .filter(Boolean)
      .some((item) => keywordList.some((kw) => kw.includes(item)))
  ) {
    return true;
  }

  if (
    keywordList
      .filter(Boolean)
      .some((item) => transformSearch.some((ts) => ts.includes(item)))
  ) {
    return true;
  }

  return false;
};

const mapActivityEnToKo = (activity) => {
  switch (activity) {
    case "credit_charge":
      return "Credit Charge";
    case "plotting":
      return "플로팅";
    case "credit_ultrafit_shop":
      return "Shop구매";
    case "charge_bonus":
      return "충전보너스";
    case "code_bonus":
      return "정품인증";
    case "credit_charge_refund":
      return "Credit Charge 환불";
    case "credit_ultrafit_shop_refund":
      return "Shop구매 환불";
    case "reward_bonus":
      return "리워드";
    case "review":
      return "리뷰";
    case "buy_premium":
      return "Edit&Plot Subscription";
    default:
      return "";
  }
};

const mapActivityKoToEn = (activity) => {
  const newActivity = activity ? activity.trim() : "";
  const result = [];
  if (
    isMatchSearchCondition(["Credit Charge", "Credit", "Charge"], newActivity)
  ) {
    result.push("credit_charge");
  }
  if (isMatchSearchCondition(["플로팅", "플로", "로팅"], newActivity)) {
    result.push("plotting");
  }
  if (isMatchSearchCondition(["Shop구매", "Shop", "구매"], newActivity)) {
    result.push("credit_ultrafit_shop");
  }
  if (isMatchSearchCondition(["충전보너스", "충전", "보너스"], newActivity)) {
    result.push("charge_bonus");
  }
  if (isMatchSearchCondition(["정품인증", "정품", "인증"], newActivity)) {
    result.push("code_bonus");
  }
  if (
    isMatchSearchCondition(
      ["Credit Charge 환불", "Credit", "Charge", "Credit Charge", "환불"],
      newActivity
    )
  ) {
    result.push("credit_charge_refund");
  }
  if (
    isMatchSearchCondition(
      ["Shop구매 환불", "Shop", "구매", "환불"],
      newActivity
    )
  ) {
    result.push("credit_ultrafit_shop_refund");
  }
  if (isMatchSearchCondition(["리워드", "리워", "워드"], newActivity)) {
    result.push("reward_bonus");
  }
  if (isMatchSearchCondition(["리뷰"], newActivity)) {
    result.push("review");
  }
  if (
    isMatchSearchCondition(
      ["Edit&Plot Subscription", "Edit", "Plot", "Subscription"],
      newActivity
    )
  ) {
    result.push("buy_premium");
  }
  return result.filter(Boolean).join(",");
};

const activitiesUseCredit = ["plotting", "credit_ultrafit_shop"];

function CreditUsageHistory({ showToast, searchParams }) {
  const [loading, setLoading] = useState(true);
  const [listCreditHistory, setCreditHistory] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [dateTo, setDateTo] = useState(null);
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: ""
  });

  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const navigate = useNavigate();

  const handleCreateCondition = (isSearching = false, isResetting = false) => {
    const { type = "", search = "" } = searchTerms;
    let condition = { userId: searchParams.id };
    if (isSearching || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1
        ? { ...condition, page: 1, limit: rowsPerPage }
        : { ...condition };
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearching ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    if (type !== "history" && type !== "all" && type !== "") {
      condition = {
        ...condition,
        type: type ? type : "all",
        search: search ? search.trim() : ""
      };
    } else if (
      (type === "all" || type === "") &&
      search &&
      search.trim() &&
      mapActivityKoToEn(search.trim())
    ) {
      condition = {
        ...condition,
        activity: mapActivityKoToEn(search.trim())
      };
    } else if (
      (type === "all" || type === "") &&
      search &&
      search.trim() &&
      !mapActivityKoToEn(search.trim())
    ) {
      condition = {
        ...condition,
        type: "all",
        search: search ? search.trim() : ""
      };
    } else if (search && search.trim()) {
      condition = {
        ...condition
      };
      if (mapActivityKoToEn(search.trim())) {
        condition = {
          ...condition,
          activity: mapActivityKoToEn(search.trim())
        };
      }
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearch(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearch(dateTo, "YYYY-MM-DD HH:mm:ss")
      };
    }

    return condition;
  };

  async function fetchData(isSearching = false, isResetting = false) {
    try {
      const response = await ManageCreditService.getCreditHistory(
        handleCreateCondition(isSearching, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setCreditHistory(response?.data ? response.data?.data : [] ?? []);
        setCount(response?.data?.count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    // await dispatch(
    //   getListMember({
    //     ...searchTerms
    //   })
    // );
    // setPage(0);
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };
  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "" });
    setDateFrom(null);
    setDateTo(null);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
  };

  const handleCreateExcel = async (transactions) => {
    const fileName = "Credit 관리 - 충전 완료 목록 - Credit 사용 내역";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet(
      "Credit 관리 - 충전 완료 목록 - Credit 사용 내역"
    );

    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      // "사용일",
      "날짜",
      "내역",
      "충전 Credit",
      "적립 Credit",
      "사용 Credit",
      "잔여 Credit"
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const columnWidth = [10, 20, 30, 20, 20, 20, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // const calculateFee = (netAmount, amount) => {
    //   if (!netAmount || !amount) return "";
    //   return Number(netAmount) - Number(amount);
    // };

    const calculatePayment = (payment, amount) => {
      if (payment) return payment;
      if (amount) return amount;
      return "";
    };

    // add value, style cell // body
    transactions.forEach((transaction, index) => {
      const charge = transaction?.netAmount;
      const payment = calculatePayment(
        transaction?.payment,
        transaction?.amount
      );
      const deposited = transaction?.amount;
      ws.getCell(`A${index + 2}`).value = index + 1;
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = transaction.createdAt
        ? formatDate(transaction.createdAt)
        : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = transaction?.user?.nickName ?? "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = transaction?.user?.email ?? "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = mapActivityEnToKo(
        transaction?.activity ?? ""
      );
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value =
        transaction?.activity === "credit_charge" ? transaction?.amount : 0;
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`G${index + 2}`).value =
        transaction?.activity !== "credit_charge"
          ? transaction?.amount > 0
            ? transaction?.amount
            : 0
          : 0;
      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`H${index + 2}`).value =
        transaction?.amount < 0 ? Math.abs(transaction?.amount) : 0;
      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`I${index + 2}`).value = transaction?.user?.totalBalance ?? 0;
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    const { type = "", search = "" } = searchTerms;
    let condition = { userId: searchParams.id };
    if (isDownloadFilter) {
      if (type !== "history" && type !== "all" && type !== "") {
        condition = {
          ...condition,
          type: type ? type : "all",
          search: search ? search.trim() : ""
        };
      } else if (
        (type === "all" || type === "") &&
        search &&
        search.trim() &&
        mapActivityKoToEn(search.trim())
      ) {
        condition = {
          ...condition,
          activity: mapActivityKoToEn(search.trim())
        };
      } else if (
        (type === "all" || type === "") &&
        search &&
        search.trim() &&
        !mapActivityKoToEn(search.trim())
      ) {
        condition = {
          ...condition,
          type: "all",
          search: search ? search.trim() : ""
        };
      } else if (search && search.trim()) {
        condition = {
          ...condition
        };
        if (mapActivityKoToEn(search.trim())) {
          condition = {
            ...condition,
            activity: mapActivityKoToEn(search.trim())
          };
        }
      }
      if (dateFrom) {
        condition = {
          ...condition,
          from: formatDateSearch(dateFrom, "YYYY-MM-DD HH:mm:ss")
        };
      }

      if (dateTo) {
        condition = {
          ...condition,
          to: formatDateSearch(dateTo, "YYYY-MM-DD HH:mm:ss")
        };
      }
    }
    let listTransaction = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageCreditService.getCreditHistory(condition);
      if (response?.data?.statusCode === 200) {
        const transactions = response?.data ? response.data?.data : [] ?? [];
        listTransaction = [...transactions];
        handleCreateExcel(listTransaction);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleBackToList = () => {
    navigate("/credit-management/charging-complete-list");
  };
  const handleBackToDetail = () => {
    navigate({
      pathname: "/credit-management/charging-complete-list/detail",
      search: `?code=${searchParams.code}`
    });
  };

  const renderTable = () => {
    const sizeWidth = [
      "5%",
      "10%",
      "15%",
      "10%",
      "10%",
      "10%",
      "10%",
      "10%",
      "10%",
      "10%"
    ];

    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      // "사용일",
      "날짜",
      "내역",
      "패턴",
      "충전 Credit",
      "적립 Credit",
      "사용 Credit",
      "잔여 Credit"
    ];

    const genProductCode = (...rest) => {
      rest = rest.filter((item) => !!item);
      return rest.join("-");
    };

    const genDeposit = (netAmount, orderId) => {
      if (orderId !== null && netAmount !== null)
        return `$${formatNumber(netAmount)}`;
    };

    const tableData = listCreditHistory.map((creditHistory, index) => {
      return {
        ...creditHistory,
        index: index + 1 + rowsPerPage * page,
        dateOfUse: creditHistory.createdAt
          ? formatDate(creditHistory.createdAt)
          : "",
        name: creditHistory?.user?.nickName ?? "",
        email: creditHistory?.user?.email ?? "",
        pattern: creditHistory?.patternId,
        patternName:
          creditHistory?.pattern?.category ||
          creditHistory?.order?.orderDetail?.[0]?.name ||
          creditHistory?.order?.orderDetail?.[0]?.ulftrafits?.name ||
          "",
        // history: creditHistory?.activity?.replace("_", " ") ?? "",
        history: mapActivityEnToKo(creditHistory?.activity ?? ""),
        // rechargeCredit: creditHistory?.netAmount ?? 0,
        rechargeCredit:
          creditHistory?.type === "deposit" ? creditHistory?.amount : 0,
        earnCredit:
          creditHistory?.type === "deposit" ? creditHistory?.netAmount : 0,
        useCredit:
          creditHistory?.type === "withdrawal" ? creditHistory?.amount : 0,
        remainingCredit: creditHistory?.balance ?? 0
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        searchParams={searchParams}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>Credit 사용 내역</Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  {/* 사용일 */}
                  날짜
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <FormControl size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="Credit 관리 - 충전 완료 목록 - Credit 사용 내역">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                mt: 2,
                mb: 2,
                width: "100%"
              }}
            >
              {renderSearchArea()}
            </Box>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Grid item>
            <Box sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                style={{
                  border: "1px solid #a5a5a5",
                  textTransform: "capitalize",
                  color: "#000",
                  width: 150,
                  // lineHeight: 1,
                  fontWeight: "bold"
                }}
                onClick={handleBackToList}
              >
                목록
              </Button>
              {/* <Button
                variant="outlined"
                style={{
                  border: "1px solid #a5a5a5",
                  textTransform: "capitalize",
                  color: "#000",
                  width: 210,
                  lineHeight: 1,
                  padding: "10px",
                  marginLeft: 20,
                  fontWeight: "bold",
                }}
                onClick={handleBackToDetail}
              >
                충전 정보
              </Button> */}
            </Box>
          </Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(withQuery(CreditUsageHistory));
