/* eslint-disable no-useless-escape */
import styled from "@emotion/styled";
import { ButtonBase, InputBase, Checkbox, Typography } from "@mui/material";

const svgData = `<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.7584 0.269939C15.2721 0.688699 15.349 1.44459 14.9303 1.95827L7.10417 11.5583C6.87628 11.8378 6.53474 12 6.17408 12C5.81341 12 5.47187 11.8378 5.24398 11.5583L1.07006 6.43827C0.651304 5.92459 0.728251 5.1687 1.24193 4.74994C1.75561 4.33118 2.5115 4.40813 2.93026 4.92181L6.17408 8.90088L13.0701 0.441805C13.4888 -0.0718744 14.2447 -0.148822 14.7584 0.269939Z" fill="white"/>
</svg>`;

const BpIcon = styled("span")({
  borderRadius: 7,
  width: 24,
  height: 24,
  boxShadow: "inset 0 0 0 1px #dddddd, inset 0 -1px 0 #dddddd",
  backgroundColor: "#ffffff",
});

const BpCheckedIcon = styled(BpIcon)({
  boxShadow: "unset",
  backgroundColor: "#0046a6",
  backgroundImage: `url("data:image/svg+xml, ${encodeURIComponent(svgData)}")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
});

export function CustomCheckbox(props) {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export const CustomInput = styled(InputBase)({
  width: 478,
  height: 56,
  padding: "0 16px",
  border: "1px solid #dddddd",
  fontWeight: 400,
  fontSize: "16px",
  "& input": {
    paddingTop: "10px",
  },
  "& svg": {
    marginRight: "16px",
  },
});

export const LoginBtn = styled(ButtonBase)({
  width: 478,
  height: 60,
  background: "#0046a6",
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "23px",
  color: "#ffffff",
});

export const ErrorTypo = styled(Typography)({
  fontWeight: 400,
  fontSize: 14,
  color: "#d70000",
  marginTop: "8px",
});
