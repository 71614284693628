import URL from "./url";
import axiosClient from "../axiosClient";

class ManageBannerService {
  getBannerList(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_BANNER_LIST + query;
    return axiosClient.get(url);
  }
  getBannerById(id) {
    const url = URL.GET_BANNER + "/" + id;
    return axiosClient.get(url);
  }
  updateBanner(id, body = {}) {
    const url = URL.UPDATE_BANNER + "/" + id;
    return axiosClient.put(url, body);
  }
  setBanner(body = {}) {
    const url = URL.SET_BANNER;
    return axiosClient.post(url, body);
  }
  deleteBanner(body = {}) {
    const url = URL.DELETE_BANNER;
    return axiosClient.post(url, body);
  }
}

export default new ManageBannerService();
