import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  Button
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/TableData";
import { formatDate, formatNumber } from "utils/function";
import {
  getListMember,
  resetMembers,
  updateMerchantRight
} from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageMemberService, ManageMerchantService } from "services";
import Excel from "exceljs";
import Popup from "components/Popup";

const listStatus = [
  { value: "all", text: "전체 회원" },
  { value: "active", text: "활성 회원" },
  { value: "suspended", text: "정지 회원" },
  // { value: "secession", text: "탈퇴 회원" }
];

const listLevels = [
  { value: "begginer", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

function MerchantManagement({ showToast }) {
  // const [listChecked, setListChecked] = useState([]);
  const [memberSelected, setMemberSelected] = useState("");
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState({
    searchType: 0,
    search: "",
    levels: [],
    status: "all"
  });
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [state, dispatch] = useGlobalStore();

  const {
    members = [],
    loading = true,
    error = null
  } = useMemo(() => state?.member, [state]);

  useEffect(() => {
    dispatch(getListMember({ merchant: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetMembers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectUser = (e, userId) => {
    if (e.target.checked) {
      setMemberSelected(userId);
    } else {
      setMemberSelected("");
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await dispatch(
      getListMember({
        ...searchTerms,
        status: searchTerms.status === "all" ? "" : searchTerms.status,
        merchant: true
      })
    );
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ searchType: 0, search: "", levels: [], status: "all" });
    await dispatch(getListMember({ merchant: true }));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handlerRemoveMerchantRight = async () => {
    if (!memberSelected) {
      showToast(TOAST_TYPE.WARN, "No members selected");
      return;
    }

    const data = {
      code: memberSelected
    };

    // setLoadingProcess({ ...loadingProcess, suspending: true });

    try {
      await ManageMerchantService.removeMerchantRight(data);
      dispatch(updateMerchantRight(data));
      // setLoadingProcess({ ...loadingProcess, suspending: false });
      setMemberSelected("");
      showToast(TOAST_TYPE.SUCCESS, "Suspended Uploader");
    } catch (error) {
      //setLoadingProcess({ ...loadingProcess, suspending: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "searchType") {
      setSearchTerms((prevState) => ({ ...prevState, searchType: value }));
    }
    if (key === "status") {
      setSearchTerms((prevState) => ({ ...prevState, status: value }));
    }
  };

  const handleCreateExcel = async (listUser) => {
    const fileName = "판매자 관리";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("판매자 관리");

    const headersTitle = [
      "판매자",
      "판매자 등급",
      "총 수익금(수수료율%)",
      "보유 Profit",
      "업로드 패턴 ",
      "다운로드 수"
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F"];
    const columnWidth = [20, 20, 20, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // add value, style cell // body
    listUser.forEach((member, index) => {
      ws.getCell(`A${index + 2}`).value = member.merchant
        ? member.merchant
        : "";
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = member.level ? member.level : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = member.profit ? member.profit : "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = member.holding ? member.holding : "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = member.uploads
        ? formatNumber(member.uploads)
        : "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value = member.downloads
        ? formatNumber(member.downloads)
        : "";
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    let condition = {};
    if (isDownloadFilter) {
      condition = {
        ...searchTerms,
        status: searchTerms.status === "all" ? "" : searchTerms.status
      };
    }
    let listUser = [];
    try {
      const response = await ManageMemberService.getList(condition);
      if (response.status === 201) {
        const users = response?.data ? response.data?.users : [] ?? [];
        listUser = [...users];
        handleCreateExcel(listUser);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["10%", "12%", "18%", "12%", "14%", "10%", "19%"];

    const headersTitle = [
      "판매자",
      "판매자 등급",
      "총 수익금(수수료율%)",
      "보유 Profit",
      "업로드 패턴 ",
      "다운로드 수",
      <Button
        variant="outline"
        sx={{
          border: "1px solid #a5a5a5",
          textTransform: "capitalize"
        }}
        onClick={handlerRemoveMerchantRight}
      >
        업로더 권한 정지
      </Button>
    ];

    const tableData = members.map((member) => {
      return {
        ...member,
        createdAt: member.createdAt ? formatDate(member.createdAt) : "",
        downloads: formatNumber(member.downloads),
        uploads: formatNumber(member.uploads),
        profit: member.profit ? formatNumber(member.profit) : "",
        suspendUploader: (
          <Checkbox
            checked={memberSelected === member.id}
            disabled={!!memberSelected && memberSelected !== member.id}
            onChange={(e) => handleSelectUser(e, member.id)}
          />
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item style={{ fontWeight: "bold" }}>
          판매자 목록
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item style={{ marginRight: "20px" }}>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["status"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("status", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px",
                    width: "90px"
                  }}
                >
                  {listStatus.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "140px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item style={{ marginLeft: "20px" }}>
              <Grid container alignItems="center">
                <FormControl sx={{ m: 1 }} size="small">
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px"
                    }}
                  >
                    회원등급
                  </Box>
                </FormControl>
                <FormControl>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px"
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem"
                              }
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important"
                          }
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="판매자 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item style={{ fontWeight: "bold" }}>
                <span>총 </span>
                <span style={{ color: "#0468b4", fontWeight: "bold" }}>
                  {formatNumber(members.length)
                    ? formatNumber(members.length)
                    : 0}
                </span>
                <span>명</span>
              </Grid>
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(MerchantManagement);
