import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import Popup from "components/Popup";
import { ManageCreditService, ManageWithdrawService } from "services";
import { withToast } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box
} from "@mui/material";
import {
  formatDate,
  formatNumber,
  addMonths,
  sortListByDate,
  sortListByAmount
} from "utils/function";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Excel from "exceljs";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    cancel: createColor("#ff5454"),
    approve: createColor("#fff")
  }
});

const listSortBy = [
  { value: "newest", text: "최신순" },
  { value: "oldest", text: "오래된 순" },
  { value: "withdrawalAmount", text: "출금금액순" }
];

function WithdrawalReceiptList({ showToast }) {
  const [listTransaction, setListTransaction] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [sortBy, setSortBy] = useState("newest");
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [dateTo, setDateTo] = useState(addMonths(new Date()));
  const [searchTerms, setSearchTerms] = useState({
    name: "",
    dateFrom: "",
    dateTo: "",
    ppEmail: ""
  });
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    approving: false,
    cancelling: false
  });
  const [timeReCall, setTimeReCall] = useState(0);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageCreditService.getListTransaction({
          description: "payout"
        });
        if (response.status === 201) {
          setListTransaction(
            response?.data ? response.data?.transactions : [] ?? []
          );
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeReCall]);

  const dataListTransaction = useMemo(() => {
    if (sortBy === "newest")
      return sortListByDate(listTransaction, "createdAt", -1);
    if (sortBy === "oldest")
      return sortListByDate(listTransaction, "createdAt", 1);
    if (sortBy === "withdrawalAmount")
      return sortListByAmount(listTransaction, "amount", -1);
    return listTransaction;
  }, [listTransaction, sortBy]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    try {
      const condition = {
        ...searchTerms,
        dateFrom: dateFrom,
        dateTo: dateTo,
        description: "payout"
      };
      const response = await ManageCreditService.getListTransaction(condition);
      if (response.status === 201) {
        setListTransaction(
          response?.data ? response.data?.transactions : [] ?? []
        );
        setLoadingProcess({ ...loadingProcess, searching: false });
      } else {
        setLoadingProcess({ ...loadingProcess, searching: false });
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, searching: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleReset = async () => {
    setSearchTerms({
      code: "",
      name: "",
      products: [],
      dateFrom: "",
      dateTo: ""
    });
    setDateFrom(new Date());
    setDateTo(addMonths(new Date()));
    setLoadingProcess({ ...loadingProcess, resetting: true });
    try {
      const response = await ManageCreditService.getListTransaction({
        description: "payout"
      });
      if (response.status === 201) {
        setListTransaction(
          response?.data ? response.data?.transactions : [] ?? []
        );
        setLoadingProcess({ ...loadingProcess, resetting: false });
      } else {
        setLoadingProcess({ ...loadingProcess, resetting: false });
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, resetting: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "name") {
      setSearchTerms((prevState) => ({ ...prevState, name: value }));
    }
    if (key === "ppEmail") {
      setSearchTerms((prevState) => ({ ...prevState, ppEmail: value }));
    }
    if (key === "dateFrom") {
      setSearchTerms((prevState) => ({ ...prevState, dateFrom: value }));
    }
    if (key === "dateTo") {
      setSearchTerms((prevState) => ({ ...prevState, dateTo: value }));
    }
  };

  const handleCreateExcel = async (transactions) => {
    const fileName = "Withdrawal.js";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("withdrawal");
    const headersTitle = [
      "Code.",
      "Name",
      "Date",
      "Withdraw amount",
      "Transaction fee",
      "Paypal E-mail"
    ];
    const columnExcel = ["A", "B", "C", "D", "E", "F"];
    const columnWidth = [10, 20, 20, 20, 20, 20];
    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    const calculateFee = (netAmount, amount) => {
      if (!netAmount || !amount) return "";
      const fee = formatNumber(Number(netAmount) - Number(amount));
      return fee ? "$" + fee : "";
    };

    // add value, style cell // body
    transactions.forEach((transaction, index) => {
      ws.getCell(`A${index + 2}`).value = transaction.id ? transaction.id : "";
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = transaction.nick
        ? transaction.nick
        : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = transaction.createdAt
        ? formatDate(transaction.createdAt)
        : "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = transaction.amount
        ? "$" + formatNumber(transaction.amount)
        : "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = calculateFee(
        transaction?.netAmount,
        transaction.amount
      );
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value = transaction.ppEmail
        ? Number(transaction.ppEmail) + " Credit"
        : "";
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownloadExcel = async () => {
    let condition = { description: "payout" };
    if (isDownloadFilter) {
      condition = {
        ...searchTerms,
        dateFrom: dateFrom,
        dateTo: dateTo,
        description: "payout"
      };
    }
    let listTransaction = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageCreditService.getListTransaction(condition);
      if (response.status === 201) {
        const transactions = response?.data
          ? response.data?.transactions
          : [] ?? [];
        listTransaction = [...transactions];
        handleCreateExcel(listTransaction);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleSelectTransaction = (e, tid) => {
    if (e.target.checked) {
      setListChecked([...listChecked, tid]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== tid);
      setListChecked(newListChecked);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      const newListChecked = [];
      listTransaction.forEach((transaction) => {
        newListChecked.push(transaction.id);
      });
      setListChecked([...newListChecked]);
    } else {
      setIsCheckAll(false);
      setListChecked([]);
    }
  };

  const handleCancelPayout = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No transaction selected");
      return;
    }
    const data = {
      userIds: listChecked
    };
    setLoadingProcess({ ...loadingProcess, cancelling: true });
    try {
      const response = await ManageWithdrawService.cancelPayout(data);
      if (response.status === 201 && response.data.status === SUCCESS_STATUS) {
        setTimeReCall((prevState) => prevState + 1);
        setListChecked([]);
        setIsCheckAll(false);
        showToast(TOAST_TYPE.SUCCESS, "Cancelled");
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleApprovePayout = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No transaction selected");
      return;
    }
    const data = {
      userIds: listChecked
    };
    setLoadingProcess({ ...loadingProcess, approving: true });
    try {
      const response = await ManageWithdrawService.approvePayout(data);
      if (response.status === 201 && response.data.status === SUCCESS_STATUS) {
        setTimeReCall((prevState) => prevState + 1);
        setListChecked([]);
        setIsCheckAll(false);
        showToast(TOAST_TYPE.SUCCESS, "Approved");
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, approving: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, approving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["15%", "15%", "15%", "15%", "15%", "15%", "10%"];
    const headersTitle = [
      "Code.",
      "출금자명",
      "출금요청일",
      "출금금액",
      "거래 수수료",
      "Paypal 이메일 주소",
      <Checkbox
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#fff"
          }
        }}
        checked={isCheckAll}
        onChange={(e) => handleSelectAll(e)}
      />
    ];
    const calculateFee = (netAmount, amount) => {
      if (!netAmount || !amount) return "";
      const fee = formatNumber(Number(netAmount) - Number(amount));
      return fee ? "$" + fee : "";
    };

    const tableData = dataListTransaction.map((transaction) => {
      return {
        ...transaction,
        amount: transaction.amount
          ? "$" + formatNumber(transaction.amount)
          : "",
        createdAt: transaction.createdAt
          ? formatDate(transaction.createdAt)
          : "",
        fees: calculateFee(transaction?.netAmount, transaction.amount),
        check: (
          <Checkbox
            checked={listChecked.includes(transaction.id) ? true : false}
            onChange={(e) => handleSelectTransaction(e, transaction.id)}
          />
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>검색필터</Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  출금자명
                </Box>
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      width: "120px",
                      height: "13px"
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  value={searchTerms["name"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("name", e.target.value)
                  }
                />
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  완료일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "100px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "100px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ margin: "10px 10px 10px 0" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  Paypal 이메일 주소
                </Box>
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      width: "120px",
                      height: "13px"
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  value={searchTerms["ppEmail"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("ppEmail", e.target.value)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="출금 관리 - 출금 접수 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography sx={{ mr: 2 }}>정렬기준</Typography>
                  <Select
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      borderRadius: "0",
                      height: "30px",
                      width: "120px"
                    }}
                  >
                    {listSortBy.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize",
                      padding: " 0 20px"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel파일로 다운받기
                  </Button>
                </Grid>
                <Grid item sx={{ mt: "10px" }}>
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "0",
                          height: "30px"
                        }}
                        color="approve"
                        onClick={handleApprovePayout}
                        loading={loadingProcess["approving"] ? true : false}
                        loadingIndicator="승인"
                        variant="contained"
                      >
                        출금승인
                      </LoadingButton>
                      <LoadingButton
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "0",
                          height: "30px"
                        }}
                        color="cancel"
                        onClick={handleCancelPayout}
                        loading={loadingProcess["cancelling"] ? true : false}
                        loadingIndicator="취소"
                        variant="contained"
                      >
                        취소
                      </LoadingButton>
                    </ThemeProvider>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(WithdrawalReceiptList);
