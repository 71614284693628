export const ERROR_STATUS = "errors";
export const SUCCESS_STATUS = "ok";
export const TOAST_TYPE = Object.freeze({
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARN: "WARN",
  INFO: "INFO"
});

export const CREDIT_PRODUCT_CATEGORY = Object.freeze({
  STANDARD: "STANDARD",
  PLUS: "PLUS",
  PREMIUM: "PREMIUM",
  GOLD: "Gold"
});
