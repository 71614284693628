import {
  Button,
  Grid,
  Modal,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  formatDate,
  formatNumber,
  generateImageSrc,
  subtractDecimalNumber
} from "utils/function";
// import { useNavigate } from "react-router-dom";

import "./style.css";

const listStatusEnToKo = {
  order_confirmation: "주문확인",
  product_preparation: "상품준비중",
  shipping: "배송중",
  delivery: "배송완료",
  cancellation_pending: "취소대기중",
  cancellation_completed: "취소완료",
  return_accepted: "반품접수",
  return_processing: "반품처리중",
  return_completed: "반품완료"
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.7rem !important",
    height: 15,
    padding: "2px 5px",
    // width: "120px",
    textAlign: "left",
    fontWeight: "bold",
    borderTop: "1px solid #a5a5a5",
    borderBottom: "1px solid #a5a5a5",
    wordBreak: "break-all"
    // borderLeft: "none",
    // borderRight: "none",
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.7rem !important",
    height: 15,
    padding: "2px 5px",
    width: "90px",
    textAlign: "center",
    // border: "1px solid #a5a5a5",
    borderTop: "1px solid #a5a5a5",
    borderBottom: "1px solid #a5a5a5",
    // color: "#ffff",
    backgroundColor: "#d9d9d9"
    // fontWeight: "bold",
  }
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  overflowY: "scroll",
  bgcolor: "#fff",
  border: "1px solid #c4c4c4",
  // boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   padding: "40px 0 0 0",
  maxHeight: 600,
  fontSize: "0.7rem !important"
};

const StyledTypography = styled(Typography)({
  fontSize: "0.7rem !important"
});

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.handlePrint = this.handlePrint.bind(this);
  }
  handlePrint = () => {
    window.print();
  };
  render() {
    const {
      open = false,
      setOpen = () => {},
      data = {},
      handleConfirm = () => {}
    } = this.props;

    const calculateFinalPrice = () => {
      let dcPrice = 0;
      if (data.discountAmount > 0) {
        dcPrice += data.discountAmount;
      }
      if (data?.coupon) {
        dcPrice += data?.coupon?.discount;
      }
      const finalPrice = data.totalAmount - dcPrice;
      return `$ ${finalPrice}`;
    };

    // const totalOrderAmount = () =>
    //   Number(data?.totalAmount ?? 0) +
    //   Number(data?.totalCredit ?? 0) +
    //   Number(data?.totalProfit ?? 0) +
    //   Number(data?.discountAmount ?? 0) +
    //   Number(data?.shippingFee ?? 0);

    // console.log("totalOrderAmount", data, totalOrderAmount());

    const couponAmount = () => {
      if (data?.coupon?.benefit === "free_shipping") {
        return Number(data?.shippingFee?.replace?.("$", "") || 0);
      } else {
        return data?.coupon?.discount || 0;
      }
    };

    const discount = () =>
      subtractDecimalNumber(
        Number(data?.discountAmount?.replace?.("$", "") || 0),
        Number(couponAmount())
      );

    return (
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style} className="body-print">
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              style={{
                border: "1px solid #c4c4c4",
                display: "flex",
                alignItems: "center"
              }}
            >
              <LocalPrintshopIcon
                className="icon-print"
                fontSize="large"
                style={{ cursor: "pointer" }}
                onClick={this.handlePrint}
              />
            </Box>
          </Box>
          <Typography style={{ textAlign: "center" }}>주문상세보기</Typography>
          <Grid container spacing={4} sx={{ pl: 4, pr: 4 }}>
            <Grid item sm={6}>
              <StyledTypography>주문정보</StyledTypography>
              <TableContainer sx={{ pl: 2 }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft className="color-cell-print">
                        주문 일자
                      </StyledTableCellLeft>
                      <StyledTableCell width={"120px"}>
                        {data.createdAt ? formatDate(data.createdAt) : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>주문자</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.shippingName ? data.shippingName : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>주문 번호</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.id ? data.id : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>배송정보</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.status ? listStatusEnToKo[data.status] : ""}
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCellLeft>환불 사유</StyledTableCellLeft>
                      <StyledTableCell>{data.reason}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={6}>
              <StyledTypography>결제 정보</StyledTypography>
              <TableContainer sx={{ pl: 2 }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>총주문금액</StyledTableCellLeft>
                      <StyledTableCell>
                        {data?.orderAmount
                          ? "$ " + data?.orderAmount?.replace?.("$", "")
                          : 0}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>총 할인 금액</StyledTableCellLeft>
                      {/* <StyledTableCell>
                        {data.DCPrice
                          ? formatNumber(data.DCPrice) + " USD"
                          : ""}
                      </StyledTableCell> */}
                      <StyledTableCell>
                        {discount() ? "$ " + discount() : 0}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>쿠폰 할인</StyledTableCellLeft>
                      <StyledTableCell>
                        {couponAmount() ? "$ " + couponAmount() : 0}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>프로핏 사용</StyledTableCellLeft>
                      <StyledTableCell>
                        {data?.totalProfit
                          ? "$ " + Number(data?.totalProfit)
                          : 0}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>크레딧 사용</StyledTableCellLeft>
                      <StyledTableCell>
                        {data?.totalCredit
                          ? "$ " + Number(data?.totalCredit)
                          : 0}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer sx={{ pl: 2, mt: 2 }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>결제금액</StyledTableCellLeft>
                      {/* <StyledTableCell>
                        {data.amounts
                          ? formatNumber(Number(data.amounts)) + " USD"
                          : ""}
                      </StyledTableCell> */}
                      {/* <StyledTableCell>{calculateFinalPrice()}</StyledTableCell> */}
                      <StyledTableCell>
                        {/* {data.totalAmount ? `$ ${data.totalAmount}` : 0} */}
                        {data?.paypal ? `$ ${data?.paypal}` : 0}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container sx={{ pl: 4, pr: 4 }} flexDirection="column">
            <Grid item>
              <StyledTypography>주문 상품 정보</StyledTypography>
            </Grid>
            <Grid item sx={{ pl: 2 }}>
              <Grid container flexDirection="column">
                {data?.orderDetail &&
                  data?.orderDetail.length > 0 &&
                  data.orderDetail.map((item, index) => (
                    <Grid item key={index}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item style={{ width: "90px" }}>
                          {item?.ulftrafits?.image && (
                            <img
                              width="90px"
                              src={item.ulftrafits.image}
                              alt=""
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          style={{ fontWeight: "bold" }}
                          sx={{ ml: 2 }}
                        >
                          {item?.ulftrafits?.name ?? ""}
                        </Grid>
                        <Grid item sx={{ ml: 2 }}>
                          {item?.quantity ? item?.quantity + " EA" : ""}
                        </Grid>
                        <Grid item sx={{ ml: 2 }}>
                          {item?.price
                            ? "$ " + formatNumber(Number(item?.price))
                            : ""}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}

                {/* {data?.product1 && (
                  <Grid item>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item style={{ width: "90px" }}>
                        {data?.product1?.file && (
                          <img
                            width="90px"
                            src={generateImageSrc(data.product1.file)}
                            alt=""
                          />
                        )}
                      </Grid>
                      <Grid item style={{ fontWeight: "bold" }}>
                        {data?.product1?.name ?? ""}
                      </Grid>
                      <Grid item>
                        {data?.product1?.inventory
                          ? data?.product1?.inventory + " EA"
                          : ""}
                      </Grid>
                      <Grid item>
                        {data?.product1?.price
                          ? "$ " + formatNumber(Number(data?.product1?.price))
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {data?.product2 && (
                  <Grid item sx={{ mt: 3, mb: 2 }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item style={{ width: "90px" }}>
                        {data?.product2?.file && (
                          <img
                            width="90px"
                            src={generateImageSrc(data.product2.file)}
                            alt=""
                          />
                        )}
                      </Grid>
                      <Grid item style={{ fontWeight: "bold" }}>
                        {data?.product2?.name ?? ""}
                      </Grid>
                      <Grid item>
                        {data?.product2?.inventory
                          ? data?.product2?.inventory + " EA"
                          : ""}
                      </Grid>
                      <Grid item>
                        {data?.product2?.price
                          ? "$ " + formatNumber(Number(data?.product2?.price))
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {data?.product3 && (
                  <Grid item>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        {data?.product3?.file && (
                          <img
                            width="90px"
                            src={generateImageSrc(data.product3.file)}
                            alt=""
                          />
                        )}
                      </Grid>
                      <Grid item>{data?.product3?.name ?? ""}</Grid>
                      <Grid item>
                        {data?.product3?.inventory
                          ? data?.product3?.inventory + " EA"
                          : ""}
                      </Grid>
                      <Grid item>
                        {data?.product3?.price
                          ? "$ " + formatNumber(Number(data?.product3?.price))
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {data?.product4 && (
                  <Grid item>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        {data?.product4?.file && (
                          <img
                            width="90px"
                            src={generateImageSrc(data.product4.file)}
                            alt=""
                          />
                        )}
                      </Grid>
                      <Grid item>{data?.product4?.name ?? ""}</Grid>
                      <Grid item>
                        {data?.product4?.inventory
                          ? data?.product4?.inventory + " EA"
                          : ""}
                      </Grid>
                      <Grid item>
                        {data?.product4?.price
                          ? "$ " + formatNumber(Number(data?.product4?.price))
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {data?.product5 && (
                  <Grid item>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        {data?.product5?.file && (
                          <img
                            width="90px"
                            src={generateImageSrc(data.product5.file)}
                            alt=""
                          />
                        )}
                      </Grid>
                      <Grid item>{data?.product5?.name ?? ""}</Grid>
                      <Grid item>
                        {data?.product5?.inventory
                          ? data?.product5?.inventory + " EA"
                          : ""}
                      </Grid>
                      <Grid item>
                        {data?.product5?.price
                          ? "$ " + formatNumber(Number(data?.product5?.price))
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                )} */}
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ pl: 4, pr: 4 }}>
            <Grid item>
              <TableContainer sx={{ pl: 2 }} style={{ width: "400px" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>국가</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.shippingCountry ? data.shippingCountry : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>주소</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.shippingAddress1 ? data.shippingAddress1 : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>주소 2</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {data.shippingAddress2 ? data.shippingAddress2 : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Zip/Postal</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.shippingZip ? data.shippingZip : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Town/City</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.shippingCity ? data.shippingCity : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Region</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.shippingState ? data.shippingState : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>VAT/TAX ID</StyledTableCellLeft>
                      <StyledTableCell>
                        {data.shippingVat ? data.shippingVat : "-"}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              // variant="contained"
              // variant="outline"
              // variant="contained"
              variant={
                data?.status === "order reciept" ? "contained" : "outlined"
              }
              className="button-print"
              style={
                data?.status === "order reciept"
                  ? {
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize",
                      margin: "20px 0",
                      borderRadius: 0,
                      color: "#fff"
                    }
                  : {
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize",
                      margin: "20px 0",
                      borderRadius: 0,
                      color: "#000"
                    }
              }
              onClick={() => {
                setOpen(!open);
              }}
            >
              확인
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export default OrderDetail;
