import URL from "./url";
import axiosClient from "../axiosClient";
class ManageSponsorService {
  getListSponsor(body = {}) {
    const url = URL.GET_SPONSOR_LIST;
    return axiosClient.post(url, body);
  }
  getSponsorAdPrice(body = {}) {
    const url = URL.GET_SPONSOR_PRICE;
    return axiosClient.post(url, body);
  }
  setSponsorAdPrice(body = {}) {
    const url = URL.SET_SPONSOR_PRICE;
    return axiosClient.post(url, body);
  }
}

export default new ManageSponsorService();
