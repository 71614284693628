import { adminConstants } from "../constants";

const initState = {
  loading: true,
  admins: [],
  error: null
};

const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case adminConstants.GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
        error: null
      };
    case adminConstants.GET_ADMINS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case adminConstants.UPDATE_ADMIN: {
      const { id = "", data } = action.payload;
      const { admins } = state;
      const newAdmins = admins.map((admin) => {
        if (id === admin.id) {
          return { ...admin, ...data };
        }
        return admin;
      });

      return {
        ...state,
        admins: newAdmins
      };
    }

    case adminConstants.CREATE_ADMIN: {
      const { admins } = state;
      const newAdmins = [...admins, action.payload];
      return {
        ...state,
        admins: newAdmins
      };
    }
    case adminConstants.DELETE_ADMIN: {
      const id = action.payload;
      const { admins = [] } = state;
      let newAdmins = [...admins];

      newAdmins = newAdmins.filter((item) => item.id !== id);

      return {
        ...state,
        admin: newAdmins
      };
    }
    case adminConstants.RESET_ADMINS: {
      return {
        ...state,
        loading: true,
        admins: [],
        error: null
      };
    }
    default:
      return state;
  }
};

export default adminReducer;
