import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  styled
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import LoadingOneLine from "components/LoadingOneLine";
import TableData from "./TableIssuedMember";
import { formatDate, formatDateSearch } from "utils/function";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageCouponService } from "services";
import Popup from "components/Popup";
import Excel from "exceljs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const listStatus = [
  { value: "all", text: "전체 회원" },
  { value: "active", text: "활성 회원" },
  { value: "suspended", text: "정지 회원" },
  { value: "secession", text: "탈퇴 회원" }
];

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& label": {
    paddingLeft: (theme) => theme.spacing(2)
  },
  "& input": {
    paddingLeft: (theme) => theme.spacing(1),

    height: "30px",
    padding: "0 5px"
  },
  "& fieldset": {
    paddingLeft: (theme) => theme.spacing(2.5),
    borderRadius: 0
  }
}));

// const listLevels = [
//   { value: "begginer", text: "Beginner" },
//   { value: "advanced", text: "Advanced" },
//   { value: "expert", text: "Expert" },
//   { value: "master", text: "Master" },
// ];

function ListIssuedMember({ showToast, couponId, timeReCall }) {
  const [loading, setLoading] = useState(true);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [listMember, setListMember] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    name: "",
    email: "",
    contact: "",
    issueDate: null,
    useDate: null
  });

  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const {
      name = "",
      email = "",
      contact = "",
      issueDate = "",
      useDate = ""
    } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1
        ? {
            ...condition,
            page: 1,
            limit: rowsPerPage
          }
        : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    if (name) {
      condition = { ...condition, name: name ? name.trim() : "" };
    }
    if (email) {
      condition = { ...condition, email: email ? email.trim() : "" };
    }
    if (contact) {
      condition = { ...condition, phone: contact ? contact.trim() : "" };
    }
    if (issueDate) {
      condition = {
        ...condition,
        // dateOfIssue: formatDateSearch(new Date(issueDate), "YYYY-MM-DD")
        // dateOfIssue: new Date(issueDate).toUTCString()
        dateOfIssue: issueDate
          ? formatDateSearch(issueDate, "YYYY-MM-DD HH:mm:ss")
          : ""
        // dateOfIssue: "2023-07-21"
      };
    }
    if (useDate) {
      condition = {
        ...condition,
        // dateOfUse: formatDateSearch(new Date(useDate), "YYYY-MM-DD")
        dateOfUse: useDate
          ? formatDateSearch(useDate, "YYYY-MM-DD HH:mm:ss")
          : ""
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const res = await ManageCouponService.getCouponUserList(
        couponId,
        handleCreateCondition(isSearch, isResetting)
      );
      if (res?.data?.statusCode === 200) {
        let users = res?.data?.data ?? [];
        setCount(res?.data?.count ?? 0);
        setListMember([...users]);
        setLoading(false);
      } else {
        setLoading(false);
        showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
      }
    } catch (error) {
      setLoading(false);
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText || error.message || "";
      showToast(TOAST_TYPE.ERROR, message);
    }
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({
      ...{
        name: "",
        email: "",
        contact: "",
        issueDate: null,
        useDate: null
      }
    });
    setPage(0);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleCreateExcel = async (users) => {
    const fileName = "발급 받은 회원 ";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("발급 받은 회원 ");

    const headersTitle = ["성명", "이메일", "연락처", "발급일", "사용일"];

    const columnExcel = ["A", "B", "C", "D", "E"];
    const columnWidth = [10, 30, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // add value, style cell // body
    users.forEach((row, index) => {
      ws.getCell(`A${index + 2}`).value = row?.user?.id ? row?.user?.id : "";
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = row?.user?.email
        ? row?.user?.email
        : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = row?.user?.phoneNumber
        ? row.user.phoneNumber
        : "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = row.createdAt
        ? formatDate(row.createdAt)
        : "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value =
        row.used === true && row.updatedAt ? formatDate(row.updatedAt) : "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    let condition = {};
    if (isDownloadFilter) {
      const {
        name = "",
        email = "",
        contact = "",
        issueDate = "",
        useDate = ""
      } = searchTerms;

      if (name) {
        condition = { ...condition, name: name ? name.trim() : "" };
      }
      if (email) {
        condition = { ...condition, email: email ? email.trim() : "" };
      }
      if (contact) {
        condition = { ...condition, phone: contact ? contact.trim() : "" };
      }
      if (issueDate) {
        condition = {
          ...condition,
          // dateOfIssue: formatDateSearch(new Date(issueDate), "YYYY-MM-DD")
          // dateOfIssue: new Date(issueDate).toUTCString()
          dateOfIssue: issueDate ? issueDate.trim() : ""
          // dateOfIssue: "2023-07-21"
        };
      }
      if (useDate) {
        condition = {
          ...condition,
          // dateOfUse: formatDateSearch(new Date(useDate), "YYYY-MM-DD")
          dateOfUse: useDate ? useDate.trim() : ""
        };
      }
    }
    try {
      const res = await ManageCouponService.getCouponUserList(
        couponId,
        condition
      );
      if (res?.data?.statusCode === 200) {
        let users = res?.data?.data ?? [];
        handleCreateExcel([...users]);
      } else {
        showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      showToast(TOAST_TYPE.ERROR, message);
    }
    setOpenPopupAll(false);
    setOpenPopupFilter(false);
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "name") {
      setSearchTerms((prevState) => ({ ...prevState, name: value }));
    }
    if (key === "email") {
      setSearchTerms((prevState) => ({ ...prevState, email: value }));
    }
    if (key === "contact") {
      setSearchTerms((prevState) => ({ ...prevState, contact: value }));
    }
    if (key === "issueDate") {
      setSearchTerms((prevState) => ({ ...prevState, issueDate: value }));
    }
    if (key === "useDate") {
      setSearchTerms((prevState) => ({ ...prevState, useDate: value }));
    }
  };

  const renderTable = () => {
    const sizeWidth = ["15%", "15%", "20%", "13%", "12%", "10%", "10%"];

    const headersTitle = [
      "성명",
      "이메일",
      "연락처",
      // "분배 방법",
      "발급일",
      "사용일"
    ];

    const tableData = listMember.map((member) => {
      return {
        ...member,
        createdAt: member.createdAt ? formatDate(member.createdAt) : "",
        updatedAt: member.updatedAt ? formatDate(member.updatedAt) : "",
        issuance: member.issuance === "normal" ? "지정발급" : "자동으로"
      };
    });

    // const tableData = [
    //   {
    //     id: "000001",
    //     email: "tmdgus04040@gmail.com",
    //     phone: "82+ 01056132640",
    //     issueDate: "23.02.17",
    //     useDate: "23.02.18"
    //   },
    //   {
    //     id: "000002",
    //     email: "sh53960@gmail.com",
    //     phone: "82+ 01056132640",
    //     issueDate: "23.02.23",
    //     useDate: "23.03.01"
    //   },
    //   {
    //     id: "000003",
    //     email: "sh53960@aiaracorp.com",
    //     phone: "82+ 01056132640",
    //     issueDate: "23.02.30",
    //     useDate: "23.03.01"
    //   }
    // ];

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container flexDirection="column">
        <Box
          component="div"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box style={{ flexBasis: "12%", display: "flex" }}>
            <Box
              component="span"
              sx={{
                // border: "1px solid #c4c4c4",
                width: "60px",
                height: 30,
                backgroundColor: "#1565c0",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 5px"
              }}
            >
              성명
            </Box>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& label": {
                  paddingLeft: (theme) => theme.spacing(2)
                },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1),
                  height: "30px",
                  padding: "0 5px"
                },
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(2.5),
                  borderRadius: 0
                }
              }}
              value={searchTerms["name"]}
              onChange={(e) => handleChangeSearchTerms("name", e.target.value)}
            />
          </Box>
          <Box style={{ flexBasis: "20%", display: "flex" }}>
            <Box
              component="span"
              sx={{
                // border: "1px solid #c4c4c4",
                height: 30,
                backgroundColor: "#1565c0",
                color: "#fff",
                display: "flex",
                padding: "0 5px",
                ml: 2,
                width: "92px",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              이메일
            </Box>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& label": {
                  paddingLeft: (theme) => theme.spacing(2)
                },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1),
                  height: "30px",
                  padding: "0 5px"
                },
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(2.5),
                  borderRadius: 0
                }
              }}
              value={searchTerms["email"]}
              onChange={(e) => handleChangeSearchTerms("email", e.target.value)}
            />
          </Box>
          <Box style={{ flexBasis: "20%", display: "flex" }}>
            <Box
              component="span"
              sx={{
                // border: "1px solid #c4c4c4",
                height: 30,
                backgroundColor: "#1565c0",
                color: "#fff",
                display: "flex",
                padding: "0 5px",
                ml: 2,
                width: "92px",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              연락처
            </Box>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& label": {
                  paddingLeft: (theme) => theme.spacing(2)
                },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1),

                  height: "30px",
                  padding: "0 5px"
                },
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(2.5),
                  borderRadius: 0
                }
              }}
              value={searchTerms["contact"]}
              onChange={(e) =>
                handleChangeSearchTerms("contact", e.target.value)
              }
            />
          </Box>
          <Box style={{ flexBasis: "23.5%", display: "flex" }}>
            <Box
              component="span"
              sx={{
                // border: "1px solid #c4c4c4",
                height: 30,
                backgroundColor: "#1565c0",
                color: "#fff",
                display: "flex",
                padding: "0 5px",
                ml: 2,
                width: "92px",
                maxWidth: "92px",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              발급일
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="yyyy/MM/dd"
                value={searchTerms.issueDate || null}
                // maxDate={new Date("2200-01-01")}
                onChange={(value) => {
                  handleChangeSearchTerms("issueDate", value);
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} size="small" width="100px" />
                )}
              />
            </LocalizationProvider>
            {/* <TextField
              fullWidth
              size="small"
              sx={{
                "& label": {
                  paddingLeft: (theme) => theme.spacing(2)
                },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1),

                  height: "30px",
                  padding: "0 5px"
                },
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(2.5),
                  borderRadius: 0
                }
              }}
              value={searchTerms["issueDate"]}
              onChange={(e) =>
                handleChangeSearchTerms("issueDate", e.target.value)
              }
            /> */}
          </Box>

          <Box style={{ flexBasis: "23.5%", display: "flex" }}>
            <Box
              component="span"
              sx={{
                // border: "1px solid #c4c4c4",
                height: 30,
                backgroundColor: "#1565c0",
                color: "#fff",
                display: "flex",
                padding: "0 5px",
                ml: 2,
                width: "92px",
                maxWidth: "92px",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              사용일
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="yyyy/MM/dd"
                value={searchTerms.useDate || null}
                // maxDate={new Date("2200-01-01")}
                onChange={(value) => {
                  handleChangeSearchTerms("useDate", value);
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} size="small" width="100px" />
                )}
              />
            </LocalizationProvider>
            {/* <TextField
              fullWidth
              size="small"
              sx={{
                "& label": {
                  paddingLeft: (theme) => theme.spacing(2),
                },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1),

                  height: "30px",
                  padding: "0 5px",
                },
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(2.5),
                  borderRadius: 0,
                },
              }}
              value={searchTerms["useDate"]}
              onChange={(e) =>
                handleChangeSearchTerms("useDate", e.target.value)
              }
            /> */}
          </Box>
        </Box>

        <Grid item>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              onClick={handleSearch}
              loading={loadingProcess["searching"] ? true : false}
              loadingIndicator="검색"
              variant="contained"
              sx={{ m: 1 }}
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "30px"
              }}
            >
              검색
            </LoadingButton>

            <LoadingButton
              onClick={handleReset}
              loading={loadingProcess["resetting"] ? true : false}
              loadingIndicator="초기화"
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "30px",
                width: 90
              }}
            >
              초기화
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingOneLine />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item sx={{ mt: 1 }}>
            {renderSearchArea()}
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0,
                        display: "flex",
                        alignItems: "center"
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </React.Fragment>
  );
}

export default withToast(ListIssuedMember);
