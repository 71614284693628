import { PrivateRouter } from "HOC";
import ProductManagement from "../ProductManagement";
import Category from "../Category";
import ProductEdit from "../ProductEdit";
import ProductAddNew from "../ProductAddNew";
import OrderDeliveryManagement from "../OrderDeliveryManagement";
import OrderCancellation from "../OrderCancellation";
import GenuineAuthNumberList from "../GenuineAuthNumberList/index";

const routerManageUltrafitShop = [
  {
    path: "/ultrafit-shop-management/product",
    element: (
      <PrivateRouter>
        <ProductManagement />
      </PrivateRouter>
    )
  },
  {
    path: "/ultrafit-shop-management/category",
    element: (
      <PrivateRouter>
        <Category />
      </PrivateRouter>
    )
  },
  {
    path: "/ultrafit-shop-management/product/edit/:id",
    element: (
      <PrivateRouter>
        <ProductEdit />
      </PrivateRouter>
    )
  },
  {
    path: "/ultrafit-shop-management/product/add",
    element: (
      <PrivateRouter>
        <ProductAddNew />
      </PrivateRouter>
    )
  },
  {
    path: "/ultrafit-shop-management/order-delivery-management",
    element: (
      <PrivateRouter>
        <OrderDeliveryManagement />
      </PrivateRouter>
    )
  },
  // {
  //   path: "/ultrafit-shop-management/order-cancellation",
  //   element: (
  //     <PrivateRouter>
  //       <OrderCancellation />
  //     </PrivateRouter>
  //   )
  // },
  {
    path: "/ultrafit-shop-management/genuine-authentication-numbers-list",
    element: (
      <PrivateRouter>
        <GenuineAuthNumberList />
      </PrivateRouter>
    )
  }
];

export default routerManageUltrafitShop;
