import { memberConstants } from "../constants";

const initState = {
  loading: true,
  members: [],
  count: 0,
  error: null
};

const memberReducer = (state = initState, action) => {
  switch (action.type) {
    case memberConstants.GET_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action.payload.members,
        count: action.payload.count,
        error: null
      };
    case memberConstants.GET_MEMBERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case memberConstants.UPDATE_MEMBER: {
      const { userIds = [], suspendedTime = null } = action.payload;
      const { members } = state;
      const newMembers = members.map((member) => {
        if (userIds.includes(member.id)) {
          return { ...member, suspendedTime: suspendedTime };
        }
        return member;
      });

      return {
        ...state,
        members: newMembers
      };
    }
    case memberConstants.UPDATE_MEMBER_CREDIT: {
      const { code = [], amount = null } = action.payload;
      const { members } = state;
      const newMembers = members.map((member) => {
        if (code.includes(member.id)) {
          return { ...member, credit: Number(member.credit) + Number(amount) };
        }
        return member;
      });

      return {
        ...state,
        members: newMembers
      };
    }
    case memberConstants.UPDATE_MERCHANT_RIGHT: {
      const { code = "" } = action.payload;
      const { members } = state;
      const newMembers = members.filter((member) => member.id !== code);
      return {
        ...state,
        members: newMembers
      };
    }
    case memberConstants.RESET_MEMBERS: {
      return {
        ...state,
        loading: true,
        members: [],
        count: 0,
        error: null
      };
    }
    default:
      return state;
  }
};

export default memberReducer;
