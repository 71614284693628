const URL = Object.freeze({
  GET_LIST_URL: "/credits",
  CREATE_CREDIT: "/credits",
  UPDATE_CREDIT: "/credits",
  GET_TRANSACTIONS: "/admin/getTransactions",
  GET_TRANSACTIONS_BY_ID: "/transactions",
  GET_TRANSACTIONS_CHARGE: "/transactions/charge",
  GET_TRANSACTIONS_REFUND: "/transactions/refund",
  GET_CREDIT_HISTORY: "/transactions/credit/history",
  CREATE_GIFT_CREDIT: "/gifts",
  GET_CREDIT_HISTORY_BY_ID: "credit/history",
  CREATE_FLOAT_VARIABLES: "/float-variables",
  GET_FLOAT_VARIABLES: "/float-variables",
  UPDATE_FLOAT_VARIABLES: "/float-variables",
  UPDATE_REFUND_STATUS: "/transactions/refund"
});

export default URL;
