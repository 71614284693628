import { PrivateRouter } from "HOC";
import WithdrawalReceiptList from "../WithdrawalReceiptList";
import DetailWithdrawalReceipt from "../DetailWithdrawalReceipt";
import WithdrawalCompletedList from "../WithdrawalCompletedList";
import DetailWithdrawalCompleted from "../DetailWithdrawalCompleted";

const routerManageWithdrawal = [
  {
    path: "/withdrawal-management/withdrawal-receipt-list",
    element: (
      <PrivateRouter>
        <WithdrawalReceiptList />
      </PrivateRouter>
    ),
  },
  {
    path: "/withdrawal-management/withdrawal-receipt-list/detail",
    element: (
      <PrivateRouter>
        <DetailWithdrawalReceipt />
      </PrivateRouter>
    ),
  },
  {
    path: "/withdrawal-management/withdrawal-completed-list",
    element: (
      <PrivateRouter>
        <WithdrawalCompletedList />
      </PrivateRouter>
    ),
  },
  {
    path: "/withdrawal-management/withdrawal-completed-list/detail",
    element: (
      <PrivateRouter>
        <DetailWithdrawalCompleted />
      </PrivateRouter>
    ),
  },
];

export default routerManageWithdrawal;
