export const routes = [
  {
    id: 0,
    label: "회원관리",
    route: "member-management",
    children: [
      {
        id: 0,
        label: "회원 조회/관리",
        route: "/member-management/list-member"
      },
      {
        id: 1,
        label: "회원가입 정책",
        route: "/member-management/policy"
      },
      {
        id: 2,
        label: "Editor 결제 내역",
        route: "/member-management/editor-payment-history"
      }
    ]
  },
  {
    id: 1,
    label: "Credit 관리",
    route: "credit-management",
    children: [
      {
        id: 0,
        label: "Credit 상품관리",
        route: "/credit-management/credit-product-management"
      },
      {
        id: 1,
        label: "충전 완료 목록",
        route: "/credit-management/charging-complete-list"
      },
      {
        id: 2,
        label: "환불 관리 목록",
        route: "/credit-management/refund-management-list"
      },

      {
        id: 3,
        label: "증정 Credit 관리",
        route: "/credit-management/gift-credit-management"
      },
      {
        id: 4,
        label: "크레딧 사용내역",
        route: "/credit-management/main-credit-usage-history"
      }
    ]
  },
  {
    id: 2,
    label: "업로더 관리",
    route: "uploader-management",
    children: [
      {
        id: 0,
        label: "업로더 목록",
        route: "/uploader-management/uploader-list"
      },
      {
        id: 1,
        label: "Profit 사용 내역",
        route: "/uploader-management/profit-usage-history"
      },
      {
        id: 2,
        // label: "출금 접수 목록",
        label: "Profit 출금 접수 목록",
        route: "/uploader-management/withdrawal-receipt-list"
      },
      {
        id: 3,
        label: "Sponsor AD ITEM 목록",
        route: "/uploader-management/list-of-sponsor-ad-items"
      },
      {
        id: 4,
        label: "Sponsor AD ITEM 단가",
        route: "/uploader-management/sponsor-ad-item-unit-price"
      },
      {
        id: 5,
        // label: "T&C",
        label: "T&C (이용약관)",
        route: "/uploader-management/term-condition"
      }
    ]
  },
  // {
  //   id: 4,
  //   label: "출금 관리",
  //   route: "withdrawal-management",
  //   children: [
  //     {
  //       id: 0,
  //       label: "출금 접수 목록",
  //       route: "/withdrawal-management/withdrawal-receipt-list"
  //     },
  //     {
  //       id: 0,
  //       label: "출금 완료 목록",
  //       route: "/withdrawal-management/withdrawal-completed-list"
  //     }
  //   ]
  // },
  {
    id: 3,
    label: "패턴관리",
    route: "pattern-management",
    children: [
      {
        id: 0,
        label: "패턴 승인/등록",
        route: "/pattern-management/approve-registration"
      },
      {
        id: 1,
        label: "패턴 목록",
        route: "/pattern-management/pattern-list"
      },
      {
        id: 2,
        label: "패턴 카테고리",
        route: "/pattern-management/category"
      }
      // {
      //   id: 3,
      //   label: "Miss Cut",
      //   route: "/pattern-management/miss-cut"
      // }
    ]
  },
  // {
  //   id: 6,
  //   label: "Sponsor AD Item",
  //   route: "sponsor-management",
  //   children: [
  //     {
  //       id: 0,
  //       label: "Sponsor AD Item 목록",
  //       route: "/sponsor-management/sponsor-list"
  //     },
  //     {
  //       id: 1,
  //       label: "Sponsor AD Item 금액설정",
  //       route: "/sponsor-management/sponsor-item-price"
  //     }
  //   ]
  // },
  {
    id: 4,
    label: "Ultrafit Shop 관리",
    route: "ultrafit-shop-management",
    children: [
      {
        id: 0,
        label: "상품관리",
        route: "/ultrafit-shop-management/product"
      },
      {
        id: 1,
        label: "Shop 카테고리",
        route: "/ultrafit-shop-management/category"
      },
      {
        id: 2,
        label: "주문배송관리",
        route: "/ultrafit-shop-management/order-delivery-management"
      },
      // {
      //   id: 3,
      //   label: "주문취소",
      //   route: "/ultrafit-shop-management/order-cancellation"
      // },
      {
        id: 3,
        label: "정품인증 번호 목록",
        route: "/ultrafit-shop-management/genuine-authentication-numbers-list"
      }
    ]
  },
  {
    id: 5,
    label: "쿠폰관리",
    route: "coupon-management",
    children: [
      {
        id: 0,
        label: "쿠폰 목록",
        route: "/coupon-management/coupon-list"
      },
      {
        id: 1,
        label: "쿠폰 추가",
        route: "/coupon-management/add-coupon"
      }
    ]
  },
  {
    id: 6,
    label: "고객센터",
    route: "e-mail-management",
    children: [
      {
        id: 0,
        label: "보낸 이메일 관리",
        route: "/e-mail-management/list-emails"
      },
      {
        id: 1,
        label: "받은 이메일 관리",
        route: "/e-mail-management/received-emails"
      }
    ]
  },
  {
    id: 7,
    label: "배너관리",
    route: "popup-banner-management",
    children: [
      {
        id: 0,
        label: "배너 관리",
        route: "/popup-banner-management/banner-list"
      },
      {
        id: 1,
        label: "팝업 관리",
        route: "/popup-banner-management/popup-list"
      }
    ]
  },
  {
    id: 8,
    label: "환경설정",
    route: "preferences",
    children: [
      {
        id: 0,
        label: "서비스 이용 단가",
        route: "/preferences/service-use-unit-price"
      },
      {
        id: 1,
        label: "관리자 설정",
        route: "/preferences/admin-settings"
      },
      {
        id: 2,
        label: "배송지역 관리",
        route: "/preferences/region"
      },
      {
        id: 3,
        label: "배송중량 관리",
        route: "/preferences/weight"
      }
    ]
  }
];
