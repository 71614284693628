const URL = Object.freeze({
  GET_SHIPPING_REGION: "/shippings",
  UPDATE_SHIPPING_REGION: "/shippings",
  CREATE_SHIPPING_REGION: "/shippings",
  SET_SHIPPING_REGION: "/admin/shippingAreaRgionCreateUpdate",
  DELETE_SHIPPING_REGION: "/shippings/fee",
  GET_SHIPPING_WEIGHT: "/admin/shippingAreaWeightList",
  SET_SHIPPING_WEIGHT: "/admin/shippingAreaWeightCreateUpdate",
  UPDATE_SHIPPING_WEIGHT: "/shipping-weight",
  CREATE_FLOAT_VARIABLES: "/float-variables",
  GET_FLOAT_VARIABLES: "/float-variables",
  UPDATE_FLOAT_VARIABLES: "/float-variables",
  GET_ADMIN: "/admins",
  CREATE_ADMIN: "/admins",
  UPDATE_ADMIN: "/admins",
  DELETE_ADMIN: "/admins"
});

export default URL;
