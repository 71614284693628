import React, { useState, useEffect } from "react";
import withToast from "HOC/withToast";
import Layout from "components/Layout";
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  CheckBox,
} from "@mui/material";
import DropAndDrag from "./components/DropAndDrag";
import { PreferencesService } from "services";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import { listGroupBy, sortList } from "utils/function";
import LoadingPage from "components/LoadingPage";
import AddIcon from "@mui/icons-material/Add";
import Excel from "exceljs";
import * as XLSX from "xlsx";

function SettingTheShippingFee({ showToast }) {
  const [loading, setLoading] = useState(true);

  const [timeReCall, setTimeReCall] = useState(0);
  const [listArea, setListArea] = useState({});
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PreferencesService.getShippingRegion();
        if (response?.data?.statusCode === 200) {
          const data = response?.data?.data ?? [];
          setListArea(listGroupBy(data, "region"));
          // setListArea(data)
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeReCall]);

  // const handleAddArea = async () => {
  //   const numArea = Object.keys(listArea).length;
  //   const nextArea = Number(Object.keys(listArea)[numArea - 1]) + 1;
  //   try {
  //     const response = await PreferencesService.setShippingRegion({
  //       // dept: title,
  //       // name: nameCategoryAdded,
  //       // parent: `${parent}` ?? "",
  //       // index: items.length,
  //       area: nextArea,
  //       region: "",
  //       index: -1
  //     });
  //     if (
  //       response.status === 201 &&
  //       response?.data?.status === SUCCESS_STATUS
  //     ) {
  //       setTimeReCall((prevState) => prevState + 1);
  //       showToast(TOAST_TYPE.SUCCESS, "Add success");
  //     } else {
  //       const errorData = response?.data?.message ?? "Error";
  //       showToast(TOAST_TYPE.ERROR, errorData);
  //     }
  //   } catch (error) {
  //     const errorStatusText = error?.response ? error.response?.statusText : "";
  //     const errorDataMessage = error?.response
  //       ? error.response?.data
  //         ? error.response.data?.message
  //         : ""
  //       : "";
  //     const errorMessage =
  //       errorDataMessage ?? errorStatusText ?? "System Error";
  //     showToast(TOAST_TYPE.ERROR, errorMessage);
  //   }
  // };
  const handleAddArea = async () => {
    const numArea = Object.keys(listArea).length;
    const nextArea = numArea + 1;
    try {
      // const response = await PreferencesService.setShippingRegion({
      //   // dept: title,
      //   // name: nameCategoryAdded,
      //   // parent: `${parent}` ?? "",
      //   // index: items.length,
      //   area: nextArea,
      //   region: "",
      //   index: -1
      // });
      const response = await PreferencesService.createShippingRegion({
        region: `${nextArea}`,
        order: 1,
        countries: [],
        costs: [],
      });
      if (response?.data?.statusCode === 200) {
        setTimeReCall((prevState) => prevState + 1);
        showToast(TOAST_TYPE.SUCCESS, "Add success");
      } else {
        const errorData = response?.data?.message ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDownloadExcel = async () => {
    const fileName = "환경설정 - 배송료 구간 설정";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("환경설정 - 배송료 구간 설정");

    const headersTitle = [];

    const columnExcel = [];
    const columnWidth = [];
    const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

    if (Object.keys(listArea).length > 0) {
      Object.keys(listArea).forEach((area, index) => {
        headersTitle.push(`${area} 지역`);
        columnExcel.push(alphabet[index].toUpperCase());
        columnWidth.push(30);
      });
    }

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" },
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    if (Object.keys(listArea).length > 0) {
      Object.keys(listArea).forEach((area, indexColumn) => {
        const listCountries = listArea[area]?.[0].countries;
        listCountries.forEach((country, index) => {
          ws.getCell(`${columnExcel[indexColumn]}${index + 2}`).value =
            country.country;
          ws.getCell(`A${index + 2}`).style = {
            alignment: { vertical: "center", horizontal: "center" },
          };
        });
      });
    }

    // const calculateFee = (netAmount, amount) => {
    //   if (!netAmount || !amount) return "";
    //   return Number(netAmount) - Number(amount);
    // };

    // const calculatePayment = (payment, amount) => {
    //   if (payment) return payment;
    //   if (amount) return amount;
    //   return "";
    // };

    // add value, style cell // body
    // transactions.forEach((transaction, index) => {
    //   const charge = transaction?.netAmount;
    //   const payment = calculatePayment(
    //     transaction?.payment,
    //     transaction?.amount
    //   );
    //   const deposited = transaction?.amount;
    //   ws.getCell(`A${index + 2}`).value = transaction.code
    //     ? transaction.code
    //     : "";
    //   ws.getCell(`A${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`B${index + 2}`).value = "";
    //   ws.getCell(`B${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`C${index + 2}`).value = transaction.nick
    //     ? transaction.nick
    //     : "";
    //   ws.getCell(`C${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`D${index + 2}`).value = transaction.productName
    //     ? transaction.productName
    //     : "";
    //   ws.getCell(`D${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`E${index + 2}`).value = charge
    //     ? Number(charge) + " Credit"
    //     : "";
    //   ws.getCell(`E${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`F${index + 2}`).value = transaction.hoarding
    //     ? Number(transaction.hoarding) + " Credit"
    //     : "";
    //   ws.getCell(`F${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`G${index + 2}`).value = payment ? "$" + payment : "";
    //   ws.getCell(`G${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`H${index + 2}`).value = deposited
    //     ? "$" + Number(deposited)
    //     : "";
    //   ws.getCell(`H${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`I${index + 2}`).value = "";
    //   ws.getCell(`I${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   ws.getCell(`J${index + 2}`).value = "";
    //   ws.getCell(`J${index + 2}`).style = {
    //     alignment: { vertical: "center", horizontal: "center" }
    //   };
    //   // ws.getCell(`I${index + 2}`).value = transaction.externalId
    //   //   ? transaction.externalId
    //   //   : "";
    //   // ws.getCell(`I${index + 2}`).style = {
    //   //   alignment: { vertical: "center", horizontal: "center" },
    //   // };
    // });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleUploadExcel = () => {};

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined);
      return;
    }

    setUploading(true);
    const fileName = e.target.files[0].name;
    if (fileName?.includes(".xlsx") || fileName?.includes(".xls")) {
      setSelectedFile(fileName);

      const promiseReadData = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const ws = wb.Sheets["수출_IP"];

          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
          setUploading(false);
        };
      });

      await promiseReadData
        .then(async (listRow) => {
          if (Object.keys(listArea)?.length) {
            for (let c of Object.keys(listArea)) {
              const idArea = listArea[c]?.[0].id;
              console.log('idArea', idArea)
              await PreferencesService.deleteShippingRegion(idArea);
            }
          }

          let listCountry = [];

          try {
            for (let i = 0; i < listRow[2]?.length - 1; i++) {
              // for (let i = 0; i < 2; i++) {
              const response = await PreferencesService.createShippingRegion({
                region: `${i + 1}`,
                order: 1,
                countries: [],
                costs: [],
              });
              if (response?.data?.statusCode === 200) {
                listCountry.push(response?.data?.data);
              } else {
                listCountry = [];
                break;
              }
            }
          } catch (e) {
            listCountry = [];
          }

          if (listCountry?.length) {
            for (let rowi = 2; rowi < listRow?.length - 1; rowi++) {
              // for (let rowi = 2; rowi < 4; rowi++) {
              const data = listRow[rowi];
              for (let rowj = 0; rowj < listCountry?.length; rowj++) {
                // for (let rowj = 0; rowj < 2; rowj++) {
                const country = listCountry[rowj] || {};
                const addData = data[rowj];
                if (String(addData)?.trim()) {
                  const response =
                    await PreferencesService.updateShippingRegion(country?.id, {
                      ...country,
                      countries: [
                        ...country?.countries,
                        {
                          order: country?.countries?.length,
                          country: String(addData)?.trim(),
                        },
                      ],
                    });
                  if (response?.data?.statusCode === 200) {
                    listCountry[rowj] = response?.data?.data;
                  }
                }
              }
            }
            setUploading(false);
          } else {
            setUploading(false);
          }
          setTimeReCall((prevState) => prevState + 1);

          // await Promise.all(
          //   dataFillDB.map((item) => {
          //     if (item?.id) {
          //       return PreferencesService.updateShippingRegion(item.id, {
          //         ...item,
          //       });
          //     } else {
          //       return PreferencesService.createShippingRegion({
          //         ...item,
          //       });
          //     }
          //   })
          // ).catch(function (err) {
          //   showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
          //   setUploading(false);
          // });

          // setTimeReCall((prevState) => prevState + 1);
        })
        .catch((err) => {
          // showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
          showToast(TOAST_TYPE.ERROR, err?.message || "Wrong file");
          setUploading(false);
        });
      setUploading(false);
    } else {
      showToast(TOAST_TYPE.ERROR, "Wrong file extension");
      setUploading(false);
      return;
    }
  };

  return (
    <Layout header="환경설정 - 배송지역 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ mb: 5 }}>배송지역 관리</Typography>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 5 }}
          >
            <Button
              variant="contained"
              style={{
                border: "1px solid #c4c4c4",
                textTransform: "capitalize",
                marginRight: "5px",
              }}
              onClick={handleUploadExcel}
            >
              엑셀 파일 갖고오기
              <input
                type="file"
                // hidden
                disabled={uploading}
                onChange={(e) => {
                  handleSelectFile(e);
                  e.target.value = null;
                }}
                style={{
                  position: "absolute",
                  left: "0px",
                  width: "180px",
                  height: "36px",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </Button>
            <Button
              variant="outline"
              style={{
                border: "1px solid #c4c4c4",
                textTransform: "capitalize",
              }}
              onClick={handleDownloadExcel}
            >
              Excel 파일 다운받기
            </Button>
          </Grid>
          <Box
            sx={{
              whiteSpace: "nowrap",
              width: "100%",
              overflowX: "scroll",
            }}
          >
            {!!Object.keys(listArea).length
              ? Object.keys(listArea).map((area, index) => {
                  const listCountries = listArea[area]?.[0].countries;
                  const listItems = listCountries.filter(
                    (item) => item.order !== -1
                  );

                  console.log("listArea", listArea);

                  return (
                    <Box key={index} sx={{ display: "inline-block" }}>
                      <DropAndDrag
                        setTimeReCall={setTimeReCall}
                        area={area}
                        listItems={listItems}
                        // ListItem={[]}
                        showToast={showToast}
                        regionDetail={listArea[area]?.[0]}
                      />
                    </Box>
                  );
                })
              : null}
            <Box sx={{ display: "inline-block" }}>
              <Box
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleAddArea}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  지역 추가하기
                </Typography>
                <AddIcon
                  fontSize="large"
                  style={{ width: "50px", height: "50px" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default withToast(SettingTheShippingFee);
