import { couponConstants } from "../constants";

const initState = {
  loading: true,
  coupons: [],
  count: 0,
  error: null
};

const couponReducer = (state = initState, action) => {
  switch (action.type) {
    case couponConstants.GET_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        coupons: action.payload.coupons,
        count: action.payload.count,
        error: null
      };
    case couponConstants.GET_COUPONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case couponConstants.UPDATE_COUPON_STAGE: {
      const { id = "", stage = "" } = action.payload;
      const { coupons = [] } = state;
      const newCoupons = coupons.map((coupon) => {
        if (id === coupon.id) {
          return { ...coupon, stage: stage };
        }
        return coupon;
      });

      return {
        ...state,
        orders: newCoupons
      };
    }
    case couponConstants.DELETE_COUPONS: {
      const { ids = [] } = action.payload;
      const { coupons = [] } = state;
      let newCoupons = [...coupons];

      ids.forEach((id) => {
        newCoupons = newCoupons.filter((item) => item.id !== id);
      });

      return {
        ...state,
        coupons: newCoupons
      };
    }
    case couponConstants.RESET_COUPONS: {
      return {
        ...state,
        loading: true,
        coupons: [],
        error: null
      };
    }
    default:
      return state;
  }
};

export default couponReducer;
