import { Button, Modal, Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "none",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90%"
};

export default function Popup(props) {
  const {
    open = false,
    setOpen = () => {},
    button,
    image,
    onClick = () => {}
  } = props;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <Box
          style={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            objectFit: "fill"
          }}
        >
          <img
            src={image}
            alt=""
            style={{
              width: "640px"
            }}
          />
        </Box>
        <Button
          variant="outline"
          style={{
            // border: "1px solid #c4c4c4",
            textTransform: "capitalize",
            margin: "20px 0",
            color: "white",
            backgroundColor: "#5b9bd5"
          }}
          onClick={onClick}
        >
          {button}
        </Button>
      </Box>
    </Modal>
  );
}
