import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { withQuery, withToast } from "HOC";
import { ManageCreditService } from "services";
import { TOAST_TYPE, SUCCESS_STATUS } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Box
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
import { formatDate, formatNumber } from "utils/function";
import { useNavigate } from "react-router-dom";
import { ManageWithdrawService } from "services";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    cancel: createColor("#ff5454"),
    approve: createColor("#fff")
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 30,
    padding: "10px 20px",
    width: "150px",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    fontWeight: "bold"
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 30,
    padding: 5,
    width: "150px",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: "#ffff",
    backgroundColor: "#0468b4",
    fontWeight: "bold"
  }
}));

function DetailWithdrawalReceipt({ searchParams, showToast }) {
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState({});
  const [loadingProcess, setLoadingProcess] = useState({
    cancelling: false,
    approving: false
  });
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ManageCreditService.getListTransaction({
          ...searchParams,
          description: "payout"
        });
        if (response.status === 201) {
          setTransaction(
            response?.data ? response.data?.transactions[0] : {} ?? {}
          );
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate("/withdrawal-management/withdrawal-receipt-list");
  };

  const handleCancelPayout = async () => {
    const data = {
      userIds: []
    };
    setLoadingProcess({ ...loadingProcess, cancelling: true });
    try {
      const response = await ManageWithdrawService.cancelPayout(data);
      if (response.status === 201 && response.data.status === SUCCESS_STATUS) {
        showToast(TOAST_TYPE.SUCCESS, "Cancelled");
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleApprovePayout = async () => {
    const data = {
      userIds: []
    };
    setLoadingProcess({ ...loadingProcess, approving: true });
    try {
      const response = await ManageWithdrawService.approvePayout(data);
      if (response.status === 201 && response.data.status === SUCCESS_STATUS) {
        showToast(TOAST_TYPE.SUCCESS, "Approved");
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, approving: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, approving: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Layout header="출금 관리 - 출금 접수 목록 - 상세보기">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontWeight: "bold"
          }}
        >
          <Box
            sx={{
              border: "1px solid #a5a5a5",
              borderRadius: 1,
              padding: "5px 10px",
              textAlign: "center",
              mt: 5
            }}
          >
            출금 요청 정보
          </Box>
          <Box sx={{ mt: 2 }}>
            <TableContainer style={{ border: "1px solid #a5a5a5" }}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <StyledTableCellLeft>출금자명</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction?.nick ? transaction.nick : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>출금 요청 일시</StyledTableCellLeft>
                    <StyledTableCell>
                      {transaction?.createdAt
                        ? formatDate(transaction.createdAt)
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>Profit 잔액</StyledTableCellLeft>
                    <StyledTableCell style={{ textTransform: "capitalize" }}>
                      {transaction?.hoarding
                        ? "$ " + formatNumber(Number(transaction.hoarding))
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>출금 금액</StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.amount
                        ? "$" + formatNumber(Number(transaction.amount))
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>요청 수단</StyledTableCellLeft>
                    <StyledTableCell width="50%">Paypal</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>
                      Paypal 이메일 주소
                    </StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.ppEmail ? transaction.ppEmail : ""}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCellLeft>총 출금 금액</StyledTableCellLeft>
                    <StyledTableCell width="50%">
                      {transaction?.total
                        ? "$" + formatNumber(Number(transaction.total))
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ mt: 8, display: "flex" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <ThemeProvider theme={theme}>
                <LoadingButton
                  style={{
                    textTransform: "capitalize",
                    border: "1px solid #a5a5a5",
                    width: 150,
                    fontWeight: "bold"
                  }}
                  color="cancel"
                  onClick={handleCancelPayout}
                  loading={loadingProcess["cancelling"] ? true : false}
                  loadingIndicator="취소"
                  variant="contained"
                >
                  출금취소
                </LoadingButton>
                <LoadingButton
                  style={{
                    textTransform: "capitalize",
                    border: "1px solid #a5a5a5",
                    width: 150,
                    marginLeft: 20,
                    fontWeight: "bold"
                  }}
                  color="approve"
                  onClick={handleApprovePayout}
                  loading={loadingProcess["approving"] ? true : false}
                  loadingIndicator="승인"
                  variant="contained"
                >
                  출금승인
                </LoadingButton>
              </ThemeProvider>
            </Box>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                width: 150,
                marginLeft: 20,
                flexBasis: "50%",
                fontWeight: "bold"
              }}
              onClick={handleBack}
            >
              목록
            </Button>
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default withQuery(withToast(DetailWithdrawalReceipt));
