import { LoginService } from "services";
import { authConstants } from "../constants";

export const signIn = (jwt, refreshToken, user, isAutoLogin) => {
  return async (dispatch) => {
    localStorage.setItem("token", jwt);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("isAutoLogin", isAutoLogin);
    dispatch({
      type: authConstants.LOGIN_SUCCESS,
      payload: {
        token: jwt,
        user: user
      }
    });
  };
};

export const updateToken = (jwt) => {
  return async (dispatch) => {
    dispatch({
      type: authConstants.UPDATE_TOKEN,
      payload: {
        token: jwt
      }
    });
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    // const refreshToken = localStorage.getItem("refreshToken");
    // const isAutoLogin = JSON.parse(localStorage.getItem("isAutoLogin"));

    // if (isAutoLogin && refreshToken) {
    //   const response = await LoginService.getToken({ token: refreshToken });
    //   const newToken = response?.data?.jwt ?? "";
    //   localStorage.setItem("token", newToken);
    // }

    const token = localStorage.getItem("token");

    if (token) {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          user
        }
      });
    } else {
      dispatch(signOut());
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({ type: authConstants.LOGOUT_SUCCESS });
  };
};
