import { ManageCouponService } from "services";
import { couponConstants } from "../constants";

export const getListCoupon = (condition = {}) => {
  return async (dispatch) => {
    try {
      const res = await ManageCouponService.getCouponList(condition);
      if (res?.data?.statusCode === 200) {
        dispatch({
          type: couponConstants.GET_COUPONS_SUCCESS,
          payload: {
            coupons: res?.data?.data ?? [],
            count: res?.data?.count ?? 0
          }
        });
      } else {
        dispatch({
          type: couponConstants.GET_COUPONS_FAIL,
          payload: res?.data?.message ?? ""
        });
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      dispatch({
        type: couponConstants.GET_COUPONS_FAIL,
        payload: message
      });
    }
  };
};

// export const updateOrderStatus = (data) => {
//   return (dispatch) => {
//     dispatch({
//       type: orderConstants.UPDATE_ORDER_STATUS,
//       payload: data,
//     });
//     dispatch(getListOrder());
//   };
// };

export const updateCouponStage = (data) => {
  return (dispatch) => {
    dispatch({
      type: couponConstants.UPDATE_COUPON_STAGE,
      payload: data
    });
    dispatch(getListCoupon());
  };
};

export const deleteCoupons = (ids, condition) => {
  return (dispatch) => {
    dispatch({
      type: couponConstants.DELETE_COUPONS,
      payload: ids
    });
    dispatch(getListCoupon(condition));
  };
};

export const resetCoupons = () => {
  return (dispatch) => {
    dispatch({
      type: couponConstants.RESET_COUPONS
    });
  };
};
