import { PrivateRouter } from "HOC";
import ListEmail from "../ListEmail";
import EmailAutomaticEdit from "../EmailAutomaticEdit";
import EmailAdd from "../EmailAdd";
import ListEmailReceived from "../ListEmailReceived";
import EmailReceivedDetail from "../EmailReceivedDetail";
import HistoryEmailSent from "../HistoryEmailSent";
import ManualSendEmail from "../ManualSendEmail";
import EmailManualAdd from "../EmailManualAdd";
import EmailManualEdit from "../EmailManualEdit";
import ReplyReceivedEmail from "../ReplyReceivedEmail";

const routerManageEmail = [
  {
    path: "/e-mail-management/list-emails",
    element: (
      <PrivateRouter>
        <ListEmail />
      </PrivateRouter>
    )
  },
  {
    path: "/e-mail-management/list-emails/history-email-sent/:id",
    element: (
      <PrivateRouter>
        <HistoryEmailSent />
      </PrivateRouter>
    )
  },
  {
    path: "/e-mail-management/list-emails/manual-send-email/:id",
    element: (
      <PrivateRouter>
        <ManualSendEmail />
      </PrivateRouter>
    )
  },
  {
    path: "/e-mail-management/list-emails/add-manual-email",
    element: (
      <PrivateRouter>
        <EmailManualAdd />
      </PrivateRouter>
    )
  },
  {
    path: "/e-mail-management/list-emails/edit-manual-email/:id",
    element: (
      <PrivateRouter>
        <EmailManualEdit />
      </PrivateRouter>
    )
  },
  // {
  //   path: "/e-mail-management/list-emails/edit-email-automatic-send/:id",
  //   element: (
  //     <PrivateRouter>
  //       <EmailAutomaticEdit />
  //     </PrivateRouter>
  //   )
  // },
  {
    path: "/e-mail-management/sent-emails/add",
    element: (
      <PrivateRouter>
        <EmailAdd />
      </PrivateRouter>
    )
  },
  {
    path: "/e-mail-management/received-emails",
    element: (
      <PrivateRouter>
        <ListEmailReceived />
      </PrivateRouter>
    )
  },
  {
    path: "/e-mail-management/received-emails/detail/:id",
    element: (
      <PrivateRouter>
        <EmailReceivedDetail />
      </PrivateRouter>
    )
  },
  {
    path: "/e-mail-management/received-emails/reply/:id",
    element: (
      <PrivateRouter>
        <ReplyReceivedEmail />
      </PrivateRouter>
    )
  }
];

export default routerManageEmail;
