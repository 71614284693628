import URL from "./url";
import axiosClient from "../axiosClient";

class ManageWithdrawService {
  approvePayout(body = {}) {
    const url = URL.APPROVE_PAYOUT;
    return axiosClient.post(url, body);
  }
  cancelPayout(body = {}) {
    const url = URL.CANCEL_PAYOUT;
    return axiosClient.post(url, body);
  }
}

export default new ManageWithdrawService();
