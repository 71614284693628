import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/Table";
import {
  formatDate,
  formatDateSearch,
  formatDateSearchEnd,
  formatDateSearchStart,
  formatNumber
} from "utils/function";
import {
  getListMember,
  resetMembers,
  updateMemberSuspension
} from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageUploader } from "services";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Popup from "./components/Popup";
import Excel from "exceljs";

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "history", text: "내역" }
];

const listLevels = [
  { value: "beginner", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

const listActivityEnToKo = (activity) => {
  switch (activity) {
    case "earn_credit":
      return "플로팅 수익";
    case "earn_profit":
      return "플로팅 수익";
    case "payout":
      return "출금";
    case "sponsor_ads":
      return "AD구매";
    case "profit_ultrafit_shop":
      return "Shop구매";
    case "profit_ultrafit_shop_refund":
      return "환불";
    default:
      return activity;
  }
};

const filterSearch = (string) => {
  return string
    .trim()
    .replace(/[\s,.-]+/gi, " ")
    .split(" ")
    .map((el) => el?.toLowerCase())
    .filter(Boolean);
};

const isMatchSearchCondition = (keyword, search) => {
  const transformSearch = filterSearch(search || "");
  const keywordList = (typeof keyword === "string" ? [keyword] : keyword).map(
    (el) => el?.toLowerCase()
  );

  if (
    transformSearch
      .filter(Boolean)
      .some((item) => keywordList.some((kw) => kw.includes(item)))
  ) {
    return true;
  }

  if (
    keywordList
      .filter(Boolean)
      .some((item) => transformSearch.some((ts) => ts.includes(item)))
  ) {
    return true;
  }

  return false;
};

const mapActivityKoToEn = (activity) => {
  const newActivity = activity ? activity.trim() : "";
  const result = [];
  // if (
  //   isMatchSearchCondition(["Credit Charge", "Credit", "Charge"], newActivity)
  // ) {
  //   result.push("credit_charge");
  // }
  // if (isMatchSearchCondition(["플로팅", "플로", "로팅"], newActivity)) {
  //   result.push("plotting");
  // }
  if (isMatchSearchCondition(["AD구매", "AD", "구매"], newActivity)) {
    result.push("sponsor_ads");
  }
  if (isMatchSearchCondition(["Shop구매", "Shop", "구매"], newActivity)) {
    // result.push("credit_ultrafit_shop");
    result.push("profit_ultrafit_shop");
  }
  // if (isMatchSearchCondition(["충전보너스", "충전", "보너스"], newActivity)) {
  //   result.push("charge_bonus");
  // }
  // if (isMatchSearchCondition(["정품인증", "정품", "인증"], newActivity)) {
  //   result.push("code_bonus");
  // }
  // if (
  //   isMatchSearchCondition(
  //     ["Credit Charge 환불", "Credit", "Charge", "Credit Charge", "환불"],
  //     newActivity
  //   )
  // ) {
  //   result.push("credit_charge_refund");
  // }
  if (isMatchSearchCondition(["환불"], newActivity)) {
    // result.push("credit_ultrafit_shop_refund");
    result.push("profit_ultrafit_shop_refund");
  }
  // if (isMatchSearchCondition(["리워드", "리워", "워드"], newActivity)) {
  //   result.push("reward_bonus");
  // }
  // if (isMatchSearchCondition(["리뷰"], newActivity)) {
  //   result.push("review");
  // }
  // if (
  //   isMatchSearchCondition(
  //     ["Edit&Plot Subscription", "Edit", "Plot", "Subscription"],
  //     newActivity
  //   )
  // ) {
  //   result.push("buy_premium");
  // }
  if (isMatchSearchCondition(["출금"], newActivity)) {
    result.push("payout");
  }
  if (isMatchSearchCondition(["플로팅 수익", "플로팅", "수익"], newActivity)) {
    result.push("earn_credit");
    result.push("earn_profit");
  }
  return result.filter(Boolean).join(",");
};

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    suspend: createColor("#ff5454")
  }
});

function ProfitUsageHistory({ showToast }) {
  const [listTransaction, setListTransaction] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listChecked, setListChecked] = useState([]);
  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: ""
  });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [state, dispatch] = useGlobalStore();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleCreateCondition = (isSearching = false, isResetting = false) => {
    const { type = "", search = "" } = searchTerms;
    let condition = {};
    if (isSearching || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearching ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    // if (type) {
    if (type !== "history" && type !== "all" && type !== "") {
      condition = {
        ...condition,
        type: type ? type : "all",
        search: search ? search.trim() : ""
      };
    } else if (
      (type === "all" || type === "") &&
      search &&
      search.trim() &&
      mapActivityKoToEn(search.trim())
    ) {
      condition = {
        ...condition,
        activity: mapActivityKoToEn(search.trim())
      };
    } else if (
      (type === "all" || type === "") &&
      search &&
      search.trim() &&
      !mapActivityKoToEn(search.trim())
    ) {
      condition = {
        ...condition,
        type: "all",
        search: search ? search.trim() : ""
      };
    } else if (search && search.trim()) {
      condition = {
        ...condition
      };
      if (mapActivityKoToEn(search.trim())) {
        condition = {
          ...condition,
          activity: mapActivityKoToEn(search.trim())
        };
      }
    }
    // }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD HH:mm:ss")
      };
    }

    return condition;
  };

  async function fetchData(isSearching = false, isResetting = false) {
    try {
      const response = await ManageUploader.getProfitHistory(
        handleCreateCondition(isSearching, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListTransaction(response?.data ? response.data?.data : [] ?? []);
        setCount(response?.data?.count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };
  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "" });
    setDateFrom(null);
    setDateTo(null);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
  };

  const handleCreateExcel = async (transactions) => {
    const fileName = "업로더 관리 - Profit 사용 내역";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("업로더 관리 - Profit 사용 내역");

    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "사용일",
      "내역",
      "적립 Profit ",
      "사용 Profit",
      "출금 Profit",
      "잔여 Profit"
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const columnWidth = [10, 20, 30, 20, 20, 20, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // const calculateFee = (netAmount, amount) => {
    //   if (!netAmount || !amount) return "";
    //   return Number(netAmount) - Number(amount);
    // };

    const calculatePayment = (payment, amount) => {
      if (payment) return payment;
      if (amount) return amount;
      return "";
    };

    // add value, style cell // body
    transactions.forEach((transaction, index) => {
      const charge = transaction?.netAmount;
      const payment = calculatePayment(
        transaction?.payment,
        transaction?.amount
      );
      const deposited = transaction?.amount;
      ws.getCell(`A${index + 2}`).value = index + 1;
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = transaction?.user?.nickName ?? "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = transaction?.user?.email ?? "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = transaction.createdAt
        ? formatDate(transaction.createdAt)
        : "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = transaction.activity
        ? listActivityEnToKo(transaction.activity)
        : "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value =
        transaction?.activity !== "profit_ultrafit_shop_refund" &&
        transaction?.activity !== "payout"
          ? transaction?.amount > 0
            ? transaction?.amount
            : 0
          : 0;
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`G${index + 2}`).value =
        transaction?.activity !== "profit_ultrafit_shop_refund" &&
        transaction?.activity !== "payout"
          ? transaction?.amount < 0
            ? Math.abs(transaction?.amount)
            : 0
          : 0;
      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`H${index + 2}`).value =
        transaction?.activity === "profit_ultrafit_shop_refund" ||
        transaction?.activity === "payout"
          ? Math.abs(transaction?.amount)
          : 0;
      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`I${index + 2}`).value = transaction?.balance ?? "";
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    const { type = "", search = "" } = searchTerms;
    let condition = {};
    if (isDownloadFilter) {
      // if (type) {
      if (type !== "history" && type !== "all" && type !== "") {
        condition = {
          ...condition,
          type: type ? type : "all",
          search: search ? search.trim() : ""
        };
      } else if (
        (type === "all" || type === "") &&
        search &&
        search.trim() &&
        mapActivityKoToEn(search.trim())
      ) {
        condition = {
          ...condition,
          activity: mapActivityKoToEn(search.trim())
        };
      } else if (
        (type === "all" || type === "") &&
        search &&
        search.trim() &&
        !mapActivityKoToEn(search.trim())
      ) {
        condition = {
          ...condition,
          type: "all",
          search: search ? search.trim() : ""
        };
      } else if (search && search.trim()) {
        condition = {
          ...condition
        };
        if (mapActivityKoToEn(search.trim())) {
          condition = {
            ...condition,
            activity: mapActivityKoToEn(search.trim())
          };
        }
      }
      // }
      if (dateFrom) {
        condition = {
          ...condition,
          from: formatDateSearch(dateFrom, "YYYY-MM-DD HH:mm:ss")
        };
      }

      if (dateTo) {
        condition = {
          ...condition,
          to: formatDateSearch(dateTo, "YYYY-MM-DD HH:mm:ss")
        };
      }
    }
    let listTransaction = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    // handleCreateExcel([]);
    try {
      const response = await ManageUploader.getProfitHistory(condition);
      if (response?.data?.statusCode === 200) {
        const transactions = response?.data ? response.data?.data : [] ?? [];
        listTransaction = [...transactions];
        handleCreateExcel(listTransaction);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const renderTable = () => {
    const sizeWidth = [
      "3%",
      "10%",
      "20%",
      "13%",
      "9%",
      "13%",
      "10%",
      "10%",
      "12%"
    ];

    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "사용일",
      "내역",
      "적립 Profit ",
      "사용 Profit",
      "출금 Profit",
      "잔여 Profit"
    ];

    const tableData = listTransaction.map((transaction, index) => {
      return {
        ...transaction,
        index: index + 1 + rowsPerPage * page,
        // checkbox: (
        //   <Checkbox
        //     checked={listChecked.includes(member.id)}
        //     onChange={(e) => handleSelectUser(e, member.id)}
        //   />
        // ),
        createdAt: transaction.createdAt
          ? formatDate(transaction.createdAt)
          : "",
        nickName: transaction?.user?.nickName ?? "",
        email: transaction?.user?.email ?? "",
        dateOfUse: transaction.createdAt
          ? formatDate(transaction.createdAt)
          : "",
        // history: transaction?.activity?.replace("_", " "),
        history: transaction.activity
          ? listActivityEnToKo(transaction.activity)
          : "",
        earnProfit:
          transaction?.type === "deposit" ? Math.abs(transaction?.amount) : 0,
        useProfit:
          transaction?.type === "withdrawal" &&
          transaction?.activity !== "payout"
            ? Math.abs(transaction?.amount)
            : 0,
        withdrawalProfit:
          transaction?.type === "withdrawal" &&
          transaction?.activity === "payout"
            ? Math.abs(transaction?.amount)
            : 0,
        residualProfit: Math.abs(transaction?.balance) ?? 0
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography
            style={{
              fontWeight: "bold",
              backgroundColor: "#003f77",
              color: "white",
              maxWidth: "130px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "32px"
            }}
          >
            Profit 사용 내역
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  사용일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="업로더 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item style={{ fontWeight: "bold" }}>
                <span>총 </span>
                <span style={{ color: "#0468b4" }}>
                  {count ? formatNumber(count) : 0}
                </span>
                <span>명</span>
              </Grid>
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel 파일 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(ProfitUsageHistory);
