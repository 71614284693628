import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { ProfileService } from "services";
import get from 'lodash/get';
import { signOut } from "redux/actions/auth";
import { useGlobalStore } from "hooks";
import { useNavigate } from "react-router-dom";

const PrivateRouter = ({ children }) => {
  const { authenticate = false } = useSelector((state) => state.auth);

  const location = useLocation();
  const [state, dispatch] = useGlobalStore();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  
  const handleComparePassword = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const res = await ProfileService.getAdminProfile();
      const password = get(res, 'data.data.password', null)
      if (user?.password && password && user?.password !== password) {
        dispatch(signOut());
        navigate('/login', { replace: true })
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    handleComparePassword();
  }, [location])

  if (token || authenticate) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default PrivateRouter;
