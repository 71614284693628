import React, { useEffect, useMemo } from "react";
import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import StyledTabPanel from "./components/TabPanel";
import { useGlobalStore } from "hooks";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import { getListCredit } from "redux/actions/credit";

function CreditProductManagement({ showToast }) {
  const [state, dispatch] = useGlobalStore();

  const {
    credits = [],
    loading = true,
    error = null
  } = useMemo(() => state?.credit, [state]);

  useEffect(() => {
    dispatch(getListCredit());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);
  return (
    <Layout header="Credit 관리 - Credit 상품 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <StyledTabPanel
          credits={credits}
          dispatch={dispatch}
          showToast={showToast}
        />
      )}
    </Layout>
  );
}

export default withToast(CreditProductManagement);
