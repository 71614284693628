export const COUPON_TYPE = Object.freeze({
  discountCoupon: "Discount Coupon",
  shippingFeeFreeCoupon: "Shipping Fee Free Coupon"
});

export const ISSUANCE_TYPE = Object.freeze({
  signup: "Sign up",
  firstTimeCustomer: "First-time Customer",
  satisfyPurchaseQuantity: "Satisfy Purchase Quantity",
  satisfyThePurchaseAmount: "Satisfy The Purchase Amount"
});

export const ISSUANCE_CLASSIFICATION = Object.freeze({
  memberRegistration: "Member registration",
  firstTimePurchaser: "First-time purchaser",
  satisfyPurchaseQuantity: "Satisfy the purchase quantity",
  satisfyThePurchaseAmount: "Satisfy the purchase amount"
});

export const BENEFIT_TYPE = Object.freeze({
  discount: "금액할인",
  free_shipping: "배송비 무료",
  discount_shipping: "금액할인 + 배송비 무료",
  credit_presentation: "Credit 증정"
});
