import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import { ManageUltrafitShopService } from "services";
import { withToast } from "HOC";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormControl,
  Stack,
  Link
} from "@mui/material";
import {
  addDecimalNumber,
  formatDate,
  formatDateSearch,
  formatDateSearchEnd,
  formatDateSearchStart,
  formatNumber,
  getTimeFromToInToDay,
  getTimeFromToInYesterday,
  startAndEndOfMonth,
  startAndEndOfWeek,
  subtractDecimalNumber
} from "utils/function";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from "@mui/icons-material/Search";
import TableData from "./components/TableData";
import OrderDetail from "./components/OrderDetail";
import { useGlobalStore } from "hooks";
import {
  getListOrder,
  resetOrders,
  // updateOrderShipping,
  updateOrderStatus
} from "redux/actions/order";
import { useForm } from "react-hook-form";
import Excel from "exceljs";
import Popup from "components/Popup";
import ManageUltrafitShop from "services/ManageUltrafitShop/ManageUltrafitShop";

const listSearchField = [
  { value: "code", text: "고객 코드" },
  // { value: "date", text: "Date" },
  { value: "name", text: "주문자명" }
];

const listShippingCarrier = [
  {
    text: "FEDEX",
    value: "FEDEX"
  },
  {
    text: "DHL",
    value: "DHL"
  },
  {
    text: "UPS",
    value: "UPS"
  }
];

const shippingInfoUrl = {
  FEDEX: "https://www.fedex.com",
  DHL: "https://www.dhl.com",
  UPS: "https://www.ups.com"
};

const statusOrder = [
  { value: "new order", text: "신규 주문" },
  { value: "order reciept", text: "주문 접수" },
  { value: "shipping", text: "배송 처리" },
  { value: "delivery completed", text: "배송완료" }
];

const listStatus = [
  { value: "order_confirmation", text: "주문확인" },
  { value: "product_preparation", text: "상품준비중" },
  { value: "shipping", text: "배송중" },
  { value: "delivery", text: "배송완료" },
  { value: "cancellation_pending", text: "취소대기중" },
  { value: "cancellation_completed", text: "취소완료" },
  { value: "return_accepted", text: "반품접수" },
  { value: "return_processing", text: "반품처리중" },
  { value: "return_completed", text: "반품완료" }
  // { value: "reject", text: "반품반려" }
];

const listStatusEnToKo = {
  order_confirmation: "주문접수",
  product_preparation: "상품준비중",
  shipping: "배송중",
  delivery: "배송완료",
  cancellation_pending: "취소접수",
  cancellation_completed: "취소완료",
  return_accepted: "반품접수",
  return_processing: "반품처리중",
  return_completed: "반품완료",
  reject: "반품반려"
};

const listStatusKoToEn = {
  주문접수: "order_confirmation",
  상품준비중: "product_preparation",
  배송중: "shipping",
  배송완료: "delivery",
  취소접수: "cancellation_pending",
  취소완료: "cancellation_completed",
  반품접수: "return_accepted",
  반품처리중: "return_processing",
  반품완료: "return_completed",
  반품반려: "reject"
};

function convertStatus(status) {
  switch (status) {
    case "주문확인":
      return "order_confirmation";
    case "상품준비중":
      return "product_preparation";
    case "배송중":
      return "shipping";
    case "배송완료":
      return "delivery";
    case "취소대기중":
      return "cancellation_pending";
    case "취소완료":
      return "cancellation_completed";
    case "반품접수":
      return "return_accepted";
    case "반품처리중":
      return "return_processing";
    case "반품완료":
      return "return_completed";
    // case "반품반려":
    //   return "reject";
    default:
      return "unknown";
  }
}

const StyledButtonPeriod = styled(Button)(({ active }) => ({
  textTransform: "capitalize",
  backgroundColor: active === "true" ? "#1565c0" : "#fff",
  color: "#000",
  borderRadius: 0,
  border: "1px solid #c4c4c4",
  height: "33px",
  marginLeft: "5px"
}));

const StyledButtonPrint = styled(Button)({
  textTransform: "capitalize",
  borderRadius: 0,
  height: "33px",
  padding: "0 20px"
});

const StyledButtonDownload = styled(Button)({
  border: "1px solid #c4c4c4",
  textTransform: "capitalize",
  height: "33px",
  fontSize: "0.7rem"
});

const StyledBox = styled(Box)({
  fontSize: "0.9rem",
  height: "40px",
  backgroundColor: "#1565c0",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  padding: "5px 2px",
  marginLeft: "5px"
});

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "150px",
    height: height ? height : "25px",
    padding: "4px 10px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

const StyledSelect = styled(Select)({
  borderRadius: "0",
  height: "33px",
  width: "100px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
});

const StyledSelectStatus = styled(Select)({
  "& .MuiSvgIcon-root": {
    right: 0
  },
  "& .MuiSelect-select": {
    padding: "2px",
    // fontSize: "0.8rem",
    paddingRight: "18px !important"
  },
  borderRadius: "0",
  height: "33px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
});

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "product", text: "구매상품" },
  { value: "status", text: "상태" },
  { value: "waybill", text: "운송장 번호" }
];

function OrderDeliveryManagement({ showToast }) {
  // const [searching, setSearching] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: ""
  });

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [openDetail, setOpenDetail] = useState(false);

  const [dataDetail, setDataDetail] = useState({});

  const [state, dispatch] = useGlobalStore();

  const [checked, setChecked] = useState("");

  const { setValue, register } = useForm({
    defaultValues: {
      listShippingURL: {}
    }
  });

  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);
  const [quantity, setQuantity] = useState({
    newOrder: 0,
    orderReceipt: 0,
    shipping: 0
  });

  const [status, setStatus] = useState("배송중");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    orders = [],
    loading = true,
    count = 0,
    error = null
  } = useMemo(() => state?.order, [state]);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "" } = searchTerms;
    let condition = {};
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1
        ? {
            page: 1,
            limit: rowsPerPage
          }
        : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    if (type !== "status" && type !== "waybill" && type !== "all") {
      condition = {
        ...condition,
        type: type ? type : "all",
        search: search ? search.trim() : ""
      };
    } else {
      if (search && search.trim().length > 0) {
        if (type === "all") {
          condition = {
            ...condition,
            type: "all",
            status: convertStatus(search.trim() ?? ""),
            waybill: search.trim(),
            search: search.trim()
          };
        }
        if (type === "status") {
          condition = {
            ...condition,
            status: convertStatus(search.trim() ?? "")
          };
        }
        if (type === "waybill") {
          condition = {
            ...condition,
            waybill: search.trim()
          };
        }
      }
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo)
      };
    }

    return condition;
  };

  useEffect(() => {
    dispatch(getListOrder(handleCreateCondition()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  // useEffect(() => {
  //   let shipping = 0,
  //     orderReceipt = 0,
  //     newOrder = 0;
  //   orders.forEach((order) => {
  //     if (order.status === "shipping") shipping++;
  //     if (order.status === "order reciept") orderReceipt++;
  //     if (order.status === "new order") newOrder++;
  //     setValue(`listShippingURL[order${order.id}]`, order.shippingURL);
  //   });
  //   setQuantity({ shipping, orderReceipt, newOrder });
  // }, [orders, setValue]);

  useEffect(() => {
    orders.forEach((order) => {
      setValue(
        `listShippingWayNumber[order${order.id}]`,
        order?.shippingInfos?.trackingNumber
      );
    });
  }, [orders, setValue]);

  useEffect(() => {
    return () => {
      dispatch(resetOrders());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSearch = async () => {
  //   setSearching(true);
  //   let condition = {};
  //   if (searchTerms["searchType"] === "code") {
  //     condition = { code: searchTerms["text"] };
  //   }
  //   if (searchTerms["searchType"] === "name") {
  //     condition = { nick: searchTerms["text"] };
  //   }
  //   condition = { ...condition, dateFrom: dateFrom, dateTo: dateTo };
  //   console.log("condition", condition);
  //   await dispatch(getListOrder(condition));
  //   setSearching(false);
  // };

  const handleCheck = (e, id) => {
    if (e.target.checked) {
      setChecked(id);
    } else {
      setChecked("");
    }
  };

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await dispatch(getListOrder(handleCreateCondition(true)));
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "" });
    setDateFrom(null);
    setDateTo(null);
    await dispatch(getListOrder(handleCreateCondition(false, true)));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleChangeStatus = async (id, value) => {
    const data = { status: value };
    try {
      const response = await ManageUltrafitShopService.updateOrderStatus(
        id,
        data
      );
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateOrderStatus({ id: id, status: value }, handleCreateCondition())
        );
        showToast(TOAST_TYPE.SUCCESS, "Changed status");
      } else {
        const error = response?.data?.msg ?? "";
        showToast(TOAST_TYPE.ERROR, error);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeShippingURL = async (value, id, status) => {
    const data = { shippingURL: value };
    try {
      const response = await ManageUltrafitShopService.updateOrder(id, data);
      if (response.status === 200) {
        setValue(`listShippingURL[order${id}]`, value);
        // dispatch(
        //   updateOrderShipping({ id: id, shippingURL: value, status: status })
        // );
        showToast(TOAST_TYPE.SUCCESS, "Changed shipping URL");
      } else {
        const error = response?.data?.msg ?? "";
        showToast(TOAST_TYPE.ERROR, error);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeCarrier = async (value, shippingInfo) => {
    const data = {
      carriers: value,
      trackingNumber: shippingInfo?.trackingNumber,
      information: shippingInfoUrl[value]
    };
    try {
      const response = await ManageUltrafitShopService.updateShippingInfo(
        shippingInfo.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        showToast(TOAST_TYPE.SUCCESS, "Update success");
        dispatch(getListOrder(handleCreateCondition()));
      } else {
        const error = response?.data?.msg ?? "";
        showToast(TOAST_TYPE.ERROR, error);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeWaybillNumber = async (value, shippingInfo, id) => {
    const data = {
      carriers: shippingInfo?.carriers ?? "",
      trackingNumber: value,
      information: shippingInfo?.information
    };
    try {
      const response = await ManageUltrafitShopService.updateShippingInfo(
        shippingInfo.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        // setValue(`listShippingURL[order${id}]`, value);
        setValue(`listShippingWayNumber[order${id}]`, value);
        // dispatch(
        //   updateOrderShipping({ id: id, shippingURL: value, status: status })
        // );
        showToast(TOAST_TYPE.SUCCESS, "Update success");
        dispatch(getListOrder(handleCreateCondition()));
      } else {
        const error = response?.data?.msg ?? "";
        showToast(TOAST_TYPE.ERROR, error);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangePeriod = (type) => {
    if (searchTerms["dateType"] === type) {
      setSearchTerms((prevState) => ({ ...prevState, dateType: "" }));
    } else {
      setSearchTerms((prevState) => ({ ...prevState, dateType: type }));
    }
    let start,
      end = "";
    if (type === "today") {
      [start, end] = getTimeFromToInToDay();
    }
    if (type === "yesterday") {
      [start, end] = getTimeFromToInYesterday();
    }
    if (type === "week") {
      [start, end] = startAndEndOfWeek();
    }
    if (type === "month") {
      [start, end] = startAndEndOfMonth();
    }
    setDateFrom(start);
    setDateTo(end);
  };

  const handelPrint1 = async () => {
    if (!checked) {
      showToast(TOAST_TYPE.WARN, "No order selected");
      return;
    }
    const data = orders.find((order) => order.id === checked);
    const dataOrder = { ...data };
    let listProduct = [];
    if (dataOrder.product1) {
      listProduct.push({ name: "product1", id: dataOrder.product1 });
    }
    if (dataOrder.product2) {
      listProduct.push({ name: "product2", id: dataOrder.product2 });
    }
    if (dataOrder.product3) {
      listProduct.push({ name: "product3", id: dataOrder.product3 });
    }
    if (dataOrder.product4) {
      listProduct.push({ name: "product4", id: dataOrder.product4 });
    }
    if (dataOrder.product5) {
      listProduct.push({ name: "product5", id: dataOrder.product5 });
    }
    console.log("listProduct", listProduct);
    await Promise.all(
      listProduct.map(async (item) => {
        const response = await ManageUltrafitShopService.getUltrafitList({
          code: item.id
        });
        console.log("response", response);
        if (response.status === 201) {
          return {
            name: item.name,
            product: response?.data?.ult[0] ? response?.data?.ult[0] : {}
          };
        }
      })
    )
      .then((responses) => {
        return Promise.all(
          responses.map(function (response) {
            return response;
          })
        );
      })
      .then((responses) => {
        responses.forEach((item) => {
          const { name = "", product = {} } = item;
          dataOrder[name] = product;
        });
        setDataDetail({ ...dataOrder });
      })
      .catch(function (error) {
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      });
    setOpenDetail(true);
  };

  const handelPrint = (row) => {
    // const tableData = {
    //   index: 1,
    //   createdAt: "2023.04.13",
    //   nick: "David",
    //   mail: "david002@gmail.com",
    //   orderId: "ADXF-001A",
    //   price1: "100$",
    //   price2: "0",
    //   price3: "0",
    //   price4: "0",
    //   price5: "0",
    //   price6: "100",
    //   totalPrice: 800,
    //   product1: {
    //     file: "",
    //     name: "ULTRAFIT WINCREST V5 35BK",
    //     inventory: 1,
    //     price: "$ 50"
    //   },
    //   product2: {
    //     file: "",
    //     name: "ULTRAFIT WINCREST V5 35BK",
    //     inventory: 1,
    //     price: "$ 50"
    //   },
    //   country: "대한민국",
    //   address1: "강남구 서초대로 123",
    //   address2: "강남구 테헤란로 123",
    //   ZipPostal: "123-456",
    //   townCity: "서울",
    //   region: "아시아",
    //   status: (
    //     <Select
    //       MenuProps={{
    //         disableScrollLock: true
    //       }}
    //       fullWidth
    //       // value={searchTerms["sorts"]}
    //       // onChange={(e) => {
    //       //   e.stopPropagation();
    //       //   handleChangeSearchTerms("sorts", e.target.value);
    //       // }}
    //       style={{
    //         borderRadius: "0",
    //         height: "30px"
    //       }}
    //     >
    //       {listStatus.map((item) => (
    //         <MenuItem key={item.value} value={item.value}>
    //           {item.text}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   ),
    //   shippingInformation: status,
    //   waybillNumber: "SSSS-AAAA-BBBB",
    //   carrier: "UUUU"
    // };
    setDataDetail(row);
    setOpenDetail(true);
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
  };

  const handleCreateExcel = async (orders) => {
    const fileName = "주문배송관리";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("주문배송관리");

    const headersTitle = [
      "고객 코드",
      "주문일",
      "주문자명",
      "결제방법",
      "주문금액",
      "할인금액",
      "실제결제금액",
      "상태",
      "배송정보 입력",
      "트레킹번호"
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const columnWidth = [10, 30, 20, 20, 20, 20, 20, 20, 40, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    // add value, style cell // body
    orders.forEach((order, index) => {
      ws.getCell(`A${index + 2}`).value = order.id ? order.id : "";
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = order.createdAt
        ? formatDate(order.createdAt)
        : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = order.nick ? order.nick : "";
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = order.payment ? order.payment : "";
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = order.totalPrice
        ? formatNumber(Number(order.totalPrice)) + " USD"
        : "";
      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value = order.DCPrice
        ? formatNumber(Number(order.DCPrice)) + " USD"
        : "";
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`G${index + 2}`).value = order.amounts
        ? formatNumber(Number(order.amounts)) + " USD"
        : "";
      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`H${index + 2}`).value = order.status ? order.status : "";
      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`I${index + 2}`).value = order.shippingURL
        ? order.shippingURL
        : "";
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    let condition = {};
    if (isDownloadFilter) {
      if (searchTerms["searchType"] === "code") {
        condition = { code: searchTerms["text"] };
      }
      if (searchTerms["searchType"] === "name") {
        condition = { nick: searchTerms["text"] };
      }
      condition = { ...condition, dateFrom: dateFrom, dateTo: dateTo };
    }
    let listOrder = [];
    try {
      const response = await ManageUltrafitShopService.getOrder(condition);
      if (response.status === 201) {
        const orders = response?.data?.orders ?? [];
        listOrder = [...orders];
        handleCreateExcel(listOrder);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  // const renderSearchArea = () => (
  //   <Box
  //     style={{
  //       display: "flex",
  //       flexDirection: "column",
  //       border: "1px solid #c4c4c4",
  //       backgroundColor: "#d9d9d9",
  //     }}
  //   >
  //     <Grid
  //       container
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         padding: "10px",
  //       }}
  //     >
  //       <Grid item>
  //         <Typography sx={{ mr: 4 }}>기간</Typography>
  //       </Grid>
  //       <Grid>
  //         <LocalizationProvider dateAdapter={AdapterDateFns}>
  //           <DesktopDatePicker
  //             inputFormat="yyyy/MM/dd"
  //             value={dateFrom}
  //             onChange={(value) => {
  //               setDateFrom(value);
  //             }}
  //             renderInput={(params) => <StyledTextField {...params} />}
  //           />
  //           <span>&nbsp;~&nbsp;</span>
  //           <DesktopDatePicker
  //             inputFormat="yyyy/MM/dd"
  //             value={dateTo}
  //             onChange={(value) => {
  //               setDateTo(value);
  //             }}
  //             renderInput={(params) => <StyledTextField {...params} />}
  //           />
  //         </LocalizationProvider>
  //       </Grid>
  //       <Grid item>
  //         <StyledButtonPeriod
  //           active={searchTerms["dateType"] === "today" ? "true" : "false"}
  //           onClick={() => handleChangePeriod("today")}
  //         >
  //           오늘
  //         </StyledButtonPeriod>
  //       </Grid>
  //       <Grid item>
  //         <StyledButtonPeriod
  //           active={searchTerms["dateType"] === "yesterday" ? "true" : "false"}
  //           onClick={() => handleChangePeriod("yesterday")}
  //         >
  //           어제
  //         </StyledButtonPeriod>
  //       </Grid>
  //       <Grid item>
  //         <StyledButtonPeriod
  //           active={searchTerms["dateType"] === "week" ? "true" : "false"}
  //           onClick={() => handleChangePeriod("week")}
  //         >
  //           1주일
  //         </StyledButtonPeriod>
  //       </Grid>
  //       <Grid item>
  //         <StyledButtonPeriod
  //           active={searchTerms["dateType"] === "month" ? "true" : "false"}
  //           onClick={() => handleChangePeriod("month")}
  //         >
  //           1개월
  //         </StyledButtonPeriod>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         padding: "5px 0 5px 10px",
  //         borderTop: "1px solid #c4c4c4",
  //       }}
  //     >
  //       <Grid item>
  //         <Typography sx={{ mr: 4 }}>검색</Typography>
  //       </Grid>
  //       <Grid item>
  //         <StyledSelect
  //           MenuProps={{
  //             disableScrollLock: true,
  //           }}
  //           value={searchTerms["searchType"]}
  //           onChange={(e) =>
  //             setSearchTerms((prevState) => ({
  //               ...prevState,
  //               searchType: e.target.value,
  //             }))
  //           }
  //         >
  //           {listSearchField.map((item) => (
  //             <MenuItem key={item.value} value={item.value}>
  //               {item.text}
  //             </MenuItem>
  //           ))}
  //         </StyledSelect>
  //         <StyledTextField
  //           value={searchTerms["text"]}
  //           onChange={(e) =>
  //             setSearchTerms((prevState) => ({
  //               ...prevState,
  //               text: e.target.value,
  //             }))
  //           }
  //           width="220px"
  //           InputProps={{
  //             endAdornment: (
  //               <InputAdornment position="end">
  //                 <SearchIcon edge="end" />
  //               </InputAdornment>
  //             ),
  //           }}
  //           placeholder="Product name, Product no"
  //         />
  //       </Grid>
  //       <Grid item>
  //         <LoadingButton
  //           onClick={handleSearch}
  //           loading={searching ? true : false}
  //           loadingIndicator="검색"
  //           variant="contained"
  //           style={{
  //             textTransform: "capitalize",
  //             borderRadius: "0",
  //             height: "33px",
  //             marginLeft: "5px",
  //             marginRight: "20px",
  //           }}
  //         >
  //           검색
  //         </LoadingButton>
  //       </Grid>
  //       <Grid item>
  //         <StyledBox>신규 주문 ({quantity["newOrder"]})</StyledBox>
  //       </Grid>
  //       <Grid item>
  //         <StyledBox>주문 접수 ({quantity["orderReceipt"]})</StyledBox>
  //       </Grid>
  //       <Grid item>
  //         <StyledBox>배송 처리 ({quantity["shipping"]})</StyledBox>
  //       </Grid>
  //     </Grid>
  //   </Box>
  // );

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography
          // style={{
          //   fontWeight: "bold",
          //   color: "white",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   height: "32px"
          // }}
          >
            검색필터
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  주문날짜
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // console.log(orders);

  // const handleUpdateStatus = async (id, status) => {
  //   try {
  //     const response = await ManageUltrafitShop.updateOrderStatus(id, {
  //       status
  //     });
  //     if (response?.data?.statusCode === 200) {
  //       await fetchData();
  //       showToast(TOAST_TYPE.SUCCESS, "Success");
  //     }
  //   } catch (error) {
  //     setLoadingProcess({ ...loadingProcess, suspending: false });
  //     const errorStatusText = error?.response ? error.response?.statusText : "";
  //     const errorDataMessage = error?.response?.data?.message?.[0] ?? "";
  //     const errorMessage =
  //       errorDataMessage ?? errorStatusText ?? "System Error";
  //     showToast(TOAST_TYPE.ERROR, errorMessage);
  //   }
  // };

  const renderTable = () => {
    const sizeWidth = [
      "3%",
      "6%",
      "6%",
      "15%",
      "6%",
      "6%",
      "6%",
      "6%",
      "6%",
      "6%",
      "6%",
      "6%",
      "8%",
      "8%",
      "6%"
    ];
    const headersTitle = [
      "NO.",
      "주문일",
      "성명",
      "이메일",
      "구매상품",
      "주문금액",
      "할인금액",
      "Credit",
      "Profit",
      "Paypal",
      "카드",
      "상태",
      "배송정보",
      "운송장번호",
      "운송사"
    ];

    const getProductName = (orderDetail = []) => {
      const productName = [];
      orderDetail.forEach((item) => {
        productName.push(item?.ulftrafits?.name || "");
      });
      return productName;
    };

    const tableData = orders.map((order, index) => {
      const listProductName = getProductName(order?.orderDetail ?? []);
      const totalOrderAmount = () => {
        const amountSum = order.orderDetail.reduce(
          (result, item) =>
            addDecimalNumber(Number(item.options.amount), result),
          0
        );
        const sum = addDecimalNumber(
          amountSum,
          Number(order?.shippingFee || 0)
        );
        return sum;
      };
      // const totalOrderAmount = () =>
      //   Number(order?.totalAmount || 0) +
      //   Number(order?.totalCredit || 0) +
      //   Number(order?.totalProfit || 0) +
      //   Number(order?.discountAmount || 0) +
      //   Number(order?.shippingFee || 0);

      const paypalAmount = () => {
        let sum = subtractDecimalNumber(
          Number(totalOrderAmount()),
          Number(order?.discountAmount || 0)
        );
        sum = subtractDecimalNumber(sum, Number(order?.totalProfit || 0));
        sum = subtractDecimalNumber(sum, Number(order?.totalCredit || 0));
        return sum;
      };

      return {
        ...order,
        index: index + 1 + rowsPerPage * page,
        orderDate: order.createdAt ? formatDate(order.createdAt) : "",
        name: order?.shippingName ?? "",
        email: order?.shippingEmail ?? "",
        purchaseProduct: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            {listProductName.map((item, index) => (
              <Typography key={index}>{item}</Typography>
            ))}
          </Box>
        ),
        orderAmount: totalOrderAmount() ? totalOrderAmount() + "$" : 0,
        discountAmount:
          order?.discountAmount && Number(order?.discountAmount)
            ? Number(order?.discountAmount) + "$"
            : 0,
        credit: Number(order?.totalCredit ?? 0),
        profit: Number(order?.totalProfit ?? 0),
        paypal: order?.paymentMethod === "paypal" ? paypalAmount() : 0,
        card: order?.paymentMethod === "card" ? order?.totalAmount : 0,
        state: (
          <Select
            MenuProps={{
              disableScrollLock: true
            }}
            fullWidth
            value={order?.status ?? ""}
            onChange={(e) => {
              e.stopPropagation();
              handleChangeStatus(order.id, e.target.value);
            }}
            style={{
              borderRadius: "0",
              height: "33px"
            }}
          >
            {listStatus.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        ),
        shippingInformation:
          order?.shippingInfos && order?.shippingInfos?.information ? (
            <Link href={order?.shippingInfos?.information} target="_blank">
              배송정보
            </Link>
          ) : (
            ""
          ),
        // waybillNumber: order?.shippingInfos?.trackingNumber ?? "",
        waybillNumber: (
          <StyledTextField
            name={`order${order.id}`}
            defaultValue={order?.shippingInfos?.trackingNumber ?? ""}
            // value={order?.shippingInfos?.trackingNumber ?? ""}
            // {...register(`listShippingURL[order${order.id}]`)}
            {...register(`listShippingWayNumber[order${order.id}]`)}
            onBlur={(e) =>
              handleChangeWaybillNumber(
                e.target.value,
                order?.shippingInfos,
                order.id
              )
            }
          />
        ),
        // carrier: order?.shippingInfos?.carriers ?? "",
        carrier: (
          <Select
            MenuProps={{
              disableScrollLock: true
            }}
            fullWidth
            value={order?.shippingInfos?.carriers ?? ""}
            onChange={(e) => {
              e.stopPropagation();
              handleChangeCarrier(e.target.value, order?.shippingInfos);
            }}
            style={{
              borderRadius: "0",
              height: "33px"
            }}
          >
            {listShippingCarrier.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        ),
        DCPrice: order.DCPrice
          ? formatNumber(Number(order.DCPrice)) + " USD"
          : "",
        amounts: order.amounts
          ? formatNumber(Number(order.amounts)) + " USD"
          : "",
        // state: (
        //   <StyledSelectStatus
        //     MenuProps={{
        //       disableScrollLock: true
        //     }}
        //     value={order?.status ? order.status : ""}
        //     fullWidth
        //     onChange={(e) => handleChangeStatus(e.target.value, order.id)}
        //   >
        //     {statusOrder.map((item) => (
        //       <MenuItem key={item.value} value={item.text}>
        //         {item.text}
        //       </MenuItem>
        //     ))}
        //   </StyledSelectStatus>
        // ),
        shippingUrl: (
          <StyledTextField
            name={`order${order.id}`}
            {...register(`listShippingURL[order${order.id}]`)}
            onBlur={(e) =>
              handleChangeShippingURL(e.target.value, order.id, order.status)
            }
          />
        ),
        check: (
          <Checkbox
            disabled={checked && checked !== order.id ? true : false}
            checked={checked === order.id ? true : false}
            onChange={(e) => handleCheck(e, order.id)}
          />
        )
      };
    });

    // const tableData = [
    //   {
    //     index: 1,
    //     createdAt: "2023.04.13",
    //     nick: "David",
    //     mail: "david002@gmail.com",
    //     orderId: "ADXF-001A",
    //     price1: "100$",
    //     price2: "0",
    //     price3: "0",
    //     price4: "0",
    //     price5: "0",
    //     price6: "100",
    //     status: (
    //       <Select
    //         MenuProps={{
    //           disableScrollLock: true
    //         }}
    //         fullWidth
    //         // value={searchTerms["sorts"]}
    //         // onChange={(e) => {
    //         //   e.stopPropagation();
    //         //   handleChangeSearchTerms("sorts", e.target.value);
    //         // }}
    //         value={status}
    //         onChange={(e) => {
    //           e.stopPropagation();
    //           setStatus(e.target.value);
    //         }}
    //         style={{
    //           borderRadius: "0",
    //           height: "30px"
    //         }}
    //       >
    //         {listStatus.map((item) => (
    //           <MenuItem key={item.value} value={item.text}>
    //             {item.text}
    //           </MenuItem>
    //         ))}
    //       </Select>
    //     ),
    //     shippingInformation: <Link href="#">배송정보</Link>,
    //     waybillNumber: "SSSS-AAAA-BBBB",
    //     carrier: "UUUU"
    //   }
    // ];
    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        handelPrint={handelPrint}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  return (
    <Layout header="주문배송관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container flexDirection="column">
          <Grid item>{renderSearchArea()}</Grid>
          {/* <Grid item sx={{ mt: 3 }}>
            <Grid container flexDirection="column" alignItems="flex-end">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDownloadFilter}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "0.7rem"
                        }
                      }}
                      onChange={() => setIsDownloadFilter(!isDownloadFilter)}
                    />
                  }
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "0.7rem !important"
                    },

                    marginRight: 0
                  }}
                  label="현재 검색 필터 적용"
                />
              </Grid>
              <StyledButtonDownload
                variant="outline"
                onClick={() => {
                  if (isDownloadFilter) {
                    setOpenPopupAll(false);
                    setOpenPopupFilter(true);
                  } else {
                    setOpenPopupAll(true);
                    setOpenPopupFilter(false);
                  }
                }}
              >
                Excel파일로 다운받기
              </StyledButtonDownload>
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 1, mb: 1 }}>
            <Grid container flexDirection="column" alignItems="flex-end">
              <Grid item>
                <StyledButtonPrint variant="contained" onClick={handelPrint}>
                  주문서 출력
                </StyledButtonPrint>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item sx={{ mt: 3 }} style={{ overflowX: "auto" }}>
            {renderTable()}
          </Grid>
          <OrderDetail
            open={openDetail}
            setOpen={setOpenDetail}
            data={dataDetail}
            handleConfirm={handleChangeStatus}
          />
          {/* <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          /> */}
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(OrderDeliveryManagement);
