import React, { useEffect, useState, useMemo, useRef } from "react";

import {
  Box,
  Grid,
  TextField,
  styled,
  Button,
  Typography
} from "@mui/material";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/TableData";
import { useGlobalStore } from "hooks";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { PreferencesService } from "services";
import ModalSetting from "./components/ModalSetting";
import Popup2Action from "components/Popup2Action";
import {
  getListAdmin,
  resetAdmins,
  updateAdmin,
  deleteAdmin,
  createAdmin
} from "redux/actions/admin";
import { formatDate } from "utils/function";
import { useNavigate } from "react-router-dom";
import { signOut } from "redux/actions/auth";

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  border: "1px solid #c4c4c4",
  color: "#000"
});

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "150px",
    height: height ? `${height}` : "25px",
    padding: "4px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

const mapAuthority = {
  admin: "Admin",
  super_admin: "Super"
};

function AdminSetting({ showToast }) {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [errorInput, setErrorInput] = useState({ id: "", pwd: "", pwdC: "" });

  const [state, dispatch] = useGlobalStore();
  const userRight = useRef(null);
  const adminId = useRef(null);
  const currentAdmin = useRef(null);

  const navigate = useNavigate();

  const {
    admins = [],
    loading = true,
    error = null
  } = useMemo(() => state?.admin, [state]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    userRight.current = user.type;
    adminId.current = user.id;
    currentAdmin.current = user;
    // userRight.current = "superAdmin";
    dispatch(getListAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && error) {
      showToast(TOAST_TYPE.ERROR, error);
    }
  }, [error, loading, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetAdmins());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDelete = (id) => {
    setDataModal({ id: id });
    setOpenDeleteModal(true);
  };
  const handleDelete = async () => {
    try {
      const response = await PreferencesService.deleteAdmin(dataModal.id);
      if (response?.data?.statusCode === 200) {
        dispatch(deleteAdmin(response.data.data));
        setOpenDeleteModal(false);
        showToast(TOAST_TYPE.SUCCESS, "Deleted admin");
      } else {
        setOpenDeleteModal(false);
        showToast(TOAST_TYPE.ERROR, response?.data?.msg ?? "Error");
      }
    } catch (error) {
      setOpenDeleteModal(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.msg
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleOpenEdit = (data) => {
    setErrorInput({ id: "", pwd: "", pwdC: "" });
    setDataModal({ ...data });
    setOpenEditModal(true);
  };
  const handleEdit = async () => {
    if (!dataModal.id) {
      setErrorInput({ ...errorInput, id: "아이디가 필요합니다" });
      return;
    }
    if (!dataModal.pwd.trim() || dataModal.pwd.trim().length < 6) {
      setErrorInput({ ...errorInput, id: "비밀번호는 6자 이상이어야 합니다" });
      return;
    }
    if (dataModal.pwd.trim() !== dataModal.pwdC.trim()) {
      setErrorInput({
        ...errorInput,
        pwdC: "비밀번호와 확인 비밀번호가 일치하지 않습니다"
      });
      return;
    }
    try {
      let body = {};
      if (dataModal.id) {
        body = { ...body, email: dataModal.id };
      }
      if (dataModal.pwd) {
        body = { ...body, password: dataModal.pwd };
      }
      const response = await PreferencesService.updateAdmin(
        dataModal.code,
        body
      );
      if (response?.data?.statusCode === 200) {
        dispatch(updateAdmin(response.data.data));
        setOpenEditModal(false);
        showToast(TOAST_TYPE.SUCCESS, "Updated admin");
        if (adminId.current === dataModal.code) {
          dispatch(signOut());
          navigate("/login");
        }
      } else {
        setOpenEditModal(false);
        showToast(TOAST_TYPE.ERROR, response?.data?.message ?? "Error");
      }
    } catch (error) {
      setOpenEditModal(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleOpenAdd = () => {
    setErrorInput({ id: "", pwd: "", pwdC: "" });
    setDataModal({ id: "", pwd: "", pwdC: "" });
    setOpenAddModal(true);
  };

  const handleAdd = async () => {
    if (!dataModal.id) {
      setErrorInput({ ...errorInput, id: "아이디가 필요합니다" });
      return;
    }
    if (!dataModal.pwd) {
      setErrorInput({ ...errorInput, pwd: "암호가 필요합니다" });
      return;
    }
    if (!dataModal.pwd.trim() || dataModal.pwd.trim().length < 6) {
      setErrorInput({ ...errorInput, id: "비밀번호는 6자 이상이어야 합니다" });
      return;
    }
    if (dataModal.pwd.trim() !== dataModal.pwdC.trim()) {
      setErrorInput({
        ...errorInput,
        pwdC: "비밀번호와 확인 비밀번호가 일치하지 않습니다"
      });
      return;
    }
    try {
      const response = await PreferencesService.createAdmin({
        email: dataModal.id,
        password: dataModal.pwd
      });
      if (response?.data?.statusCode === 200) {
        dispatch(createAdmin(response.data.data));
        setOpenAddModal(false);
        setErrorInput({ id: "" });
        showToast(TOAST_TYPE.SUCCESS, "Added admin");
      } else {
        setOpenAddModal(false);
        showToast(TOAST_TYPE.ERROR, response?.data?.msg ?? "Error");
      }
    } catch (error) {
      setOpenAddModal(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.msg
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["10%", "20%", "20%", "20%", "30%"];

    const headersTitle = ["No", "권한", "아이디", "최근 접속일", ""];

    const tableData = admins.map((admin, index) => {
      return {
        ...admin,
        no: index + 1,
        authority: admin?.type ? mapAuthority[admin.type] : "",
        id: admin.email ? admin.email : "",
        lastAccessDate: admin.lastAccess ? formatDate(admin.lastAccess) : "",
        action: (
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <Box sx={{ width: "60px" }}>
              {userRight === "super_admin" || adminId.current === admin.id ? (
                <StyledButton
                  variant="outlined"
                  style={{ borderRadius: "20px", width: "60px" }}
                  sx={{ mr: 1 }}
                  onClick={() =>
                    handleOpenEdit({
                      id: admin.email ? admin.email : "",
                      pwd: "",
                      pwdC: "",
                      code: admin.id ? admin.id : ""
                    })
                  }
                >
                  변경
                </StyledButton>
              ) : null}
            </Box>
            <Box sx={{ width: "60px" }}>
              {adminId.current !== admin.id && admin.type !== "super_admin" ? (
                <StyledButton
                  variant="outlined"
                  style={{ borderRadius: "20px", width: "60px" }}
                  onClick={() => handleOpenDelete(admin.id)}
                >
                  삭제
                </StyledButton>
              ) : null}
            </Box>
          </Box>
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
      />
    );
  };

  return (
    <Layout header="환경설정 - 관리자 설정">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid
          container
          direction="column"
          spacing={1}
          sx={{ pt: 5, pr: 20, pl: 20 }}
        >
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <StyledButton
                  variant="outlined"
                  style={{ borderRadius: "0" }}
                  onClick={handleOpenAdd}
                  disabled={userRight.current !== "super_admin"}
                >
                  추가
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <ModalSetting
            open={openAddModal}
            setOpen={setOpenAddModal}
            onClickSave={handleAdd}
            title="관리자 추가"
            disabled={
              !dataModal.id ||
              !!errorInput["id"] ||
              !!errorInput["pwd"] ||
              !!errorInput["pwdC"] ||
              !dataModal.pwd ||
              !dataModal.pwdC
            }
          >
            <Grid
              container
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid container sx={{ mb: 1 }}>
                <Grid item sm={3}>
                  <Typography>ID: </Typography>
                </Grid>
                <Grid item>
                  <StyledTextField
                    // height="18px"
                    width="250px"
                    value={dataModal.id}
                    error={!!errorInput["id"]}
                    helperText={errorInput["id"]}
                    onBlur={(e) => {
                      const id = e.target.value;
                      if (!id)
                        setErrorInput({
                          ...errorInput,
                          id: "아이디가 필요합니다"
                        });
                      else if (
                        !/^[\s*A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z\s*]{2,}$/i.test(
                          id
                        )
                      ) {
                        setErrorInput({
                          ...errorInput,
                          id: "잘못된 이메일 입력하셨습니다. ID 다시 확인해주세요."
                        });
                      } else {
                        setErrorInput({ ...errorInput, id: "" });
                      }
                    }}
                    onChange={(e) => {
                      const id = e.target.value;
                      setDataModal({ ...dataModal, id: id });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item sm={3}>
                  <Typography>비밀번호: </Typography>
                </Grid>
                <Grid item>
                  <StyledTextField
                    // height="18px"
                    width="250px"
                    value={dataModal.pwd}
                    error={!!errorInput["pwd"]}
                    helperText={errorInput["pwd"]}
                    onBlur={(e) => {
                      const pwd = e.target.value;
                      if (!pwd)
                        setErrorInput({
                          ...errorInput,
                          pwd: "암호가 필요합니다"
                        });
                      if (pwd.length < 6)
                        setErrorInput({
                          ...errorInput,
                          pwd: "비밀번호는 6자 이상이어야 합니다"
                        });
                      else {
                        setErrorInput({ ...errorInput, pwd: "" });
                      }
                    }}
                    onChange={(e) =>
                      setDataModal({ ...dataModal, pwd: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <Typography>비밀번호 확인: </Typography>
                </Grid>
                <Grid item>
                  <StyledTextField
                    // height="18px"
                    width="250px"
                    value={dataModal.pwdC}
                    error={!!errorInput["pwdC"]}
                    helperText={errorInput["pwdC"]}
                    onBlur={(e) => {
                      const pwdC = e.target.value;
                      if (dataModal.pwd.trim() !== pwdC.trim()) {
                        setErrorInput({
                          ...errorInput,
                          pwdC: "비밀번호와 확인 비밀번호가 일치하지 않습니다"
                        });
                      } else {
                        setErrorInput({ ...errorInput, pwdC: "" });
                      }
                    }}
                    onChange={(e) =>
                      setDataModal({ ...dataModal, pwdC: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </ModalSetting>

          <ModalSetting
            open={openEditModal}
            setOpen={setOpenEditModal}
            onClickSave={handleEdit}
            title="관리자 수정"
            disabled={
              !dataModal.id ||
              !!errorInput["id"] ||
              !!errorInput["pwd"] ||
              !!errorInput["pwdC"] ||
              !dataModal.pwd ||
              !dataModal.pwdC
            }
          >
            <Grid
              container
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid container>
                <Grid item sm={3.5} sx={{ mb: 1 }}>
                  <Typography>ID: </Typography>
                </Grid>
                <Grid item>
                  <StyledTextField
                    // height="18px"
                    width="250px"
                    value={dataModal.id}
                    error={!!errorInput["id"]}
                    helperText={errorInput["id"]}
                    onBlur={(e) => {
                      const id = e.target.value;
                      if (!id)
                        setErrorInput({
                          ...errorInput,
                          id: "아이디가 필요합니다"
                        });
                      else if (
                        !/^[\s*A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z\s*]{2,}$/i.test(
                          id
                        )
                      ) {
                        setErrorInput({
                          ...errorInput,
                          id: "잘못된 이메일 입력하셨습니다. ID 다시 확인해주세요."
                        });
                      } else {
                        setErrorInput({ ...errorInput, id: "" });
                      }
                    }}
                    onChange={(e) => {
                      const id = e.target.value;
                      setDataModal({ ...dataModal, id: id });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 1 }}>
                <Grid item sm={3.5}>
                  <Typography>신규 비밀번호: </Typography>
                </Grid>
                <Grid item>
                  <StyledTextField
                    // height="18px"
                    width="250px"
                    value={dataModal.pwd}
                    error={!!errorInput["pwd"]}
                    helperText={errorInput["pwd"]}
                    onBlur={(e) => {
                      const pwd = e.target.value;
                      if (!pwd)
                        setErrorInput({
                          ...errorInput,
                          pwd: "암호가 필요합니다"
                        });
                      if (pwd.length < 6)
                        setErrorInput({
                          ...errorInput,
                          pwd: "비밀번호는 6자 이상이어야 합니다"
                        });
                      else {
                        setErrorInput({ ...errorInput, pwd: "" });
                      }
                    }}
                    onChange={(e) =>
                      setDataModal({ ...dataModal, pwd: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3.5}>
                  <Typography>신규 비밀번호 확인: </Typography>
                </Grid>
                <Grid item>
                  <StyledTextField
                    // height="18px"
                    width="250px"
                    value={dataModal.pwdC}
                    error={!!errorInput["pwdC"]}
                    helperText={errorInput["pwdC"]}
                    onBlur={(e) => {
                      const pwdC = e.target.value;
                      if (dataModal.pwd.trim() !== pwdC.trim()) {
                        setErrorInput({
                          ...errorInput,
                          pwdC: "비밀번호와 확인 비밀번호가 일치하지 않습니다"
                        });
                      } else {
                        setErrorInput({ ...errorInput, pwdC: "" });
                      }
                    }}
                    onChange={(e) =>
                      setDataModal({ ...dataModal, pwdC: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </ModalSetting>

          <Popup2Action
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            message="관리자를 삭제하시겠습니까?"
            onClickConfirm={handleDelete}
            onClickCancel={() => setOpenDeleteModal(false)}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(AdminSetting);
