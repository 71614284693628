import { PrivateRouter } from "HOC";
import CouponList from "../CouponList";
import CouponDetail from "../CouponDetail";
import AddCoupon from "../AddCoupon";
import EditCoupon from "../EditCoupon";
import CouponDesignatedIssuance from "../CouponDesignatedIssuance";

const routerManageCoupon = [
  {
    path: "/coupon-management/coupon-list",
    element: (
      <PrivateRouter>
        <CouponList />
      </PrivateRouter>
    )
  },
  {
    path: "/coupon-management/coupon-list/designated-issuance/:id",
    element: (
      <PrivateRouter>
        <CouponDesignatedIssuance />
      </PrivateRouter>
    )
  },
  {
    path: "/coupon-management/coupon-list/detail/:id",
    element: (
      <PrivateRouter>
        <CouponDetail />
      </PrivateRouter>
    )
  },
  {
    path: "/coupon-management/coupon-list/edit/:id",
    element: (
      <PrivateRouter>
        <EditCoupon />
      </PrivateRouter>
    )
  },
  {
    path: "/coupon-management/add-coupon",
    element: (
      <PrivateRouter>
        <AddCoupon />
      </PrivateRouter>
    )
  }
];

export default routerManageCoupon;
