import { bannerConstants } from "../constants";

const initState = {
  loading: true,
  banners: [],
  error: null,
};

const bannerReducer = (state = initState, action) => {
  switch (action.type) {
    case bannerConstants.GET_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        banners: action.payload,
        error: null,
      };
    case bannerConstants.GET_BANNERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case bannerConstants.SET_BANNER: {
      const { id = "", status = "" } = action.payload;
      const { banners = [] } = state;
      const newBanners = banners.map((banner) => {
        if (id === banner.id) {
          return { ...banner, status: status };
        }
        return banner;
      });

      return {
        ...state,
        banners: newBanners,
      };
    }
    case bannerConstants.DELETE_BANNERS: {
      const { ids = [] } = action.payload;
      const { banners = [] } = state;
      let newBanners = [...banners];

      ids.forEach((id) => {
        newBanners = newBanners.filter((item) => item.id !== id);
      });

      return {
        ...state,
        banners: newBanners,
      };
    }
    case bannerConstants.RESET_BANNERS: {
      return {
        ...state,
        loading: true,
        banners: [],
        error: null,
      };
    }
    default:
      return state;
  }
};

export default bannerReducer;
