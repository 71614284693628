const URL = Object.freeze({
  GET_LIST_URL: "/admins/users",
  SUSPEND_MEMBER_URL: "/admins/users/suspend",
  GET_USER_URL: "/admins/users",
  GET_POLICY_URL: "/policy",
  // SET_POLICY_URL: "/admin/setPolicy",
  CREATE_POLICY_URL: "/policy",
  UPDATE_POLICY_URL: "/policy",
  GET_EDITOR_PAYMENT_HISTORY_URL: "/transactions/credit/history",
  GET_USER_PHOTO: "/users/photo"
});

export default URL;
