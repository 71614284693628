import React, { useState, useEffect, useRef } from "react";
import Layout from "components/Layout";
import { withParam, withToast } from "HOC";
import { ManagePatternService } from "services";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Box,
  Grid,
  TextField,
  Typography
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatNumber,
  formatDateTime,
  blobToFile,
  trimSvgContent,
  removeWhitespaceFromSVG,
  svgImport
} from "utils/function";
// import { Buffer } from "buffer";
import Popup from "components/Popup";
import Popup2Action from "./components/Popup2Action";
import paper from "paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 20,
    padding: 5,
    minWidth: "230px",
    textAlign: "center",
    border: "1px solid #a5a5a5",
    fontWeight: "bold"
  }
}));

const StyledTableCellHeaderReview = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 20,
    padding: 5,
    minWidth: "460px",
    border: "1px solid #a5a5a5",
    fontWeight: "bold",
    color: "#ffff",
    backgroundColor: "#0468b4"
  }
}));

const StyledTableCellBodyReview = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    padding: 5,
    border: "1px solid #a5a5a5"
  }
}));

const StyledTableCellLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.9rem",
    height: 20,
    padding: 5,
    minWidth: "230px",
    textAlign: "center",
    border: "1px solid #a5a5a5",
    color: "#ffff",
    backgroundColor: "#0468b4",
    fontWeight: "bold"
  }
}));

const StyledBoxContent = styled(Box)({
  border: "1px solid #a5a5a5",
  borderRadius: "5px",
  padding: "2px",
  width: 200,
  textAlign: "center",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxImageTitle = styled(Box)({
  fontSize: "0.9rem",
  height: "32px",
  padding: 5,
  width: "230px",
  textAlign: "center",
  border: "1px solid #a5a5a5",
  color: "#ffff",
  backgroundColor: "#0468b4",
  fontWeight: "bold"
});

const StyledBoxImageContent = styled(Box)({
  fontSize: "0.9rem",
  height: "160px",
  padding: 5,
  width: "230px",
  textAlign: "center",
  border: "1px solid #a5a5a5",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxIntroductionTitle = styled(Box)({
  fontSize: "0.9rem",
  height: "70px",
  width: "230px",
  border: "1px solid #a5a5a5",
  color: "#ffff",
  backgroundColor: "#0468b4",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxIntroductionContent = styled(Box)({
  fontSize: "0.9rem",
  height: "70px",
  width: "100%",
  textAlign: "center",
  border: "1px solid #a5a5a5",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledBoxPatternFile = styled(Box)({
  fontSize: "0.9rem",
  height: "70px",
  width: "100%",
  padding: "0 0 0 10px",
  border: "1px solid #a5a5a5",
  display: "flex",
  alignItems: "center"
});

const StyledButtonEdit = styled(Button)({
  textTransform: "capitalize",
  border: "1px solid #a5a5a5",
  padding: 0
});

const StyledButtonList = styled(Button)({
  border: "1px solid #a5a5a5",
  textTransform: "capitalize",
  color: "#000",
  width: 150,
  marginLeft: 20,
  fontWeight: "bold"
});

const StyledLoadingButton = styled(LoadingButton)({
  textTransform: "capitalize",
  border: "1px solid #a5a5a5",
  width: 150,
  fontWeight: "bold"
});

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& label": {
    paddingLeft: (theme) => theme.spacing(2)
  },
  "& input": {
    width: width ? width : "100%",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    paddingLeft: (theme) => theme.spacing(2.5),
    borderRadius: 0
  }
}));

function DetailPattern({ params, showToast }) {
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [pattern, setPattern] = useState({});
  // const [comments, setComments] = useState([]);
  const [idRatting, setIdRatting] = useState("");
  const [loadingProcess, setLoadingProcess] = useState({
    cancelling: false,
    approving: false,
    rejecting: false
  });

  const [openResumeConfirm, setOpenResumeConfirm] = useState(false);
  const [openResumeComplete, setOpenResumeComplete] = useState(false);
  const [openStopConfirm, setOpenStopConfirm] = useState(false);
  const [openStopReason, setOpenStopReason] = useState(false);
  const [openStopComplete, setOpenStopComplete] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openUpdateAreaConfirm, setOpenUpdateAreaConfirm] = useState(false);
  const [reason, setReason] = useState("");
  const [valueArea, setValueArea] = useState(null);

  const navigate = useNavigate();

  async function fetchData() {
    try {
      const response = await ManagePatternService.getPatternById(params.id);
      if (response?.data?.statusCode === 200) {
        let data = response?.data ? response.data?.data : {} ?? {};
        if (data?.sqft && Number(data?.sqft)) {
          data = { ...data, sqft: Number(data?.sqft) };
        }
        setPattern(data);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  // useEffect(() => {
  //   Promise.all([
  //     ManagePatternService.getPatternInfo(params.id),
  //     ManagePatternService.getPatternComment(params.id)
  //   ])
  //     .then((responses) => {
  //       return Promise.all(
  //         responses.map(function (response) {
  //           return response;
  //         })
  //       );
  //     })
  //     .then((data) => {
  //       const [responsePatternInfo = null, responsePatternComment = null] =
  //         data;
  //       if (
  //         responsePatternInfo?.status === 201 &&
  //         responsePatternInfo?.data?.status === SUCCESS_STATUS
  //       ) {
  //         setPattern(responsePatternInfo?.data?.patterns ?? {});
  //       } else {
  //         const errorData = responsePatternInfo?.data
  //           ? responsePatternInfo.data?.msg
  //           : "Error" ?? "Error";
  //         showToast(TOAST_TYPE.ERROR, errorData);
  //       }

  //       if (responsePatternComment?.status === 201) {
  //         setComments(responsePatternComment?.data?.comments ?? []);
  //       } else {
  //         const errorData = responsePatternComment?.data
  //           ? responsePatternComment.data?.msg
  //           : "Error" ?? "Error";
  //         showToast(TOAST_TYPE.ERROR, errorData);
  //       }
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       setLoading(false);
  //       const errorStatusText = error?.response
  //         ? error.response?.statusText
  //         : "";
  //       const errorDataMessage = error?.response
  //         ? error.response?.data
  //           ? error.response.data?.message
  //           : ""
  //         : "";
  //       const errorMessage =
  //         errorDataMessage ?? errorStatusText ?? "System Error";
  //       showToast(TOAST_TYPE.ERROR, errorMessage);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timeReCall]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate("/pattern-management/pattern-list");
  };

  const handleResumePattern = async () => {
    const data = {
      status: "approved"
    };
    setLoadingProcess({ ...loadingProcess, rejecting: true });
    try {
      const response = await ManagePatternService.updatePatternStatus(
        params.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        setOpenResumeConfirm(false);
        setOpenResumeComplete(true);
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, rejecting: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, rejecting: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  // const url = pattern.svg;

  // url &&
  //   trimSvgContent(url).then((result) => {
  //     if (result) {
  //       console.log(
  //         "Exact Content Size (without whitespace):",
  //         result.width1,
  //         "x",
  //         result.height1
  //       );

  //       const naturalHeightCm = result.width1 / 37.7952; // cm
  //       const naturalWidthCm = result.height1 / 37.7952; // cm
  //       const naturalHeightFeet = naturalHeightCm * 0.032808; // feet
  //       const naturalWidthFeet = naturalWidthCm * 0.032808; // feet
  //       const naturalArea = naturalWidthFeet * naturalHeightFeet; // square feet
  //       console.log("naturalArea", naturalArea);
  //     } else {
  //       console.log("Failed to trim SVG content.");
  //     }
  //   });

  // url &&
  //   svgImport(url, (result) => {
  //     if (result) {
  //       console.log(
  //         "Exact Content Size (without whitespace):",
  //         result.size.width,
  //         "x",
  //         result.size.height
  //       );

  //       const naturalHeightCm = result.size.width / 37.7952; // cm
  //       const naturalWidthCm = result.size.height / 37.7952; // cm
  //       const naturalHeightFeet = naturalHeightCm * 0.032808; // feet
  //       const naturalWidthFeet = naturalWidthCm * 0.032808; // feet
  //       const naturalArea = naturalWidthFeet * naturalHeightFeet; // square feet
  //       console.log("naturalArea", naturalArea);
  //     } else {
  //       console.log("Failed to trim SVG content.");
  //     }
  //   });

  useEffect(() => {
    if (canvasRef.current && pattern.svg) {
      // Set up Paper.js with the canvas element
      paper.setup(canvasRef.current);

      // Load the SVG file
      loadSVG(pattern.svg);

      // Optionally, adjust Paper.js view if needed
      // paper.view.viewSize = new paper.Size(800, 600); // Set the canvas size manually if necessary
    }

    // Cleanup Paper.js view on component unmount
    return () => {
      paper.remove();
    };
  }, [pattern.svg]);

  // Function to load and handle SVG
  const loadSVG = (url) => {
    paper.project.clear(); // Clear the current project (important if you reload the SVG)

    // Fetch SVG content
    fetch(url)
      .then((res) => res.text())
      .then((svgText) => {
        const svg = paper.project.importSVG(svgText);

        // Once the SVG is imported, retrieve its bounding box (size)
        const bounds = svg.bounds;
        console.log("SVG Width:", bounds.size);
        // console.log("SVG Height:", bounds.height);

        // Optionally, you can manipulate the imported SVG

        // You can also center it on the canvas, for example:
        svg.position = paper.view.center;
      })
      .catch((err) => console.error("Error loading SVG:", err));
  };

  const handleStopPattern = async () => {
    const data = {
      status: "stop_selling",
      reason: reason
    };
    setLoadingProcess({ ...loadingProcess, cancelling: true });
    try {
      const response = await ManagePatternService.updatePatternStatus(
        params.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        setOpenStopReason(false);
        setOpenStopComplete(true);
        setReason("");
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDeleteReview = async () => {
    try {
      const response = await ManagePatternService.deleteRatting(idRatting);
      if (response?.data?.statusCode === 200) {
        setOpenDeleteConfirm(false);
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleUpdateArea = async (value) => {
    // const regexPattern = /^\d+(\.\d+)?\.Sq\.ft \(\d+(\.\d+)?\.Sq\.m\)$/;
    // if (!regexPattern.test(value)) {
    //   showToast(TOAST_TYPE.ERROR, "Wrong area format");
    //   return;
    // }
    // const areaNumber = value.split(".Sq.ft")[0];
    if (Number(value) <= 0) {
      showToast(TOAST_TYPE.ERROR, "Area must be a positive number");
      return;
    }
    // if (pattern.unitPrice <= 0) {
    //   showToast(TOAST_TYPE.ERROR, "UnitPrice must be a positive number");
    //   return;
    // }
    const data = {
      sqft: Number(value),
      price: Number(value) * pattern.unitPrice
    };
    // setLoadingProcess({ ...loadingProcess, cancelling: true });

    //  if (value !==naturalArea) {
    //   showToast(TOAST_TYPE.WARN, "You have entered invalid data.");
    //   return;
    // }
    try {
      const response = await ManagePatternService.updatePatternPrice(
        params.id,
        data
      );
      if (response?.data?.statusCode === 200) {
        // setOpenStopReason(false);
        // setOpenStopComplete(true);
        showToast(TOAST_TYPE.SUCCESS, "Update Success");
        setValueArea(null);
        setOpenUpdateAreaConfirm(false);
        // setReason("");
        // setTimeReCall((prevState) => prevState + 1);
        await fetchData();
      } else {
        const errorData = response?.data
          ? response.data?.message?.[0]
          : "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
      // setLoadingProcess({ ...loadingProcess, cancelling: false });
    } catch (error) {
      // setLoadingProcess({ ...loadingProcess, cancelling: false });
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error.response.data?.message?.[0] ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const srcImg = (svg) => {
    const patternImgBlob = new Blob([new Uint8Array(svg.data)], {
      type: "image/svg+xml"
    });
    const imgBlobToFile = blobToFile(patternImgBlob, "pattenrImg");
    const imgFromFile = URL.createObjectURL(imgBlobToFile);
    return imgFromFile;
  };

  const getCategory = (type, column) => {
    if (type === "models") {
      const listModels = pattern?.models ?? [];
      const modelColumn =
        listModels.find((item) => item.column === column) || null;
      return modelColumn?.category ?? "";
    }
    if (type === "vehicles") {
      const listModels = pattern?.vehicles ?? [];
      const vehicleColumn =
        listModels.find((item) => item.column === column) || null;
      return vehicleColumn?.category ?? "";
    }
  };

  const getCategoryList = (pattern) => {
    const listModels = pattern?.models ?? [];
    const listModelsCategory = listModels.map((item) => item.category);
    const listVehicles = pattern?.vehicles ?? [];
    const listVehiclesCategory = listVehicles.map((item) => item.category);
    return [...listModelsCategory, ...listVehiclesCategory].join(" > ");
  };

  const handleOpenEditor = () => {
    const token = window.localStorage.getItem("token");
    const currentRefreshToken = localStorage.getItem("refreshToken");
    window.open(
      `${process.env.REACT_APP_EDITOR_URL}?patternId=${pattern.patternId}&token=${token}&refreshToken=${currentRefreshToken}`,
      "_blank"
    );
  };

  return (
    <Layout header="패턴관리 - 패턴 목록 - 패턴 보기">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <canvas ref={canvasRef} style={{ display: "none" }} />
          <StyledBoxContent sx={{ mb: 2 }}>업로더 정보</StyledBoxContent>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            style={{
              flexWrap: "wrap",
              gap: "12px"
            }}
          >
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>Uploader</StyledTableCellLeft>
                      <StyledTableCell>
                        {pattern.nickName ? pattern.nickName : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Company</StyledTableCellLeft>
                      <StyledTableCell>
                        {pattern.companyName ? pattern.companyName : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Country</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.country ? pattern.country : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Join Date</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.updatedAt ? formatDate(pattern.updatedAt) : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Contact Email</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.email ? pattern.email : ""}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <Box style={{ display: "flex" }}>
                <StyledBoxImageTitle>CI/BI Image</StyledBoxImageTitle>
                <StyledBoxImageContent>
                  {pattern.avatar ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                        width: "100px"
                      }}
                    >
                      <img
                        src={pattern.avatar}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          objectFit: "cover",
                          height: "100px",
                          width: "100px"
                        }}
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </StyledBoxImageContent>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 2 }}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <StyledBoxIntroductionTitle>
              Introduction
            </StyledBoxIntroductionTitle>
            <StyledBoxIntroductionContent>
              {pattern?.introduction && pattern?.introduction.trim()
                ? pattern.introduction
                : "X"}
            </StyledBoxIntroductionContent>
          </Box>
          <Box
            style={{ display: "flex", alignItems: "flex-end" }}
            sx={{ mt: 2 }}
          >
            <StyledBoxContent sx={{ mb: 2, mr: 2 }}>패턴 정보</StyledBoxContent>
            <StyledBoxContent
              sx={{
                mb: 2,
                fontSize: "0.9rem",
                height: "20px",
                padding: "0",
                width: "auto"
              }}
            >
              {/* {pattern.patternId ? pattern.patternId : ""} */}
              {getCategoryList(pattern)}
            </StyledBoxContent>
          </Box>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            style={{
              flexWrap: "wrap",
              gap: "12px"
            }}
          >
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>Region</StyledTableCellLeft>
                      <StyledTableCell>
                        {/* {pattern.region ? pattern.region : ""} */}
                        {getCategory("models", "region")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Maker</StyledTableCellLeft>
                      <StyledTableCell>
                        {/* {pattern.make ? pattern.make : ""} */}
                        {getCategory("models", "brand")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Year</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.year ? pattern.year : ""} */}
                        {getCategory("models", "year")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Model</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.model ? pattern.model : ""} */}
                        {getCategory("models", "model")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Sub model</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.subModel ? pattern.subModel : ""} */}
                        {getCategory("models", "sub_model")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Series</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.series ? pattern.series : "-"} */}
                        {getCategory("models", "series")}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>Side</StyledTableCellLeft>
                      <StyledTableCell>
                        {/* {pattern.side ? pattern.side : ""} */}
                        {getCategory("models", "part_section")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Pattern Part</StyledTableCellLeft>
                      <StyledTableCell>
                        {/* {pattern.part ? pattern.part : ""} */}
                        {getCategory("models", "pattern_part")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Sub part</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.subPart ? pattern.subPart : ""} */}
                        {getCategory("models", "sub_part")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Tag</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.tags ? pattern.tags : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Price</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.unitPrice && Number(pattern.unitPrice)
                          ? "$ " + formatNumber(Number(pattern.unitPrice))
                          : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Area</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {/* {pattern.area ? pattern.area + "ft" : "-"} */}
                        <TextField
                          // autoFocus
                          inputProps={{
                            step: 0.0001,
                            min: 0,
                            type: "number",
                            style: { textAlign: "center" }
                          }}
                          defaultValue={pattern?.sqft ?? ""}
                          onBlur={(e) => {
                            setValueArea(e.target.value);
                            setTimeout(() => {
                              setOpenUpdateAreaConfirm(true);
                            }, 0);
                          }}
                          placeholder="Sq.ft"
                          margin="dense"
                          fullWidth
                          variant="standard"
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 2 }}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <StyledButtonEdit variant="outline" onClick={handleOpenEditor}>
              Editor
            </StyledButtonEdit>
          </Box>
          <Box
            sx={{ mt: 2 }}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <StyledBoxIntroductionTitle>
              Pattern File
            </StyledBoxIntroductionTitle>
            <StyledBoxPatternFile>
              {pattern.svg ? (
                <img
                  ref={imageRef}
                  id="imageRef"
                  style={{ height: "65px" }}
                  src={pattern.svg ? pattern.svg : srcImg(pattern.plt)}
                  alt=""
                />
              ) : (
                ""
              )}
            </StyledBoxPatternFile>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-start" }}>
            <StyledBoxIntroductionTitle>
              Description Memo
            </StyledBoxIntroductionTitle>
            <StyledBoxPatternFile>
              {pattern.description ? pattern.description : "X"}
            </StyledBoxPatternFile>
          </Box>
          <Box
            style={{ display: "flex", alignItems: "flex-end" }}
            sx={{ mt: 2 }}
          >
            <StyledBoxContent sx={{ mb: 2, mr: 2 }}>판매 정보</StyledBoxContent>
          </Box>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            style={{
              flexWrap: "wrap",
              gap: "12px"
            }}
          >
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellLeft>Earning Sum</StyledTableCellLeft>
                      <StyledTableCell>
                        {pattern.earningSum
                          ? "$ " + formatNumber(Number(pattern.earningSum))
                          : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Download</StyledTableCellLeft>
                      <StyledTableCell>
                        {pattern.downloads
                          ? formatNumber(Number(pattern.downloads))
                          : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Cut Area Sum</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.cutAreaSum ? pattern.cutAreaSum + "ft" : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Size Score</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.sizeScore ? pattern.sizeScore : ""}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellLeft>Shape Score</StyledTableCellLeft>
                      <StyledTableCell style={{ textTransform: "capitalize" }}>
                        {pattern.shapeScore ? pattern.shapeScore : ""}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <TableContainer style={{ border: "1px solid #a5a5a5" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellHeaderReview colSpan={2} align="left">
                        Review
                      </StyledTableCellHeaderReview>
                    </TableRow>
                    {pattern.reviews && pattern.reviews.length ? (
                      pattern.reviews.map((comment, index) => (
                        <TableRow>
                          <StyledTableCellBodyReview
                            style={{ width: "30%" }}
                            align="center"
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {index + 1}
                            </span>
                            &nbsp;&nbsp; {comment?.nickName ?? ""}
                          </StyledTableCellBodyReview>
                          <StyledTableCellBodyReview style={{ width: "70%" }}>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <Typography style={{ flexBasis: "67%" }}>
                                {comment?.review ?? ""}
                              </Typography>
                              <Box
                                style={{
                                  flexBasis: "33%",
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "0.55rem",
                                    display: "inline-block"
                                  }}
                                >
                                  {comment.createdAt
                                    ? formatDateTime(comment.createdAt)
                                    : ""}
                                </Typography>
                                <Typography
                                  style={{
                                    textAlign: "right",
                                    color: "red",
                                    fontSize: "0.65rem",
                                    cursor: "pointer",
                                    marginTop: "7px"
                                  }}
                                  onClick={() => {
                                    setIdRatting(comment.id);
                                    setOpenDeleteConfirm(true);
                                  }}
                                >
                                  삭제
                                </Typography>
                              </Box>
                            </Box>
                          </StyledTableCellBodyReview>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <StyledTableCellBodyReview>
                          No review
                        </StyledTableCellBodyReview>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            {pattern && pattern.status !== "stop_selling" ? (
              <StyledLoadingButton
                color="cancel"
                onClick={() => setOpenStopConfirm(true)}
                loading={loadingProcess["stopping"] ? true : false}
                loadingIndicator="중지..."
                variant="contained"
              >
                판매중지
              </StyledLoadingButton>
            ) : (
              <StyledLoadingButton
                color="cancel"
                onClick={() => setOpenResumeConfirm(true)}
                loading={loadingProcess["resuming"] ? true : false}
                loadingIndicator="재개..."
                variant="contained"
              >
                판매 재개
              </StyledLoadingButton>
            )}
            <StyledButtonList variant="outlined" onClick={handleBack}>
              목록
            </StyledButtonList>
          </Box>

          <Popup2Action
            open={openStopConfirm}
            setOpen={setOpenStopConfirm}
            message="해당 패턴의 판매를 중지 하시겠습니까?"
            onClickConfirm={() => {
              setOpenStopConfirm(false);
              setOpenStopReason(true);
            }}
            onClickCancel={() => setOpenStopConfirm(false)}
          />

          <Popup
            open={openStopReason}
            setOpen={setOpenStopReason}
            message="사유를 입력해주세요."
            button="확인"
            onClick={handleStopPattern}
          >
            <Box
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                flexWrap: "nowrap",
                gap: "5px",
                width: "80%",
                textAlign: "center"
              }}
            >
              <Typography style={{ fontSize: "0.9rem" }}>
                *업로더에게 판매중지 사유로 보여집니다.
              </Typography>
              <StyledTextField
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Box>
          </Popup>
          <Popup
            open={openStopComplete}
            setOpen={setOpenStopComplete}
            button="확인"
            message="패턴 판매 중지가 완료되었습니다."
            onClick={() => setOpenStopComplete(false)}
          />

          <Popup2Action
            open={openResumeConfirm}
            setOpen={setOpenResumeConfirm}
            message="패턴을 재개하시겠습니까?"
            onClickConfirm={handleResumePattern}
            onClickCancel={() => setOpenResumeConfirm(false)}
          />

          <Popup
            open={openResumeComplete}
            setOpen={setOpenResumeComplete}
            button="확인"
            message="패턴이 재개되었습니다."
            onClick={() => setOpenResumeComplete(false)}
          />

          <Popup2Action
            open={openDeleteConfirm}
            setOpen={setOpenDeleteConfirm}
            message="이 리뷰를 삭제하시겠습니까?"
            onClickConfirm={handleDeleteReview}
            onClickCancel={() => setOpenDeleteConfirm(false)}
          />
          <Popup2Action
            open={openUpdateAreaConfirm}
            setOpen={setOpenUpdateAreaConfirm}
            message="Are you sure about the data you entered?"
            onClickConfirm={() => handleUpdateArea(valueArea)}
            onClickCancel={() => setOpenUpdateAreaConfirm(false)}
            confirmText="Yes"
            closeText="No"
          />
        </Box>
      )}
    </Layout>
  );
}

export default withParam(withToast(DetailPattern));
