import { PreferencesService } from "services";
import { adminConstants } from "../constants";

export const getListAdmin = (condition = {}) => {
  return async (dispatch) => {
    try {
      const res = await PreferencesService.getAdminsList(condition);
      if (res?.data?.statusCode === 200) {
        dispatch({
          type: adminConstants.GET_ADMINS_SUCCESS,
          payload: res?.data?.data ?? []
        });
      } else {
        dispatch({
          type: adminConstants.GET_ADMINS_FAIL,
          payload: res?.data?.message ?? ""
        });
      }
    } catch (error) {
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      dispatch({
        type: adminConstants.GET_ADMINS_FAIL,
        payload: message
      });
    }
  };
};

export const updateAdmin = (data) => {
  return (dispatch) => {
    dispatch({
      type: adminConstants.UPDATE_ADMIN,
      payload: data
    });
    dispatch(getListAdmin());
  };
};

export const createAdmin = (data) => {
  return (dispatch) => {
    dispatch({
      type: adminConstants.CREATE_ADMIN,
      payload: data
    });
    dispatch(getListAdmin());
  };
};

export const deleteAdmin = (id) => {
  return (dispatch) => {
    dispatch({
      type: adminConstants.DELETE_ADMIN,
      payload: id
    });
    dispatch(getListAdmin());
  };
};

export const resetAdmins = () => {
  return (dispatch) => {
    dispatch({
      type: adminConstants.RESET_ADMINS
    });
  };
};
