import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Select,
  MenuItem,
  Stack,
  TextField
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import Layout from "components/Layout";
import LoadingPage from "components/LoadingPage";
import TableData from "./components/Table";
import {
  formatDate,
  formatNumber,
  addMonths,
  subDays,
  monthDifference,
  formatDateSearch,
  formatDateSearchEnd,
  formatDateSearchStart
} from "utils/function";
import { getListMember, resetMembers } from "redux/actions/member";
import { useGlobalStore } from "hooks";
import { TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ManageMemberService } from "services";

const listStatus = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" }
];

function EditorSubscriptionCustomers({ showToast }) {
  const [loading, setLoading] = useState(true);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false
  });
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: ""
  });
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [listTransactions, setListTransactions] = useState([]);

  const handleCreateCondition = (isSearch = false, isResetting = false) => {
    const { type = "", search = "" } = searchTerms;
    let condition = {
      activity: "buy_premium",
      sortField: "nickName",
      sortType: "ASC"
    };
    if (isSearch || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1
        ? {
            ...condition,
            page: 1,
            limit: rowsPerPage
          }
        : { ...condition };
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearch ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    // if (type) {
    condition = {
      ...condition,
      type: type ? type : "all",
      search: search ? search.trim() : ""
    };
    // }
    if (dateFrom) {
      condition = {
        ...condition,
        startEditorFrom: formatDateSearch(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        startEditorTo: formatDateSearchEnd(dateTo)
      };
    }

    return condition;
  };

  async function fetchData(isSearch = false, isResetting = false) {
    try {
      const response = await ManageMemberService.getEditorPaymentHistory(
        handleCreateCondition(isSearch, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        const resData = response?.data?.data ?? [];
        setListTransactions(resData);
        setCount(response?.data?.count ?? 0);

        setLoading(false);
      } else {
        showToast(TOAST_TYPE.ERROR, response?.data?.message ?? "Error");
        setLoading(false);
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    // await dispatch(
    //   getListMember({
    //     ...searchTerms
    //   })
    // );
    // setPage(0);
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "" });
    setDateFrom(null);
    setDateTo(null);
    setPage(0);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const renderTable = () => {
    const sizeWidth = [
      "3%",
      "10%",
      "14%",
      "6%",
      "7%",
      "7%",
      "7%",
      "12%",
      "12%",
      // "7%",
      "22%"
    ];

    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "이용기간",
      "결제금액",
      "Paypal",
      "카드",
      "사용시작일",
      "사용만료일",
      // "상태",
      "Paypal email 주소"
    ];

    const tableData = listTransactions.map((member, index) => {
      // let paymentDueTime = null;
      // let startDate = null;
      // if (member.premiumTime) {
      //   paymentDueTime = addMonths(member.premiumTime);
      // }
      // if (member.premiumTime) {
      //   startDate = subDays(member.premiumTime, 30);
      // }
      let status = "",
        periodOfUse = "";
      if (member.end) {
        status = new Date(member.end) - new Date() > 0 ? "사용중" : "만료";
      }
      if (
        member.end &&
        member.start &&
        new Date(member.end) - new Date(member.start)
      ) {
        periodOfUse = Math.ceil(monthDifference(member.end, member.start)) + "개월";
      }
      return {
        ...member,
        index: index + 1 + rowsPerPage * page,
        // name: `${member?.user?.firstName ?? ""} ${
        //   member?.user?.lastName ?? ""
        // }`,
        name: member?.user?.nickName ?? "",
        email: member?.user?.email,
        periodOfUse: periodOfUse,
        amountOfPayment: member?.amount ? Math.abs(member?.amount) + "$" : "",
        paypal:
          member?.paymentMethod === "paypal" ? Math.abs(member?.amount) : 0,
        card: member?.paymentMethod === "paypal" ? 0 : Math.abs(member?.amount),
        startDate: member?.start ? formatDate(member?.start) : "",
        endDate: member?.end ? formatDate(member?.end) : "",
        status: status,
        ppEmail: member?.email ?? ""
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>회원 검색</Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  사용시작일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            {/* <Grid item style={{ marginRight: "20px" }}>
              <Grid container alignItems="center">
                <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px"
                    }}
                  >
                    회원등급
                  </Box>
                </FormControl>
                <FormControl>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px"
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem"
                              }
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important"
                          }
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid> */}
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listStatus.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px",
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="회원관리 - Editor 결제 내역">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <span>총 </span>
                <span style={{ color: "#0468b4", fontWeight: "bold" }}>
                  {formatNumber(count) ? formatNumber(count) : 0}
                </span>
                <span>명</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(EditorSubscriptionCustomers);
