import { PrivateRouter } from "HOC";
import ListBanner from "../ListBanner";
import AddBanner from "../AddBanner";
import ListPopup from "../ListPopup";
import EditBanner from "../EditBanner";
import EditPopup from "../EditPopup";
import AddPopup from "../AddPopup";

const routerManageBanner = [
  {
    path: "/popup-banner-management/banner-list",
    element: (
      <PrivateRouter>
        <ListBanner />
      </PrivateRouter>
    )
  },
  {
    path: "/popup-banner-management/banner-list/add",
    element: (
      <PrivateRouter>
        <AddBanner />
      </PrivateRouter>
    )
  },
  {
    path: "/popup-banner-management/banner-list/edit/:id",
    element: (
      <PrivateRouter>
        <EditBanner />
      </PrivateRouter>
    )
  },
  {
    path: "/popup-banner-management/popup-list",
    element: (
      <PrivateRouter>
        <ListPopup />
      </PrivateRouter>
    )
  },
  {
    path: "/popup-banner-management/popup-list/edit/:id",
    element: (
      <PrivateRouter>
        <EditPopup />
      </PrivateRouter>
    )
  },
  {
    path: "/popup-banner-management/popup-list/add",
    element: (
      <PrivateRouter>
        <AddPopup />
      </PrivateRouter>
    )
  }
];

export default routerManageBanner;
