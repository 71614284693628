import React, { useEffect, useMemo, useState } from "react";
import Layout from "components/Layout";
import {
  // ManageUltrafitShopService,
  // ManagePatternService,
  // UploadService,
  ManageEmailService
} from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
// import LoadingPage from "components/LoadingPage";
import LoadingOneLine from "components/LoadingOneLine";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { useForm } from "react-hook-form";
import {
  Grid,
  // TextField,
  Typography,
  // Box,
  styled,
  Button,
  Select,
  MenuItem,
  Box
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
// import { generateImageSrc, listGroupBy, sortList } from "utils/function";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
import { useNavigate } from "react-router-dom";
import DesignatedTableData from "./DesignatedTableData";
import { useGlobalStore } from "hooks";
import {
  getListEmailManual,
  resetEmailsManual,
  updateEmailState
} from "redux/actions/email";

const listState = [
  {
    value: "automatic",
    text: "자동발송"
  },
  {
    value: "stop",
    text: "발송 중지"
  }
];

const listConditionAutoSend = [
  { value: "회원 가입 시", text: "회원 가입 시" },
  { value: "휴면 회원 전환 시", text: "휴면 회원 전환 시" },
  { value: "회원 정지 시", text: "회원 정지 시" },
  { value: "배송 시작 시", text: "배송 시작 시" }
];

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  border: "1px solid #c4c4c4",
  color: "#000"
});

const StyledSelect = styled(Select)(({ width }) => ({
  borderRadius: "0",
  height: "27px",
  width: width ? width : "200px",
  marginRight: "5px",
  backgroundColor: "#fff",
  color: "#000"
}));

function DesignatedList({ showToast }) {
  const [state, dispatch] = useGlobalStore();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate();

  const handleCreateCondition = () => {
    let condition = { sendType: "manual" };
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: page + 1,
        limit: rowsPerPage
      };
    }

    return condition;
  };

  const {
    emailsManual = [],
    loadingManual = true,
    countManual = 0,
    errorManual = null
  } = useMemo(() => state?.email, [state]);

  useEffect(() => {
    dispatch(getListEmailManual(handleCreateCondition()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!loadingManual && errorManual) {
      showToast(TOAST_TYPE.ERROR, errorManual);
    }
  }, [errorManual, loadingManual, showToast]);

  useEffect(() => {
    return () => {
      dispatch(resetEmailsManual());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditManualEmail = (id) => {
    navigate(`/e-mail-management/list-emails/edit-manual-email/${id}`);
  };

  const handleDesignedEmail = (id) => {
    navigate(`/e-mail-management/list-emails/manual-send-email/${id}`);
  };

  const renderDesignatedTable = () => {
    const sizeWidth = ["20%", "55%", "15%", "10%"];

    const headersTitle = ["제목", "내용", "내용 수정", ""];

    const tableData = emailsManual.map((email) => {
      return {
        ...email,
        name: email?.title ?? "",
        content: (
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1
            }}
            variant="body1"
            component={"span"}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: email?.body ? email.body : ""
              }}
            />
          </Typography>
        ),
        state: (
          <StyledSelect
            MenuProps={{
              disableScrollLock: true
            }}
            width="120px"
            value={email?.status ?? ""}
            // onChange={(e) => handleChangeState(e, email.id)}
          >
            {listState.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </StyledSelect>
        ),
        edit: (
          <StyledButton
            variant="outlined"
            onClick={() => handleEditManualEmail(email.id)}
          >
            수정
          </StyledButton>
        ),
        issue: (
          <StyledButton
            variant="outline"
            onClick={() => handleDesignedEmail(email.id)}
          >
            지정발급
          </StyledButton>
        )
      };
    });

    return (
      <DesignatedTableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={countManual}
      />
    );
  };

  return (
    <React.Fragment>
      {loadingManual ? <LoadingOneLine /> : <>{renderDesignatedTable()}</>}
    </React.Fragment>
  );
}

export default withToast(DesignatedList);
