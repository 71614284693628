import axiosClient from "../axiosClient";
import URL from "./url";

class ManageMemberService {
  getList(condition = { page: 1, limit: 5 }) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_LIST_URL + query;
    return axiosClient.get(url);
  }
  suspendMember(body = {}) {
    const url = URL.SUSPEND_MEMBER_URL;
    return axiosClient.post(url, body);
  }
  unsuspendMember(userId) {
    const url = `${URL.SUSPEND_MEMBER_URL}/${userId}`;
    return axiosClient.delete(url);
  }
  getUser(userId) {
    const url = `${URL.GET_USER_URL}/${userId}`;
    return axiosClient.get(url);
  }
  getPolicy(condition = {}) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_POLICY_URL + query;
    return axiosClient.get(url);
  }
  // setPolicy(body = {}) {
  //   const url = URL.SET_POLICY_URL;
  //   return axiosClient.post(url, body);
  // }
  createPolicy(body = {}) {
    const url = URL.CREATE_POLICY_URL;
    return axiosClient.post(url, body);
  }
  updatePolicy(id, body = {}) {
    const url = URL.UPDATE_POLICY_URL + "/" + id;
    return axiosClient.put(url, body);
  }
  getEditorPaymentHistory(
    condition = { activity: "sponsor_credit", page: 1, limit: 5 }
  ) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_EDITOR_PAYMENT_HISTORY_URL + query;
    return axiosClient.get(url);
  }
  getUserPhotoById(id) {
    const url = URL.GET_USER_PHOTO + "/" + id;
    return axiosClient.get(url);
  }
}

export default new ManageMemberService();
