import { Button, Modal, Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "1px solid #c4c4c4",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "40px 0 0 0"
};

export default function Popup2Action(props) {
  const {
    open = false,
    setOpen = () => {},
    message,
    onClickConfirm = () => {},
    onClickCancel = () => {},
    confirmText = "",
    closeText = ""
  } = props;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <Typography style={{ textAlign: "center" }}>{message}</Typography>
        {props.children ? props.children : ""}
        <Box style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
          <Button
            variant="outline"
            style={{
              border: "1px solid #c4c4c4",
              textTransform: "capitalize",
              margin: "20px 0"
            }}
            onClick={onClickConfirm}
          >
            {confirmText ? confirmText : "확인"}
          </Button>
          <Button
            variant="outline"
            style={{
              border: "1px solid #c4c4c4",
              textTransform: "capitalize",
              margin: "20px 0"
            }}
            onClick={onClickCancel}
          >
            {closeText ? closeText : "취소"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
