import React, { useState, useEffect } from "react";
import withToast from "HOC/withToast";
import Layout from "components/Layout";
import {
  Box,
  TextField,
  Typography,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
} from "@mui/material";
import { PreferencesService } from "services";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import {
  listGroupBy,
  mergeArrays,
  sortObjectByKey,
  updateArr2,
} from "utils/function";
import LoadingPage from "components/LoadingPage";
// import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { tableCellClasses } from "@mui/material/TableCell";

import * as XLSX from "xlsx";
import { useForm } from "react-hook-form";

const transformString = (data) => {
  if (typeof data === "number") {
    return data;
  }
  if (typeof data === "string") {
    return Number(data?.replace?.("kg", "")?.replace?.(",", ".")?.trim?.());
  }
  return "";
};

const transformNumber = (data) => {
  if (typeof data === "number") {
    return data;
  }
  if (typeof data === "string") {
    return Number(data?.replace?.(",", ".")?.trim?.());
  }
  return "";
};

const StyledTextField = styled(TextField)(({ height, width }) => ({
  backgroundColor: "#fff",
  color: "#000",
  "& input": {
    width: width ? `${width}` : "150px",
    height: height ? `${height}` : "36px",
    boxSizing: "border-box",
    textAlign: "center",
  },
  "& input:focus": {
    outline: "none",
  },
  "& fieldset": {
    borderRadius: 0,
    top: -6,
    bottom: 1,
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: "0.9rem",
    height: 55,
    padding: "5px 7px",
    textAlign: "center",
    // border: "1px solid rgb(110, 118, 118)",
    border: "none",
    minWidth: 150,
  },
}));

function ShippingWeightFee({ showToast }) {
  const [loading, setLoading] = useState(true);

  const [listAreWeightFee, setListAreWeightFee] = useState({});
  const [listAreWeightFeeDefault, setListAreWeightFeeDefault] = useState({});
  const [listWeight, setListWeight] = useState([]);
  const [listArea, setListArea] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [timeRerender, setTimeRerender] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);

  const classes = useStyles();

  // const navigate = useNavigate();
  const { setValue, register, getValues } = useForm({
    defaultValues: {
      weights: {},
      listArea: {},
    },
  });

  useEffect(() => {
    // async function fetchData() {
    //   try {
    //     const response = await PreferencesService.getShippingWeight();
    //     if (response.status === 201) {
    //       setListAreWeightFeeDefault(response?.data?.result ?? []);
    //       const areaWeight = sortObjectByKey(
    //         listGroupBy(response?.data?.result ?? [], "area")
    //       );
    //       console.log("areaWeight", areaWeight);
    //       setListAreWeightFee(areaWeight);
    //       const groupByWeight = listGroupBy(
    //         response?.data?.result ?? [],
    //         "weight"
    //       );
    //       const weights = Object.keys(groupByWeight)
    //         .map((weight) => weight)
    //         .sort((a, b) => Number(a) - Number(b));
    //       setListWeight(weights);
    //       setLoading(false);
    //     } else {
    //       setLoading(false);
    //       const errorData = response?.data
    //         ? response.data?.msg
    //         : "Error" ?? "Error";
    //       showToast(TOAST_TYPE.ERROR, errorData);
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //     const errorStatusText = error?.response
    //       ? error.response?.statusText
    //       : "";
    //     const errorDataMessage = error?.response
    //       ? error.response?.data
    //         ? error.response.data?.message
    //         : ""
    //       : "";
    //     const errorMessage =
    //       errorDataMessage ?? errorStatusText ?? "System Error";
    //     showToast(TOAST_TYPE.ERROR, errorMessage);
    //   }
    // }
    async function fetchData() {
      try {
        const response = await PreferencesService.getShippingRegion();
        if (response?.data?.statusCode === 200) {
          const data = response?.data?.data ?? [];
          const listGroupRegion = listGroupBy(data, "region");
          setListArea(listGroupRegion);
          setValue("listArea", listGroupRegion);
          // console.log(
          //   'listGroupBy(data, "region")',
          //   listGroupBy(data, "region")
          // );
          let listCost = [];
          data.forEach((item) => {
            const costs = item.costs ?? [];
            listCost = [...listCost, ...costs];
          });
          const groupByWeight = listGroupBy(listCost, "weight");
          // console.log("groupByWeight", groupByWeight);
          const weights = Object.keys(groupByWeight)
            .map((item) => ({
              weight: item,
              index: groupByWeight[item][0].index,
            }))
            .sort((a, b) => Number(a.index) - Number(b.index));
          // console.log("weights", weights);
          setValue("weights", weights);
          // const weights = Object.keys(groupByWeight)
          //   .map((weight) => weight)
          //   .sort((a, b) => Number(a) - Number(b));
          setListWeight(weights);
          setLoading(false);
        } else {
          setLoading(false);
          const errorData = response?.data
            ? response.data?.msg
            : "Error" ?? "Error";
          showToast(TOAST_TYPE.ERROR, errorData);
        }
      } catch (error) {
        setLoading(false);
        const errorStatusText = error?.response
          ? error.response?.statusText
          : "";
        const errorDataMessage = error?.response
          ? error.response?.data
            ? error.response.data?.message
            : ""
          : "";
        const errorMessage =
          errorDataMessage ?? errorStatusText ?? "System Error";
        showToast(TOAST_TYPE.ERROR, errorMessage);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRerender]);

  const handleSetWeightFee = async (data) => {
    try {
      const response = await PreferencesService.setShippingAreaWeight(data);
      if (response.status === 201 && response.data.status === SUCCESS_STATUS) {
        showToast(TOAST_TYPE.SUCCESS, "Update success");
      } else {
        showToast(TOAST_TYPE.ERROR, response?.data?.msg ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.msg
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  // const handleUpdateWeight = async (oldWeight, newWeight) => {
  //   const item = listAreWeightFeeDefault.find(
  //     (item) => item.weight === oldWeight
  //   );
  //   try {
  //     const response = await PreferencesService.updateShippingAreaWeight(
  //       item.id,
  //       {
  //         weight: Number(newWeight)
  //       },
  //       oldWeight
  //     );
  //     if (response.status === 200) {
  //       showToast(TOAST_TYPE.SUCCESS, "Update success");
  //     } else {
  //       showToast(TOAST_TYPE.ERROR, response?.data?.msg ?? "Error");
  //     }
  //   } catch (error) {
  //     const errorStatusText = error?.response ? error.response?.statusText : "";
  //     const errorDataMessage = error?.response
  //       ? error.response?.data
  //         ? error.response.data?.msg
  //         : ""
  //       : "";
  //     const errorMessage =
  //       errorDataMessage ?? errorStatusText ?? "System Error";
  //     showToast(TOAST_TYPE.ERROR, errorMessage);
  //   }
  // };

  // const handleBackToList = () => {
  //   navigate("/preferences/setting-the-shipping-fee");
  // };

  const handleSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      // setSelectedFile(undefined);
      return;
    }

    setUploading(true);
    const fileName = e.target.files[0].name;
    if (fileName?.includes(".xlsx") || fileName?.includes(".xls")) {
      setSelectedFile(fileName);

      const promiseReadData = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const ws = wb.Sheets["수출_IP"];

          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
          setUploading(false);
        };
      });

      await promiseReadData
        .then(async (listRow) => {
          // row 14 ----> 73
          // console.log("listRow", listRow);
          let listAreaWeight = {};
          for (let i = 1; i < listRow[1]?.length; i++) {
            listAreaWeight[i] = [];
          }
          // const listAreaWeight = {
          //   1: [],
          //   2: [],
          //   3: [],
          //   4: [],
          //   5: [],
          //   6: [],
          //   7: [],
          //   8: []
          // };
          // for (let rowi = 1; rowi < listRow?.length; rowi++) {
          for (let rowi = 2; rowi < listRow?.length - 1; rowi++) {
            // let index = rowi - 13;
            const row = listRow[rowi];
            for (let j = 0; j < row?.length - 1; j++) {
              listAreaWeight[j + 1].push({
                weight: transformString(row[0]),
                cost: transformNumber(row[j + 1]),
                index: rowi - 2,
              });
            }
            // listAreaWeight["1"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[2].replace(",", ".")),
            // });
            // listAreaWeight["2"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[3].replace(",", ".")),
            //   index: index,
            // });
            // listAreaWeight["3"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[4].replace(",", ".")),
            //   index: index,
            // });
            // listAreaWeight["4"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[5].replace(",", ".")),
            //   index: index,
            // });
            // listAreaWeight["5"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[6].replace(",", ".")),
            //   index: index,
            // });
            // listAreaWeight["6"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[7].replace(",", ".")),
            //   index: index,
            // });
            // listAreaWeight["7"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[8].replace(",", ".")),
            //   index: index,
            // });
            // listAreaWeight["8"].push({
            //   weight: Number(`${row[0]}`),
            //   cost: Number(row[9].replace(",", ".")),
            //   index: index,
            // });
          }
          // console.log("items file excel", listAreaWeight);

          // while (index < listRow.length) {
          //   const row = listRow[index];
          //   if (row) {
          //     Object.keys(listArea).forEach((item, idx) => {
          //       const data = {
          //         weight: Number(`${row[0]}`) || "",
          //         cost: Number(row[idx + 1]) || "",
          //         index: index
          //       };
          //       if (Array.isArray(listAreaWeight[item])) {
          //         listAreaWeight[item].push(data);
          //       } else {
          //         listAreaWeight[item] = [data];
          //       }
          //     });
          //   }
          //   index++;
          // }
          const dataFillDB = [];

          Object.keys(listAreaWeight).forEach((item, index) => {
            if (Object.keys(listArea).includes(item)) {
              const data = listArea[item]?.[0];
              dataFillDB.push({
                ...data,
                // costs: mergeArrays(listAreaWeight[item], data.costs)
                costs: listAreaWeight[item],
              });
            } else {
              dataFillDB.push({
                region: `${item}`,
                order: 1,
                countries: [],
                costs: listAreaWeight[item],
              });
            }
          });

          // if (Object.keys(listAreaWeight).length < Object.keys(listArea)) {
          //   for (
          //     let i = Object.keys(listAreaWeight).length + 1;
          //     i <= Object.keys(listArea).length;
          //     i++
          //   ) {
          //     const area = listArea[i][0];
          //     dataFillDB.push({
          //       ...area,
          //       costs: updateArr2(dataFillDB[0].costs, area.costs)
          //     });
          //   }
          // }
          // console.log("dataFillDB", dataFillDB);

          await Promise.all(
            dataFillDB.map((item) => {
              if (item?.id) {
                return PreferencesService.updateShippingRegion(item.id, {
                  ...item,
                });
              } else {
                return PreferencesService.createShippingRegion({
                  ...item,
                });
              }
            })
          ).catch(function (err) {
            showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
            setUploading(false);
          });

          setTimeRerender((prevState) => prevState + 1);
        })
        .catch((err) => {
          // showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
          showToast(TOAST_TYPE.ERROR, err?.message || "Wrong file");
          setUploading(false);
        });
      setUploading(false);
    } else {
      showToast(TOAST_TYPE.ERROR, "Wrong file extension");
      setUploading(false);
      return;
    }
  };

  const handleSave = async () => {
    // console.log("getValues", getValues());
    setSaving(true);
    const { listArea, weights } = getValues();
    const newListArea = [];
    Object.keys(listArea).forEach((item, index) => {
      const area = listArea[item][0];
      let costs = area.costs;
      costs = costs
        .map((cost, idx) => {
          if (cost.cost) {
            return {
              cost: Number(cost.cost),
              index: cost.index || idx,
              weight: Number(weights[idx].weight),
            };
          }
          return null;
        })
        .filter((cost2) => !!cost2);
      newListArea.push({ ...area, costs: costs });
    });
    // console.log("newListArea", newListArea);
    await Promise.all(
      newListArea.map((item) => {
        return PreferencesService.updateShippingRegion(item.id, {
          ...item,
        });
      })
    ).catch(function (err) {
      showToast(TOAST_TYPE.ERROR, err?.message ?? "Error");
      setSaving(false);
    });
    setSaving(false);
    setTimeRerender((prevState) => prevState + 1);
  };

  return (
    <Layout header="환경설정 - 배송중량 관리">
      {loading ? (
        <LoadingPage />
      ) : (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {/* <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Typography>배송 지역 관리</Typography>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #a5a5a5",
                textTransform: "capitalize",
                color: "#000",
                fontWeight: "bold",
                lineHeight: 1.2
              }}
              onClick={handleBackToList}
            >
              돌아가다
            </Button>
          </Box> */}

          <Grid container alignItems="center" sx={{ position: "relative" }}>
            <Button
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px",

                width: 180,
                marginRight: 5,
              }}
            >
              엑셀 파일 갖고오기
              <input
                type="file"
                // hidden
                disabled={uploading}
                onChange={(e) => {
                  handleSelectFile(e);
                  e.target.value = null;
                }}
                style={{
                  position: "absolute",
                  left: "0px",
                  width: "180px",
                  height: "36px",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </Button>
            <StyledTextField
              style={{
                position: "absolute",
                top: 1,
                left: 185,
              }}
              width="250px"
              height="36px"
              size="small"
              value={selectedFile ? selectedFile : "파일 위치"}
              disabled
            />
            <input
              type="file"
              disabled={uploading}
              onChange={(e) => {
                handleSelectFile(e);
                e.target.value = null;
              }}
              style={{
                position: "absolute",
                left: "185px",
                width: "250px",
                opacity: 0,
              }}
            />

            {uploading && (
              <LoadingButton
                loading={uploading}
                variant="outlined"
                style={{
                  position: "absolute",
                  left: 440,
                  border: "none",
                  outline: "none",
                }}
              >
                Submit
              </LoadingButton>
            )}

            <LoadingButton
              loading={saving}
              onClick={handleSave}
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px",
                width: 90,
                marginLeft: "auto",
              }}
            >
              저장
            </LoadingButton>
          </Grid>

          <Box
            sx={{
              height: "100%",
              whiteSpace: "nowrap",
              width: "100%",
              // overflowX: "scroll",
              mt: 4,
            }}
          >
            <TableContainer>
              <Table style={{ width: "auto" }}>
                <TableBody>
                  <TableRow>
                    <StyledTableCell
                      style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                    >
                      중량(KG)
                    </StyledTableCell>
                    {!!Object.keys(listArea).length
                      ? Object.keys(listArea).map((area, index) => (
                          <StyledTableCell
                            style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                            key={index}
                          >{`${area} 지역`}</StyledTableCell>
                        ))
                      : null}
                  </TableRow>
                  {listWeight.map((weight, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>
                        <StyledTextField
                          className="classes.input"
                          key={index}
                          sx={{ mb: 1.5 }}
                          width="120px"
                          inputProps={{
                            step: 0.0001,
                            min: 0,
                            type: "number",
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          InputProps={{ pattern: "[0-9]+.*[0-9]*" }}
                          {...register(`weights[${index}.weight]`)}
                          // defaultValue={Number(weight).toFixed(1)}
                          // onBlur={(e) => {
                          //   handleUpdateWeight(weight, e.target.value);
                          // }}
                        />
                        {/* <Typography
                          sx={{
                            mb: 1.5,
                            width: 120,
                            height: 36,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #c4c4c4"
                          }}
                          key={index}
                        >
                          {Number(weight.weight).toFixed(1)}
                        </Typography>*/}
                      </StyledTableCell>
                      {!!Object.keys(listArea).length
                        ? Object.keys(listArea).map((area, idx) => {
                            //const listCost = listArea[area]?.[0]?.costs ?? [];
                            //   console.log(listArea[area]);
                            //const currentWeight = listCost.find(
                            //  (item) => item.weight === weight
                            //);
                            //  console.log(currentWeight);
                            //const fee = currentWeight?.cost ?? "";

                            return (
                              <StyledTableCell
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.1rem",
                                }}
                                key={idx}
                              >
                                {/* {index} */}
                                <StyledTextField
                                  // key={index}
                                  sx={{ mb: 1.5 }}
                                  width="120px"
                                  className="classes.input"
                                  // defaultValue={fee ? Number(fee) : ""}
                                  inputProps={{
                                    step: 0.0001,
                                    min: 0,
                                    type: "number",
                                    pattern: "[0-9]+.*[0-9]*",
                                  }}
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  InputProps={{ pattern: "[0-9]+.*[0-9]*" }}
                                  {...register(
                                    `listArea[${area}][0].costs[${index}].cost`
                                  )}
                                />
                                {/* <Typography
                                  sx={{
                                    mb: 1.5,
                                    width: 120,
                                    height: 36,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px solid #c4c4c4"
                                  }}
                                  key={index}
                                >
                                  {Number(fee)}
                                </Typography> */}
                              </StyledTableCell>
                            );
                          })
                        : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box sx={{ display: "inline-block", width: "150px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  weight(KG)
                </Typography>
                {listWeight.map((weight, index) => (
                  <StyledTextField
                    key={index}
                    sx={{ mb: 1.5 }}
                    width="120px"
                    height="30px"
                    defaultValue={Number(weight).toFixed(2)}
                  />
                ))}
              </Box>
            </Box>
            {!!Object.keys(listAreWeightFee).length
              ? Object.keys(listAreWeightFee).map((area, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "inline-block",
                      width: "150px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        style={{
                          border: "1px solid #a5a5a5",
                          width: "120px",
                          textAlign: "center",
                          height: "30px",
                          position: "relative",
                          top: "-16px",
                          zIndex: "2",
                        }}
                      >
                        {`${area} area`}
                      </Box>
                      {listAreWeightFee[area]
                        .sort((a, b) => a.weight - b.weight)
                        .map((fee, index) => (
                          <StyledTextField
                            key={index}
                            sx={{ mb: 1.5 }}
                            width="120px"
                            height="30px"
                            defaultValue={Number(fee.sum)}
                          />
                        ))}
                    </Box>
                  </Box>
                ))
              : null} */}
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default withToast(ShippingWeightFee);
