import React /*{ useEffect, useMemo }*/ from "react";
import Layout from "components/Layout";
// import {
//   ManageUltrafitShopService,
//   ManagePatternService,
//   UploadService,
//   ManageEmailService
// } from "services";
import { withToast } from "HOC";
// import { TOAST_TYPE } from "utils/constants";
// import LoadingPage from "components/LoadingPage";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { useForm } from "react-hook-form";
import {
  Grid,
  // TextField,
  Typography,
  // Box,
  // styled,
  Button,
  // Select,
  // MenuItem,
  // Box
} from "@mui/material";
// import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
// import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
// import { generateImageSrc, listGroupBy, sortList } from "utils/function";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
import { useNavigate } from "react-router-dom";
import AutomaticList from "./components/AutomaticList";
import DesignatedList from "./components/DesignatedList";
// import { useGlobalStore } from "hooks";
// import {
//   getListEmail,
//   resetEmails,
//   updateEmailState
// } from "redux/actions/email";

// const listState = [
//   {
//     value: "automatic",
//     text: "자동발송"
//   },
//   {
//     value: "stop",
//     text: "발송 중지"
//   }
// ];

// const listConditionAutoSend = [
//   { value: "회원 가입 시", text: "회원 가입 시" },
//   { value: "휴면 회원 전환 시", text: "휴면 회원 전환 시" },
//   { value: "회원 정지 시", text: "회원 정지 시" },
//   { value: "배송 시작 시", text: "배송 시작 시" }
// ];

// const StyledButton = styled(Button)({
//   textTransform: "capitalize",
//   border: "1px solid #c4c4c4",
//   color: "#000"
// });

// const StyledSelect = styled(Select)(({ width }) => ({
//   borderRadius: "0",
//   height: "27px",
//   width: width ? width : "200px",
//   marginRight: "5px",
//   backgroundColor: "#fff",
//   color: "#000"
// }));

function EmailList({ showToast }) {
  const navigate = useNavigate();

  const handleAddEmailManual = () => {
    navigate("/e-mail-management/list-emails/add-manual-email");
  };

  return (
    <Layout header="보낸 이메일 관리">
      <Grid container flexDirection="column">
        <Grid item>
          <Typography>사용중인 이메일: admin@plofix.com</Typography>
        </Grid>
        <Grid item sx={{ mt: 5 }}>
          <Typography>자동 발송</Typography>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <AutomaticList />
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography>지정 발송</Typography>
            <Button
              onClick={handleAddEmailManual}
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px",
              }}
            >
              새로운 메일 생성
            </Button>
          </Grid>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <DesignatedList />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default withToast(EmailList);
