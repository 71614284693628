import URL from "./url";
import axiosClient from "../axiosClient";
class ManagePatternService {
  getListPattern(condition = { page: 1, limit: 5 }) {
    const query =
      condition && Object.keys(condition).length > 0
        ? "?" + new URLSearchParams(condition).toString()
        : "";
    const url = URL.GET_PATTERN_LIST + query;
    return axiosClient.get(url);
  }
  getPatternInfo(id) {
    const url = URL.GET_PATTERN_INFO + "/" + id;
    return axiosClient.post(url);
  }
  getPatternById(id) {
    const url = URL.GET_PATTERN_BY_ID + "/" + id;
    return axiosClient.get(url);
  }
  deleteRatting(id) {
    const url = URL.DELETE_RATTING + "/" + id;
    return axiosClient.delete(url);
  }
  updatePatternStatus(id, body = {}) {
    const url = URL.UPDATE_PATTERN_STATUS + "/" + id;
    return axiosClient.put(url, body);
  }
  updatePattern(id, body = {}) {
    const url = URL.UPDATE_PATTERN + "/" + id;
    return axiosClient.put(url, body);
  }

  updatePatternPrice(id, body = {}) {
    const url = URL.UPDATE_PATTERN_PRICE + "/" + id;
    return axiosClient.put(url, body);
  }
  getPatternComment(id) {
    const url = URL.GET_PATTERN_COMMENT + "/" + id;
    return axiosClient.post(url);
  }
  getUltrafitCategoryList(body = {}) {
    const url = URL.GET_ULTRAFIT_CATEGORY_LIST;
    return axiosClient.post(url, body);
  }
  getCategoryList() {
    const url = URL.GET_CATEGORY_LIST;
    return axiosClient.get(url);
  }
  createCategory(body = {}) {
    const url = URL.CREATE_CATEGORY;
    return axiosClient.post(url, body);
  }
  updateCategory(id, body = {}) {
    const url = URL.UPDATE_CATEGORY + "/" + id;
    return axiosClient.put(url, body);
  }
  deleteCategory(id) {
    const url = URL.DELETE_CATEGORY + "/" + id;
    return axiosClient.delete(url);
  }
  addCarModel(body = {}) {
    const url = URL.CREATE_CAR_MODEL;
    return axiosClient.post(url, body);
  }
  getCarModel(body = {}) {
    const url = URL.GET_CAR_MODEL;
    return axiosClient.post(url, body);
  }
  deleteCarModel(id, body = {}) {
    const url = URL.DELETE_CAR_MODEL + "/" + id;
    return axiosClient.put(url, body);
  }
  updateCarModel(id, body = {}) {
    const url = URL.UPDATE_CAR_MODEL + "/" + id;
    return axiosClient.put(url, body);
  }
  updateCarModelColumn(column, body = {}) {
    const url = URL.UPDATE_CAR_MODEL + "/" + column;
    return axiosClient.put(url, body);
  }
}

export default new ManagePatternService();
