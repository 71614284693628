import React, { useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import LoadingOneLine from "components/LoadingOneLine";
import TableData from "./TableManualIssuingMember";
import { formatDate } from "utils/function";
import { SUCCESS_STATUS, TOAST_TYPE } from "utils/constants";
import { withToast } from "HOC";
import { ManageCouponService, ManageMemberService } from "services";

const listStatus = [
  { value: "all", text: "전체 회원" },
  { value: "active", text: "활성 회원" },
  { value: "suspended", text: "정지 회원" },
  { value: "secession", text: "탈퇴 회원" }
];

// const listLevels = [
//   { value: "begginer", text: "Beginner" },
//   { value: "advanced", text: "Advanced" },
//   { value: "expert", text: "Expert" },
//   { value: "master", text: "Master" },
// ];

function ListManualIssuingMember({ showToast, couponId, setTimeReCall }) {
  const [loading, setLoading] = useState(true);
  const [listChecked, setListChecked] = useState([]);
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false,
    suspending: false,
    fetchingMember: false
  });
  const [listMember, setListMember] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    search: "",
    // levels: [],
    status: "all"
  });

  // const [timeReCall, setTimeReCall] = useState(0);

  const handleSelectUser = (e, userId) => {
    if (e.target.checked) {
      setListChecked([...listChecked, userId]);
    } else {
      const newListChecked = listChecked.filter((id) => id !== userId);
      setListChecked(newListChecked);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function fetchData() {
      try {
        const res = await ManageMemberService.getList();
        if (res.status === 201) {
          let users = res?.data?.users ?? [];
          setListMember([...users]);
          setLoading(false);
        } else {
          setLoading(false);
          showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
        }
      } catch (error) {
        setLoading(false);
        const message = error?.response
          ? error.response?.statusText
          : error?.statusText ?? "";
        showToast(TOAST_TYPE.ERROR, message);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponId]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    try {
      const res = await ManageMemberService.getList({
        ...searchTerms,
        status: searchTerms.status === "all" ? "" : searchTerms.status
      });
      if (res.status === 201) {
        let users = res?.data?.users ?? [];
        setListMember([...users]);
        setLoading(false);
      } else {
        setLoading(false);
        showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
      }
    } catch (error) {
      setLoading(false);
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      showToast(TOAST_TYPE.ERROR, message);
    }
    setLoadingProcess({ ...loadingProcess, searching: false });
  };

  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({
      search: "",
      status: "all"
    });
    try {
      const res = await await ManageMemberService.getList();
      if (res.status === 201) {
        let users = res?.data?.users ?? [];
        setListMember([...users]);
        setLoading(false);
      } else {
        setLoading(false);
        showToast(TOAST_TYPE.ERROR, res?.data?.msg ?? "Error");
      }
    } catch (error) {
      setLoading(false);
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      showToast(TOAST_TYPE.ERROR, message);
    }
    // await dispatch(getListMember({}));
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleChangeSearchTerms = (key, value) => {
    // if (key === "levels") {
    //   const levels = searchTerms["levels"];
    //   const newLevels = levels.includes(value)
    //     ? levels.filter((level) => level !== value)
    //     : [...levels, value];
    //   setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    // }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
    if (key === "status") {
      setSearchTerms((prevState) => ({ ...prevState, status: value }));
    }
  };

  const handleIssue = async () => {
    if (!!!listChecked.length) {
      showToast(TOAST_TYPE.WARN, "No members selected");
      return;
    }

    try {
      let body = listChecked.map((item) => ({
        userId: item,
        couponId: couponId,
        issueMethod: "manual"
      }));

      const res = await ManageCouponService.issueCoupon(body);
      if (res.status === 201) {
        showToast(TOAST_TYPE.SUCCESS, "Success");
        setListChecked([]);
        setTimeReCall((prevState) => prevState + 1);
      } else {
        setLoading(false);
        showToast(TOAST_TYPE.ERROR, res?.data?.message ?? "Error");
      }
    } catch (error) {
      setLoading(false);
      const message = error?.response
        ? error.response?.statusText
        : error?.statusText ?? "";
      showToast(TOAST_TYPE.ERROR, message);
    }
  };

  const renderTable = () => {
    const sizeWidth = ["15%", "15%", "20%", "13%", "10%", "15%", "7%"];

    const headersTitle = [
      "Code",
      "성명",
      "이메일",
      "연락처",
      "국가",
      "가입일",
      <Button
        variant="outline"
        sx={{
          border: "1px solid #a5a5a5",
          textTransform: "capitalize",
          padding: "0 5px"
        }}
        onClick={handleIssue}
      >
        발급
      </Button>
    ];

    const tableData = listMember.map((member) => {
      return {
        ...member,
        createdAt: member.createdAt ? formatDate(member.createdAt) : "",
        issued: (
          <Checkbox
            checked={listChecked.includes(member.id)}
            onChange={(e) => handleSelectUser(e, member.id)}
          />
        )
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Box component="div" style={{ display: "flex", alignItems: "center" }}>
        <Box
          component="span"
          sx={{
            border: "1px solid #c4c4c4",
            height: 30,
            // backgroundColor: "#1565c0",
            // color: "#fff",
            display: "flex",
            alignItems: "center",
            padding: "0 5px"
          }}
        >
          검색어
        </Box>
        <Select
          value={searchTerms["status"]}
          onChange={(e) => handleChangeSearchTerms("status", e.target.value)}
          style={{
            borderRadius: "0",
            height: "30px",
            width: "170px"
          }}
          MenuProps={{
            disableScrollLock: true
          }}
        >
          {listStatus.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
        <TextField
          size="small"
          sx={{
            "& label": {
              paddingLeft: (theme) => theme.spacing(2)
            },
            "& input": {
              paddingLeft: (theme) => theme.spacing(1),
              width: "200px",
              height: "30px",
              padding: "0 5px"
            },
            "& fieldset": {
              paddingLeft: (theme) => theme.spacing(2.5),
              borderRadius: 0
            }
          }}
          value={searchTerms["search"]}
          onChange={(e) => handleChangeSearchTerms("search", e.target.value)}
        />
        {/* <Grid item style={{ marginLeft: "5px" }}>
          <Grid container alignItems="center">
            <FormControl sx={{ m: 1 }} size="small">
              <Box
                sx={{
                  fontSize: "0.7rem",
                  height: 30,
                  backgroundColor: "#1565c0",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 10px",
                }}
              >
                Membership level
              </Box>
            </FormControl>
            <FormControl>
              <Grid
                container
                alignItems="center"
                style={{
                  border: "1px solid rgb(118, 118, 118)",
                  height: "30px",
                  padding: "0 0 0 10px",
                }}
              >
                {listLevels.map((level) => (
                  <FormControlLabel
                    key={level.value}
                    control={
                      <Checkbox
                        checked={searchTerms["levels"].includes(level.value)}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "0.7rem",
                          },
                        }}
                        onChange={() =>
                          handleChangeSearchTerms("levels", level.value)
                        }
                      />
                    }
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "0.7rem !important",
                      },
                    }}
                    label={level.text}
                  />
                ))}
              </Grid>
            </FormControl>
          </Grid>
        </Grid> */}
        <LoadingButton
          onClick={handleSearch}
          loading={loadingProcess["searching"] ? true : false}
          loadingIndicator="검색"
          variant="contained"
          sx={{ m: 1 }}
          style={{
            textTransform: "capitalize",
            borderRadius: "0",
            height: "30px"
          }}
        >
          검색
        </LoadingButton>
        <LoadingButton
          onClick={handleReset}
          loading={loadingProcess["resetting"] ? true : false}
          loadingIndicator="초기화"
          variant="contained"
          sx={{ mt: 1, mb: 1 }}
          style={{
            textTransform: "capitalize",
            borderRadius: "0",
            height: "30px",
            width: 90
          }}
        >
          초기화
        </LoadingButton>
      </Box>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingOneLine />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>{renderTable()}</Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default withToast(ListManualIssuingMember);
