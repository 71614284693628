import React, { useState } from "react";
import Layout from "components/Layout";
import { ManageEmailService } from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import {
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Button
} from "@mui/material";
import ListEmailAdmin from "./components/ListEmailAdmin";
import ListReceivedMember from "./components/ListReceivedMember";
import { useNavigate } from "react-router-dom";
import Editor from "./components/Editor";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& input": {
    width: width ? width : "400px",
    height: height ? height : "0px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    borderRadius: 0
  }
}));

function EmailAdd({ showToast }) {
  const [email, setEmail] = useState({
    signUp: 0,
    dormantAccount: 0,
    stopAccount: 0,
    shippingInfo: 0,
    name: "",
    content: ""
  });
  const [emailId, setEmailId] = useState("");

  const navigate = useNavigate();

  const handleAdd = async () => {
    try {
      const response = await ManageEmailService.setEmail({
        ...email
      });
      if (response.status === 201) {
        setEmailId(response?.data?.msg?.id ?? "");
        showToast(TOAST_TYPE.SUCCESS, "Add success");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await ManageEmailService.deleteEmail(emailId);
      if (response.status === 201) {
        showToast(TOAST_TYPE.SUCCESS, "Delete success");
        navigate("/e-mail-management");
      } else {
        showToast(TOAST_TYPE.SUCCESS, response?.data?.message ?? "Error");
      }
    } catch (error) {
      const errorStatusText = error?.response?.statusText ?? "";
      const errorDataMessage = error?.response?.data?.message ?? "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  return (
    <Layout header="자동 발송 이메일 관리">
      <Grid container flexDirection="column">
        <Grid item>
          <Box
            style={{
              border: "1px solid #c4c4c4",
              display: "flex",
              flexDirection: "column",
              padding: "10px"
            }}
          >
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              <Typography sx={{ mr: 2 }}>이메일 명</Typography>
              <StyledTextField
                height="0px"
                value={email?.name ? email.name : ""}
                onChange={(e) => {
                  setEmail({ ...email, name: e.target.value });
                }}
              />
            </Grid>
            <Editor data={email?.content} setData={setEmail} />
          </Box>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Button
              onClick={handleAdd}
              variant="contained"
              style={{
                textTransform: "capitalize",
                borderRadius: "0",
                height: "36px"
              }}
            >
              생성하기
            </Button>
            <DeleteSweepIcon
              style={{ cursor: "pointer" }}
              onClick={handleDelete}
            />
          </Grid>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <ListEmailAdmin detailType={email} setDetailType={setEmail} />
        </Grid>
        <Grid item>
          <ListReceivedMember email={email} />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default withToast(EmailAdd);
