import axiosClient from "../axiosClient";
import URL from "./url";

class ManageMerchantService {
  removeMerchantRight(body = {}) {
    const url = URL.REMOVE_MERCHANT_RIGHT;
    return axiosClient.post(url, body);
  }
}

export default new ManageMerchantService();
