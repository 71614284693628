import React, { useState, useEffect, useMemo } from "react";
import Layout from "components/Layout";
import { ManageSponsorService, ManageUploader } from "services";
import { withToast } from "HOC";
import { TOAST_TYPE } from "utils/constants";
import LoadingPage from "components/LoadingPage";
import LoadingButton from "@mui/lab/LoadingButton";
import TableData from "./components/TableData";
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  styled,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl
} from "@mui/material";
import {
  formatDate,
  formatNumber,
  addMonths,
  sortListByDate,
  formatDateSearch,
  formatDateSearchEnd,
  formatDateSearchStart
} from "utils/function";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Excel from "exceljs";
import Popup from "components/Popup";

const filterSearch = (string) => {
  return string
    .trim()
    .replace(/[\s,.-]+/gi, " ")
    .split(" ")
    .map((el) => el?.toLowerCase())
    .filter(Boolean);
};

const isMatchSearchCondition = (keyword, search) => {
  const transformSearch = filterSearch(search || "");
  const keywordList = (typeof keyword === "string" ? [keyword] : keyword).map(
    (el) => el?.toLowerCase()
  );

  if (
    transformSearch
      .filter(Boolean)
      .some((item) => keywordList.some((kw) => kw.includes(item)))
  ) {
    return true;
  }

  if (
    keywordList
      .filter(Boolean)
      .some((item) => transformSearch.some((ts) => ts.includes(item)))
  ) {
    return true;
  }

  return false;
};

const listSortBy = [
  { value: "newest", text: "최신순" },
  { value: "oldest", text: "오래된" }
];

const listSorts = [
  { value: "all", text: "전체" },
  { value: "name", text: "성명" },
  { value: "email", text: "이메일" },
  { value: "status", text: "상태" }
];

const listLevels = [
  { value: "beginner", text: "Beginner" },
  { value: "advanced", text: "Advanced" },
  { value: "expert", text: "Expert" },
  { value: "master", text: "Master" }
];

const mapStatusEnToKo = (status) => {
  switch (status) {
    case "serving":
      return "게재중";
    case "closed":
      return "게재종료";
    default:
      return "";
  }
};

const mapStatusKoToEn = (activity) => {
  const newActivity = activity ? activity.trim() : "";
  let result = [];
  if (isMatchSearchCondition(["게재중", "게재", "중"], newActivity)) {
    result.push("serving");
  }
  if (isMatchSearchCondition(["게재종료", "게재", "종료"], newActivity)) {
    result.push("closed");
  }
  if (["게재중"].includes(newActivity)) {
    result = ["serving"];
  }
  if (["게재종료", "게재종"].includes(newActivity)) {
    result = ["closed"];
  }
  return result.filter(Boolean).join(",");
};

const InputLabel = styled(Box)({
  fontSize: "0.7rem",
  height: "30px",
  backgroundColor: "#1565c0",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 2px",
  minWidth: "76px"
});

const StyledTextField = styled(TextField)(({ height, width }) => ({
  "& label": {
    paddingLeft: (theme) => theme.spacing(2)
  },
  "& input": {
    width: width ? width : "120px",
    height: height ? height : "13px"
  },
  "& input:focus": {
    outline: "none"
  },
  "& fieldset": {
    paddingLeft: (theme) => theme.spacing(2.5),
    borderRadius: 0
  }
}));

function SponsorList({ showToast }) {
  const [listTransaction, setListTransaction] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [searchTerms, setSearchTerms] = useState({
    type: "all",
    search: "",
    levels: []
  });
  const [loadingProcess, setLoadingProcess] = useState({
    searching: false,
    resetting: false
  });

  const [isDownloadFilter, setIsDownloadFilter] = useState(false);
  const [openPopupAll, setOpenPopupAll] = useState(false);
  const [openPopupFilter, setOpenPopupFilter] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);

  const handleCreateCondition = (isSearching = false, isResetting = false) => {
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = {};
    if (isSearching || isResetting) {
      setPage(0);
    }
    if (isResetting) {
      return rowsPerPage !== -1 ? { page: 1, limit: rowsPerPage } : {};
    }
    if (rowsPerPage !== -1) {
      condition = {
        ...condition,
        page: isSearching ? 1 : page + 1,
        limit: rowsPerPage
      };
    }
    if (type !== "status" && type !== "all" && type !== "") {
      condition = {
        ...condition,
        type: type ? type : "all",
        search: search ? search.trim() : ""
      };
    } else if (
      search &&
      search.trim() &&
      (type === "all" || type === "") &&
      mapStatusKoToEn(search.trim())
    ) {
      condition = { ...condition, status: mapStatusKoToEn(search.trim()) };
    } else if (
      (type === "all" || type === "") &&
      search &&
      search.trim() &&
      !mapStatusKoToEn(search.trim())
    ) {
      condition = {
        ...condition,
        type: "all",
        search: search ? search.trim() : ""
      };
    } else if (search && search.trim()) {
      condition = {
        ...condition
      };
      if (type === "status") {
        condition = {
          ...condition,
          status: mapStatusKoToEn(search.trim()) || search.trim()
        };
      }
    }
    if (levels.length > 0) {
      condition = { ...condition, levels: levels.join(",") };
    }
    if (dateFrom) {
      condition = {
        ...condition,
        from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
      };
    }

    if (dateTo) {
      condition = {
        ...condition,
        to: formatDateSearchEnd(dateTo, "YYYY-MM-DD")
      };
    }

    return condition;
  };

  async function fetchData(isSearching = false, isResetting = false) {
    try {
      const response = await ManageUploader.getSponsorsList(
        handleCreateCondition(isSearching, isResetting)
      );
      if (response?.data?.statusCode === 200) {
        setListTransaction(response?.data ? response.data?.data : [] ?? []);
        setCount(response?.data?.count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
        const errorData = response?.data
          ? response.data?.message
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoading(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? error.message ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    setLoadingProcess({ ...loadingProcess, searching: true });
    await fetchData(true, false);
    setLoadingProcess({ ...loadingProcess, searching: false });
  };
  const handleReset = async () => {
    setLoadingProcess({ ...loadingProcess, resetting: true });
    setSearchTerms({ type: "all", search: "", levels: [] });
    setDateFrom(null);
    setDateTo(null);
    await fetchData(false, true);
    setLoadingProcess({ ...loadingProcess, resetting: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleCreateExcel = async (patterns) => {
    const fileName = "Sponsor AD Item 목록";
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("Sponsor AD Item 목록");

    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "등급",
      "패턴",
      "Profit",
      "Paypal",
      "카드",
      "광고시작일",
      "광고만료일",
      "상태"
    ];

    const columnExcel = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];
    const columnWidth = [10, 20, 40, 20, 20, 20, 20, 20, 20, 20, 20];

    //columnWidth && header style, value cell //header
    columnExcel.forEach((column, index) => {
      ws.getColumn(index + 1).width = columnWidth[index];
      ws.getCell(`${column}1`).style = {
        font: { bold: true },
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`${column}1`).value = headersTitle[index];
    });

    const generateClass = (region, make, year, model, submodel, side) => {
      const nullValue = (value) => (value ? value : "");
      return `${nullValue(region)} - ${nullValue(make)} - ${nullValue(
        year
      )} - ${nullValue(model)} - ${nullValue(submodel)} - ${nullValue(side)}`;
    };

    // const dataExcel = patterns.map((pattern) => {
    //   return {
    //     ...pattern,
    //     patternCode: generateClass(
    //       pattern.region,
    //       pattern.make,
    //       pattern.year,
    //       pattern.model,
    //       pattern.submodel,
    //       pattern.side
    //     ),
    //     profit: pattern.profit ? formatNumber(Number(pattern.profit)) : "",
    //     credit: pattern.credit ? formatNumber(Number(pattern.credit)) : "",
    //     paypal: pattern.paypal ? formatNumber(Number(pattern.paypal)) : "",
    //     begin: pattern.start ? formatDate(pattern.start) : "",
    //     end: pattern.end ? formatDate(pattern.end) : ""
    //   };
    // });

    const dataExcel = patterns.map((pattern, index) => {
      return {
        ...pattern,
        index: index + 1,
        name: pattern?.pattern?.user?.nickName ?? "",
        email: pattern?.pattern?.user?.email ?? "",
        rating: pattern?.pattern?.user?.level ?? "",
        pattern: pattern?.pattern?.id ?? "",
        profit: pattern?.type === "profit" ? pattern?.price : "",
        paypal: pattern?.type === "paypal" ? pattern?.price : "",
        card: pattern?.type === "card" ? pattern?.price : "",
        advertisementStartDate: pattern?.start
          ? formatDate(pattern?.start)
          : "",
        adExpiryDate: pattern?.end ? formatDate(pattern?.end) : "",
        status: pattern?.status ?? ""
      };
    });

    // add value, style cell // body
    dataExcel.forEach((sponsor, index) => {
      ws.getCell(`A${index + 2}`).value = sponsor.index;
      ws.getCell(`A${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`B${index + 2}`).value = sponsor.name ? sponsor.name : "";
      ws.getCell(`B${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`C${index + 2}`).value = sponsor.email;
      ws.getCell(`C${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`D${index + 2}`).value = sponsor.rating;
      ws.getCell(`D${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`E${index + 2}`).value = sponsor.pattern;

      ws.getCell(`E${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`F${index + 2}`).value = sponsor.profit;
      ws.getCell(`F${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`G${index + 2}`).value = sponsor.paypal;

      ws.getCell(`G${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`H${index + 2}`).value = sponsor.card;

      ws.getCell(`H${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`I${index + 2}`).value = sponsor.advertisementStartDate;
      ws.getCell(`I${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`J${index + 2}`).value = sponsor.adExpiryDate;
      ws.getCell(`J${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
      ws.getCell(`K${index + 2}`).value = sponsor.status;
      ws.getCell(`K${index + 2}`).style = {
        alignment: { vertical: "center", horizontal: "center" }
      };
    });

    const buffer = await wb.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    link.remove();
  };

  const handleDownloadExcel = async () => {
    const { type = "", search = "", levels = [] } = searchTerms;
    let condition = {};
    if (isDownloadFilter) {
      if (type !== "status" && type !== "all" && type !== "") {
        condition = {
          ...condition,
          type: type ? type : "all",
          search: search ? search.trim() : ""
        };
      } else if (
        search &&
        search.trim() &&
        (type === "all" || type === "") &&
        mapStatusKoToEn(search.trim())
      ) {
        condition = { ...condition, status: mapStatusKoToEn(search.trim()) };
      } else if (
        (type === "all" || type === "") &&
        search &&
        search.trim() &&
        !mapStatusKoToEn(search.trim())
      ) {
        condition = {
          ...condition,
          type: "all",
          search: search ? search.trim() : ""
        };
      } else if (search && search.trim()) {
        condition = {
          ...condition
        };
        if (type === "status") {
          condition = {
            ...condition,
            status: mapStatusKoToEn(search.trim()) || search.trim()
          };
        }
      }
      if (levels.length > 0) {
        condition = { ...condition, levels: levels.join(",") };
      }
      if (dateFrom) {
        condition = {
          ...condition,
          from: formatDateSearchStart(dateFrom, "YYYY-MM-DD HH:mm:ss")
        };
      }

      if (dateTo) {
        condition = {
          ...condition,
          to: formatDateSearchEnd(dateTo, "YYYY-MM-DD")
        };
      }
    }
    let listPatterns = [];
    setLoadingProcess({ ...loadingProcess, downloading: true });
    try {
      const response = await ManageUploader.getSponsorsList(condition);
      if (response?.data?.statusCode === 200) {
        const patterns = response?.data?.data ?? [];
        listPatterns = [...patterns];
        handleCreateExcel(listPatterns);
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
      } else {
        setLoadingProcess({ ...loadingProcess, downloading: false });
        setOpenPopupAll(false);
        setOpenPopupFilter(false);
        const errorData = response?.data
          ? response.data?.msg
          : "Error" ?? "Error";
        showToast(TOAST_TYPE.ERROR, errorData);
      }
    } catch (error) {
      setLoadingProcess({ ...loadingProcess, downloading: false });
      setOpenPopupAll(false);
      setOpenPopupFilter(false);
      const errorStatusText = error?.response ? error.response?.statusText : "";
      const errorDataMessage = error?.response
        ? error.response?.data
          ? error.response.data?.message
          : ""
        : "";
      const errorMessage =
        errorDataMessage ?? errorStatusText ?? error.message ?? "System Error";
      showToast(TOAST_TYPE.ERROR, errorMessage);
    }
  };

  const handleChangeSearchTerms = (key, value) => {
    if (key === "levels") {
      const levels = searchTerms["levels"];
      const newLevels = levels.includes(value)
        ? levels.filter((level) => level !== value)
        : [...levels, value];
      setSearchTerms((prevState) => ({ ...prevState, levels: newLevels }));
    }
    if (key === "type") {
      setSearchTerms((prevState) => ({ ...prevState, type: value }));
    }
    if (key === "search") {
      setSearchTerms((prevState) => ({ ...prevState, search: value }));
    }
  };

  const renderTable = () => {
    const sizeWidth = [
      "3%",
      "8%",
      "20%",
      "8%",
      "12",
      "7%",
      "7%",
      "7%",
      "10%",
      "10%",
      "8%"
    ];
    const headersTitle = [
      "NO.",
      "성명",
      "이메일",
      "등급",
      "패턴",
      "Profit",
      "Paypal",
      "카드",
      "광고시작일",
      "광고만료일",
      "상태"
    ];

    const generateClass = (region, make, year, model, submodel, side) => {
      const nullValue = (value) => (value ? value : "");
      return `${nullValue(region)} - ${nullValue(make)} - ${nullValue(
        year
      )} - ${nullValue(model)} - ${nullValue(submodel)} - ${nullValue(side)}`;
    };

    const getCategory = (pattern) => {
      const listModels = pattern?.models ?? [];
      const listModelsCategory = listModels.map((item) => item.category);
      const listVehicles = pattern?.vehicles ?? [];
      const listVehiclesCategory = listVehicles.map((item) => item.category);
      return [...listModelsCategory, ...listVehiclesCategory].join(" - ");
    };

    const tableData = listTransaction.map((transaction, index) => {
      return {
        ...transaction,
        // patternCode: generateClass(
        //   pattern.region,
        //   pattern.make,
        //   pattern.year,
        //   pattern.model,
        //   pattern.submodel,
        //   pattern.side
        // ),
        // profit: pattern.profit ? formatNumber(Number(pattern.profit)) : "",
        // credit: pattern.credit ? formatNumber(Number(pattern.credit)) : "",
        // paypal: pattern.paypal ? formatNumber(Number(pattern.paypal)) : "",
        // begin: pattern.start ? formatDate(pattern.start) : "",
        // end: pattern.end ? formatDate(pattern.end) : ""
        index: index + 1 + rowsPerPage * page,
        name: transaction?.pattern?.user?.nickName ?? "",
        email: transaction?.pattern?.user?.email ?? "",
        rating: transaction?.pattern?.user?.level ?? "",
        pattern: getCategory(transaction?.pattern ?? ""),
        profit: transaction?.type === "profit" ? transaction?.price : "",
        paypal: transaction?.type === "paypal" ? transaction?.price : "",
        card: transaction?.type === "card" ? transaction?.price : "",
        advertisementStartDate: transaction?.start
          ? formatDate(transaction?.start)
          : "",
        adExpiryDate: transaction?.end ? formatDate(transaction?.end) : "",
        status: mapStatusEnToKo(transaction?.status ?? "")
      };
    });

    return (
      <TableData
        tableData={tableData}
        headers={headersTitle}
        sizeWidth={sizeWidth}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    );
  };

  const renderSearchArea = () => {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item style={{ fontWeight: "bold" }}>
          회원 검색
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item style={{ marginRight: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", mt: 1, mb: 1 }}>
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 2px",
                    minWidth: "76px"
                  }}
                >
                  광고시작일
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateFrom}
                    maxDate={dateTo || new Date("2200-01-01")}
                    onChange={(value) => {
                      setDateFrom(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                  <span>&nbsp;-&nbsp;</span>
                  <DesktopDatePicker
                    inputFormat="yyyy/MM/dd"
                    value={dateTo}
                    minDate={dateFrom || new Date("1970-01-01")}
                    onChange={(value) => {
                      setDateTo(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2)
                          },
                          "& input": {
                            width: "80px",
                            height: "13px"
                          },
                          "& fieldset": {
                            paddingLeft: (theme) => theme.spacing(2.5),
                            borderRadius: 0
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item style={{ marginRight: "20px" }}>
              <Grid container alignItems="center">
                <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      height: 30,
                      backgroundColor: "#1565c0",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px"
                    }}
                  >
                    회원등급
                  </Box>
                </FormControl>
                <FormControl>
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      border: "1px solid rgb(118, 118, 118)",
                      height: "30px",
                      padding: "0 0 0 20px"
                    }}
                  >
                    {listLevels.map((level) => (
                      <FormControlLabel
                        key={level.value}
                        control={
                          <Checkbox
                            checked={searchTerms["levels"].includes(
                              level.value
                            )}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "0.7rem"
                              }
                            }}
                            onChange={() =>
                              handleChangeSearchTerms("levels", level.value)
                            }
                          />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "0.7rem !important"
                          }
                        }}
                        label={level.text}
                      />
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Box
                  sx={{
                    fontSize: "0.7rem",
                    height: 30,
                    backgroundColor: "#1565c0",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  검색어
                </Box>
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }} size="small">
                <Select
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={searchTerms["type"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("type", e.target.value)
                  }
                  style={{
                    borderRadius: "0",
                    height: "30px"
                    // width: "90px"
                  }}
                >
                  {listSorts.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small">
                <TextField
                  size="small"
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2)
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5)
                    },
                    "& fieldset": {
                      paddingLeft: (theme) => theme.spacing(2.5),
                      borderRadius: 0
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "30px",
                      padding: "0 5px",
                      borderRadius: 0,
                      outline: "none",
                      width: "300px"
                    }
                  }}
                  value={searchTerms["search"]}
                  onChange={(e) =>
                    handleChangeSearchTerms("search", e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Stack spacing={2} direction="row">
              <LoadingButton
                onClick={handleSearch}
                loading={loadingProcess["searching"] ? true : false}
                loadingIndicator="검색"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0"
                }}
              >
                검색
              </LoadingButton>
              <LoadingButton
                onClick={handleReset}
                loading={loadingProcess["resetting"] ? true : false}
                loadingIndicator="초기화"
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "0",
                  width: 90
                }}
              >
                초기화
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout header="Sponsor AD Item 목록">
      {loading ? (
        <LoadingPage />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>{renderSearchArea()}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              {/* <Grid item>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Typography sx={{ mr: 2 }}>정렬기준</Typography>
                  <Select
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      borderRadius: "0",
                      height: "30px",
                      width: "120px"
                    }}
                  >
                    {listSortBy.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid> */}
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDownloadFilter}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "0.7rem"
                            }
                          }}
                          onChange={() =>
                            setIsDownloadFilter(!isDownloadFilter)
                          }
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.7rem !important"
                        },
                        marginRight: 0
                      }}
                      label="현재 검색 필터 적용"
                    />
                  </Grid>
                  <Button
                    variant="outline"
                    style={{
                      border: "1px solid #c4c4c4",
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      if (isDownloadFilter) {
                        setOpenPopupAll(false);
                        setOpenPopupFilter(true);
                      } else {
                        setOpenPopupAll(true);
                        setOpenPopupFilter(false);
                      }
                    }}
                  >
                    Excel파일로 다운받기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTable()}</Grid>
          <Popup
            open={openPopupAll}
            setOpen={setOpenPopupAll}
            button="확인"
            message="전체 데이터를 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
          <Popup
            open={openPopupFilter}
            setOpen={setOpenPopupFilter}
            button="확인"
            message="현재 검색필터의 결과값을 엑셀로 받으시겠습니까?"
            onClick={handleDownloadExcel}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default withToast(SponsorList);
