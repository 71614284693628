import axiosClient from "../axiosClient";
import URL from "./url";

class LoginService {
  login(body = {}) {
    const url = URL.LOGIN_IN;
    return axiosClient.post(url, body);
  }
  logout() {
    const url = URL.LOGIN_OUT;
    return axiosClient.post(url);
  }
  getToken(body = {}) {
    const url = URL.GET_TOKEN;
    return axiosClient.post(url, body);
  }
}

export default new LoginService();
