import { Box, Typography } from "@mui/material";

export function Hedaer({ header }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: 120,
        display: "flex",
        alignItems: "center",
        padding: "0 60px",
        border: "1px solid #dddddd",
      }}
    >
      <Typography sx={{ fontWeight: 400, fontSize: 28, lineHeight: "30px" }}>
        {header}
      </Typography>
    </Box>
  );
}
